import { langOptions } from "util/option";
import Select from "..";
import { langProps } from "components/radio/radioEditor";

type IProps = {
  option: string;
  options?: langProps[];
  handleSelect(target: EventTarget & HTMLLIElement): void;
};

const SmallSelect = ({
  option,
  options = langOptions,
  handleSelect,
}: IProps) => {
  return (
    <>
      <Select
        id={"langCd"}
        name={"langCd"}
        wrapStyle=""
        option={option}
        options={options}
        small
        isInitial
        onSelect={handleSelect}
      />
    </>
  );
};

export default SmallSelect;
