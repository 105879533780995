import { lodGrpStatus } from "util/option";
import Select from "components/select";
import { lodGrpSearchProps } from "interface/lod";
import CountrySelect from "components/select/country";
import LodGrpList from "components/tableList/lod/grp";
import { useLocation } from "react-router-dom";
import THeader from "components/layout/header/subHeader/tHeader";
import LodGrpNmSelect from "components/select/room/lodGrpNmSelect ";

type IProps = {
  searchInfo: Omit<lodGrpSearchProps, "pageNo" | "langCd">;
  memType: "admin" | "manager";
  handleChange(target: EventTarget & HTMLInputElement): void;
  handleReset(): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
};

const LodGroupListView = ({
  searchInfo,
  memType,
  handleChange,
  handleReset,
  handleSelect,
}: IProps) => {
  const { pathname } = useLocation();

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader
          sbTtl={"숙소그룹조회"}
          rstBtn
          newBtn
          url={pathname}
          searchBtn
          handleReset={handleReset}
        />

        <div className="tb-form" data-list>
          <ul>
            <li>
              <label htmlFor="">숙소그룹명</label>
              <LodGrpNmSelect
                option={searchInfo.lodGrpNo}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="">숙소그룹주소국가</label>
              <CountrySelect
                option={searchInfo.lodGrpCntyCd}
                handleSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="">숙소그룹상태</label>
              <Select
                options={lodGrpStatus}
                option={searchInfo.uyn || "A"}
                onSelect={(target) => handleSelect(target)}
                id={"uyn"}
                name={"uyn"}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">숙소그룹주소</label>
              <input
                type="text"
                name="lodGrpNm"
                value={searchInfo.lodGrpNm}
                data-w-m
                onChange={(e) => handleChange(e.currentTarget)}
              />
            </li>
          </ul>
        </div>
      </div>
      <LodGrpList memType={memType} searchInfo={searchInfo} />
    </>
  );
};

export default LodGroupListView;
