import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { lodManDtlApi, lodManModifyApi } from "components/api/lod";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import PhoneSelect from "components/select/phone";
import { lodManProps } from "interface/lod";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";

type IProps = {
  manDtl: lodManProps;
};

const ManModifyView = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ["lodManDtl"],
    queryFn: () => {
      return lodManDtlApi({
        lodNo: search.replace("?lodNo=", ""),
      });
    },
  });

  const manDtl = query?.data?.body?.lodManDtl || {};

  const [inf, setInf] = useState<lodManProps>({
    rgSeq: manDtl?.rgSeq ?? "",
    lodNo: search?.replace("?lodNo=", ""),
    manMkV: manDtl?.manMkV ?? "",
    manGrd: "",
    manFstnm: "",
    manSurnm: "",
    telCntyCd: "",
    telNo: "",
    uyn: "",
  });
  const handleChange = (name: string, value: string) => {
    setInf({
      ...inf,
      [name]: value,
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setInf({
        ...inf,
        [name]: value,
      });
    }
  };

  const manMutation = useMutation(lodManModifyApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "담당자정보가 수정되었습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                queryClient.invalidateQueries(["lodManDtl"]);
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModify = () => {
    const { lodNo, rgSeq, manMkV, ...res } = inf;
    const isAble = Object.values(res).some((able) => {
      return !!able;
    });

    if (!isAble) {
      return alert("담당자 정보를 수정하여 주세요.");
    }

    dispatch(
      setMessage({
        message: "담당자정보를 수정하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          manMutation.mutate(inf);
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  const [infOpen, setInfOpen] = useState(false);

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"담당자정보"}
          clsBtn
          mdfyBtn
          isClose={infOpen}
          handleModify={handleModify}
          handleClose={() => {
            setInfOpen(!infOpen);
          }}
        />
        <div className={infOpen ? "" : "hidden"}>
          <ul>
            <li>
              <label htmlFor="">담당자 영문이름</label>
              <input
                type="text"
                name="manFstnm"
                defaultValue={inf.manFstnm || manDtl?.manFstnm}
                onChange={(e) =>
                  handleChange("manFstnm", e.currentTarget.value)
                }
              />
            </li>
            <li>
              <label htmlFor="">담당자 영문성</label>
              <input
                type="text"
                name="manSurnm"
                defaultValue={inf.manSurnm || manDtl?.manSurnm}
                onChange={(e) =>
                  handleChange("manSurnm", e.currentTarget.value)
                }
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">담당자 직급</label>
              <input
                type="text"
                name="manGrd"
                defaultValue={inf.manGrd || manDtl?.manGrd}
                onChange={(e) => handleChange("manGrd", e.currentTarget.value)}
              />
            </li>
            <li>
              <label htmlFor="phone">담당자 전화번호</label>
              <div className="flex gap-x-1 relative">
                <PhoneSelect
                  wrapStyle=""
                  name="telCntyCd"
                  isNm
                  mobCd={inf.telCntyCd || manDtl?.telCntyCd}
                  handleSelect={(target) => handleSelect(target)}
                />
                <span className="flex gap-x-1 w-full">
                  <input
                    type="text"
                    name="telNo"
                    defaultValue={inf.telNo || manDtl?.telNo}
                    onChange={(e) =>
                      handleChange("telNo", e.currentTarget.value)
                    }
                  />
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ManModifyView;
