import "./home.scss";
import { Link, useLocation } from "react-router-dom";
import ChkInList from "./chkInList";
import PayInfoList from "./payInfoList";
import THeader from "components/layout/header/subHeader/tHeader";
import LodGrpNmSelect from "components/select/room/lodGrpNmSelect ";
import LodNmSelect from "components/select/room/lodNmSelect";

type IProps = {
  lodNo: string;
  data: any;
  handleSelect(target: EventTarget & HTMLLIElement): void;
};

const HomeView = ({ lodNo, data, handleSelect }: IProps) => {
  const { pathname } = useLocation();

  const manInf = localStorage.getItem("manInf")
    ? JSON.parse(localStorage.getItem("manInf") ?? "")
    : "";

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader sbTtl={"홈"} url={pathname} handleReset={() => {}} />
        <div className="home-wrap">
          <div className="flex gap-4">
            <div className="card">
              <div className="flex justify-between items-end">
                <h4>공지사항</h4>
                <Link
                  to={`board`}
                  onClick={(e) => {
                    alert("페이지 준비중입니다.");
                    e.preventDefault();
                  }}
                >
                  더보기
                </Link>
              </div>

              <ul>
                <li>
                  <Link
                    to={`board`}
                    onClick={(e) => {
                      alert("페이지 준비중입니다.");
                      e.preventDefault();
                    }}
                  >
                    9월 회계 마감 안내
                  </Link>
                </li>
                <li>
                  <Link
                    to={`board`}
                    onClick={(e) => {
                      alert("페이지 준비중입니다.");
                      e.preventDefault();
                    }}
                  >
                    9월 회계 마감 안내
                  </Link>
                </li>
              </ul>
            </div>
            <div className="card">
              <div>
                <h4>
                  {manInf?.manSurnm} {manInf?.manFstnm}
                  <span className="text-[0.625rem] text-red-350 ps-2 font-bold">
                    (* 숙소 검색결과가 없는경우 관리자에서 숙소를 등록하여
                    주세요.)
                  </span>
                </h4>
              </div>
              <ul>
                <li className="flex items-center gap-x-1 ">
                  <label htmlFor="" className="w-[7.5rem]">
                    숙소그룹
                  </label>
                  <LodGrpNmSelect
                    option={manInf.manLodGrpNo}
                    onSelect={() => {}}
                    disabled
                    isArrow
                    wrapStyle="w-full flex-1"
                  />
                </li>
                <li className="flex items-center gap-x-1">
                  <label htmlFor="" className="w-[7.5rem]">
                    숙소
                  </label>
                  <LodNmSelect
                    option={lodNo ?? ""}
                    wrapStyle="w-[12.5rem] flex-1"
                    params={manInf.manLodGrpNo}
                    onSelect={handleSelect}
                  />
                  {/* <Select
                    wrapStyle="w-[12.5rem]"
                    id="lodNo"
                    name="lodNo"
                    option={lodNo ?? sltLodNo + "A"}
                    options={lodList}
                    onSelect={(target) => {
                      handleSelect(target);
                    }}
                  /> */}
                </li>
              </ul>
            </div>
          </div>
          <div className="pt-4 max-w-[55rem]">
            <h3 className="mb-2">예약현황</h3>
            <strong className={!!lodNo ? "hidden" : "text-xs text-red-350"}>
              * 예약현황을 확인하시려면 숙소를 선택하여 주세요
            </strong>
            <div className={!!lodNo ? `` : "hidden"}>
              <PayInfoList lodNo={lodNo} />
              <ChkInList lodNo={lodNo} list={[]} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeView;
