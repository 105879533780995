import { IPayInfo } from "interface/home";
import { Suspense, useState } from "react";
import { homePayInfoHead } from "util/option";
import { useMutation } from "@tanstack/react-query";
import { homeManagerDayPayInfoApi } from "components/api/home";
import { useDispatch } from "react-redux";
import { setIsLoading, setMessage } from "store/commonSlice";
import { dateFormat, inCom } from "util/common";

type IProps = {
  payInfo: IPayInfo | null;
};

const PayInfo = ({ payInfo }: IProps) => {
  return (
    <>
      <tr>
        <td>{dateFormat(payInfo?.resvDt ?? "")}</td>
        <td>{payInfo?.resvCt}</td>
        <td>{inCom(payInfo?.resvPayAmt ?? "")}</td>
        <td>{inCom(payInfo?.resvCnlAmt ?? "")}</td>
        <td>{inCom(payInfo?.resvCalAmt ?? "")}</td>
      </tr>
    </>
  );
};

const PayInfoList = ({ lodNo }: { lodNo: string }) => {
  const dispatch = useDispatch();
  const now = new Date();

  const [info, setInfo] = useState<IPayInfo | null>(null);
  const payInfoMutation = useMutation(homeManagerDayPayInfoApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setInfo(body?.dayPayInfo);
          dispatch(setIsLoading(false));
        }
        if (code === -1) {
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });
  const handleReload = () => {
    dispatch(setIsLoading(true));
    const date = now.toISOString().split("T")[0].replaceAll("-", "");
    const params = {
      lodNo,
      date,
    };
    payInfoMutation.mutate(params);
  };

  return (
    <div className="relative pb-[1.25rem]">
      <div className="flex justify-between">
        <h4>오늘 예약 현황</h4>
        <button onClick={handleReload}>더보기</button>
      </div>
      <table>
        <thead>
          <tr>
            {homePayInfoHead.map((bk) => (
              <th key={bk}>{bk}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <Suspense>
            <PayInfo payInfo={info} />
          </Suspense>
        </tbody>
      </table>
    </div>
  );
};

export default PayInfoList;
