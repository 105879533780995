import THeader from "components/layout/header/subHeader/tHeader";
import InfCreate from "./infCreate";
import AdInfCreate from "./adInfCreate";
import { rmOptCreateProps } from "interface/room";

type IProps = {
  opInf: rmOptCreateProps;
  handleAdd(adInf: Pick<rmOptCreateProps, "rmOpInfList">): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleCreate(): void;
};

const RmOpCreate = ({
  opInf,
  handleAdd,
  handleSelect,
  handleCreate,
}: IProps) => {
  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader
          sbTtl={"객실옵션 생성"}
          clsBtn
          first
          isBack
          handleReset={() => {}}
        >
          <button
            className="btn-sm bg-red-400 text-white-0"
            onClick={handleCreate}
          >
            등록
          </button>
        </THeader>
        <InfCreate
          opInf={opInf}
          handleSelect={handleSelect}
          handleCreate={handleCreate}
        />
        <AdInfCreate adList={opInf.rmOpInfList} handleAdd={handleAdd} />
      </div>
    </>
  );
};

export default RmOpCreate;
