import { IlodGrpAddInf, IlodGrpDtl } from "interface/lod";
import { useLocation } from "react-router-dom";
import InfModifyView from "./infModify";
import AdInfModifyView from "./adInfModify";
import THeader from "components/layout/header/subHeader/tHeader";

type IProps = {
  lodGrpDtl: IlodGrpDtl;
  lodGrpAdList: Array<IlodGrpAddInf>;
  inf: any;
};

const LodGrpModifyView = ({ lodGrpDtl, lodGrpAdList }: IProps) => {
  return (
    <div className="main-cont" data-dir-col>
      <THeader sbTtl={"숙소그룹수정"} clsBtn handleReset={() => {}} />
      <InfModifyView lodGrpDtl={lodGrpDtl} />
      <AdInfModifyView lodGrpAddInf={lodGrpAdList} />
    </div>
  );
};

export default LodGrpModifyView;
