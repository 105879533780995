import THeader from "components/layout/header/subHeader/tHeader";
import AdInfCreate from "./adInfCreate";
import { MutableRefObject } from "react";
import ImgCreate from "./imgCreate";
import { IRmInfo } from "interface/room";
import { imgList } from "interface";
import InfCreate from "./infCreate";

type IProsp = {
  data: any;
  rmNoRef: MutableRefObject<string>;
};
const RmCreateView = ({ data, rmNoRef }: IProsp) => {
  let rmDtl = data?.rmDtl || ({} as IRmInfo);
  let rmAddInfList = data?.addInfList || [];
  let rmImgList = data?.rmImgList || ([] as imgList[]);

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader sbTtl={"객실생성"} clsBtn handleReset={() => {}} />
        <InfCreate rmNoRef={rmNoRef} rmDtl={rmDtl} />
        <AdInfCreate rmNoRef={rmNoRef} adList={rmAddInfList} />
        <ImgCreate rmNoRef={rmNoRef} imgList={rmImgList} />
      </div>
    </>
  );
};

export default RmCreateView;
