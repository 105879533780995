import { rmBkCnlListHead } from "util/option";
import { useLocation, useNavigate } from "react-router-dom";
import Pagenation from "components/pagenation";
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  isSearchSelector,
  setIsLoading,
  setIsSearch,
  setMessage,
} from "store/commonSlice";
import { rmResvCnlSearchProps } from "interface/room";
import { pageNavProps } from "interface";
import { rmResvCnlListApi } from "components/api/room";
import { dateFormat, inCom } from "util/common";
import SearchHeader from "components/layout/header/subHeader/srchHeader";

type IListProps = {
  searchInfo: rmResvCnlSearchProps;
  memType: "admin" | "manager";
  col: number;
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};

const List = ({ searchInfo, memType, col, setPageNav }: IListProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();
  const isSearch = useSelector(isSearchSelector);

  const [list, setList] = useState([]);

  const rmResvMutation = useMutation(rmResvCnlListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setPageNav(body?.pageNav);
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));
          setList(body?.rmResvCnlList);
        }
        if (code === -1) {
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));
          dispatch(
            setMessage({
              message,
            })
          );
        }
      }
    },
  });

  let page = search?.replace("?page=", "") || null;

  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch) {
      const {
        sellEDt,
        sellSDt,
        cnlEDt,
        cnlSDt,
        resvEDt,
        resvSDt,
        resvCstFstnm,
        resvCstMblNo,
        memMobNo,
        memFstnm,
        dateTyp,
      } = searchInfo;

      const seSDt =
        sellSDt?.replaceAll("-", "") ?? state?.sellSDt?.replaceAll("-", "");
      const params = {
        sellSDt:
          dateTyp === "rmPdDt"
            ? state?.sellSDt.replaceAll("-", "") ?? sellSDt.replaceAll("-", "")
            : "",
        sellEDt:
          dateTyp === "rmPdDt"
            ? state?.sellEDt?.replaceAll("-", "") ??
              sellEDt?.replaceAll("-", "")
            : "",
        cnlSDt:
          dateTyp === "rmCnlResvDt"
            ? state?.cnlSDt?.replaceAll("-", "") ?? cnlSDt?.replaceAll("-", "")
            : "",
        cnlEDt:
          dateTyp === "rmCnlResvDt"
            ? state?.cnlEDt?.replaceAll("-", "") ?? cnlEDt?.replaceAll("-", "")
            : "",
        resvSDt:
          dateTyp === '"rmResvDt"'
            ? state?.resvSDt?.replaceAll("-", "") ??
              resvSDt?.replaceAll("-", "")
            : "",
        resvEDt:
          dateTyp === '"rmResvDt"'
            ? state?.resvEDt?.replaceAll("-", "") ??
              resvEDt?.replaceAll("-", "")
            : "",
        resvCstFstnm: resvCstFstnm ?? state?.resvCstFstnm,
        resvCstMblNo: resvCstMblNo ?? state?.resvCstMblNo,
        memMobNo: memMobNo ?? state?.memMobNo,
        memFstnm: memFstnm ?? state?.memFstnm,
        pageNo: search?.replace("?page=", "") || state.pageNo,
      };

      rmResvMutation.mutate(params);
    }
  }, [isSearch, searchInfo, search]);

  const pageRows = rmResvMutation.data?.body?.pageNav?.pageRows;
  const isNotData = !pageRows;
  return (
    <>
      {list.length === 0 && (
        <tr>
          <td colSpan={col}>
            {isNotData && (
              <span className="font-bold">검색조건을 선택하여 주세요.</span>
            )}
            {!isNotData && (
              <span className="font-bold">검색결과가 없습니다.</span>
            )}
          </td>
        </tr>
      )}
      {list &&
        list?.map((li: any, index: number) => {
          return (
            <tr
              key={index}
              onClick={() => {
                navigate(`info?rmResvNo=${li.rmResvNo}`, {
                  state: {
                    rmNo: li.rmNo,
                    rmPdNo: li.rmPdNo,
                    rmResvNo: li.rmResvNo,
                    searchInfo: searchInfo,
                    url: pathname + search,
                  },
                });
              }}
            >
              <td>{(Number(page) - 1) * (pageRows || 10) + index + 1}</td>
              {memType === "admin" && <td>{li.lodGrpNo}</td>}
              {memType === "admin" && <td>{li.lodGrpNm}</td>}
              {memType === "admin" && <td>{li.lodNo}</td>}
              {memType === "admin" && <td>{li.lodNm}</td>}
              <td>{li.rmNo}</td>
              <td>{li.rmNm}</td>
              <td>{li.rmTypNm}</td>
              <td>{li.rmOlkNm}</td>
              <td>{li.rmPdNo}</td>
              <td>{li.lodChlNm}</td>
              <td>{li.rmOpNm}</td>
              <td>{dateFormat(li.sellDt)}</td>
              <td>
                <div>
                  {dateFormat(li?.resvDtti?.slice(0, 8))} <span>/</span>
                </div>
                <div>{dateFormat(li?.cnlDtti?.slice(0, 8))}</div>
              </td>
              <td>{li.rmResvNo}</td>
              <td>{li.resvCstSurnm + li.resvCstFstnm}</td>
              <td>{li.resvCstMblNo}</td>
              <td>{li.resvCstSurnm + li.resvCstFstnm}</td>
              <td>{li.resvCstMblNo}</td>
              <td>
                <div>
                  {inCom(li.payAmt)} <span>/</span>
                </div>
                <div>{inCom(li.refAmt)}</div>
              </td>
            </tr>
          );
        })}
    </>
  );
};

type IProps = {
  memType: "admin" | "manager";
  searchInfo: rmResvCnlSearchProps;
};

const RoomBookCancelList = ({ memType, searchInfo }: IProps) => {
  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const headList = rmBkCnlListHead.map((head) => {
    return head;
  });

  const [No, lodGrpNo, lodGrpNm, lodNo, lodNm, ...res] = headList;
  const col = memType === "admin" ? rmBkCnlListHead.length : res.length + 1;

  return (
    <div className="list-wrap">
      <SearchHeader pageNav={pageNav} />
      <div>
        <table>
          <thead>
            {memType === "admin" && (
              <tr>
                {headList.map((li) => (
                  <th key={li}>{li}</th>
                ))}
              </tr>
            )}
            {memType === "manager" && (
              <tr>
                {[No, ...res].map((li) => (
                  <th key={li}>{li}</th>
                ))}
              </tr>
            )}
          </thead>

          <tbody>
            <Suspense>
              <List
                searchInfo={searchInfo}
                memType={memType}
                col={col}
                setPageNav={setPageNav}
              />
            </Suspense>
            {pageNav && pageNav?.totalRows > 0 && (
              <tr>
                <td colSpan={col}>
                  <Pagenation total={pageNav?.totalPage} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RoomBookCancelList;
