import { useMutation } from "@tanstack/react-query";
import { rmCreateApi } from "components/api/room";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import UYNRadio from "components/radio/uynRadio";
import BedTypeSelect from "components/select/room/bedTypSelect";
import LodGrpNmSelect from "components/select/room/lodGrpNmSelect ";
import LodNmSelect from "components/select/room/lodNmSelect";
import RmOlkSelect from "components/select/room/rmOlkSelect";
import RmTypeSelect from "components/select/room/rmTypSelect";
import { IRmInfo, rmCreateProps } from "interface/room";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setIsSearch, setMessage } from "store/commonSlice";

type IProsp = {
  rmDtl: IRmInfo;
  rmNoRef: MutableRefObject<string>;
};

const InfCreate = ({ rmNoRef, rmDtl }: IProsp) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isAbleRef = useRef(false);
  const memType = pathname.includes("admin") ? "admin" : "manager";

  let lodGrpInfo =
    memType === "manager"
      ? JSON.parse(localStorage.getItem("lodGrpInfo") + "")
      : "";

  const [inf, setInf] = useState<rmCreateProps>({
    lodGrpNo: lodGrpInfo?.lodGrpNo,
    lodGrpNm: lodGrpInfo?.lodGrpNm,
    lodNo: localStorage.getItem("sltLodNo") ?? "",
    lodNm: lodGrpInfo?.lodNm,
    rmTypCd: "",
    rmOlkCd: "",
    rmAreaDesc: "",
    bedTypCd: "",
    rmMinPerCt: 1,
    rmMaxPerCt: 2,
    babyFreePerCt: null,
    chldFreePerCt: null,
    uyn: "Y",
  });

  const [isOpen, setIsOpen] = useState(true);

  const handleChange = (name: string, value: string) => {
    setInf({
      ...inf,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setInf({
        ...inf,
        [name]: value,
      });
    }
  };

  //객실 생성
  const rmCreateMutation = useMutation(rmCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: `객실을 생성하였습니다./생성된 객실번호는 ${body?.rmNo} 입니다.`,
              isConfirm: true,
              handleClick() {
                rmNoRef.current = body?.rmNo;
                dispatch(setIsSearch(true));
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleInfCreate = () => {
    const params = {
      ...inf,
      rmTypcd: inf.rmTypCd === "A" ? "" : inf.rmTypCd,
      rmOlkCd: inf.rmOlkCd === "A" ? "" : inf.rmOlkCd,
      bedTypCd: inf.bedTypCd === "A" ? "" : inf.bedTypCd,
    };
    if (!params.lodGrpNo && pathname.includes("admin")) {
      return alert("숙소그룹명을 선택하여 주세요.");
    }
    if (!params.lodNo && pathname.includes("admin")) {
      return alert("숙소명을 선택하여 주세요.");
    }
    if (!params.rmTypCd) {
      return alert("객실타입을 선택하여 주세요.");
    }
    if (!params.rmOlkCd) {
      return alert("객실전망을 선택하여 주세요.");
    }
    if (!params.bedTypCd) {
      return alert("객실침대종류를 선택하여 주세요.");
    }

    console.log("params", params);

    dispatch(
      setMessage({
        message: "객실을 생성하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          dispatch(setMessage({ message: null }));
          rmCreateMutation.mutate(params);
        },
      })
    );
  };

  useEffect(() => {
    if (!!rmNoRef.current) {
      const {
        lodGrpNo,
        lodGrpNm,
        lodNo,
        lodNm,
        rmTypCd,
        rmOlkCd,
        bedTypCd,
        babyFreePerCt,
        chldFreePerCt,
        rmAreaDesc,
        rmMinPerCt,
        rmMaxPerCt,
        uyn,
      } = rmDtl;
      setInf({
        lodGrpNo,
        lodGrpNm,
        lodNo,
        lodNm,
        rmTypCd,
        rmOlkCd,
        rmAreaDesc,
        bedTypCd,
        rmMinPerCt,
        rmMaxPerCt,
        babyFreePerCt,
        chldFreePerCt,
        uyn,
      });
    }
  }, []);

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"기본정보"}
          createBtn
          clsBtn
          isClose={isOpen}
          desc="* 필수입력"
          handleCreate={handleInfCreate}
          handleClose={() => {
            setIsOpen(!isOpen);
          }}
        />

        <div className={isOpen ? "" : "hidden"}>
          <ul>
            {pathname.includes("manager") && (
              <li>
                <label htmlFor="">숙소그룹명</label>
                <input type="text" value={inf.lodGrpNm} readOnly />
              </li>
            )}
            {pathname.includes("admin") && (
              <li>
                <label htmlFor="" data-dot>
                  숙소그룹명
                </label>
                <LodGrpNmSelect
                  option={inf.lodGrpNo}
                  disabled={!!rmNoRef.current}
                  isArrow={!!rmNoRef.current}
                  onSelect={handleSelect}
                />
              </li>
            )}
            {pathname.includes("manager") && (
              <li>
                <label htmlFor="">숙소명</label>
                <input type="text" value={inf.lodNm} readOnly />
              </li>
            )}
            {pathname.includes("admin") && (
              <li>
                <label htmlFor="" data-dot>
                  숙소명
                </label>
                <LodNmSelect
                  option={inf.lodNo}
                  params={inf.lodGrpNo}
                  disabled={!!rmNoRef.current}
                  isArrow={!!rmNoRef.current}
                  onSelect={handleSelect}
                />
              </li>
            )}
            {!!rmNoRef.current && (
              <li>
                <label htmlFor="">객실번호</label>
                <input type="text" value={rmNoRef.current} readOnly />
              </li>
            )}
          </ul>
          <ul>
            <li className="relative">
              <label htmlFor="" data-dot>
                객실타입
              </label>
              <RmTypeSelect
                option={inf.rmTypCd}
                wrapStyle="w-[12.5rem]"
                disabled={!!rmNoRef.current}
                isArrow={!!rmNoRef.current}
                onSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="" data-dot>
                객실전망
              </label>
              <RmOlkSelect
                wrapStyle="w-[12.5rem]"
                option={inf.rmOlkCd}
                disabled={!!rmNoRef.current}
                isArrow={!!rmNoRef.current}
                onSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="" data-dot>
                객실침대종류
              </label>
              <BedTypeSelect
                wrapStyle="w-[12.5rem]"
                option={inf.bedTypCd}
                disabled={!!rmNoRef.current}
                isArrow={!!rmNoRef.current}
                onSelect={handleSelect}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">객실크기</label>
              <input
                type="text"
                name="rmAreaDesc"
                value={inf.rmAreaDesc}
                readOnly={!!rmNoRef.current}
                onChange={(e) =>
                  handleChange("rmAreaDesc", e.currentTarget.value)
                }
              />
            </li>
            <li>
              <label htmlFor="">객실최소인원수</label>
              <input
                type="text"
                name="rmMinPerCt"
                value={inf.rmMinPerCt ?? ""}
                readOnly={!!rmNoRef.current}
                onChange={(e) =>
                  handleChange("rmMinPerCt", e.currentTarget.value)
                }
              />
            </li>
            <li>
              <label htmlFor="">객실최대인원수</label>
              <input
                type="text"
                name="rmMaxPerCt"
                value={inf.rmMaxPerCt ?? ""}
                readOnly={!!rmNoRef.current}
                onChange={(e) =>
                  handleChange("rmMaxPerCt", e.currentTarget.value)
                }
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">유아무료인원수</label>
              <input
                type="text"
                name="babyFreePerCt"
                value={inf.babyFreePerCt ?? ""}
                readOnly={!!rmNoRef.current}
                onChange={(e) =>
                  handleChange("babyFreePerCt", e.currentTarget.value)
                }
              />
            </li>
            <li>
              <label htmlFor="">아동무료인원수</label>
              <input
                type="text"
                name="chldFreePerCt"
                value={inf.chldFreePerCt ?? ""}
                readOnly={!!rmNoRef.current}
                onChange={(e) =>
                  handleChange("chldFreePerCt", e.currentTarget.value)
                }
              />
            </li>
            <li>
              <label htmlFor="">상태</label>
              <UYNRadio
                value={inf.uyn}
                handleChange={(target) => handleChange("uyn", target.id)}
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default InfCreate;
