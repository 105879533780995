import ITHeader from "components/layout/header/subHeader/itemHeader";
import THeader from "components/layout/header/subHeader/tHeader";
import ReadTuiEditor from "components/tuiEditor/readEditor";
import { prvProps } from "interface/term";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { dateFormat } from "util/common";
type IProps = {
  trmDtl: prvProps;
};
const TrmDtlView = ({ trmDtl }: IProps) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [infOpen, setInfOpen] = useState(true);

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader sbTtl={"약관내용상세"} clsBtn handleReset={() => {}} />
        <div className="tb-form">
          <ITHeader
            sbTtl={"기본정보"}
            clsBtn
            mdfyBtn
            isClose={infOpen}
            handleModify={() =>
              navigate((pathname + search).replace("info", "modify"))
            }
            handleClose={() => {
              setInfOpen(!infOpen);
            }}
          />
          <div className={infOpen ? "" : "hidden"}>
            <ul>
              <li>
                <label htmlFor="">약관명</label>
                <input type="text" defaultValue={trmDtl?.prvNm} readOnly />
              </li>
              <li>
                <label htmlFor="">약관버전명</label>
                <input type="text" defaultValue={trmDtl?.verNm} readOnly />
              </li>
              <li>
                <label htmlFor="">약관코드</label>
                <input type="text" defaultValue={trmDtl?.prvCd} readOnly />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">필수여부</label>
                <input
                  type="text"
                  defaultValue={trmDtl?.essYn === "Y" ? "필수" : "선택"}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">적용여부</label>
                <input
                  type="text"
                  defaultValue={trmDtl?.uyn === "Y" ? "적용" : "미적용"}
                  readOnly
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">적용시작일</label>
                <input
                  type="text"
                  defaultValue={dateFormat(trmDtl.aplySDt)}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">적용종료일</label>
                <input
                  type="text"
                  defaultValue={dateFormat(trmDtl?.aplyEDt)}
                  readOnly
                />
              </li>
            </ul>
            <ul>
              <li data-full>
                <label htmlFor="">약관설명</label>
                <input type="text" defaultValue={trmDtl?.prvDesc} readOnly />
              </li>
            </ul>
            <ul>
              <li data-full>
                <label htmlFor="">약관내용</label>
                <ReadTuiEditor name={"trmCn"} value={trmDtl?.trmCn} />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrmDtlView;
