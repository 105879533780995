import THeader from "components/layout/header/subHeader/tHeader";
import { promProps } from "interface/promotion";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

type IProps = {
  promInf: promProps;
};

const PromInfoView = ({ promInf }: IProps) => {
  const [infOpen, setInfOpen] = useState(true);

  return (
    <div className="main-cont" data-dir-col>
      <THeader sbTtl={"프로모션코드상세"} clsBtn handleReset={() => {}} />
      <div className="tb-form">
        <THeader sbTtl={"기본정보"} clsBtn mdfyBtn handleReset={() => {}} />
        <div className={infOpen ? "" : "hidden"}>
          <ul>
            <li>
              <label htmlFor="">프로모션코드</label>
              <input type="text" defaultValue={promInf?.promCd} readOnly />
            </li>
            <li>
              <label htmlFor="">프모모션명</label>
              <input type="text" defaultValue={promInf?.promNm} readOnly />
            </li>
            <li>
              <label htmlFor="">언어코드</label>
              <input type="text" defaultValue={promInf?.influLangCd} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">휴대폰국가번호</label>
              <input
                type="text"
                defaultValue={promInf?.influMobCntyNo}
                readOnly
              />
            </li>
            <li>
              <label htmlFor="">휴대폰번호</label>
              <input type="text" defaultValue={promInf?.influMobNo} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">인플루언서명</label>
              <input type="text" defaultValue={promInf?.influNm} readOnly />
            </li>
            <li>
              <label htmlFor="">인플루언서 이메일</label>
              <input type="text" defaultValue={promInf?.influEml} readOnly />
            </li>
            <li>
              <label htmlFor="">인플루언서 국가</label>
              <input type="text" defaultValue={promInf?.influCnty} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">최초생성일</label>
              <input
                type="text"
                value={promInf?.fstCrtDtti?.split(" ")[0]}
                readOnly
              />
            </li>
            <li>
              <label htmlFor="">최초생성사용자</label>
              <input type="text" value={promInf?.fstCrtUsid} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">최초수정일</label>
              <input
                type="text"
                value={promInf?.ltChDtti?.split(" ")[0]}
                readOnly
              />
            </li>
            <li>
              <label htmlFor="">최초수정사용자</label>
              <input type="text" value={promInf?.ltChUsid} readOnly />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PromInfoView;
