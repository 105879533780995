import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { lodRefAdList, lodRefdAdCreateApi } from "components/api/lod";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import Modal from "components/modal/modal";
import { langProps } from "components/radio/radioEditor";
import Select from "components/select";
import { lodRefAdProps } from "interface/lod";
import i18n from "locales/i18n";
import { MutableRefObject, useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";
import { langOptions } from "util/option";
import InTuiEditor from "components/tuiEditor/inEditot";
import SmallSelect from "components/select/smSelect";
import ReadTuiEditor from "components/tuiEditor/readEditor";

type IProps = {
  lodNoRef: MutableRefObject<string>;
  rgSeqRef: MutableRefObject<number>;
  isAbleRef: MutableRefObject<boolean>;
};

const InfAdCreateView = ({ lodNoRef, rgSeqRef, isAbleRef }: IProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: ["lodRefdAd", lodNoRef.current],
    queryFn: () => {
      return lodRefAdList({
        lodNo: lodNoRef.current,
        rgSeq: rgSeqRef.current,
      });
    },
    enabled: isAbleRef.current,
  });

  const adList = query?.data?.body?.lodRefdAdList;

  let isLangOp = [] as langProps[];
  let addOp = [] as langProps[];

  langOptions.forEach((lang) => {
    const isAble = adList?.some((ad: any) => {
      return lang.value === ad.langCd;
    });

    if (isAble) {
      isLangOp.push(lang);
    }

    if (!isAble) {
      addOp.push(lang);
    }
  });

  const [adInf, setAdInf] = useState<lodRefAdProps>({
    lodNo: lodNoRef.current,
    langCd: addOp[0]?.value || i18n.language,
    refdInfNm: "",
    refdDesc: "",
  });

  const [isOpen, setIsOpen] = useState(false);
  const [adOpen, setAdOpen] = useState(true);

  const handleChange = (name: string, value: string) => {
    const adNm = name.split("_")[0];
    setAdInf({
      ...adInf,
      [adNm]: value,
    });
  };

  const adInfMutation = useMutation(lodRefdAdCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "숛소환불수수료 추가정보를 등록하였습니다",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                setIsOpen(false);
                queryClient.invalidateQueries(["lodRefdAd", lodNoRef.current]);
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCreate = () => {
    if (!adInf.refdInfNm || !adInf.refdDesc) {
      return alert("추가정보를 입력하여 주세요.");
    }

    const params = {
      ...adInf,
      lodNo: lodNoRef.current,
      rgSeq: rgSeqRef.current || 1,
    };
    console.log("params", params);

    dispatch(
      setMessage({
        message: "숙소환불수수료 추가정보를 등록하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          adInfMutation.mutate(params);
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  const [langCd, setLangCd] = useState(isLangOp[0]?.value || i18n.language);
  let adInfo = adList?.find((ad: any) => {
    return ad.langCd === langCd;
  });

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"추가정보"}
          clsBtn
          createBtn
          createBtnTxt="등록"
          addBtn
          addBtnTxt="언어추가"
          desc="* 기본정보 등록후 추가정보 등록이 가능합니다."
          isClose={adOpen}
          handleAdCreate={() => {
            setIsOpen(true);
          }}
          handleClose={() => {
            setAdOpen(!adOpen);
          }}
        >
          {isLangOp.length > 0 && (
            <SmallSelect
              option={isLangOp[0]?.value}
              options={isLangOp}
              handleSelect={(target) => {
                const { value } = target.dataset;
              }}
            />
          )}
        </ITHeader>
        <div className={adOpen ? "" : "hidden"}>
          <ul>
            <li data-full>
              <label htmlFor="">환불명</label>
              <input type="text" value={adInfo?.refdInfNm} readOnly />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">환불설명</label>
              <ReadTuiEditor name={"refdDesc"} value={adInfo?.refdDesc} />
            </li>
          </ul>
        </div>
        <Modal
          title={"추가정보"}
          isOpen={isOpen}
          styles="w-[90%]"
          btnText="추가"
          handleCloseModal={() => {
            setIsOpen(false);
          }}
          onClick={handleCreate}
        >
          <div className="tb-form mt-[3rem]">
            <ul data-brd-top>
              <li>
                <label htmlFor="">언어선택</label>
                <Select
                  id={"langCd"}
                  name={"langCd"}
                  option={adInf.langCd}
                  options={addOp}
                  isInitial
                  onSelect={(target) => {
                    const { value } = target.dataset;
                    setAdInf({
                      ...adInf,
                      langCd: value ?? i18n.language,
                    });
                  }}
                />
              </li>
            </ul>
            <ul>
              <li data-full>
                <label htmlFor="">환불명</label>
                <input
                  type="text"
                  value={adInf.refdInfNm}
                  onChange={(e) =>
                    handleChange("refdInfNm", e.currentTarget.value)
                  }
                />
              </li>
            </ul>
            <ul>
              <li data-full>
                <label htmlFor="">환불설명</label>
                <InTuiEditor
                  name={"refdDesc"}
                  value={adInf?.refdDesc}
                  onChange={(value) => handleChange("refdDesc", value)}
                />
              </li>
            </ul>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default InfAdCreateView;
