import { useQuery } from "@tanstack/react-query";
import { rmOpInfoApi } from "components/api/room";
import RmOpModifyView from "components/view/room/opt/modify";
import i18n from "locales/i18n";
import { useLocation } from "react-router-dom";

export const RmOptModifyInfoPage = () => {
  const { state, search } = useLocation();

  const query = useQuery({
    queryKey: ["rmOpInfo"],
    queryFn: () => {
      return rmOpInfoApi({
        rmNo: state.rmNo,
        opRgSeq: search.replace("?opRgSeq=", ""),
        langCd: i18n.language,
      });
    },
    enabled: !!search.replace("?opRgSeq=", ""),
  });

  const rmOpDtl = query?.data?.body?.rmOpDtl;
  const addInfList = query?.data?.body?.addInfList;

  return <RmOpModifyView rmOpDtl={rmOpDtl} adInfList={addInfList} />;
};

export default RmOptModifyInfoPage;
