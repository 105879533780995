import Select from "components/select";

type IProps = {
  option: string;
  isInitial?: boolean;
  onSelect(target: EventTarget & HTMLLIElement): void;
};

const LodChlSelect = ({ isInitial, option, onSelect }: IProps) => {
  // const { data } = useQuery(["lodGrpList"], lodGrpListApi);

  // const options = data?.body.lodGrpList.map((li: IlodGrpList) => {
  //   const option = {
  //     value: li.lodGrpNo,
  //     kr: li.lodGrpNm,
  //     en: li.lodGrpNm,
  //   };
  //   return option;
  // });

  const options = [
    {
      kr: "UBS",
      value: "UBS",
      en: "UBS",
    },
    // {
    //   kr: "USA",
    //   value: "USA",
    //   en: "USA",
    // },
  ];

  return (
    <Select
      id="lodChlCd"
      name="lodChlCd"
      option={option || "A"}
      isInitial={isInitial}
      options={options}
      onSelect={onSelect}
    />
  );
};

export default LodChlSelect;
