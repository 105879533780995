import THeader from "components/layout/header/subHeader/tHeader";
import LodGrdSelect from "components/select/room/lodGrdSelect";
import LodGrpNmSelect from "components/select/room/lodGrpNmSelect ";
import LodGrpNoSelect from "components/select/room/lodGrpNoSelect";
import LodNmSelect from "components/select/room/lodNmSelect";
import LodNoSelect from "components/select/room/lodNoSelect";
import SearchSelect from "components/select/searchSelect";
import LodDefaultList from "components/tableList/lod/default";
import { lodDftSearchProps } from "interface/lod";
import { useLocation } from "react-router-dom";

type IProps = {
  isLoading: boolean;
  searchInfo: lodDftSearchProps;
  memType: "admin" | "manager";
  handleChange(target: EventTarget & HTMLInputElement): void;
  handleReset(): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
};

const LodDftListView = ({
  memType,
  searchInfo,
  handleChange,
  handleReset,
  handleSelect,
}: IProps) => {
  const { pathname } = useLocation();

  const searchOp = [{ kr: "숙소명", value: "lodNm", en: "LodName" }];
  const searchOps = pathname.includes("admin")
    ? [{ kr: "숙소그룹명", value: "lodGrpNm", en: "LodGrpName" }, ...searchOp]
    : searchOp;

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader
          sbTtl={"숙소기본정보"}
          rstBtn
          url={pathname}
          searchBtn
          handleReset={handleReset}
        />

        <div className="tb-form" data-list>
          <ul>
            {pathname.includes("admin") && (
              <li>
                <label htmlFor="">숙소그룹명</label>
                <LodGrpNmSelect
                  option={searchInfo.lodGrpNo}
                  onSelect={(target) => handleSelect(target)}
                />
              </li>
            )}
            <li>
              <label htmlFor="">숙소명</label>
              <LodNmSelect
                isArrow={pathname.includes("manager")}
                disabled={pathname.includes("manager") || !searchInfo?.lodGrpNo}
                option={searchInfo.lodNo}
                params={searchInfo.lodGrpNo}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="">숙소등급</label>
              <LodGrdSelect
                option={searchInfo?.lodGrdCd}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">검색조건</label>
              <SearchSelect
                id={"searchTyp"}
                name={searchInfo.searchTyp ?? ""}
                options={searchOps}
                option={searchInfo.searchTyp || "A"}
                value={
                  searchInfo[
                    `${searchInfo.searchTyp}` as keyof lodDftSearchProps
                  ] || ""
                }
                onSelect={handleSelect}
                onChange={handleChange}
              />
            </li>
          </ul>
        </div>
      </div>
      <LodDefaultList memType={memType} searchInfo={searchInfo} />
    </>
  );
};

export default LodDftListView;
