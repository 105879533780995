import RoomBookListView from "components/view/room/book/list";
import { rmResvSearchProps } from "interface/room";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setIsLoading, setIsSearch } from "store/commonSlice";
import { afterDay } from "util/common";
import { dateTypOptions } from "util/option";

const RoomBookListPage = () => {
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();

  const memType = pathname.includes("admin") ? "admin" : "manager";
  let lodNo = localStorage.getItem("lodNo") ?? "";
  let manInf = localStorage.getItem("manInf")
    ? JSON.parse(localStorage.getItem("manInf") ?? "")
    : null;

  const { sDt, eDt } = afterDay(7);

  const [searchInfo, setSearchInfo] = useState<rmResvSearchProps>({
    lodGrpNo: manInf?.manLodGrpNo,
    lodNo: lodNo,
    lodGrpCd: state?.lodGrpCd ?? "",
    rmNo: state?.rmNo ?? "",
    rmNm: state?.rmNM ?? "",
    rmTypCd: state?.rmTypCd,
    rmOlkCd: state?.rmOlkCd,
    maxPerCtMax: state?.maxPerCtMax ?? "",
    maxPerCtMin: state?.maxPerCtMin ?? "",
    rmPdNo: state?.rmPdNo ?? "",
    lodChlSeq: state?.lodChlSeq ?? "",
    lodChlCd: state?.lodCnlCd ?? "",
    rmResvNo: state?.rmResvNo ?? "",
    resvCstMblCntyCd: state?.resvCstMblCntyCd,
    resvCstMblNo: state?.resvCstMblNo ?? "",
    resvCstnm: state?.resvCstnm ?? "",
    rmOpNm: state?.rmOpNm ?? "",
    resvMemFstnm: state?.resvMemFstnm ?? "",
    resvMemMobNo: state?.resvMemMobNo ?? "",
    mobCntyCd: state?.mobCntyCd ?? "",
    memMobNo: state?.memMobNo ?? "",
    memFstnm: state?.memFstnm ?? "",
    resvCstFstnm: state?.resvCstFstnm ?? "",
    sellSDt: state?.sellSDt ?? sDt,
    sellEDt: state?.sellEDt ?? eDt,
    resvSDt: state?.resvSDt ?? sDt,
    resvEDt: state?.resvEDt ?? eDt,
    dateTyp: state?.dateTyp ?? dateTypOptions[0]?.value,
    searchTyp: state?.searchTyp ?? "",
  });

  const handleChange = (name: string, value: string) => {
    if (name === "SDt" || name === "EDt") {
      if (searchInfo?.dateTyp === "rmPdDt") {
        setSearchInfo({
          ...searchInfo,
          [`sell${name}`]: value,
        });

        return;
      }
      if (searchInfo?.dateTyp === "rmResvDt") {
        setSearchInfo({
          ...searchInfo,
          [`resv${name}`]: value,
        });
        return;
      }
    }

    setSearchInfo({
      ...searchInfo,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { lng, name, value } = target.dataset;
    if (name && value) {
      if (name === "dateTyp") {
        setSearchInfo({
          ...searchInfo,
          [name]: value,
        });
        return;
      }
      if (name === "lodGrpNo") {
        setSearchInfo({
          ...searchInfo,
          lodNo: "",
          rmNo: "",
          rmOlkCd: "",
          rmTypCd: "",
          [name]: value === "A" ? "" : value,
        });
        return;
      }
      if (name === "lodNo") {
        setSearchInfo({
          ...searchInfo,
          rmNo: "",
          rmOlkCd: "",
          rmTypCd: "",
          [name]: value === "A" ? "" : value,
        });
        return;
      }
      setSearchInfo({
        ...searchInfo,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const handleReset = () => {
    setSearchInfo({
      lodGrpNo: manInf?.manLodGrpNo,
      lodNo: lodNo,
      lodGrpCd: "",
      rmNo: "",
      rmNm: "",
      rmTypCd: "",
      rmOlkCd: "",
      maxPerCtMax: "",
      maxPerCtMin: "",
      rmPdNo: "",
      lodChlSeq: "",
      lodChlCd: "",
      rmResvNo: "",
      resvCstMblCntyCd: "",
      resvCstMblNo: "",
      resvCstnm: "",
      rmOpNm: "",
      resvMemFstnm: "",
      resvMemMobNo: "",
      mobCntyCd: "",
      memMobNo: "",
      memFstnm: "",
      resvCstFstnm: "",
      sellSDt: sDt,
      sellEDt: eDt,
      // cnlSDt: "",
      // cnlEDt: "",
      resvSDt: sDt,
      resvEDt: eDt,
      dateTyp: dateTypOptions[0]?.value,
      searchTyp: "",
    });
    dispatch(setIsLoading(false));
    dispatch(setIsSearch(false));
  };

  return (
    <>
      <RoomBookListView
        memType={memType}
        searchInfo={searchInfo}
        handleChange={handleChange}
        handleSelect={handleSelect}
        handleReset={handleReset}
      />
    </>
  );
};

export default RoomBookListPage;
