import ITHeader from "components/layout/header/subHeader/itemHeader";
import THeader from "components/layout/header/subHeader/tHeader";
import SmallSelect from "components/select/smSelect";
import ReadTuiEditor from "components/tuiEditor/readEditor";
import { langProps } from "interface";
import { dftAdInf } from "interface/lod";
import i18n from "locales/i18n";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { langOptions } from "util/option";

type IProps = {
  dtl: {
    lodNo: string;
    lodGrpNm: string;
    lodGrdCd: string;
    lodNm: string;
  };
  adList: Array<dftAdInf>;
};
const LodDftInfoView = ({ adList, dtl }: IProps) => {
  const { pathname } = useLocation();

  let langs = [] as langProps[];

  adList.forEach((ad) => {
    const { langCd, lodNo, ...res } = ad;

    const isAble = Object.values(res).some((ad) => {
      return !!ad;
    });

    if (isAble) {
      const lang = langOptions.find((lang) => {
        return lang.value === langCd;
      });
      if (lang) {
        langs.push(lang);
      }
    }
  });

  const [infOpen, setInfOpen] = useState(true);
  const [addOpen, setAddOpen] = useState(true);

  const [langCd, setLangCd] = useState(i18n.language);

  let adInfo = adList?.find((ad) => {
    return ad.langCd === langCd;
  });

  // const isAdInf = adList.some((ad) => {
  //   const { lodNo, langCd, ...res } = ad;

  //   return Object.keys(res).every((key) => {
  //     return !!key;
  //   });
  // });

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader
          sbTtl={"숙소기본정보상세 조회"}
          mdfyBtn={pathname.includes("admin")}
          clsBtn
          handleReset={() => {}}
        />
        <div className="tb-form">
          <ITHeader
            sbTtl={"기본정보"}
            clsBtn
            isClose={infOpen}
            handleClose={() => {
              setInfOpen(!infOpen);
            }}
          />
          <div className={infOpen ? "" : "hidden"}>
            <ul>
              <li>
                <label htmlFor="">숙소그룹명</label>
                <input
                  type="text"
                  defaultValue={dtl?.lodGrpNm ?? ""}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">숙소명</label>
                <input type="text" defaultValue={dtl?.lodNm} readOnly />
              </li>
              <li>
                <label htmlFor="">숙소등급</label>
                <input
                  type="text"
                  defaultValue={dtl?.lodGrdCd ?? ""}
                  readOnly
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="tb-form">
          <ITHeader
            sbTtl={"추가정보"}
            clsBtn
            desc={
              langs.length > 1
                ? `언어를 선택하여 언어별 추가정보를 확인하세요.`
                : ""
            }
            isClose={addOpen}
            handleClose={() => {
              setAddOpen(!addOpen);
            }}
          >
            {langs.length > 0 && (
              <SmallSelect
                option={langCd}
                options={langs}
                handleSelect={(target) => {
                  const { value } = target.dataset;
                  setLangCd(value ?? i18n.language);
                }}
              />
            )}
          </ITHeader>
          <div className={addOpen ? "" : "hidden"}>
            <ul>
              <li data-full>
                <label htmlFor="">공지사항</label>
                <ReadTuiEditor name={"lodNotiCn"} value={adInfo?.lodNotiCn} />
              </li>
            </ul>
            <ul>
              <li data-full>
                <label htmlFor="">운영정보</label>
                <ReadTuiEditor name={"lodOprCn"} value={adInfo?.lodOprCn} />
              </li>
            </ul>
            <ul>
              <li data-full>
                <label htmlFor="">인원추가정보</label>
                <ReadTuiEditor name={"lodCstAdCn"} value={adInfo?.lodCstAdCn} />
              </li>
            </ul>
            <ul>
              <li data-full>
                <label htmlFor="">부대시설정보</label>
                <ReadTuiEditor
                  name={"lodAddFacCn"}
                  value={adInfo?.lodAddFacCn}
                />
              </li>
            </ul>
            <ul>
              <li data-full>
                <label htmlFor="">조식정보</label>
                <ReadTuiEditor name={"lodBrkfCn"} value={adInfo?.lodBrkfCn} />
              </li>
            </ul>
            <ul>
              <li data-full>
                <label htmlFor="">취사시설정보</label>
                <ReadTuiEditor name={"lodCokCn"} value={adInfo?.lodBrkfCn} />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default LodDftInfoView;
