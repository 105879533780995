import "./signup.scss";
import AllCheck from "./allcheck";
// import Modal from "components/modal/modal";
import { useLocation, useNavigate } from "react-router-dom";
import { passProps } from "interface/member";
import { regPW } from "util/common";
import { useSelector } from "react-redux";
import { itemCheckSelector } from "store/commonSlice";
import THeader from "components/layout/header/subHeader/tHeader";

type IProps = {
  info: passProps;
  // isOpen: boolean;
  isLoading: boolean;
  // handleCloseModal(): void;
  handleChange(target: { name: string; value: string }): void;
  handleSignup(): void;
};

const Step4View = ({
  info,
  // isOpen,
  // handleCloseModal,
  handleChange,
  handleSignup,
}: IProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const type = pathname.includes("admin") ? "admin" : "manager";
  const itemCheck = useSelector(itemCheckSelector);

  const isCheck = itemCheck.every((item) => {
    return item.essYn === "Y" && item.required;
  });

  const isAble = info.pwd === info.check_pwd && regPW.test(info.pwd) && isCheck;

  return (
    <>
      <div className="main-cont">
        <div className="card-form" data-dir-col>
          <THeader
            sbTtl={
              (pathname.includes("admin") ? "관리자" : "매니저") + "회원가입"
            }
            handleReset={() => {}}
          />
          <div className="tb-form flex flex-col items-center max-w-[50rem] mx-auto">
            <p className="p-2 m-2 text-xs bg-gray-200 w-full text-center leading-5">
              비밀번호는 8자리 이상 20자리 이내로 등록해주세요. <br />
              비밀번호 구성은 영문, 숫자, 특수문자를 포함해야 합니다. <br />
              특수문자로 허용되는 문자는 !, @, #, $, %, ^, &, * 입니다.
            </p>
            <ul>
              <li>
                <label htmlFor="">비밀번호</label>
                <input
                  type="password"
                  name="pwd"
                  value={info?.pwd ?? ""}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    handleChange({ name: "pwd", value });
                  }}
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="" data-err="비밀번호가 일치하지 않습니다.">
                  비밀번호 확인
                </label>
                <input
                  type="password"
                  name="check_pwd"
                  value={info?.check_pwd ?? ""}
                  data-err={info.check_pwd !== info.pwd && !!info.check_pwd}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    handleChange({ name: "check_pwd", value });
                  }}
                />
              </li>
            </ul>
            <AllCheck />
            <div className="flex">
              <button
                className="btn-md"
                disabled={!isAble}
                onClick={handleSignup}
              >
                회원가입
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="no-logged-wrap"></div>
      {/* <Modal
        title="안내"
        btnText="확인"
        isOpen={isOpen}
        handleCloseModal={handleCloseModal}
        onClick={() => {
          handleCloseModal();
          // navigate(`/signup/${type}/complete`, { replace: true });
        }}
      >
        <div className="modal-ctn">
          회원가입 승인요청을 완료하였습니다.
          <br /> {type === "manager" ? "매니저" : "관리자"} 회원은 승인완료 후,
          로그인이 가능합니다.
          <br />
          승인이 완료되면 회원가입완료 메일을 보내 드립니다.
        </div>
      </Modal> */}
    </>
  );
};

export default Step4View;
