import THeader from "components/layout/header/subHeader/tHeader";
import InfModify from "./inf";
import ImgModify from "./img";
import { IrmInfo, rmImgList } from "interface/room";
import { AdInfModify } from "./adInf";
import { useLocation } from "react-router-dom";

type IProps = {
  rmDtl: any;
  rmAddInfList: IrmInfo["rmInfList"];
  rmImgList: rmImgList[];
};

const RmModifyView = ({ rmDtl, rmAddInfList, rmImgList }: IProps) => {
  const { search } = useLocation();
  const rmNo = search.replace("?rmNo=", "");
  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader sbTtl={"객실수정"} clsBtn handleReset={() => {}} />
        <InfModify rmDtl={rmDtl} rmNo={rmNo} />
        <AdInfModify adInfList={rmAddInfList} rmNo={rmNo} />
        <ImgModify imgList={rmImgList} rmNo={rmNo} />
      </div>
    </>
  );
};

export default RmModifyView;
