import { useQuery } from "@tanstack/react-query";
import { lodAdDftListApi } from "components/api/lod";
import i18n from "locales/i18n";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { langOptions } from "util/option";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import { langProps } from "interface";
import ReadTuiEditor from "components/tuiEditor/readEditor";
import { dftAdInf } from "interface/lod";
import SmallSelect from "components/select/smSelect";
import AddModal from "./modal/addModal";
import { ModifyModal } from "./modal/modifyModal";

const AdInfModifyView = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const lodNo = search.replace("?lodNo=", "");

  const query = useQuery({
    queryKey: ["lodAdDftList", lodNo],
    queryFn: () => {
      return lodAdDftListApi({
        lodNo: search.replace("?lodNo=", ""),
      });
    },
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
        }

        if (code === -1) {
          dispatch(
            setMessage({
              message: message || "검색 데이터가 없습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  let adList = query?.data?.body?.lodAdDftList;

  const [langCd, setLangCd] = useState(i18n.language);

  let adInfo = adList?.find((ad: dftAdInf) => {
    return ad?.langCd === langCd;
  });

  let mdfyOp = [] as langProps[];
  let addOp = [] as langProps[];

  adList.forEach((ad: dftAdInf) => {
    const { langCd, lodNo, ...res } = ad;

    const isAble = Object.values(res).some((ad) => {
      return !!ad;
    });

    const lang = langOptions.find((lang) => {
      return lang.value === langCd;
    });

    if (lang) {
      if (isAble) {
        mdfyOp.push(lang);
      }
      if (!isAble) {
        addOp.push(lang);
      }
    }
  });

  // langOptions.forEach((lang) => {
  //   const isAble = adList?.some((ad: any) => {
  //     return lang.value === ad.langCd;
  //   });

  //   if (isAble) {
  //     langs.push(lang);
  //   }
  // });

  const [isOpen, setIsOpen] = useState("");
  const [addOpen, setAddOpen] = useState(true);

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"추가정보"}
          clsBtn
          desc={
            mdfyOp.length > 0
              ? `언어를 선택하여 언어별 추가정보를 확인하세요.`
              : ""
          }
          mdfyBtn={mdfyOp.length > 0}
          addBtn={addOp.length > 0}
          addBtnTxt={"언어추가"}
          isClose={addOpen}
          handleModify={() => setIsOpen("mdfy")}
          handleAdCreate={() => {
            setIsOpen("add");
          }}
          handleClose={() => {
            setAddOpen(!addOpen);
          }}
        >
          {mdfyOp.length > 0 && (
            <SmallSelect
              option={langCd}
              options={mdfyOp}
              handleSelect={(target) => {
                const { value } = target.dataset;
                setLangCd(value ?? i18n.language);
              }}
            />
          )}
        </ITHeader>
        <div className={addOpen ? "" : "hidden"}>
          <ul>
            <li data-full>
              <label htmlFor="">공지사항</label>
              <ReadTuiEditor name={"lodNotiCn"} value={adInfo?.lodNotiCn} />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">운영정보</label>
              <ReadTuiEditor name={"lodOprCn"} value={adInfo?.lodOprCn} />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">인원추가정보</label>
              <ReadTuiEditor name={"lodCstAdCn"} value={adInfo?.lodCstAdCn} />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">부대시설정보</label>
              <ReadTuiEditor name={"lodAddFacCn"} value={adInfo?.lodAddFacCn} />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">조식정보</label>
              <ReadTuiEditor name={"lodBrkfCn"} value={adInfo?.lodBrkfCn} />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">취사시설정보</label>
              <ReadTuiEditor name={"lodCokCn"} value={adInfo?.lodBrkfCn} />
            </li>
          </ul>
        </div>
      </div>
      {isOpen === "add" && (
        <AddModal
          langCd={langCd}
          langOp={addOp}
          isOpen={isOpen}
          lodNo={lodNo}
          setLangCd={setLangCd}
          setIsOpen={setIsOpen}
        />
      )}
      {isOpen === "mdfy" && (
        <ModifyModal
          adInfo={adInfo}
          langCd={langCd}
          langOp={mdfyOp}
          isOpen={isOpen}
          lodNo={lodNo}
          setLangCd={setLangCd}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
};

export default AdInfModifyView;
