import "./header.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isSelectSelector, setMessage, setSession } from "store/commonSlice";
import { useMutation } from "@tanstack/react-query";
import { logOutApi } from "components/api/member";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MemModal from "./memModal";
import { useEffect, useState } from "react";

type IPorps = {};

const Header = ({}: IPorps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { i18n } = useTranslation();
  const { pathname, search } = useLocation();

  // const memType = pathname.includes("admin") ? "admin" : "manager";
  const [memType, setMemType] = useState("manager");

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenLang, setIsOpenLang] = useState(false);

  const lng = i18n.language === "en" ? "ENG" : "KOR";
  const loginObject = sessionStorage.getItem("login");
  const isLogin = (loginObject && JSON.parse(loginObject).value) || null;
  const isSelect = useSelector(isSelectSelector);
  const [lodNm, setLodNm] = useState("");

  const logoutMutation = useMutation(logOutApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          navigate("/", { replace: true });
          sessionStorage.removeItem("login");
          localStorage.removeItem("lodNo");
          localStorage.removeItem("manInf");
          localStorage.removeItem("memTyp");
          dispatch(setSession(false));
        }
        if (code === -1) {
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  const handleLogout = () => {
    dispatch(
      setMessage({
        title: "",
        message: "로그아웃 하시겠습니까?",
        isConfirm: true,
        isCancel: true,
        handleClick: async () => {
          dispatch(
            setMessage({
              message: null,
              handleClick: () => {},
            })
          );

          logoutMutation.mutate(memType);
        },
      })
    );
  };
  const getMemTyp = (e: any) => {
    setMemType(e.detail);
  };
  useEffect(() => {
    window.addEventListener("memTypEvnet", getMemTyp);
  }, []);

  return (
    <header>
      <div className="header-wrap">
        <div
          className="cursor-pointer"
          onClick={() => {
            if (!!isLogin) {
              navigate(`${memType}`);
              return;
            }
            if (!isLogin) {
              navigate("");
            }
          }}
        >
          <h1>UB</h1>
          <h3 className="ps-3">
            {isLogin ? (memType === "manager" ? "매니저" : "관리자") : ""}
            <span className="text-base px-4">{lodNm}</span>
          </h3>
        </div>
        {pathname === "/" && !isLogin && (
          <div className="left-menu">
            <Link to="/signup">회원가입</Link>
            <Link to={`${memType}/resetpw/step1`}>회원비밀번호 찾기</Link>
          </div>
        )}
        {pathname !== "/" && !isLogin && (
          <div>
            <Link to="/">로그인</Link>
          </div>
        )}
        {!!isLogin && (
          <div className="left-menu">
            {memType === "manager" && !search.includes("manNo") && (
              <button onClick={() => setIsOpen(true)}>회원정보</button>
            )}
            <button onClick={handleLogout} className="text-red-600">
              로그아웃
            </button>
            <button
              className="btn-lng"
              data-icon={lng}
              onClick={() => {
                setIsOpenLang(!isOpenLang);
              }}
            >
              {lng}
            </button>
            {isOpenLang && (
              <ul className="languages" aria-disabled>
                <li
                  className="kor"
                  onClick={() => {
                    setIsOpenLang(false);
                    i18n.changeLanguage("kr");
                    localStorage.setItem("lng", "kr");
                  }}
                >
                  KOR
                </li>
                <li
                  className="eng"
                  onClick={() => {
                    setIsOpenLang(false);
                  }}
                >
                  ENG
                </li>
              </ul>
            )}
          </div>
        )}
      </div>
      {isOpen && <MemModal isOpen={isOpen} setIsOpen={setIsOpen} />}
    </header>
  );
};

export default Header;
