import { useMutation } from "@tanstack/react-query";
import { authMailApi } from "components/api/member";
import Step2View from "components/view/signup/step2";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setMessage } from "store/commonSlice";

const Step3Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { pathname, search: params } = useLocation();

  console.log("params", params);

  const type = pathname.includes("admin") ? "admin" : "manager";

  const authMatilMutation = useMutation(authMailApi, {
    onSuccess(data) {
      if (data) {
        const { code, message } = data.header;
        if (code === 0) {
          navigate(`/signup/${type}/step2`, {
            state: params,
            replace: true,
          });
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleAuthMail = () => {
    const url =
      type === "admin"
        ? `admin/join/adminCertInfo${params}`
        : `manager/join/manCertInfo${params}`;

    dispatch(
      setMessage({
        message: "이메일 인증하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          authMatilMutation.mutate(url);
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };
  return <Step2View handleAuthMail={handleAuthMail} />;
};

export default Step3Page;
