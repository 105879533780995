import { roomOpListHead } from "util/option";
import { useLocation, useNavigate } from "react-router-dom";
import Pagenation from "components/pagenation";
import Modal from "components/modal/modal";
import {
  Dispatch,
  SetStateAction,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import {
  isSearchSelector,
  setIsLoading,
  setIsSearch,
  setMessage,
} from "store/commonSlice";
import { rmOptSearchProps } from "interface/room";
import { pageNavProps } from "interface";
import { useSelector } from "react-redux";
import { rmOPInfModifyApi, rmOpListApi } from "components/api/room";
import i18n from "locales/i18n";
import SearchHeader from "components/layout/header/subHeader/srchHeader";

type IListProps = {
  searchInfo: rmOptSearchProps;
  memType: "admin" | "manager";
  col: number;
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};
const List = ({ searchInfo, memType, col, setPageNav }: IListProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, state, search } = useLocation();
  const isSearch = useSelector(isSearchSelector);
  const [list, setList] = useState([]);
  const [info, setInfo] = useState({
    isOpen: false,
    rmNo: "",
    opRgSeq: "",
    uyn: "",
  });

  const rmOpListMutation = useMutation(rmOpListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setPageNav(body?.pageNav);
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));
          setList(body?.rmOpList);
        }
        if (code === -1) {
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  const statusMutation = useMutation(rmOPInfModifyApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;

        if (code === 0) {
          dispatch(
            setMessage({
              message: "객실옵션 상태를 변경하였습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setIsSearch(true));
                setInfo({
                  isOpen: false,
                  rmNo: "",
                  opRgSeq: "",
                  uyn: "",
                });
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
          setInfo({
            isOpen: false,
            rmNo: "",
            opRgSeq: "",
            uyn: "",
          });
        }
      }
    },
  });

  const handleStatus = () => {
    const params = {
      ...info,
      langCd: i18n.language,
    };
    statusMutation.mutate(params);
  };

  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch) {
      const params = {
        lodGrpNo: searchInfo.lodGrpNo || state?.searchInfo?.lodGrpNo,
        lodNo: searchInfo.lodNo || state?.searchInfo?.lodNo,
        langCd: searchInfo?.langCd || state?.searchInfo?.langCd,
        rmOpNm: searchInfo?.rmOpNm || state?.searchInfo?.rmOpNm,
        opRgSeq: searchInfo?.opRgSeq || state?.searchInfo?.opRgSeq,
        brkfInYn: searchInfo.brkfInYn || state?.searchInfo?.brkfInYn,
        latChkOutTi:
          searchInfo.latChkOutTi.replaceAll(":", "") ||
          state?.searchInfo?.latChkOutTi.replaceAll(":", ""),
        uyn: searchInfo.uyn || state?.searchInfo?.uyn,
        pageNo: page || state?.searchInfo?.pageNo,
      };

      rmOpListMutation.mutate({
        ...params,
        uyn: params.uyn === "A" ? "" : params.uyn,
      });
    }
  }, [isSearch, searchInfo, search]);

  const pageRows = rmOpListMutation.data?.body?.pageNav?.pageRows;
  const isNotData = !pageRows;

  return (
    <>
      {list.length === 0 && (
        <tr>
          <td colSpan={col}>
            {isNotData && (
              <span className="font-bold">검색조건을 선택하여 주세요.</span>
            )}
            {!isNotData && (
              <span className="font-bold">검색결과가 없습니다.</span>
            )}
          </td>
        </tr>
      )}
      {list &&
        list?.map((li: any, index: number) => {
          return (
            <tr
              key={index}
              onClick={(e) => {
                e.preventDefault();
                navigate(`info?opRgSeq=${li.opRgSeq}`, {
                  state: {
                    rmNo: li.rmNo,
                    searchInfo,
                    url: pathname + search,
                  },
                });
              }}
            >
              <td>{(Number(page) - 1) * (pageRows || 10) + index + 1}</td>
              {memType === "admin" && <td>{li.lodGrpNo}</td>}
              {memType === "admin" && <td>{li.lodGrpNm}</td>}
              {memType === "admin" && <td>{li.lodNo}</td>}
              {memType === "admin" && (
                <td>
                  <span className="w-[12.5rem]">{li.lodNm}</span>
                </td>
              )}
              <td>{li.rmNo}</td>
              <td>{li.opRgSeq}</td>
              <td>{li.rmOpNm}</td>
              <td>{li.brkfInYn === "N" ? "미포함" : "포함"}</td>
              <td>
                {li.latChkOutTi?.replace(/\B(?=(\d{2})+(?!\d))/g, ":") || "-"}
              </td>
              <td>
                <button
                  className={li.uyn === "Y" ? "green" : "red"}
                  disabled={li.uyn === "Y"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setInfo({
                      isOpen: true,
                      rmNo: li.rmNo,
                      opRgSeq: li.opRgSeq,
                      uyn: "Y",
                    });
                  }}
                >
                  {li.uyn === "Y" ? "사용" : "미사용"}
                </button>
              </td>
            </tr>
          );
        })}
      <Modal
        title="상태 변경"
        isOpen={info.isOpen}
        cnlText="취소"
        isLoading={statusMutation.isLoading}
        disabled={statusMutation.isLoading}
        handleCloseModal={() => {
          setInfo({
            isOpen: false,
            rmNo: "",
            opRgSeq: "",
            uyn: "",
          });
        }}
        onClick={handleStatus}
      >
        <div>객실옵션 상태를 변경 하시겠습니까?</div>
      </Modal>
    </>
  );
};

type IProps = {
  memType: "admin" | "manager";
  searchInfo: rmOptSearchProps;
};
const RoomOptionList = ({ memType, searchInfo }: IProps) => {
  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const headList = roomOpListHead.map((head) => {
    return head;
  });

  const tableRef = useRef<HTMLDivElement>(null);
  const [No, lodGrpNo, lodGrpNm, lodNo, lodNm, ...res] = headList;
  const col = memType === "admin" ? roomOpListHead.length : res.length + 1;

  return (
    <div className="list-wrap" ref={tableRef}>
      <SearchHeader pageNav={pageNav} />
      <div>
        <table>
          <thead>
            {memType === "admin" && (
              <tr>
                {headList.map((li) => (
                  <th key={li}>{li}</th>
                ))}
              </tr>
            )}
            {memType === "manager" && (
              <tr>
                {[No, ...res].map((li) => (
                  <th key={li}>{li}</th>
                ))}
              </tr>
            )}
          </thead>

          <tbody>
            <Suspense>
              <List
                searchInfo={searchInfo}
                memType={memType}
                col={col}
                setPageNav={setPageNav}
              />
            </Suspense>
            {pageNav && pageNav?.totalRows > 0 && (
              <tr>
                <td colSpan={col}>
                  <Pagenation total={pageNav?.totalPage} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RoomOptionList;
