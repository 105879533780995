import CheckBox from "components/checkbox";
import DatePick from "components/datePick";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import THeader from "components/layout/header/subHeader/tHeader";
import LodChlSelect from "components/select/room/lodChlSelect";
import LodGrpSelect from "components/select/room/lodGrpNoSelect";
import LodNmSelect from "components/select/room/lodNmSelect";
import RmNmSelect from "components/select/room/rmNmSelect";
import RmOpSelect from "components/select/room/rmOpSelect";
import { optionProps } from "interface";
import { rmPrdCreateProps } from "interface/room";
import i18n from "locales/i18n";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { rmListSelector, rmOpListSelector } from "store/roomSlice";
import { dayOptions } from "util/option";

type IProps = {
  rmInf: {
    rmOlkNm: string;
    rmTypNm: string;
  };
  rmOpInf: {
    brkfInYn: string;
    latChkOutTi: string;
  };
  info: rmPrdCreateProps;
  handleChange(name: string, value: string): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleCreate(): void;
};

const RmPdCreateView = ({
  info,
  rmInf,
  rmOpInf,
  handleChange,
  handleSelect,
  handleCreate,
}: IProps) => {
  const { pathname } = useLocation();
  const lng = i18n.language;
  const txtRef = useRef<HTMLDivElement>(null);

  let rmList = useSelector(rmListSelector);
  let rmInfo = rmList && rmList.find((li) => li.rmNo === info.rmNo);
  const opList = useSelector(rmOpListSelector);

  const [opInfo, setOpInfo] = useState<any>(null);

  useEffect(() => {
    if (opList) {
      setOpInfo(
        opList &&
          info?.rmOpSeq &&
          opList.find((li) => {
            return li.rgSeq === Number(info?.rmOpSeq);
          })
      );
    }
    return () => {
      setOpInfo(null);
    };
  }, [opList, info?.rmOpSeq]);

  const isAble = Object.values(info).some((value) => {
    return value === "" || value === "NNNNNNN";
  });

  useEffect(() => {
    if (txtRef.current) {
      txtRef.current.innerHTML = opInfo?.rmOpDesc ?? "";
    }
  }, [txtRef.current, opInfo]);

  const [infOpen, setInfOpen] = useState(true);

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader sbTtl={"객실상품 생성"} clsBtn isBack handleReset={() => {}} />
        <div className="tb-form">
          <ITHeader
            sbTtl={"기본정보"}
            clsBtn
            createBtn
            isClose={infOpen}
            desc="* 필수입력"
            handleCreate={handleCreate}
            handleClose={() => {
              setInfOpen(!infOpen);
            }}
          />
          <div className={infOpen ? "" : "hidden"}>
            {pathname.includes("admin") && (
              <ul>
                <li>
                  <label htmlFor="">숙소그룹명</label>
                  <LodGrpSelect
                    option={info.lodGrpNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </li>
                <li>
                  <label htmlFor="">숙소명</label>
                  <LodNmSelect
                    disabled={!info.lodGrpNo}
                    params={info.lodGrpNo}
                    option={info.lodNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </li>
                <li>
                  <label htmlFor="" data-dot>
                    객실명
                  </label>
                  <RmNmSelect
                    params={info.lodNo}
                    option={info.rmNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </li>
              </ul>
            )}
            {pathname.includes("manager") && (
              <ul>
                <li>
                  <label htmlFor="">숙소그룹명</label>
                  <input type="text" value={info?.lodGrpNm} readOnly />
                </li>
                <li>
                  <label htmlFor="">숙소명</label>
                  <input type="text" value={info?.lodNm} readOnly />
                </li>
                <li>
                  <label htmlFor="" data-dot>
                    객실명
                  </label>
                  <RmNmSelect
                    params={info.lodNo}
                    option={info.rmNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </li>
              </ul>
            )}
            <ul>
              <li>
                <label htmlFor="">객실타입</label>
                <input type="text" defaultValue={rmInf.rmTypNm} readOnly />
              </li>
              <li>
                <label htmlFor="">객실전망</label>
                <input type="text" defaultValue={rmInf.rmOlkNm} readOnly />
              </li>
              <li>
                <label htmlFor="" data-dot>
                  객실상품채널
                </label>
                <LodChlSelect
                  option={info.lodChlCd}
                  onSelect={(target) => handleSelect(target)}
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="" data-dot>
                  객실옵션
                </label>
                <RmOpSelect
                  disabled={!info.rmNo}
                  params={info.rmNo}
                  option={opInfo?.rgSeq ?? ""}
                  onSelect={(target) => handleSelect(target)}
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">객실상품 조식포함</label>
                <input
                  type="text"
                  defaultValue={rmOpInf.brkfInYn === "Y" ? "포함" : "미포함"}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">레이트 체크아웃시간</label>
                <input
                  type="text"
                  defaultValue={rmOpInf.latChkOutTi}
                  readOnly
                />
              </li>
              {/* <li>
                  <label htmlFor="">객실특가할인</label>
                  <input type="text" defaultValue={""} readOnly />
                </li> */}
            </ul>
            <ul>
              <li>
                <label htmlFor="" data-dot>
                  상품생성수량
                </label>
                <input
                  type="text"
                  value={info.rmPdCt}
                  onChange={(e) => {
                    const value = e.currentTarget.value.replaceAll(",", "");
                    const isNum = !/[^0-9]/.test(value);
                    if (isNum || !e.currentTarget.value) {
                      handleChange("rmPdCt", value);
                    } else {
                      return;
                    }
                  }}
                />
              </li>
              <li>
                <label htmlFor="" data-dot>
                  상품금액
                </label>
                <input
                  type="text"
                  name="rmPdSellPri"
                  value={info.rmPdSellPri.replace(
                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                    ","
                  )}
                  onChange={(e) => {
                    const value = e.currentTarget.value.replaceAll(",", "");
                    const isNum = !/[^0-9]/.test(value);
                    if (isNum || !e.currentTarget.value) {
                      handleChange("rmPdSellPri", value);
                    } else {
                      return;
                    }
                  }}
                />
              </li>
            </ul>
            <ul>
              <li>
                <DatePick
                  startNm={"rmPdSDt"}
                  endNm={"rmPdEDt"}
                  start={info.rmPdSDt}
                  end={info.rmPdEDt}
                  label="시작/종료 일자"
                  handleChange={(name, value) => handleChange(name, value)}
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">적용요일</label>
                <div className="flex">
                  {dayOptions.map((day, index) => (
                    <CheckBox
                      key={day.value}
                      label={day[i18n.language as keyof optionProps]}
                      id={day.value}
                      checked={
                        info.rmPdAplyDow.charAt(Number(day.value)) === "Y"
                      }
                      handleCheckbox={(e) => {
                        const id = e.currentTarget.id;
                        const checked = e.currentTarget.checked;
                        const day =
                          info.rmPdAplyDow.substring(0, Number(id)) +
                          `${checked ? "Y" : "N"}` +
                          info.rmPdAplyDow.substring(Number(id) + 1);

                        handleChange("rmPdAplyDow", day);
                      }}
                    />
                  ))}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default RmPdCreateView;
