import { useMutation } from "@tanstack/react-query";
import { rmOlkListApi } from "components/api/comn";
import Select from "components/select";
import { optionProps } from "interface";
import { cdList } from "interface/lod";
import { useEffect, useState } from "react";

type IProps = {
  option: string;
  disabled?: boolean;
  isArrow?: boolean;
  wrapStyle?: string;
  onSelect(target: EventTarget & HTMLLIElement): void;
};

const RmOlkSelect = ({
  disabled,
  wrapStyle,
  isArrow = false,
  option,
  onSelect,
}: IProps) => {
  const [options, setOptions] = useState<optionProps[]>([]);

  const listMutation = useMutation({
    mutationKey: ["rmOlkList"],
    mutationFn: rmOlkListApi,
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;

        if (code === 0) {
          const options = body.cdList.map((li: cdList) => {
            const option = {
              value: li.lodCd,
              kr: li.codNm,
              us: li.lodCd,
            };
            return option;
          });

          setOptions([...options]);
        }
        if (code === -1) {
          setOptions([]);
        }
      }
    },
  });

  useEffect(() => {
    listMutation.mutate();

    return () => {
      listMutation.mutate();
    };
  }, []);

  return (
    <Select
      id="rmOlkCd"
      name="rmOlkCd"
      wrapStyle={wrapStyle}
      disabled={disabled}
      isArrow={isArrow}
      option={option || "A"}
      options={options}
      onSelect={onSelect}
    />
  );
};

export default RmOlkSelect;
