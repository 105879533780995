type IProps = {
  id: string;
  name: string;
  label: string;
  disable?: boolean;
  defaultChecked?: boolean;
  labelstyles?: string;
  checked?: boolean;
  onChange(name: string, value: string): void;
};
const Redio = ({
  id,
  name,
  label,
  disable = false,
  defaultChecked,
  checked,
  labelstyles = "",
  onChange,
}: IProps) => {
  return (
    <>
      <div className={"radio-wrap"}>
        <input
          type="radio"
          id={id}
          name={name}
          defaultChecked={defaultChecked}
          disabled={disable}
          checked={checked}
          onChange={(e) => onChange(name, id)}
        />
        <label htmlFor={id} className={`${labelstyles}`}>
          {label}
        </label>
      </div>
    </>
  );
};

export default Redio;
