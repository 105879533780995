import { rmListHead } from "util/option";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Pagenation from "components/pagenation";
import {
  Dispatch,
  SetStateAction,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import {
  isSearchSelector,
  setIsLoading,
  setMessage,
  setIsSearch,
} from "store/commonSlice";
import { useSelector } from "react-redux";
import { rmList, rmSearchProps } from "interface/room";
import { rmListApi, rmModifyApi } from "components/api/room";
import { pageNavProps } from "interface";
import i18n from "locales/i18n";
import Modal from "components/modal/modal";
import "../list.scss";
import SearchHeader from "components/layout/header/subHeader/srchHeader";

type IListProps = {
  searchInfo: rmSearchProps;
  memType: "admin" | "manager";
  col: number;
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};

const List = ({ searchInfo, memType, col, setPageNav }: IListProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { pathname, search, state } = useLocation();
  const [info, setInfo] = useState({
    isOpen: false,
    rmNo: "",
    uyn: "",
  });
  const isSearch = useSelector(isSearchSelector);

  const [list, setList] = useState([]);

  const rmListMutation = useMutation(rmListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        dispatch(setIsLoading(false));
        dispatch(setIsSearch(false));
        if (code === 0) {
          setPageNav(body?.pageNav);
          setList(body?.rmList);
        }
        if (code === -1) {
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  const statusMutation = useMutation(rmModifyApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;

        if (code === 0) {
          dispatch(
            setMessage({
              message: "객실 상태를 변경하였습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setIsSearch(true));
                dispatch(setMessage({ message: null }));
                setInfo({
                  isOpen: false,
                  rmNo: "",
                  uyn: "",
                });
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
          setInfo({
            isOpen: false,
            rmNo: "",
            uyn: "",
          });
        }
      }
    },
  });

  const handleStatus = () => {
    const params = {
      rmNo: info.rmNo,
      uyn: info.uyn,
      langCd: i18n.language,
    };

    statusMutation.mutate(params);
  };

  let page = search.replace("?page=", "") || 0;

 
  
  useEffect(() => {
    // if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    // }
  }, [ search]);

  useEffect(() => {
    if (isSearch) {
      const params = {
        lodGrpNo: searchInfo?.lodGrpNo || state?.lodGrpNo,
        lodNo: searchInfo?.lodNo || state?.lodNo,
        lodNm: searchInfo?.lodNm || state?.lodNm,
        rmNo: searchInfo?.rmNo || state?.rmNo,
        rmNm: searchInfo?.rmNm || state?.rmNm,
        rmTypCd: searchInfo?.rmTypCd || state?.rmTypCd,
        rmOlkCd: searchInfo?.rmOlkCd || state?.rmOlkCd,
        maxPerCt: searchInfo?.maxPerCt || state?.maxPerCt,
        minPerCt: searchInfo?.minPerCt || state?.minPerCt,
        uyn: searchInfo?.uyn || state?.uyn,
        pageNo: page || state?.pageNo,
      };

      rmListMutation.mutate(params);
    }
  }, [isSearch, searchInfo, search]);

  const pageRows = rmListMutation.data?.body?.pageNav?.pageRows;
  const isNotData = !pageRows;


  const pageNav = rmListMutation.data?.body.pageNav



  return (
    <>
      {list.length === 0 && (
        <tr>
          <td colSpan={col}>
            {isNotData && (
              <span className="font-bold">검색조건을 선택하여 주세요.</span>
            )}
            {!isNotData && (
              <span className="font-bold">검색결과가 없습니다.</span>
            )}
          </td>
        </tr>
      )}
      {list &&
        list?.map((li: rmList, index: number) => {
          // const No = pageNav?.totalRows - ((Number(page ) -1) * pageRows + index)
          const No = (Number(page) - 1) * (pageRows || 10) + index + 1
          return (
            <tr
              key={index}
              onClick={(e) => {
                e.preventDefault();
                navigate(`info?rmNo=${li.rmNo}`, {
                  state: {
                    searchInfo,
                    url: pathname + search,
                  },
                });
                dispatch(setIsSearch(false));
              }}
            >
              <td>{No}</td>
              {/* <td>{(Number(page) - 1) * (pageRows || 10) + index + 1}</td> */}
              {memType === "admin" && (
                <td>
                  <button
                    onClick={() => {
                      navigate(`?lodGrpNo=${li.lodGrpNo}`);
                    }}
                  >
                    {li.lodGrpNo}
                  </button>
                </td>
              )}
              {memType === "admin" && <td>{li.lodGrpNm}</td>}
              {memType === "admin" && <td>{li.lodNo}</td>}
              {memType === "admin" && (
                <td>
                  <span className="w-[12.5rem]">{li.lodNm}</span>
                </td>
              )}
              <td>{li.rmNo}</td>
              <td>{li.rmNm}</td>
              <td>{li.rmTypNm}</td>
              <td>{li.rmOlkNm}</td>
              <td>{li.maxPerCt}</td>

              <td>
                <button
                  className={li.uyn === "Y" ? "green" : "red"}
                  disabled={li.uyn === "Y"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setInfo({
                      isOpen: true,
                      rmNo: li.rmNo,
                      uyn: "Y",
                    });
                  }}
                >
                  {li.uyn === "Y" ? "사용" : "미사용"}
                </button>
              </td>
            </tr>
          );
        })}
      <Modal
        title="상태 변경"
        isOpen={info.isOpen}
        cnlText="취소"
        isLoading={statusMutation.isLoading}
        disabled={statusMutation.isLoading}
        handleCloseModal={() => {
          setInfo({
            isOpen: false,
            rmNo: "",
            uyn: "",
          });
        }}
        onClick={handleStatus}
      >
        <div>객실 상태를 변경 하시겠습니까?</div>
      </Modal>
    </>
  );
};
type IProps = {
  memType: "admin" | "manager";
  searchInfo: rmSearchProps;
};
const RoomList = ({ searchInfo, memType }: IProps) => {
  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const headList = rmListHead.map((head) => {
    return head;
  });

  const [No, lodGrpNo, lodGrpNm, lodNo, lodNm, ...res] = headList;

  const tableRef = useRef<HTMLDivElement>(null);
  const col = memType === "admin" ? rmListHead.length : res.length + 1;

  return (
    <div className="list-wrap" ref={tableRef}>
      <SearchHeader pageNav={pageNav} />
      <div>
        <table>
          <thead>
            {memType === "admin" && (
              <tr>
                {headList.map((li) => (
                  <th key={li}>{li}</th>
                ))}
              </tr>
            )}

            {memType === "manager" && (
              <tr>
                {[No, ...res].map((li) => (
                  <th key={li}>{li}</th>
                ))}
              </tr>
            )}
          </thead>

          <tbody>
            <Suspense>
              <List
                searchInfo={searchInfo}
                setPageNav={setPageNav}
                memType={memType}
                col={col}
              />
            </Suspense>
            {pageNav && pageNav?.totalRows > 0 && (
              <tr>
                <td colSpan={col}>
                  <Pagenation total={pageNav?.totalPage} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RoomList;
