import RoomOptionView from "components/view/room/opt/list";
import { rmOptSearchProps } from "interface/room";
import i18n from "locales/i18n";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setIsLoading, setIsSearch } from "store/commonSlice";

const RoomOptPage = () => {
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const memType = pathname.includes("admin") ? "admin" : "manager";

  let manInf = localStorage.getItem("manInf")
    ? JSON.parse(localStorage.getItem("manInf") ?? "")
    : null;
  let lodNo = localStorage.getItem("lodNo") ?? "";

  const [searchInfo, setSearchInfo] = useState<rmOptSearchProps>({
    lodGrpNo: state?.lodGrpNo || manInf?.manLodGrpNo,
    lodNo: state?.lodNo || lodNo,
    langCd: i18n.language,
    lodGrpCd: state?.lodGrpCd ?? "",
    rmNo: state?.rmNo ?? "",
    rmNm: state?.rmNm ?? "",
    rmOpNm: state?.rmOpNm ?? "",
    opRgSeq: state?.opRgSeq ?? "",
    brkfInYn: state?.brkfInYn ?? "",
    latChkOutTi: state?.latChkOutTi ?? "",
    uyn: state?.uyn ?? "",
    searchTyp: "",
  });

  const handleChange = (target: EventTarget & HTMLInputElement) => {
    const { name, value } = target;
    setSearchInfo({
      ...searchInfo,
      [name]: name === "latChkOutTi" ? value?.replace(":", "") : value,
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { lng, name, value } = target.dataset;
    if (name && value) {
      if (name === "lodGrpNo") {
        setSearchInfo({
          ...searchInfo,
          lodNo: "",
          [name]: value === "A" ? "" : value,
        });
        return;
      }
      setSearchInfo({
        ...searchInfo,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const handleReset = () => {
    setSearchInfo({
      lodGrpNo: manInf.manLodGrpNo?.lodGrpNo,
      lodNo: localStorage.getItem("sltLodNo") ?? "",
      langCd: i18n.language,
      lodGrpCd: "",
      rmNo: "",
      rmNm: "",
      rmOpNm: "",
      opRgSeq: "",
      brkfInYn: "",
      latChkOutTi: "",
      uyn: "",
      pageNo: 1,
      searchTyp: "",
    });
    dispatch(setIsLoading(false));
    dispatch(setIsSearch(false));
  };

  return (
    <RoomOptionView
      memType={memType}
      searchInfo={searchInfo}
      handleChange={handleChange}
      handleSelect={handleSelect}
      handleReset={handleReset}
    />
  );
};

export default RoomOptPage;
