import THeader from "components/layout/header/subHeader/tHeader";
import InfModify from "./infModify";
import AdInfModify from "./adInfModify";

type IProps = {
  rmOpDtl: any;
  adInfList: any[];
};

const RmOpModifyView = ({ rmOpDtl, adInfList }: IProps) => {
  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader sbTtl={"객실옵션 수정"} clsBtn handleReset={() => {}} />
        <InfModify rmOpDtl={rmOpDtl} />
        <AdInfModify adInfList={adInfList} />
      </div>
    </>
  );
};

export default RmOpModifyView;
