import { useMutation } from "@tanstack/react-query";
import { rmOPInfModifyApi } from "components/api/room";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import Select from "components/select";
import { IrmOpInfo } from "interface/room";
import i18n from "locales/i18n";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { brkfInYnOptions, lastTimeOptions, uYnOptions } from "util/option";

type IProps = {
  rmOpDtl: IrmOpInfo;
  // lodInf: {
  //   lodGrpNo: string;
  //   lodNo: string;
  // };
};

const InfModify = ({ rmOpDtl }: IProps) => {
  const dispatch = useDispatch();
  const { state, search } = useLocation();
  const opRgSeq = search.replace("?opRgSeq=", "");
  const [inf, setInf] = useState({
    brkfInYn: "",
    latChkOutTi: "",
    uyn: "",
  });
  const [infOpen, setInfOpen] = useState(true);
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setInf({
        ...inf,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const infMutation = useMutation(rmOPInfModifyApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "기본정보를 수정하였습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });
  const handleModify = () => {
    const params = {
      rmNo: state.rmNo,
      opRgSeq,
      langCd: i18n.language,
      ...inf,
    };

    dispatch(
      setMessage({
        message: "기본정보를 수정하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          infMutation.mutate(params);
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"기본정보"}
          mdfyBtn
          clsBtn
          isClose={infOpen}
          handleModify={handleModify}
          handleClose={() => {
            setInfOpen(!infOpen);
          }}
        />
        <div className={infOpen ? "" : "hidden"}>
          <ul>
            <li>
              <label htmlFor="">숙소그룹명</label>
              <input type="text" defaultValue={rmOpDtl.lodGrpNm} readOnly />
            </li>
            <li>
              <label htmlFor="">숙소명</label>
              <input type="text" defaultValue={rmOpDtl.lodNm} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">숙소체크인시간</label>
              <input
                type="text"
                value={
                  rmOpDtl?.chkInTi?.replace(/\B(?=(\d{2})+(?!\d))/g, ":") ?? ""
                }
                readOnly
              />
            </li>
            <li>
              <label htmlFor="">숙소체크아웃시간</label>
              <input
                type="text"
                value={
                  rmOpDtl?.chkOutTi?.replace(/\B(?=(\d{2})+(?!\d))/g, ":") ?? ""
                }
                readOnly
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">객실옵션 조식포함</label>
              <Select
                id={"brkfInYn"}
                name={"brkfInYn"}
                wrapStyle="w-[12.5rem]"
                option={inf.brkfInYn || rmOpDtl.brkfInYn}
                options={brkfInYnOptions}
                isInitial
                onSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="">레이트 체크아웃시간</label>
              <Select
                id={"latChkOutTi"}
                name={"latChkOutTi"}
                wrapStyle="w-[12.5rem]"
                option={
                  inf.latChkOutTi?.replace(/\B(?=(\d{2})+(?!\d))/g, ":") ||
                  rmOpDtl?.latChkOutTi?.replace(/\B(?=(\d{2})+(?!\d))/g, ":")
                }
                options={lastTimeOptions}
                onSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="">상태</label>
              <Select
                id={"uyn"}
                name={"uyn"}
                wrapStyle="w-[12.5rem]"
                option={inf.uyn || "Y"}
                options={uYnOptions}
                isInitial
                onSelect={handleSelect}
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default InfModify;
