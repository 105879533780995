import "./term.scss";
import { prvProps } from "interface/term";
import { useLocation } from "react-router-dom";
import THeader from "components/layout/header/subHeader/tHeader";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import { useState } from "react";
import Select from "components/select";
import { essYnOption, uYnOptions } from "util/option";
import InTuiEditor from "components/tuiEditor/inEditot";
import DatePick from "components/datePick/datePick";

type IProps = {
  inf: prvProps;
  prvDtl: any;
  handleChange(name: string, value: string): void;
  handleModify(): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
};

const TrmModifyView = ({
  inf,
  prvDtl,
  handleChange,
  handleModify,
  handleSelect,
}: IProps) => {
  const { pathname } = useLocation();

  const [infOpen, setInfOpen] = useState(true);

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader sbTtl={"약관내용수정"} clsBtn handleReset={() => {}} />
        <div className="tb-form">
          <ITHeader
            sbTtl={"기본정보"}
            clsBtn
            mdfyBtn
            url={pathname}
            isClose={infOpen}
            handleModify={handleModify}
            handleClose={() => {
              setInfOpen(!infOpen);
            }}
          />
          <div className={infOpen ? "" : "hidden"}>
            <ul>
              <li>
                <label htmlFor="">약관명</label>
                <input
                  type="text"
                  defaultValue={prvDtl?.prvNm || inf.prvNm}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">약관버전명</label>
                <input
                  type="text"
                  defaultValue={prvDtl?.verNm || inf.verNm}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">약관코드</label>
                <input
                  type="text"
                  defaultValue={prvDtl?.prvCd || inf.prvCd}
                  readOnly
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">필수여부</label>
                <Select
                  id={"essYn"}
                  name={"essYn"}
                  option={prvDtl?.essYn || inf.essYn}
                  options={essYnOption}
                  onSelect={handleSelect}
                />
              </li>
              <li>
                <label htmlFor="">적용여부</label>
                <Select
                  id={"uyn"}
                  name={"uyn"}
                  option={prvDtl?.uyn || inf.uyn}
                  options={uYnOptions}
                  onSelect={handleSelect}
                />
              </li>
            </ul>
            <ul>
              <li>
                <DatePick
                  label={"적용시작일"}
                  name={"aplySDt"}
                  date={prvDtl?.aplySDt || inf.aplySDt}
                  handleChange={(name, value) => handleChange(name, value)}
                />
              </li>
              <li>
                <DatePick
                  label={"적용종료일"}
                  name={"aplyEDt"}
                  date={prvDtl?.aplyEDt || inf.aplyEDt}
                  handleChange={(name, value) => handleChange(name, value)}
                />
              </li>
            </ul>
            <ul>
              <li data-full>
                <label htmlFor="">약관설명</label>
                <input
                  type="text"
                  defaultValue={prvDtl?.prvDesc || inf.prvDesc}
                  onChange={(e) => handleChange("prvDesc", e.target.value)}
                />
              </li>
            </ul>
            <ul>
              <li data-full>
                <label htmlFor="">약관내용</label>
                <InTuiEditor
                  name={"trmCn"}
                  minH="300px"
                  onChange={(value) => handleChange("trmCn", value)}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrmModifyView;
