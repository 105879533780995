import ITHeader from "components/layout/header/subHeader/itemHeader";
import THeader from "components/layout/header/subHeader/tHeader";
import SmallSelect from "components/select/smSelect";
import ReadTuiEditor from "components/tuiEditor/readEditor";
import { langProps } from "interface";
import i18n from "locales/i18n";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { langOptions } from "util/option";
type IProps = {
  memType: "admin" | "manager";
  rmOpDtl: any;
  addInfList: any[];
};

const RoomOptInfoView = ({ rmOpDtl, addInfList }: IProps) => {
  const { pathname, search, state } = useLocation();
  const navigate = useNavigate();

  let langs = [] as langProps[];
  langOptions.forEach((lang) => {
    const isAble = addInfList.some((ad) => {
      return lang.value === ad.langCd;
    });

    if (isAble) {
      langs.push(lang);
    }
  });

  const [langCd, setLangCd] = useState(i18n.language);

  const [infOpen, setInfOpen] = useState(true);
  const [addOpen, setAddOpen] = useState(true);

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setLangCd(value);
    }
  };

  const adInf = addInfList.find((ad) => {
    return ad?.langCd === langCd;
  });

  return (
    <div className="main-cont" data-dir-col>
      <THeader
        sbTtl={"객실옵션상세"}
        clsBtn
        mdfyBtn
        url={pathname}
        handleReset={() => {}}
      >
        <button
          className="btn-sm bg-red-400 text-white-0"
          onClick={() =>
            navigate(pathname.replace("info", "modify") + search, {
              state: state,
            })
          }
        >
          수정
        </button>
      </THeader>
      <div className="tb-form">
        <ITHeader
          sbTtl={"기본정보"}
          clsBtn
          isClose={infOpen}
          handleClose={() => setInfOpen(!infOpen)}
        />
        <div className={infOpen ? "" : "hidden"}>
          <ul>
            <li>
              <label htmlFor="">숙소그룹명</label>
              <input type="text" defaultValue={rmOpDtl?.lodGrpNm} readOnly />
            </li>
            <li>
              <label htmlFor="">숙소명</label>
              <input type="text" defaultValue={rmOpDtl?.lodNm} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">숙소체크인시간</label>
              <input
                type="text"
                value={
                  rmOpDtl?.chkInTi?.replace(/\B(?=(\d{2})+(?!\d))/g, ":") ?? ""
                }
                readOnly
              />
            </li>
            <li>
              <label htmlFor="">숙소체크아웃시간</label>
              <input
                type="text"
                value={
                  rmOpDtl?.chkOutTi?.replace(/\B(?=(\d{2})+(?!\d))/g, ":") ?? ""
                }
                readOnly
              />
            </li>
            <li>
              <label htmlFor="">객실옵션 조식포함</label>
              <input
                type="text"
                readOnly
                value={rmOpDtl?.brkfInYn === "Y" ? "포함" : "미포함"}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">레이트 체크아웃시간</label>
              <input
                type="text"
                defaultValue={rmOpDtl?.latChkOutTi?.replace(
                  /\B(?=(\d{2})+(?!\d))/g,
                  ":"
                )}
                readOnly
              />
            </li>
            <li>
              <label htmlFor="">상태</label>
              <input
                type="text"
                value={rmOpDtl?.uyn === "Y" ? "사용" : "미사용"}
                readOnly
              />
            </li>
          </ul>
        </div>
      </div>
      <div className="tb-form">
        <ITHeader
          sbTtl={"추가정보"}
          clsBtn
          isClose={addOpen}
          desc={
            langs.length > 1
              ? "언어를 선택하여 언어별 추가정보를 확인하세요."
              : ""
          }
          handleClose={() => setAddOpen(!addOpen)}
        >
          {addInfList.length > 0 && (
            <SmallSelect
              option={langCd}
              options={langs}
              handleSelect={handleSelect}
            />
          )}
        </ITHeader>
        <div className={addOpen ? "" : "hidden"}>
          <ul>
            <li data-full>
              <label htmlFor="">객실옵션명</label>
              <input
                type="text"
                defaultValue={adInf?.rmOpNm || ""}
                readOnly
                onChange={() => {}}
              />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="" data-center>
                객실옵션설명
              </label>
              <ReadTuiEditor name={"rmDesc"} value={adInf?.rmOpDesc} />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RoomOptInfoView;
