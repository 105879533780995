import THeader from "components/layout/header/subHeader/tHeader";
import PayList from "components/view/home/payList";
import { useLocation } from "react-router-dom";

const AdminHomePage = () => {
  const { pathname } = useLocation();
  return (
    <div className="main-cont" data-dir-col>
      <THeader sbTtl={"홈"} url={pathname} handleReset={() => {}} />
      <div className="home-wrap">
        <PayList payInfo={{} as any} lodNo={""} />
      </div>
    </div>
  );
};

export default AdminHomePage;
