import ManModifyView from "./manModify";
import InfModifyView from "./infModify";
import PayModifyView from "./payModify";
import AdInfModifyView from "./adInfModify";
import THeader from "components/layout/header/subHeader/tHeader";
import InImgModifyView from "./inImgModify";
import OutImgModifyView from "./outImgModify";
import LodInfModifyView from "./lodModify";

const lodModifyView = () => (
  <div className="main-cont" data-dir-col>
    <THeader sbTtl={"숙소수정"} clsBtn handleReset={() => {}} />
    <LodInfModifyView />
    <InfModifyView />
    <ManModifyView />
    <PayModifyView />
    <AdInfModifyView />
    <InImgModifyView />
    <OutImgModifyView />
    {/* <LodImgModifyView /> */}
  </div>
);

export default lodModifyView;
