import { useMutation } from "@tanstack/react-query";
import { lodGrpListApi } from "components/api/member";
import Select from "components/select";
import { optionProps } from "interface";
import { IlodGrpList } from "interface/member";
import { useEffect, useState } from "react";

type IProps = {
  option: string;
  isArrow?: boolean;
  disabled?: boolean;
  onSelect(target: EventTarget & HTMLLIElement): void;
};

const LodGrpSelect = ({
  isArrow = false,
  disabled = false,
  option,
  onSelect,
}: IProps) => {
  const [options, setOptions] = useState<optionProps[]>([]);

  const listMutation = useMutation({
    mutationKey: ["lodGrpList"],
    mutationFn: lodGrpListApi,
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;

        if (code === 0) {
          const options = body.lodGrpList.map((li: IlodGrpList) => {
            const option = {
              value: li.lodGrpNo,
              kr: li.lodGrpNo,
              us: li.lodGrpNo,
            };
            return option;
          });

          setOptions(options);
        }
        if (code === -1) {
          setOptions([]);
        }
      }
    },
  });

  useEffect(() => {
    listMutation.mutate();

    return () => {
      listMutation.mutate();
    };
  }, []);

  return (
    <Select
      id="lodGrpNo"
      name="lodGrpNo"
      isSusess={listMutation.data?.header?.code === 0}
      option={option || "A"}
      options={options}
      isArrow={isArrow}
      disabled={disabled}
      onSelect={onSelect}
    />
  );
};

export default LodGrpSelect;
