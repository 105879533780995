import { rmBkListHead } from "util/option";
import { useLocation, useNavigate } from "react-router-dom";
import Pagenation from "components/pagenation";
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import {
  isSearchSelector,
  setIsLoading,
  setIsSearch,
  setMessage,
} from "store/commonSlice";
import { rmResvSearchProps } from "interface/room";
import { useSelector } from "react-redux";
import { pageNavProps } from "interface";
import { rmResvListApi } from "components/api/room";
import { dateFormat, inCom } from "util/common";
import SearchHeader from "components/layout/header/subHeader/srchHeader";

type IListProps = {
  searchInfo: rmResvSearchProps;
  memType: "admin" | "manager";
  col: number;
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};

const List = ({ searchInfo, memType, col, setPageNav }: IListProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();
  const isSearch = useSelector(isSearchSelector);

  const [list, setList] = useState([]);

  const rmResvMutation = useMutation(rmResvListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code == 0) {
          setPageNav(body?.pageNav);
          setList(body?.rmResvList);
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));
        }
        if (code === -1) {
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch) {
      const {
        rmNo,
        rmNm,
        rmTypCd,
        rmOlkCd,
        maxPerCtMax,
        maxPerCtMin,
        rmPdNo,
        lodChlCd,
        rmOpNm,
        promCd,
        promNm,
        rmResvNo,
        resvMemFstnm,
        resvMemMobNo,
        resvCstFstnm,
        resvCstMblNo,
        sellEDt,
        sellSDt,
        resvEDt,
        resvSDt,
        dateTyp,
      } = searchInfo;
      const params = {
        rmNo: rmNo ?? state?.rmNo,
        rmNm: rmNm ?? state?.rmNm,
        rmTypCd: rmTypCd ?? state?.rmTypCd,
        rmOlkCd: rmOlkCd ?? state?.rmOlkCd,
        maxPerCtMin: Number(maxPerCtMin) ?? state?.maxPerCtMin ?? -1,
        maxPerCtMax: Number(maxPerCtMax) ?? state?.maxperCtMax ?? -1,
        rmPdNo: rmPdNo ?? state?.rmPdNo,
        lodChlCd: lodChlCd ?? state?.lodChlCd,
        rmOpNm: rmOpNm ?? state?.rmOpNm,
        promCd: promCd ?? state?.promCd,
        promNm: promNm ?? state?.promNm,
        rmResvNo: rmResvNo ?? state?.rmResvNo,
        resvMemFstnm: resvMemFstnm ?? state?.resvMemFstnm,
        resvMemMobNo: resvMemMobNo ?? state?.resvMemMobNo,
        resvCstFstnm: resvCstFstnm ?? state?.resvCstFstnm,
        resvCstMblNo: resvCstMblNo ?? state?.resvCstMblNo,
        sellSDt:
          dateTyp === "rmPdDt"
            ? state?.sellSDt.replaceAll("-", "") ?? sellSDt.replaceAll("-", "")
            : "",
        sellEDt:
          dateTyp === "rmPdDt"
            ? state?.sellEDt.replaceAll("-", "") ?? sellEDt.replaceAll("-", "")
            : "",
        resvSDt:
          dateTyp === "rmResvDt"
            ? state?.resvSDt.replaceAll("-", "") ?? resvSDt.replaceAll("-", "")
            : "",
        resvEDt:
          dateTyp === "rmResvDt"
            ? state?.resvEDt.replaceAll("-", "") ?? resvEDt.replaceAll("-", "")
            : "",
        pageNo: Number(page),
      };

      console.log("resv params ", params);

      rmResvMutation.mutate(params);
    }
  }, [searchInfo, search, isSearch]);

  const pageRows = rmResvMutation.data?.body?.pageNav?.pageRows;
  const isNotData = !pageRows;

  return (
    <>
      {list.length === 0 && (
        <tr>
          <td colSpan={col}>
            {isNotData && (
              <span className="font-bold">검색조건을 선택하여 주세요.</span>
            )}
            {!isNotData && (
              <span className="font-bold">검색결과가 없습니다.</span>
            )}
          </td>
        </tr>
      )}
      {list &&
        list?.map((li: any, index: number) => {
          return (
            <tr
              key={index}
              onClick={() => {
                navigate(`info?rmResvNo=${li?.rmResvNo}`, {
                  state: {
                    searchInfo: searchInfo,
                    rmNo: li.rmNo,
                    rmPdNo: li.rmPdNo,
                    rmResvNo: li.rmResvNo,
                    url: pathname + search,
                  },
                });
              }}
            >
              <td>{(Number(page) - 1) * (pageRows || 10) + index + 1}</td>
              {memType === "admin" && <td>{li.lodGrpNo}</td>}
              {memType === "admin" && <td>{li.lodGrpNm}</td>}
              {memType === "admin" && <td>{li.lodNo}</td>}
              {memType === "admin" && <td>{li.lodNm}</td>}
              <td>{li.rmNo}</td>
              <td>{li.rmNm}</td>
              <td>{li.rmTypNm}</td>
              <td>{li.rmOlkNm}</td>
              <td>{li.maxPerCt}</td>
              <td>{li.rmPdNo}</td>
              <td>{li.lodChlNm}</td>
              <td>{li.rmOpNm}</td>
              <td>{dateFormat(li.sellDt)}</td>
              <td>{dateFormat(li.rmPdResvDt)}</td>
              <td>{li.rmResvNo}</td>
              <td>{li.resvMemSurnm + li.resvMemFstnm}</td>
              <td>{li.resvMemMobNo}</td>
              <td>{li.resvCstSurnm + li.resvCstFstnm}</td>
              <td>{li.resvCstMblNo}</td>
              <td>{inCom(li.sellPri)}</td>
            </tr>
          );
        })}
    </>
  );
};

type IProps = {
  memType: "admin" | "manager";
  searchInfo: rmResvSearchProps;
};

const RoomBookList = ({ memType, searchInfo }: IProps) => {
  const dispatch = useDispatch();

  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const headList = rmBkListHead.map((head) => {
    return head;
  });
  const [No, lodGrpNo, lodGrpNm, lodNo, lodNm, ...res] = headList;
  const col = memType === "admin" ? rmBkListHead.length : res.length + 1;

  return (
    <div className="list-wrap">
      <SearchHeader pageNav={pageNav} />
      <div>
        <table>
          <thead>
            {memType === "admin" && (
              <tr>
                {rmBkListHead.map((li) => (
                  <th key={li}>{li}</th>
                ))}
              </tr>
            )}
            {memType === "manager" && (
              <tr>
                {[No, ...res].map((li) => (
                  <th key={li}>{li}</th>
                ))}
              </tr>
            )}
          </thead>

          <tbody>
            <Suspense>
              <List
                searchInfo={searchInfo}
                memType={memType}
                col={col}
                setPageNav={setPageNav}
              />
            </Suspense>
            {pageNav && pageNav?.totalRows > 0 && (
              <tr>
                <td colSpan={col}>
                  <Pagenation total={pageNav?.totalPage} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RoomBookList;
