import "../select.scss";
import { useEffect, useRef, useState } from "react";
import { countryCode } from "../countryCode";
import i18n from "locales/i18n";

type IProps = {
  wrapStyle?: string;
  optionStyle?: string;
  btnStyle?: string;
  inpStyle?: string;
  disabled?: boolean;
  direct?: "up" | "down";
  option?: string;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleChange?(target: EventTarget & HTMLInputElement): void;
};

export interface codeProps {
  kr: string;
  en: string;
  phone: string;
  code: string;
}

const CountrySelect = ({
  wrapStyle = "",
  optionStyle = "bg-white",
  btnStyle = "",
  disabled = false,
  option = "",
  handleSelect,
}: IProps) => {
  const ulRef = useRef<HTMLDivElement>(null);
  const lng = i18n.language;
  const optionRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const cntNm = countryCode.find((cnt) => {
    return cnt?.code === option;
  });

  const outSideClick = (e: any) => {
    if (ulRef.current && !ulRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", outSideClick);

    return () => {
      document.removeEventListener("click", outSideClick);
    };
  }, [ulRef]);

  return (
    <div className={`${wrapStyle} phone-select-wrap`} ref={ulRef}>
      <button
        className={`${btnStyle} ${isOpen ? "on" : ""}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        disabled={disabled}
        data-value={cntNm?.code}
        data-name="country"
      >
        <span>{cntNm ? cntNm[lng as keyof codeProps] : option}</span>
      </button>
      <div
        className={`${optionStyle}  option-wrap ${isOpen ? `on` : ""} `}
        data-name="country"
        ref={optionRef}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ul className={`${isOpen ? ` on ` : ""}`}>
          {/* <li className="list-title"> */}
          {/* <span>{lng === "kr" ? "국가명" : "Country"}</span> */}
          {/* <span>국가명</span>
            <span>Country</span> */}
          {/* <span>국가코드</span> */}
          {/* </li> */}
          {countryCode.map((op: codeProps) => (
            <li
              key={op.kr}
              data-name="country"
              data-lng={op[lng as keyof codeProps]}
              data-value={op.phone}
              data-code={op.code}
              // data-selected={option === op[lng as keyof codeProps]}
              data-selected={option === op.code}
              onClick={(e) => {
                if (option === op.phone) {
                  return;
                }
                handleSelect(e.currentTarget);
                setIsOpen(!isOpen);
              }}
            >
              <span>{op[lng as keyof codeProps]}</span>
              {/* <span> {op.en}</span> */}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CountrySelect;
