import { lodDefaultHead } from "util/option";
import { IlodDft, lodDftSearchProps } from "interface/lod";
import { useLocation, useNavigate } from "react-router-dom";
import Pagenation from "components/pagenation";
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  isLoadingSelector,
  isSearchSelector,
  setIsLoading,
  setMessage,
  setIsSearch,
} from "store/commonSlice";
import { useSelector } from "react-redux";
import { pageNavProps } from "interface";
import { useMutation } from "@tanstack/react-query";
import { lodDftListApi, lodStatusApi } from "components/api/lod";
import i18n from "locales/i18n";
import SearchHeader from "components/layout/header/subHeader/srchHeader";

type IProps = {
  memType: "admin" | "manager";
  searchInfo: lodDftSearchProps;
};

type IListProps = {
  searchInfo: lodDftSearchProps;
  memType: "admin" | "manager";
  col: number;
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};

const List = ({ searchInfo, memType, col, setPageNav }: IListProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();
  const isSearch = useSelector(isSearchSelector);

  const [list, setList] = useState([]);

  const lodDftMutation = useMutation(lodDftListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        dispatch(setIsLoading(false));
        dispatch(setIsSearch(false));
        if (code === 0) {
          setList(body.lodDftList);
          setPageNav(body.pageNav);
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const statusMutation = useMutation(lodStatusApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "숙소상태를 변경하였습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                dispatch(setIsSearch(true));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleStatus = (lodNo: string, uyn: string) => {
    if (!lodNo) {
      alert("숙소번호가 없습니다");
      return;
    }
    dispatch(
      setMessage({
        message: "숙소상태를 변경하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          statusMutation.mutate({
            lodNo,
            uyn: uyn === "Y" ? "N" : "Y",
          });
        },
      })
    );
  };

  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch) {
      const params = {
        lodGrpNo: searchInfo.lodGrpNo || state?.lodGrpNo,
        lodGrpNm: searchInfo.lodGrpNm || state?.lodGrpNm,
        langCd: i18n.language,
        lodNo: searchInfo.lodNo || state?.lodNo,
        lodNm: searchInfo.lodNm || state?.lodNm,
        lodGrdCd: searchInfo.lodGrdCd || state?.lodGrdCd,
        uyn: searchInfo.uyn || state?.uyn,
        pageNo: page || state?.page,
      };

      lodDftMutation.mutate(params);
    }
  }, [isSearch, searchInfo]);

  const pageRows = lodDftMutation.data?.body?.pageNav?.pageRows;
  const isNotData = !pageRows;

  return (
    <>
      {list.length === 0 && (
        <tr>
          <td colSpan={col}>
            {isNotData && (
              <span className="font-bold">검색조건을 선택하여 주세요.</span>
            )}
            {!isNotData && (
              <span className="font-bold">검색결과가 없습니다.</span>
            )}
          </td>
        </tr>
      )}
      {list &&
        list?.map((li: IlodDft, index: number) => {
          return (
            <tr
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`info?lodNo=${li.lodNo}`, {
                  state: {
                    searchInfo,
                    url: pathname + search,
                  },
                });
              }}
            >
              <td>{index + 1}</td>
              <td>
                <button>{li.lodGrpNo}</button>
              </td>
              <td>{li.lodGrpNm}</td>
              <td>{li.lodNo ?? "-"}</td>
              <td>{li.lodNm ?? "-"} </td>
              <td>{li.lodGrdCd ?? "-"}</td>

              <td>
                <button
                  className={li.uyn === "Y" ? "green" : "red"}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleStatus(li.lodNo, li.uyn);
                  }}
                >
                  {li.uyn === "Y" ? "사용" : "미사용"}
                </button>
              </td>
            </tr>
          );
        })}
    </>
  );
};

const LodDefaultList = ({ memType, searchInfo }: IProps) => {
  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const headList = lodDefaultHead.map((head) => {
    return head;
  });

  const col = lodDefaultHead.length;

  return (
    <div className="list-wrap">
      <SearchHeader pageNav={pageNav} />
      <div>
        <table>
          <thead>
            <tr>
              {headList.map((li) => (
                <th key={li}>{li}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            <Suspense>
              <List
                searchInfo={searchInfo}
                memType={memType}
                col={col}
                setPageNav={setPageNav}
              />
              {pageNav && pageNav?.totalRows > 0 && (
                <tr>
                  <td colSpan={col}>
                    <Pagenation total={pageNav?.totalPage} />
                  </td>
                </tr>
              )}
            </Suspense>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LodDefaultList;
