import ITHeader from "components/layout/header/subHeader/itemHeader";
import THeader from "components/layout/header/subHeader/tHeader";
import { IRmHstrInfo } from "interface/room";
import i18n from "locales/i18n";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { dateFormat, getDay } from "util/common";

type IProps = {
  memType: "admin" | "manager";
  histDtl: IRmHstrInfo;
};

const RoomHstInfoView = ({ memType, histDtl }: IProps) => {
  const { state } = useLocation();
  const [infOpen, setInfOpen] = useState(true);

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader sbTtl={"객실상품 이력조회"} clsBtn handleReset={() => {}} />
        <div className="tb-form">
          <ITHeader
            sbTtl={"기본정보"}
            clsBtn
            isClose={infOpen}
            handleClose={() => {
              setInfOpen(!infOpen);
            }}
          />
          <div className={infOpen ? "" : "hidden"}>
            {/* {memType === "admin" && (
              <ul>
                <li>
                  <label htmlFor="">숙소그룹명</label>
                  <input type="text" defaultValue={histDtl.lodGrpNm} readOnly />
                </li>
                <li>
                  <label htmlFor="">숙소명</label>
                  <input type="text" defaultValue={histDtl.lodNm} readOnly />
                </li>
              </ul>
            )} */}
            <ul>
              <li>
                <label htmlFor="">객실번호</label>
                <input type="text" defaultValue={histDtl.rmNo} readOnly />
              </li>
              <li>
                <label htmlFor="">객실명</label>
                <input type="text" defaultValue={histDtl.rmNm} readOnly />
              </li>
              <li>
                <label htmlFor="">객실상채널</label>
                <input type="text" defaultValue={histDtl?.lodChlNm} readOnly />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">객실옵션명</label>
                <input type="text" defaultValue={histDtl.rmOpNm} readOnly />
              </li>
            </ul>
          </div>
          <div className="tb-form">
            <ITHeader
              sbTtl={"상품정보"}
              clsBtn
              isClose={infOpen}
              handleClose={() => {
                setInfOpen(!infOpen);
              }}
            />
            <div className={infOpen ? "" : "hidden"}>
              <ul>
                <li>
                  <label htmlFor="">객실상품일자</label>
                  <input
                    type="text"
                    defaultValue={dateFormat(histDtl.sellDt)}
                    readOnly
                  />
                </li>
                <li>
                  <label htmlFor="">객실상품상태</label>
                  <input
                    type="text"
                    defaultValue={histDtl.sellYn === "Y" ? "사용" : "미사용"}
                    readOnly
                  />
                </li>
                <li>
                  <label htmlFor="">객실상품요일</label>
                  <input
                    type="text"
                    defaultValue={getDay(histDtl.dowV - 1 ?? "", i18n.language)}
                    readOnly
                  />
                </li>
              </ul>
              <ul>
                <li>
                  <label htmlFor="">생성수량</label>
                  <input type="text" defaultValue={histDtl.rmCt} readOnly />
                </li>
                <li>
                  <label htmlFor="">취소수량</label>
                  <input type="text" defaultValue={histDtl.rmPdCt} readOnly />
                </li>
              </ul>
              <ul>
                <li>
                  <label htmlFor="">판매수량</label>
                  <input type="text" defaultValue={histDtl.sellYCt} readOnly />
                </li>
                <li>
                  <label htmlFor="">판매중지수량</label>
                  <input type="text" defaultValue={histDtl.sellNCt} readOnly />
                </li>
              </ul>
              <ul>
                <li>
                  <label htmlFor="">최초접속자명</label>
                  <input
                    type="text"
                    defaultValue={histDtl.fstCrtAscrNm}
                    readOnly
                  />
                </li>
                <li>
                  <label htmlFor="">접속자명</label>
                  <input type="text" defaultValue={histDtl.acsrNm} readOnly />
                </li>
                <li>
                  <label htmlFor="">이력상태 유형</label>
                  <input
                    type="text"
                    defaultValue={
                      histDtl.histStaTyp === "C"
                        ? "생성"
                        : histDtl?.histStaTyp === "D"
                        ? "삭제"
                        : histDtl?.histStaTyp === "U"
                        ? "수정"
                        : ""
                    }
                    readOnly
                  />
                </li>
              </ul>
              <ul>
                <li data-full>
                  <label htmlFor="">메모</label>
                  <input type="text" defaultValue={histDtl.memo} readOnly />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoomHstInfoView;
