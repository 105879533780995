import { useMutation } from "@tanstack/react-query";
import { lodManCreateApi, lodManDtlApi } from "components/api/lod";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import PhoneSelect from "components/select/phone";
import { lodManProps } from "interface/lod";
import { MutableRefObject, useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";

type IProps = {
  lodNo: MutableRefObject<string>;
};

const ManCreateView = ({ lodNo }: IProps) => {
  const dispatch = useDispatch();

  const dtlMutation = useMutation(lodManDtlApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          const {
            rgSeq,
            manMkV,
            manGrd,
            manFstnm,
            manSurnm,
            telCntyCd,
            telNo,
            uyn,
          } = body?.lodManDtl;
          setInf({
            lodNo: lodNo.current,
            rgSeq,
            manMkV,
            manGrd,
            manFstnm,
            manSurnm,
            telCntyCd,
            telNo,
            uyn,
          });
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const [infOpen, setInfOpen] = useState(false);

  const [inf, setInf] = useState<lodManProps>({
    rgSeq: 1,
    lodNo: lodNo.current ?? "",
    manMkV: 0,
    manGrd: "",
    manFstnm: "",
    manSurnm: "",
    telCntyCd: "+82",
    telNo: "",
    uyn: "Y",
  });

  const handleChange = (name: string, value: string) => {
    setInf({
      ...inf,
      [name]: value,
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setInf({
        ...inf,
        [name]: value,
      });
    }
  };

  const manMutation = useMutation(lodManCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "담당자정보가 등록되었습니다.",
              isConfirm: true,
              handleClick() {
                dtlMutation.mutate({
                  lodNo: lodNo.current,
                });
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCreate = () => {
    if (!lodNo.current) {
      return alert("기본정보를 등록후 담당자 정보를 등록하여 주세요.");
    }
    if (!inf.manFstnm) {
      return alert("담장자 영문이름을 입력하여 주세요.");
    }
    if (!inf.manSurnm) {
      return alert("담당자 영문성을 입력하여 주세요.");
    }

    dispatch(
      setMessage({
        message: "담당자정보를 등록 하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          manMutation.mutate({
            ...inf,
            lodNo: lodNo.current ?? "",
          });
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  const isSuccess = manMutation.isSuccess;

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"담당자정보"}
          clsBtn
          createBtn={!isSuccess}
          createBtnTxt="등록"
          desc="* 기본정보 등록후 담당자정보 등록이 가능합니다."
          isClose={infOpen}
          handleCreate={handleCreate}
          handleClose={() => {
            setInfOpen(!infOpen);
          }}
        />
        <div className={infOpen ? "" : "hidden"}>
          <ul>
            <li>
              <label htmlFor="">담당자 영문이름</label>
              <input
                type="text"
                // name="manFstnm"
                defaultValue={inf.manFstnm}
                readOnly={isSuccess}
                onChange={(e) =>
                  handleChange("manFstnm", e.currentTarget.value)
                }
              />
            </li>
            <li>
              <label htmlFor="">담당자 영문성</label>
              <input
                type="text"
                // name="manSurnm"
                defaultValue={inf.manSurnm}
                readOnly={isSuccess}
                onChange={(e) =>
                  handleChange("manSurnm", e.currentTarget.value)
                }
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">담당자 직급</label>
              <input
                type="text"
                // name="manGrd"
                defaultValue={inf.manGrd}
                readOnly={isSuccess}
                onChange={(e) => handleChange("manGrd", e.currentTarget.value)}
              />
            </li>
            <li>
              <label htmlFor="phone">담당자 전화번호</label>
              <div className="flex gap-x-1">
                <PhoneSelect
                  wrapStyle=""
                  name="telCntyCd"
                  mobCd={inf.telCntyCd}
                  disabled={isSuccess}
                  isNm
                  handleSelect={(target) => handleSelect(target)}
                />
                <span className="flex gap-x-1 w-full">
                  <input
                    type="text"
                    // name="telNo"
                    defaultValue={inf.telNo}
                    readOnly={isSuccess}
                    onChange={(e) =>
                      handleChange("telNo", e.currentTarget.value)
                    }
                  />
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ManCreateView;
