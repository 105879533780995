import LodDftListView from "components/view/lod/default/list";
import { lodDftSearchProps } from "interface/lod";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setIsLoading, setIsSearch } from "store/commonSlice";

const LodDefaultListPage = () => {
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const memType = pathname.includes("admin") ? "admin" : "manager";
  const manInf = localStorage.getItem("manInf")
    ? JSON.parse(localStorage.getItem("manInf") ?? "")
    : null;

  const lodNo = localStorage.getItem("lodNo") ?? "";
  const [searchInfo, setSearchInfo] = useState<lodDftSearchProps>({
    lodGrpNo: state?.lodGrpNo || manInf?.manLodGrpNo,
    lodNo: state?.lodNo || lodNo,
    lodGrdCd: state?.lodGrdCd || "",
    uyn: state?.uyn || "",
    searchTyp: state?.searchTyp || "",
  });
  const handleChange = (target: EventTarget & HTMLInputElement) => {
    const { name, value } = target;
    setSearchInfo({
      ...searchInfo,
      [name]: value,
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value, lng } = target.dataset;
    if (name && value) {
      setSearchInfo({
        ...searchInfo,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const handleReset = () => {
    setSearchInfo({
      lodGrpNo: manInf?.manLodGrpNo || "",
      lodNo: lodNo,
      lodGrdCd: "",
      uyn: "",
      searchTyp: "",
    });
    dispatch(setIsLoading(false));
    dispatch(setIsSearch(false));
  };

  return (
    <LodDftListView
      isLoading={false}
      memType={memType}
      searchInfo={searchInfo}
      handleChange={handleChange}
      handleSelect={handleSelect}
      handleReset={handleReset}
    />
  );
};

export default LodDefaultListPage;
