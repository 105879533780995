import ITHeader from "components/layout/header/subHeader/itemHeader";
import THeader from "components/layout/header/subHeader/tHeader";
import Select from "components/select";
import LodGrdSelect from "components/select/room/lodGrdSelect";
import LodGrpNmSelect from "components/select/room/lodGrpNmSelect ";
import LodNmSelect from "components/select/room/lodNmSelect";
import LodRfdOPList from "components/tableList/lod/rfdop";
import { lodRfdSearchInfoProps } from "interface/lod";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { lodGrpStatus, lodRfdStatus } from "util/option";

type IProps = {
  memType: "admin" | "manager";
  searchInfo: lodRfdSearchInfoProps;
  handleChange(name: string, value: string): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleReset(): void;
};

const LodRfdOPView = ({ memType, searchInfo, handleSelect }: IProps) => {
  const { pathname } = useLocation();
  const [infOpen, setInfOpen] = useState(true);
  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader
          sbTtl={"환불수수료옵션조회"}
          newBtn
          rstBtn
          searchBtn
          url={pathname}
          handleReset={() => {}}
        >
          {/* <button
            className="btn-sm bg-red-400 text-white-0"
            onClick={() => setIsOpen(true)}
          >
            신규
          </button> */}
        </THeader>
        <div className="tb-form" data-list>
          <ITHeader
            sbTtl={"기본정보"}
            clsBtn
            isClose={infOpen}
            handleClose={() => {
              setInfOpen(!infOpen);
            }}
          />
          <div className={infOpen ? "" : "hidden"}>
            <ul>
              <li>
                <label htmlFor="">숙소그룹명</label>
                <LodGrpNmSelect
                  isArrow={pathname.includes("manager")}
                  disabled={pathname.includes("manager")}
                  option={searchInfo.lodGrpNo}
                  onSelect={(target) => handleSelect(target)}
                />
              </li>
              {/* {pathname.includes("manager") && (
                <li>
                  <label htmlFor="">숙소그룹번호</label>
                  <input
                    type="text"
                    defaultValue={searchInfo.lodGrpNo}
                    readOnly
                  />
                </li>
              )} */}
              <li>
                <label htmlFor="">숙소명</label>
                <LodNmSelect
                  isArrow={pathname.includes("manager")}
                  disabled={
                    pathname.includes("manager") || !searchInfo?.lodGrpNo
                  }
                  option={searchInfo.lodNo}
                  params={searchInfo.lodGrpNo}
                  onSelect={(target) => handleSelect(target)}
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">숙소등급</label>
                <LodGrdSelect
                  option={searchInfo.lodGrdCd}
                  onSelect={(target) => handleSelect(target)}
                />
              </li>
              <li>
                <label htmlFor="">숙소환불수수료종류</label>
                <Select
                  id="refdFeeTypCd"
                  name="refdFeeTypCd"
                  option={searchInfo.refdFeeTypCd || "A"}
                  options={lodRfdStatus}
                  onSelect={(target) => handleSelect(target)}
                />
              </li>
              <li>
                <label htmlFor="">숙소상태</label>
                <Select
                  id="uyn"
                  name="uyn"
                  option={searchInfo.uyn}
                  options={lodGrpStatus}
                  onSelect={(target) => handleSelect(target)}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <LodRfdOPList searchInfo={searchInfo} memType={memType} />
    </>
  );
};

export default LodRfdOPView;
