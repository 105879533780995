import LoadingButton from "components/button/loadingButton";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import THeader from "components/layout/header/subHeader/tHeader";
import Modal from "components/modal/modal";
import i18n from "locales/i18n";
import { Dispatch, MutableRefObject, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { isSearchSelector } from "store/commonSlice";
import { dateFormat, getDay, inCom } from "util/common";

type Iprops = {
  memType: "manager" | "admin";
  rmResvDtl: any;
  resvPayDtl: any;
  cnlInfo: {
    cnlFeeAmt: string;
    refAmt: string;
    rmPdCt: string;
  };
  cnlRef: MutableRefObject<boolean>;
  setCnlInfo: Dispatch<
    SetStateAction<{
      cnlFeeAmt: string;
      refAmt: string;
      rmPdCt: string;
    }>
  >;
  handleCancel(): void;
  handleCnlAmt(): void;
};

const RoomBookInfoView = ({
  memType,
  rmResvDtl,
  resvPayDtl,
  cnlInfo,
  cnlRef,
  setCnlInfo,
  handleCancel,
  handleCnlAmt,
}: Iprops) => {
  const isLoading = useSelector(isSearchSelector);

  const isPass =
    Number(new Date().toISOString().split("T")[0].replaceAll("-", "")) >=
    Number(rmResvDtl.resvSDt);

  const allPerCt =
    Number(resvPayDtl?.adltPerCt ?? 0) + Number(resvPayDtl?.chldPerCt ?? 0);

  const resvNm =
    rmResvDtl?.resvMemSurnm && rmResvDtl?.resvMemFstnm
      ? rmResvDtl?.resvMemSurnm + rmResvDtl?.resvMemFstnm
      : "";

  const cstNm =
    rmResvDtl?.resvCstSurnm && rmResvDtl?.resvCstFstnm
      ? rmResvDtl?.resvCstSurnm + rmResvDtl?.resvCstFstnm
      : "";

  const instMont =
    resvPayDtl?.instMont === "00"
      ? "일시불"
      : resvPayDtl?.instMont + resvPayDtl?.instMont
      ? "월"
      : "";

  const [infOpen, setInfOpen] = useState(true);
  const [pdOpen, setPdOpen] = useState(true);
  const [bkOpen, setBkOpen] = useState(true);
  const [payOpen, setPayOpen] = useState(true);

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader sbTtl={"객실예약상세 조회"} clsBtn handleReset={() => {}} />
        <div className="tb-form">
          <ITHeader
            sbTtl={"기본정보"}
            clsBtn
            isClose={infOpen}
            handleClose={() => {
              setInfOpen(!infOpen);
            }}
          />
          <div className={infOpen ? "" : "hidden"}>
            {memType === "admin" && (
              <ul>
                <li>
                  <label htmlFor="">숙소그룹명</label>
                  <input
                    type="text"
                    defaultValue={rmResvDtl?.lodGrpNm ?? ""}
                    readOnly
                  />
                </li>
                <li>
                  <label htmlFor="">숙소명</label>
                  <input
                    type="text"
                    defaultValue={rmResvDtl?.lodNm ?? ""}
                    readOnly
                  />
                </li>
              </ul>
            )}
            <ul>
              <li>
                <label htmlFor="">객실명</label>
                <input
                  type="text"
                  defaultValue={rmResvDtl?.rmNm ?? ""}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">객실타입</label>
                <input
                  type="text"
                  defaultValue={rmResvDtl?.rmTypNm ?? ""}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">객실전망</label>
                <input
                  type="text"
                  defaultValue={rmResvDtl?.rmOlkNm ?? ""}
                  readOnly
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">객실상품채널</label>
                <input
                  type="text"
                  defaultValue={rmResvDtl?.lodChlNm ?? ""}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">조식포함</label>
                <input
                  type="text"
                  defaultValue={rmResvDtl.brkfInYn === "Y" ? "포함" : "미포함"}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">레이트 체크아웃시간</label>
                <input
                  type="text"
                  value={
                    rmResvDtl.latChkOutTi?.replace(
                      /\B(?=(\d{2})+(?!\d))/g,
                      ":"
                    ) || "-"
                  }
                  readOnly
                />
              </li>
            </ul>
          </div>
          <div className="tb-form">
            <ITHeader
              sbTtl={"상품정보"}
              clsBtn
              isClose={pdOpen}
              handleClose={() => {
                setPdOpen(!pdOpen);
              }}
            />
            <div className={pdOpen ? "" : "hidden"}>
              <ul>
                <li>
                  <label htmlFor="">객실상품번호</label>
                  <input type="text" defaultValue={rmResvDtl.rmPdNo} readOnly />
                </li>
                <li>
                  <label htmlFor="">객실상품일자</label>
                  <input
                    type="text"
                    value={dateFormat(rmResvDtl.sellDt ?? "")}
                    readOnly
                  />
                </li>
                <li>
                  <label htmlFor="">객실상품요일</label>
                  <input
                    type="text"
                    defaultValue={
                      getDay(rmResvDtl?.sellDt + "", i18n.language) ?? ""
                    }
                    readOnly
                  />
                </li>
              </ul>
              <ul>
                <li>
                  <label htmlFor="">프로모션번호</label>
                  <input type="text" defaultValue="" readOnly />
                </li>
                <li>
                  <label htmlFor="">프로모션명</label>
                  <input type="text" defaultValue={""} readOnly />
                </li>
                <li>
                  <label htmlFor="">프로모션금액</label>
                  <input type="text" defaultValue={""} readOnly />
                </li>
              </ul>
              <ul>
                <li>
                  <label htmlFor="">객실상품금액</label>
                  <input
                    type="text"
                    defaultValue={inCom(rmResvDtl?.sellPri ?? "")}
                    readOnly
                  />
                </li>
              </ul>
            </div>
            <div className="tb-form">
              <ITHeader
                sbTtl={"예약정보"}
                clsBtn
                isClose={bkOpen}
                handleClose={() => {
                  setBkOpen(!bkOpen);
                }}
              />
              <div className={bkOpen ? "" : "hidden"}>
                <ul>
                  <li>
                    <label htmlFor="">객실예약번호</label>
                    <input
                      type="text"
                      defaultValue={rmResvDtl?.rmResvNo ?? ""}
                      readOnly
                    />
                  </li>
                  <li>
                    <label htmlFor="">객실예약순번</label>
                    <input type="text" defaultValue={"1"} readOnly />
                  </li>
                </ul>
                <ul>
                  <li>
                    <label htmlFor="">객실예약 시작일자</label>
                    <input
                      type="text"
                      value={dateFormat(rmResvDtl.resvSDt ?? "")}
                      readOnly
                    />
                  </li>
                  <li>
                    <label htmlFor="">객실예약 종료일자</label>
                    <input
                      type="text"
                      value={dateFormat(rmResvDtl.resvEDt ?? "")}
                      readOnly
                    />
                  </li>
                </ul>
                <ul>
                  <li>
                    <label htmlFor="">객실예약 수량</label>
                    <input
                      type="text"
                      defaultValue={rmResvDtl?.resvRmCt ?? ""}
                      readOnly
                    />
                  </li>
                  <li>
                    <label htmlFor="">객실예약일수</label>
                    <input
                      type="text"
                      defaultValue={rmResvDtl?.resvDayCt ?? ""}
                      readOnly
                    />
                  </li>
                </ul>
                <ul>
                  <li>
                    <label htmlFor="">객실예약 전체인원수</label>
                    <input type="text" defaultValue={allPerCt ?? ""} readOnly />
                  </li>
                  <li>
                    <label htmlFor="">객실예약 성인인원수</label>
                    <input
                      type="text"
                      defaultValue={rmResvDtl?.adltPerCt ?? ""}
                      readOnly
                    />
                  </li>
                </ul>
                <ul>
                  <li>
                    <label htmlFor="">객실예약 아동인원수</label>
                    <input
                      type="text"
                      defaultValue={rmResvDtl?.chldPerCt ?? ""}
                      readOnly
                    />
                  </li>
                  <li>
                    <label htmlFor="">객실예약 무료인원수</label>
                    <input
                      type="text"
                      defaultValue={rmResvDtl?.basPerCt ?? ""}
                      readOnly
                    />
                  </li>
                </ul>
                <ul>
                  <li>
                    <label htmlFor="">예약이메일 발송일시</label>
                    <input type="text" value="" readOnly />
                  </li>
                  <li>
                    <label htmlFor="">예약팩스 발송일시</label>
                    <input type="text" value="" readOnly />
                  </li>
                </ul>
                <ul>
                  <li>
                    <label htmlFor="">예약 회원이름</label>
                    <input type="text" defaultValue={resvNm} readOnly />
                  </li>
                  <li>
                    <label htmlFor="">예약 회원전화번호</label>
                    <input
                      type="text"
                      defaultValue={rmResvDtl?.resvMemMobNo ?? ""}
                      readOnly
                    />
                  </li>
                </ul>
                <ul>
                  <li>
                    <label htmlFor="">고객이름</label>
                    <input type="text" defaultValue={cstNm} readOnly />
                  </li>
                  <li>
                    <label htmlFor="">고객전화번호</label>
                    <input
                      type="text"
                      defaultValue={rmResvDtl?.resvCstMblNo ?? ""}
                      readOnly
                    />
                  </li>
                  <li>
                    <label htmlFor="">고객이메일</label>
                    <input
                      type="text"
                      defaultValue={rmResvDtl?.resvCstEml ?? ""}
                      readOnly
                    />
                  </li>
                </ul>
                <ul>
                  <li>
                    <label htmlFor="">정산금액</label>
                    <input
                      type="text"
                      defaultValue={inCom(rmResvDtl?.resvSumAmt ?? "")}
                      readOnly
                    />
                  </li>
                  <li>
                    <label htmlFor="">예약취소</label>
                    <button
                      className="btn-sm"
                      disabled={cnlRef.current || isPass}
                      onClick={handleCnlAmt}
                    >
                      {isPass
                        ? "투숙완료"
                        : cnlRef?.current
                        ? "취소완료"
                        : "예약취소"}
                    </button>
                  </li>
                </ul>
                <ul>
                  <li data-full>
                    <label htmlFor="">예약요청사항</label>
                    <input
                      type="text"
                      defaultValue={rmResvDtl?.reqCm ?? ""}
                      readOnly
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className="tb-form">
              <ITHeader
                sbTtl={"결제정보"}
                clsBtn
                isClose={payOpen}
                handleClose={() => {
                  setPayOpen(!payOpen);
                }}
              />
              <div className={payOpen ? "" : "hidden"}>
                <ul>
                  <li>
                    <label htmlFor="">결제일시</label>
                    <input
                      type="text"
                      defaultValue={
                        dateFormat(resvPayDtl?.tranDtti?.slice(0, 8)) +
                        " " +
                        resvPayDtl?.tranDtti?.slice(8, 10) +
                        ":" +
                        resvPayDtl?.tranDtti?.slice(10, 12)
                      }
                      readOnly
                    />
                  </li>
                  <li>
                    <label htmlFor="">결제등록일련번호</label>
                    <input
                      type="text"
                      defaultValue={resvPayDtl?.payRgSeq ?? ""}
                      readOnly
                    />
                  </li>
                  <li>
                    <label htmlFor="">결제금액</label>
                    <input
                      type="text"
                      defaultValue={inCom(resvPayDtl?.payAmt ?? "") ?? ""}
                      readOnly
                    />
                  </li>
                </ul>
                <ul>
                  <li>
                    <label htmlFor="">통화코드</label>
                    <input
                      type="text"
                      defaultValue={resvPayDtl?.currCd ?? ""}
                      readOnly
                    />
                  </li>
                  <li>
                    <label htmlFor="">할부월</label>
                    <input type="text" defaultValue={instMont} readOnly />
                  </li>
                  <li>
                    <label htmlFor="">결제유형</label>
                    <input
                      type="text"
                      defaultValue={resvPayDtl?.cardCorp}
                      readOnly
                    />
                  </li>
                </ul>
                <ul>
                  <li>
                    <label htmlFor="">결제언어코드</label>
                    <input
                      type="text"
                      defaultValue={resvPayDtl?.payLangCd}
                      readOnly
                    />
                  </li>
                  <li>
                    <label htmlFor="">결제방법</label>
                    <input
                      type="text"
                      defaultValue={resvPayDtl?.cardCorp ?? ""}
                      readOnly
                    />
                  </li>
                </ul>
                <ul>
                  <li>
                    <label htmlFor="">구매자 이름</label>
                    <input
                      type="text"
                      defaultValue={resvPayDtl?.buyerNm}
                      readOnly
                    />
                  </li>
                  <li>
                    <label htmlFor="">구매자 이메일</label>
                    <input
                      type="text"
                      defaultValue={resvPayDtl?.buyerEml ?? ""}
                      readOnly
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={"환불수수료"}
        isOpen={!!(cnlInfo?.refAmt && cnlInfo?.rmPdCt)}
        btnText=""
        handleCloseModal={() => {
          setCnlInfo({
            cnlFeeAmt: "",
            refAmt: "",
            rmPdCt: "",
          });
        }}
        onClick={() => {}}
      >
        <div className="w-[80%] mx-auto">
          <div className="text-left">객실예약수 : {cnlInfo?.rmPdCt}</div>
          <div className="text-left"> 결제 금액 : {inCom(cnlInfo?.refAmt)}</div>
          <div className="text-left">
            취소 수수료 : {inCom(cnlInfo?.cnlFeeAmt)}{" "}
          </div>
        </div>
        <div className="text-sm text-red-350 text-center">
          * 숙소환불수수료부과방식 등의 정보에 따른 계산방식을 포함하여
          환불수수료 금액을 표시합니다.
        </div>
        <div className="w-full mt-8 flex justify-center gap-x-8">
          <LoadingButton
            txt="취소"
            styles="btn-search"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={handleCancel}
          />
        </div>
      </Modal>
    </>
  );
};

export default RoomBookInfoView;
