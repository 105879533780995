import ITHeader from "components/layout/header/subHeader/itemHeader";
import CheckBox from "./authCheck";
import { useSelector } from "react-redux";
import { isSearchSelector } from "store/commonSlice";
import { useLocation, useNavigate } from "react-router-dom";

type IProps = {
  searchInfo: any;
};

const AuthInfo = ({ searchInfo }: IProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isSearch = useSelector(isSearchSelector);
  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"기본상세"}
          clsBtn={isSearch}
          isClose={false}
          desc={isSearch ? "" : "* 검색조건 입력후 검색하여 주세요"}
          handleClose={() => {}}
        />
        <div className={isSearch ? "" : "hidden"}>
          <ul>
            <li>
              <label htmlFor="">관리자권한</label>
              <input type="text" value={""} readOnly />
            </li>
            <li>
              <label htmlFor="">관리자번호</label>
              <input type="text" name="admNo" value={""} readOnly />
            </li>
            <li>
              <label htmlFor="">매니저번호</label>
              <input type="text" name="manNo" value={""} readOnly />
            </li>
          </ul>
        </div>
        <ITHeader
          sbTtl={"권한상세"}
          clsBtn={isSearch}
          mdfyBtn={isSearch}
          isClose={false}
          desc={isSearch ? "" : "* 검색조건 입력후 검색하여 주세요"}
          handleModify={() => navigate(pathname + "/modify")}
          handleClose={() => {}}
        />
        <div className={isSearch ? "" : "hidden"}>
          <ul>
            <label htmlFor="">회원</label>
            <CheckBox
              id={"mem"}
              auth={"_rd__wrt__del_dwl"}
              handleChange={() => {}}
              readonly
            />
            <li>
              <label htmlFor="">회원약관</label>
              <CheckBox
                id={"term"}
                auth={"_rd__wrt__del_dwl"}
                handleChange={() => {}}
                readonly
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">숙소그룹</label>
              <CheckBox
                id={""}
                auth={"_rd__wrt__del_dwl"}
                handleChange={() => {}}
                readonly
              />
            </li>
            <li>
              <label htmlFor="">숙소</label>
              <CheckBox
                id={"lod"}
                auth={"_rd__wrt__del_dwl"}
                handleChange={() => {}}
                readonly
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">숙소기본정보</label>
              <CheckBox
                id={"lodDft"}
                auth={"_rd__wrt__del_dwl"}
                handleChange={() => {}}
                readonly
              />
            </li>
            <li>
              <label htmlFor="">숙소제공정보</label>
              <CheckBox
                id={"lodPrd"}
                auth={"_rd__wrt__del_dwl"}
                handleChange={() => {}}
                readonly
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">숙소환불수수료</label>
              <CheckBox
                id={"lodRfd"}
                auth={"_rd__wrt__del_dwl"}
                handleChange={() => {}}
                readonly
              />
            </li>
            <li>
              <label htmlFor="">숙소채널</label>
              <CheckBox
                id={"lodChl"}
                auth={"_rd__wrt__del_dwl"}
                handleChange={() => {}}
                readonly
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">객실</label>
              <CheckBox
                id={"rm"}
                auth={"_rd__wrt__del_dwl"}
                handleChange={() => {}}
                readonly
              />
            </li>
            <li>
              <label htmlFor="">객실옵션</label>
              <CheckBox
                id={"rmOp"}
                auth={"_rd__wrt__del_dwl"}
                handleChange={() => {}}
                readonly
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">프로모션</label>
              <CheckBox
                id={"prom"}
                auth={"_rd__wrt__del_dwl"}
                handleChange={() => {}}
                readonly
              />
            </li>
            <li>
              <label htmlFor="">정산관리</label>
              <CheckBox
                id={"calc"}
                auth={"_rd__wrt__del_dwl"}
                handleChange={() => {}}
                readonly
              />
            </li>
          </ul>
          <ul>
            <li></li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AuthInfo;
