import { useMutation } from "@tanstack/react-query";
import { lodRefdCreateApi, lodRefdDtl } from "components/api/lod";
import DayCheckBox from "components/checkbox/dayChk";
import DatePick from "components/datePick";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import Select from "components/select";
import LodGrpNmSelect from "components/select/room/lodGrpNmSelect ";
import LodNmSelect from "components/select/room/lodNmSelect";
import { lodRefProps } from "interface/lod";
import i18n from "locales/i18n";
import { MutableRefObject, useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";
import { afterDay } from "util/common";
import { feeTypeOptions, lastTimeOptions, lodGrdCdOptions } from "util/option";

type IProps = {
  lodNoRef: MutableRefObject<string>;
  rgSeqRef: MutableRefObject<number>;
  isAbleRef: MutableRefObject<boolean>;
};

const InfCreateView = ({ lodNoRef, rgSeqRef, isAbleRef }: IProps) => {
  const dispatch = useDispatch();

  const lodRefDtlMutation = useMutation(lodRefdDtl, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        const {
          lodGrpNo,
          lodNo,
          langCd,
          feeRti,
          feeTypCd,
          lodGrdCd,
          rgSeq,
          refdFeeTypCd,
          monAplyYn,
          tuesAplyYn,
          wenAplyYn,
          thurAplyYn,
          friAplyYn,
          satAplyYn,
          sunAplyYn,
          aplyChkDay,
          aplyEDt,
          aplySDt,
          aplyTi,
        } = body?.lodRefDtl;

        setInf({
          lodGrpNo,
          lodNo,
          langCd,
          feeRti,
          feeTypCd,
          lodGrdCd,
          rgSeq,
          refdFeeTypCd,
          monAplyYn,
          tuesAplyYn,
          wenAplyYn,
          thurAplyYn,
          friAplyYn,
          satAplyYn,
          sunAplyYn,
          aplyChkDay,
          aplyEDt,
          aplySDt,
          aplyTi,
        });
      }
    },
  });

  const { sDt, eDt } = afterDay(14);

  const [inf, setInf] = useState<lodRefProps>({
    lodGrpNo: "",
    lodNo: "",
    langCd: i18n.language,
    feeTypCd: feeTypeOptions[0]?.value || "",
    feeRti: "",
    lodGrdCd: "",
    refdFeeTypCd: "",
    rgSeq: 1,
    monAplyYn: "Y",
    tuesAplyYn: "Y",
    wenAplyYn: "Y",
    thurAplyYn: "Y",
    friAplyYn: "Y",
    satAplyYn: "Y",
    sunAplyYn: "Y",
    aplySDt: sDt || "",
    aplyEDt: eDt || "",
    aplyChkDay: 1,
    aplyTi: "",
  });

  const handleChange = (name: string, value: string) => {
    setInf({
      ...inf,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setInf({
        ...inf,
        [name]: value,
      });
    }
  };

  const infMutation = useMutation(lodRefdCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "환불수수료기본정보를 등록하였습니다.",
              isConfirm: true,
              handleClick() {
                isAbleRef.current = true;
                lodNoRef.current = body?.lodNo;
                rgSeqRef.current = body?.rgSeq;
                lodRefDtlMutation.mutate({
                  lodNo: body?.lodNo,
                  rgSeq: body?.rgSeq,
                  langCd: i18n.language,
                });

                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  const handleCreate = () => {
    if (!inf.lodGrpNo) {
      return alert("숙소그룹명을 선택하여 주세요.");
    }
    if (!inf.lodNo) {
      return alert("숙소명을 선택하여 주세요.");
    }
    if (!inf.aplyChkDay) {
      return alert("숙박일기준일을 입력하여 주세요.");
    }
    if (!inf.feeRti) {
      return alert("적용 수수료울을 입력하여 주세요.");
    }
    if (!inf.feeTypCd) {
      return alert("기본수수료종류를 선택하여 주세요");
    }

    const params = {
      ...inf,
      aplySDt: inf.aplySDt.replaceAll("-", ""),
      aplyEDt: inf.aplyEDt.replaceAll("-", ""),
      aplyTi: inf.aplyTi.replaceAll(":", ""),
      aplyChkDay: Number(inf.aplyChkDay),
    };
    console.log("params", params);

    dispatch(
      setMessage({
        message: "환불수수료기본율기본정보를 등록하시겠습니까?",
        isConfirm: true,
        isCancel: true,
        handleClick() {
          dispatch(setMessage({ message: null }));
          infMutation.mutate(params);
        },
      })
    );
  };

  const [infOpen, setInfOpen] = useState(true);

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"기본정보"}
          clsBtn
          createBtn
          isClose={infOpen}
          desc="* 필수 입력"
          handleCreate={handleCreate}
          handleClose={() => {
            setInfOpen(!infOpen);
          }}
        />
        <div className={infOpen ? "" : "hidden"}>
          <ul>
            <li>
              <label htmlFor="" data-dot>
                숙소그룹명
              </label>
              <LodGrpNmSelect
                option={inf.lodGrpNo ?? ""}
                disabled={isAbleRef.current}
                isArrow={isAbleRef.current}
                onSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="">숙소등급</label>
              <Select
                id="lodGrdCd"
                name="lodGrdCd"
                option={inf.lodGrdCd}
                options={lodGrdCdOptions}
                disabled={isAbleRef.current}
                isArrow={isAbleRef.current}
                onSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="" data-dot>
                숙소명
              </label>
              <LodNmSelect
                option={inf.lodNo}
                params={inf.lodGrpNo ?? ""}
                disabled={isAbleRef.current}
                isArrow={isAbleRef.current}
                onSelect={handleSelect}
              />
            </li>
          </ul>

          <ul>
            <li>
              <label htmlFor="" data-dot>
                숙박일기준
              </label>
              <input
                type="text"
                value={inf.aplyChkDay}
                readOnly={isAbleRef.current}
                onChange={(e) =>
                  handleChange("aplyChkDay", e.currentTarget.value)
                }
              />
            </li>
            <li>
              <label htmlFor="">숙박일기준시간</label>
              <Select
                id={"aplyTi"}
                name={"aplyTi"}
                option={inf.aplyTi}
                options={lastTimeOptions}
                disabled={isAbleRef.current}
                isArrow={isAbleRef.current}
                onSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="" data-dot>
                적용 수수료율
              </label>
              <input
                type="text"
                value={inf.feeRti}
                maxLength={2}
                readOnly={isAbleRef.current}
                onChange={(e) => handleChange("feeRti", e.currentTarget.value)}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="" data-dot>
                기본수수료종류
              </label>
              <Select
                id="feeTypCd"
                name="feeTypCd"
                option={inf.feeTypCd}
                options={feeTypeOptions}
                disabled={isAbleRef.current}
                isArrow={isAbleRef.current}
                isInitial
                onSelect={handleSelect}
              />
            </li>
            {!isAbleRef.current && (
              <li>
                <DatePick
                  label="적용일자"
                  startNm={"aplySDt"}
                  endNm={"aplyEDt"}
                  start={inf.aplySDt}
                  end={inf.aplyEDt}
                  handleChange={(name, value) => handleChange(name, value)}
                />
              </li>
            )}
            {isAbleRef.current && (
              <li>
                <label htmlFor="">적용일자</label>
                <div className="flex ">
                  <input
                    type="text"
                    value={inf?.aplySDt}
                    readOnly
                    className="flex-1"
                  />
                  <span className="flex items-center px-1 font-bold">-</span>
                  <input
                    type="text"
                    className="flex-1"
                    value={inf?.aplyEDt}
                    readOnly
                  />
                </div>
              </li>
            )}
          </ul>
          <ul>
            <li>
              <label htmlFor="">적용요일</label>
              <div className="w-full flex">
                <DayCheckBox
                  id="MON"
                  name="day"
                  label="MON"
                  checked={inf.monAplyYn === "Y"}
                  readOnly={isAbleRef.current}
                  onChange={(target) => {
                    handleChange("monAplyYn", target.checked ? "Y" : "N");
                  }}
                />
                <DayCheckBox
                  id="TUE"
                  name="day"
                  label="TUE"
                  checked={inf.tuesAplyYn === "Y"}
                  readOnly={isAbleRef.current}
                  onChange={(target) =>
                    handleChange("tuesAplyYn", target.checked ? "Y" : "N")
                  }
                />
                <DayCheckBox
                  id="WED"
                  name="day"
                  label="WED"
                  checked={inf.wenAplyYn === "Y"}
                  readOnly={isAbleRef.current}
                  onChange={(target) =>
                    handleChange("wenAplyYn", target.checked ? "Y" : "N")
                  }
                />
                <DayCheckBox
                  id="THU"
                  name="day"
                  label="THU"
                  checked={inf.thurAplyYn === "Y"}
                  readOnly={isAbleRef.current}
                  onChange={(target) =>
                    handleChange("thurAplyYn", target.checked ? "Y" : "N")
                  }
                />
                <DayCheckBox
                  id="FRI"
                  name="day"
                  label="FRI"
                  checked={inf.friAplyYn === "Y"}
                  readOnly={isAbleRef.current}
                  onChange={(target) =>
                    handleChange("friAplyYn", target.checked ? "Y" : "N")
                  }
                />
                <DayCheckBox
                  id="SAT"
                  name="day"
                  label="SAT"
                  checked={inf.satAplyYn === "Y"}
                  readOnly={isAbleRef.current}
                  onChange={(target) =>
                    handleChange("satAplyYn", target.checked ? "Y" : "N")
                  }
                />
                <DayCheckBox
                  id="SUN"
                  name="day"
                  label="SUN"
                  checked={inf.sunAplyYn === "Y"}
                  readOnly={isAbleRef.current}
                  onChange={(target) =>
                    handleChange("sunAplyYn", target.checked ? "Y" : "N")
                  }
                />
              </div>
              {/* <div className="flex-1 w-full flex">
                {dayOptions.map((day) => {
                  return (
                    <CheckBox
                      key={day.value}
                      label={day.kr}
                      id={day.value}
                      data-checkbox
                      // checked={}
                      handleCheckbox={(e) =>
                        handleChange(e.currentTarget.id, e.currentTarget.value)
                      }
                    />
                  );
                })}
              </div> */}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default InfCreateView;
