import {
  rmAdInfoProps,
  rmCreateProps,
  rmCrrProps,
  rmImageProps,
  rmInfoProps,
  rmSearchProps,
  rmOpProps,
  rmOptCreateProps,
  rmOptSearchProps,
  rmPdHisSearch,
  rmPdSearchProps,
  rmPdStatusProps,
  rmPrdCreateProps,
  rmResvCnlDtlProps,
  rmResvCnlSearchProps,
  rmResvSearchProps,
} from "interface/room";
import api from "./api";

export const rmListApi = async (params: Partial<rmSearchProps>) => {
  const res = await api({
    method: "post",
    url: `manager/rmman/rmManList`,
    data: params,
  });

  return res;
};

export const rmCreateApi = async (params: rmCreateProps) => {
  const res = await api({
    method: "post",
    url: "manager/rmman/createRm",
    data: params,
  });

  return res;
};

export const rmAddCreateApi = async (params: rmAdInfoProps) => {
  const res = await api({
    method: "post",
    url: "manager/rmman/registerRmAdInfo",
    data: params,
  });

  return res;
};

export const rmInfoApi = async (params: rmInfoProps) => {
  const res = await api({
    method: "post",
    url: `manager/rmman/rmDtl`,
    data: params,
  });
  return res;
};

export const rmModifyApi = async (
  params: Partial<rmCreateProps> & { rmNo: string }
) => {
  const res = await api({
    method: "post",
    url: `manager/rmman/rmMgModify`,
    data: params,
  });

  return res;
};

export const rmCdListApi = async (cdTyp: string) => {
  const res = await api({
    method: "get",
    url: `manager/rmman/rmCdList/${cdTyp}`,
  });

  return res;
};

export const rmOpCreateApi = async (params: Partial<rmOptCreateProps>) => {
  console.log("create api", params);
  const res = await api({
    method: "post",
    url: "manager/rmman/createRmOp",
    data: params,
  });

  return res;
};

export const rmOpListApi = async (params: rmOptSearchProps) => {
  const res = await api({
    method: "post",
    url: `manager/rmman/rmOpList`,
    data: params,
  });

  return res;
};

export const rmOpInfoApi = async (params: {
  rmNo: string;
  opRgSeq: string;
  langCd: string;
}) => {
  const res = await api({
    method: "post",
    url: `manager/rmman/rmOpDtl`,
    data: params,
  });

  return res;
};

export const rmOPInfModifyApi = async (params: Partial<rmOpProps>) => {
  const res = await api({
    method: "post",
    url: `manager/rmman/rmOpModify`,
    data: params,
  });

  return res;
};

export const addRmOpInfoApi = async (params: Partial<rmOpProps>) => {
  const res = await api({
    method: "post",
    url: `manager/rmman/createRmOpInfWithLang`,
    data: params,
  });

  return res;
};

export const rmProductCreateApi = async (
  params: Omit<rmPrdCreateProps, "lodGrpNo">
) => {
  const res = await api({
    method: "post",
    url: "manager/rmman/createRmPd",
    data: params,
  });

  return res;
};

export const rmImgUploadApi = async (params: rmImageProps) => {
  const res = await api({
    method: "post",
    url: "manager/rmman/registerRmImg",
    data: params,
  });

  return res;
};

export const rmMonthListApi = async (params: Partial<rmCrrProps>) => {
  const res = await api({
    method: "post",
    url: "manager/rmman/rmPdSta",
    data: params,
  });

  return res;
};

export const rmPdStatusModifyApi = async (params: Partial<rmPdStatusProps>) => {
  const res = await api({
    method: "post",
    url: "manager/rmman/rmPdSellStaModify",
    data: params,
  });

  return res;
};

export const rmPdSellPriModifyApi = async (
  params: Partial<rmPdStatusProps>
) => {
  const res = await api({
    method: "post",
    url: `manager/rmman/rmPdSellPriModify`,
    data: params,
  });

  return res;
};

export const rmPdSellCtModifyApi = async (params: Partial<rmPdStatusProps>) => {
  const res = await api({
    method: "post",
    url: `manager/rmman/rmPdCtModify`,
    data: params,
  });

  return res;
};

export const rmDayListApi = async () => {
  const res = await api({
    method: "post",
    url: "manage/rmman/rmPdSta",
  });

  return res;
};

export const rmHstListApi = async (params: Partial<rmPdHisSearch>) => {
  const res = await api({
    method: "post",
    url: `manager/rmman/rmPdHistList`,
    data: params,
  });

  return res;
};

export const rmHstDtlApi = async (params: { rmPdHistNo: string }) => {
  const res = await api({
    method: "post",
    url: `manager/rmman/rmPdHIstDtl`,
    data: params,
  });

  return res;
};

export const rmResvListApi = async (params: Partial<rmResvSearchProps>) => {
  const res = await api({
    method: "post",
    url: `manager/rmman/rmResvList`,
    data: params,
  });

  return res;
};

export const rmResvDtlApi = async (params: { rmPdNo: string }) => {
  const res = await api({
    method: "post",
    url: `manager/rmman/rmResvPdDtl`,
    data: params,
  });
  return res;
};

export const rmCnlAmtApi = async (params: { rmPdNo: string }) => {
  const res = await api({
    method: "post",
    url: `manager/rmman/rmResvCnlFeeAmt`,
    data: params,
  });

  return res;
};

export const rmCnlResvApi = async (params: { rmPdNo: string }) => {
  const res = await api({
    method: "post",
    url: `manager/rmman/cnlRmResv`,
    data: params,
  });

  return res;
};

export const rmResvCnlListApi = async (
  params: Partial<rmResvCnlSearchProps>
) => {
  const res = await api({
    method: "post",
    url: `manager/rmman/rmResvCnlList`,
    data: params,
  });

  return res;
};

export const rmResvCnlInfoApi = async (params: rmResvCnlDtlProps) => {
  const res = await api({
    method: "post",
    url: `manager/rmman/rmResvCnlDtl`,
    data: params,
  });

  return res;
};

export const rmPdListApi = async (
  params: Omit<
    rmPdSearchProps,
    "lodGrpNo" | "lodGrpNm" | "lodNo" | "lodGrdCd" | "lodNm"
  >
) => {
  const res = await api({
    method: "post",
    url: `manager/rmman/rmPdList`,
    data: params,
  });

  return res;
};

export const rmPdDtlApi = async (params: { rmPdNo: string }) => {
  const res = await api({
    method: "post",
    url: `manager/rmman/rmPdDtl`,
    data: params,
  });

  return res;
};
