import THeader from "components/layout/header/subHeader/tHeader";
import LodChlSelect from "components/select/room/lodChlSelect";
import LodGrdSelect from "components/select/room/lodGrdSelect";
import LodGrpSelect from "components/select/room/lodGrpNoSelect";
import LodNoSelect from "components/select/room/lodNoSelect";
import RmNoSelect from "components/select/room/rmNoSlect";
import SearchSelect from "components/select/searchSelect";
import RoomHsrList from "components/tableList/room/hstr";
import { rmHstSearhInfo } from "interface/room";
import DatePick from "components/datePick";
import { useLocation } from "react-router-dom";

type IProps = {
  memType: "admin" | "manager";
  searchInfo: rmHstSearhInfo;
  handleChange(name: string, value: string): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleReset(): void;
};

const RmHstView = ({
  memType,
  searchInfo,
  handleChange,
  handleSelect,
  handleReset,
}: IProps) => {
  const { pathname } = useLocation();

  const searchOp = [
    {
      kr: "객실옵션명",
      value: "rmOpNm",
      en: "roomOpName",
    },
  ];

  const searchOps =
    memType === "admin"
      ? [
          {
            kr: "숙소그룹명",
            value: "lodGrpNm",
            en: "LodGrpName",
          },
          {
            kr: "숙소멍",
            value: "lodNm",
            en: "LodName",
          },
          ...searchOp,
        ]
      : searchOp;

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader
          sbTtl={"객실상품이력"}
          rstBtn
          url={pathname}
          searchBtn
          handleReset={handleReset}
        />
        <div className="tb-form" data-list>
          {memType === "admin" && (
            <ul>
              <li>
                <label htmlFor="">숙소그룹번호</label>
                <LodGrpSelect
                  option={searchInfo?.lodGrpNo}
                  onSelect={(target) => handleSelect(target)}
                />
              </li>
              <li>
                <label htmlFor="">숙소번호</label>
                <LodNoSelect
                  disabled={!searchInfo?.lodGrpNo}
                  option={searchInfo?.lodNo}
                  params={searchInfo?.lodGrpNo}
                  onSelect={(target) => handleSelect(target)}
                />
              </li>
              {/* <li>
                <label htmlFor="">숙소등급</label>
                <LodGrdSelect
                  option={searchInfo?.lodGrdCd}
                  onSelect={(target) => handleSelect(target)}
                />
              </li> */}
            </ul>
          )}
          <ul>
            <li>
              <label htmlFor="">객실번호</label>
              <RmNoSelect
                disabled={!searchInfo?.lodNo}
                option={searchInfo.rmNo || "A"}
                params={searchInfo.lodNo}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="">객실상품채널</label>
              <LodChlSelect
                option={searchInfo.lodChlCd || "A"}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <DatePick
                startNm="sellSDt"
                endNm={"sellEDt"}
                start={searchInfo.sellSDt ?? ""}
                end={searchInfo.sellEDt}
                handleChange={(name, value) => handleChange(name, value)}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">검색조건</label>
              <SearchSelect
                id={"searchTyp"}
                name={searchInfo.searchTyp ?? ""}
                option={searchInfo.searchTyp || "A"}
                value={
                  searchInfo[
                    `${searchInfo?.searchTyp}` as keyof rmHstSearhInfo
                  ] || ""
                }
                options={searchOps}
                onSelect={handleSelect}
                onChange={(target) => handleChange(target.name, target.value)}
              />
            </li>
          </ul>
        </div>
      </div>
      <RoomHsrList memType={memType} searchInfo={searchInfo} />
    </>
  );
};

export default RmHstView;
