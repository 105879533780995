import LodGroupInfview from "components/view/lod/grp/info";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { lodGrpDtlApi } from "components/api/lod";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";
import { useLocation } from "react-router-dom";
import i18n from "locales/i18n";

const LodGroupInfoPage = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const lodGrpNo = search.replace("?lodGrpNo=", "");

  const grpDtlMutation = useMutation(lodGrpDtlApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
        }
        if (code === -1) {
          dispatch(
            setMessage({
              message: message || "검색 데이터가 없습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  useEffect(() => {
    const params = {
      lodGrpNo: lodGrpNo ?? "",
      langCd: i18n.language,
    };
    console.log("params", params);
    grpDtlMutation.mutate(params);
  }, []);

  const lodGrpDtl = grpDtlMutation?.data?.body?.lodGrpDtl || {};
  const lodGrpAdList = grpDtlMutation?.data?.body?.lodGrpAdList || [];

  return <LodGroupInfview lodGrpDtl={lodGrpDtl} lodGrpAdList={lodGrpAdList} />;
};

export default LodGroupInfoPage;
