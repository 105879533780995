import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { lodPayDtlApi, lodPayModifyApi } from "components/api/lod";
import DatePick from "components/datePick";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import Select from "components/select";
import { lodPayProps } from "interface/lod";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { bankOptions, moneyOptions } from "util/option";

const PayModifyView = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: ["lodPayInf"],
    queryFn: () => {
      return lodPayDtlApi({
        lodNo: search.replace("?lodNo=", ""),
      });
    },
  });
  const payDtl = query?.data?.body?.lodPayDtl || {};

  const [inf, setInf] = useState<lodPayProps>({
    lodNo: search.replace("?lodNo=", ""),
    rgSeq: payDtl?.rgSeq,
    uyn: "",
    calcInDt: "",
    calcInBnkNm: "",
    calcInBnkCd: "",
    calcAcntNo: "",
    calcCurrCd: payDtl.calcCurrCd ?? "",
    aplySDt: "",
    aplyEDt: "",
  });

  const [infOpen, setInfOpen] = useState(false);

  const handleChange = (name: string, value: string) => {
    setInf({
      ...inf,
      [name]: value,
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value, lng } = target.dataset;
    if (name && value) {
      if (name === "calcInBnkCd") {
        setInf({
          ...inf,
          calcInBnkCd: value,
          calcInBnkNm: lng ?? "",
        });
        return;
      }

      setInf({
        ...inf,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const payMutation = useMutation(lodPayModifyApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "결제정보를 수정하였습니다.",
              isConfirm: true,
              handleClick() {
                queryClient.invalidateQueries(["lodPayInf"]);
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });
  const handleModify = () => {
    const { lodNo, calcCurrCd, rgSeq, ...res } = inf;
    const isAble = Object.values(res).some((able) => {
      return !!able;
    });

    if (!isAble) {
      return alert("결제정보를 수정하여 주세요.");
    }

    const params = {
      ...inf,
      aplySDt: inf.aplySDt.replaceAll("-", ""),
      aplyEDt: inf.aplyEDt.replaceAll("-", ""),
    };

    console.log("params", params);

    dispatch(
      setMessage({
        message: "결제정보를 수정하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          payMutation.mutate(params);
        },
      })
    );
  };

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"결제정보"}
          clsBtn
          mdfyBtn
          isClose={infOpen}
          handleModify={handleModify}
          handleClose={() => {
            setInfOpen(!infOpen);
          }}
        />
        <div className={infOpen ? "" : "hidden"}>
          <ul>
            <li>
              <label htmlFor="">정산입금일</label>
              <input
                type="text"
                name="calcInDt"
                defaultValue={inf.calcInDt || payDtl?.calcInDt}
                onChange={(e) =>
                  handleChange("calcInDt", e.currentTarget.value)
                }
              />
            </li>
            <li>
              <label htmlFor="">정산은행</label>
              <Select
                id="bnk"
                name="calcInBnkCd"
                option={inf.calcInBnkCd || payDtl?.calcInBnkCd}
                options={bankOptions}
                onSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="">정산은행계좌번호</label>
              <input
                type="text"
                name="calcAcntNo"
                defaultValue={inf.calcAcntNo || payDtl?.calcAcntNo}
                onChange={(e) =>
                  handleChange("calcAcntNo", e.currentTarget.value)
                }
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">숙소정산통화</label>
              <Select
                id={""}
                name={"calcCurrCd"}
                option={inf.calcCurrCd || payDtl?.calcCurrCd}
                options={moneyOptions}
                isInitial
                onSelect={handleSelect}
              />
            </li>
            <li>
              {/* <DatePick
                startNm={""}
                endNm={""}
                start={""}
                end={""}
                handleChange={function (name: string, value: string): void {
                  throw new Error("Function not implemented.");
                }}
              /> */}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default PayModifyView;
