import { useMutation } from "@tanstack/react-query";
import { admCertLgnPwdApi, certLgnPwdApi } from "components/api/member";
import Step3View from "components/view/resetpw/step3";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setMessage } from "store/commonSlice";

const Step3Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const [info, setInfo] = useState({
    pwd: "",
    pwd_chk: "",
  });

  const handleChange = (name: string, value: string) => {
    setInfo({
      ...info,
      [name]: value,
    });
  };

  const restPwdMuataion = useMutation(certLgnPwdApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "비밀번호 설정이 완료되었습니다.",
              cnfrmBtnText: "로그인",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                navigate("/", {
                  replace: true,
                });
                // window.location.replace("/");
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const admRestPwdMuataion = useMutation(admCertLgnPwdApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "비밀번호 설정이 완료되었습니다.",
              cnfrmBtnText: "로그인",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                navigate("/", {
                  replace: true,
                });
                // window.location.replace("/");
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleSetting = () => {
    dispatch(
      setMessage({
        message: "비밀번호를 설정하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          dispatch(setMessage({ message: null }));
          if (pathname.includes("manager")) {
            dispatch(setMessage({ message: null }));
            restPwdMuataion.mutate({
              certEncParam: search?.replace("?param=", ""),
              newLgnPwd: info.pwd,
            });
            return;
          }
          if (pathname.includes("admin")) {
            admRestPwdMuataion.mutate({
              certEncParam: search?.replace("?param=", ""),
              newLgnPwd: info.pwd,
            });
            return;
          }
        },
      })
    );
  };

  return (
    <Step3View
      info={info}
      isLoading={restPwdMuataion.isLoading}
      handleChange={handleChange}
      handleSetting={handleSetting}
    />
  );
};

export default Step3Page;
