import InContet from "components/inContent";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import Modal from "components/modal/modal";
import Select from "components/select";
import SmallSelect from "components/select/smSelect";
import InTuiEditor from "components/tuiEditor/inEditot";
import ReadTuiEditor from "components/tuiEditor/readEditor";
import { langProps } from "interface";
import i18n from "locales/i18n";
import { useEffect, useState } from "react";
import { langOptions } from "util/option";

type IProps = {
  adList: { langCd: string; rmOpNm: string; rmOpDesc: string }[];
  handleAdd(adInf: any): void;
};

const AdInfCreate = ({ adList, handleAdd }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [langCd, setLangCd] = useState(i18n.language);
  let adInfo = adList?.find((ad: any) => {
    return ad.langCd === langCd;
  });

  let mdfyOp = [] as langProps[];
  let addOp = [] as langProps[];

  langOptions.forEach((lang) => {
    const isAble = adList?.some((ad) => {
      return lang.value === ad.langCd;
    });

    if (isAble) {
      mdfyOp.push(lang);
    }
    if (!isAble) {
      addOp.push(lang);
    }
  });
  const [adOpen, setAdOpen] = useState(true);
  const [adInf, setAdInf] = useState({
    rmOpNm: "",
    rmOpDesc: "",
  });

  const handleChange = (name: string, value: string) => {
    setAdInf({
      ...adInf,
      [name]: value,
    });
  };

  useEffect(() => {
    setAdInf({
      rmOpDesc: adInfo?.rmOpDesc ?? "",
      rmOpNm: adInfo?.rmOpNm ?? "",
    });
  }, [adInfo]);

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"추가정보"}
          addBtn
          addBtnTxt="언어추가"
          clsBtn
          isClose={adOpen}
          handleAdCreate={() => setIsOpen(true)}
          handleClose={() => {
            setAdOpen(!adOpen);
          }}
        >
          {mdfyOp.length > 0 && (
            <SmallSelect
              option={langCd}
              options={mdfyOp}
              handleSelect={(target) => {
                const { value } = target.dataset;
                setLangCd(value ?? i18n.language);
              }}
            />
          )}
        </ITHeader>
        <div className={adOpen ? "" : "hidden"}>
          <ul>
            <li data-full>
              <label htmlFor="">객실옵션명</label>
              <input type="text" value={adInfo?.rmOpNm ?? ""} readOnly />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="" data-center>
                객실옵션설명
              </label>
              <ReadTuiEditor name={"rmDOpesc"} value={adInfo?.rmOpDesc} />
            </li>
          </ul>
        </div>
      </div>
      <Modal
        title={"추가정보 추가"}
        styles="w-5/6"
        btnText={"추가"}
        isOpen={isOpen}
        handleCloseModal={() => {
          setIsOpen(false);
          setAdInf({
            rmOpNm: "",
            rmOpDesc: "",
          });
          setLangCd(i18n.language);
        }}
        onClick={() => {
          handleAdd({ langCd, ...adInf });
          setIsOpen(false);
          setAdInf({
            rmOpNm: "",
            rmOpDesc: "",
          });
        }}
      >
        <div className="tb-form mt-[3rem] " data-t-brd>
          <ul>
            <li>
              <label htmlFor="">언어선택</label>
              <Select
                id={"langCd"}
                name={"langCd"}
                option={langCd}
                options={langOptions}
                isInitial
                onSelect={(target) => {
                  const { value } = target.dataset;
                  setLangCd(value ?? i18n.language);
                  // setAdInf
                }}
              />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">객실옵션명</label>
              <InContet
                name={"rmOpNm"}
                value={adInf.rmOpNm}
                onChange={(value) => {
                  handleChange("rmOpNm", value);
                }}
              />
            </li>
          </ul>
          <ul className="pb-2">
            <li data-full>
              <label htmlFor="">객실옵션설명</label>
              <InTuiEditor
                name={"rmOpDesc"}
                value={adInf.rmOpDesc}
                onChange={(value) => handleChange("rmOpDesc", value)}
              />
            </li>
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default AdInfCreate;
