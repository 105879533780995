import { useQuery } from "@tanstack/react-query";
import { lodRefAdList } from "components/api/lod";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import SmallSelect from "components/select/smSelect";
import ReadTuiEditor from "components/tuiEditor/readEditor";
import { langProps } from "interface";
import i18n from "locales/i18n";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { langOptions } from "util/option";
import AddModal from "./modal/addModal";
import { ModifyModal } from "./modal/modifyModal";

const InfAdModifyView = () => {
  const { search, state } = useLocation();
  const lodNo = search.replace("?lodNo=", "");

  const query = useQuery({
    queryKey: ["lodRdfAdList", lodNo],
    queryFn: () => {
      return lodRefAdList({
        lodNo,
        rgSeq: state?.rgSeq,
      });
    },
  });

  const adList = query?.data?.body?.lodRefdAdList || [];

  let mdfyOp = [] as langProps[];
  let addOp = [] as langProps[];

  langOptions.forEach((lang) => {
    const isAble = adList.some((ad: any) => {
      return lang.value === ad.langCd;
    });

    if (isAble) {
      mdfyOp.push(lang);
    }
    if (!isAble) {
      addOp.push(lang);
    }
  });

  const [langCd, setLangCd] = useState(mdfyOp[0]?.value || i18n.language);

  let adInfo = adList?.find((ad: any) => {
    const lng = langCd || mdfyOp[0]?.value;
    return ad.langCd === lng;
  });

  const [isOpen, setIsOpen] = useState("");
  const [addOpen, setAddOpen] = useState(true);

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"추가정보"}
          clsBtn
          addBtn={addOp.length > 0}
          addBtnTxt="언어추가"
          mdfyBtn={mdfyOp.length > 0}
          isClose={addOpen}
          handleAdCreate={() => setIsOpen("add")}
          handleModify={() => setIsOpen("mdfy")}
          handleClose={() => {
            setAddOpen(!addOpen);
          }}
        >
          {adList.length > 0 && (
            <SmallSelect
              option={langCd}
              options={mdfyOp}
              handleSelect={(target) => {
                const { value } = target.dataset;
                setLangCd(value ?? i18n.language);
              }}
            />
          )}
        </ITHeader>
        <div className={addOpen ? "" : "hidden"}>
          <ul>
            <li data-full>
              <label htmlFor="">환불명</label>
              <input type="text" value={adInfo?.refdInfNm ?? ""} readOnly />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">환불설명</label>
              <ReadTuiEditor name={"refdDesc"} value={adInfo?.refdDesc} />
            </li>
          </ul>
        </div>
      </div>
      {isOpen === "add" && (
        <AddModal
          langOp={addOp}
          isOpen={isOpen}
          lodNo={lodNo}
          setIsOpen={setIsOpen}
        />
      )}
      {isOpen === "mdfy" && (
        <ModifyModal
          // adInfo={adInfo}
          adList={adList}
          langOp={mdfyOp}
          isOpen={isOpen}
          lodNo={lodNo}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
};

export default InfAdModifyView;
