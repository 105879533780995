import AuthCreateView from "components/view/setting/auth/create";

const AuthCreatePage = () => {
  return (
    <>
      <AuthCreateView />
    </>
  );
};

export default AuthCreatePage;
