import InfModifyView from "./infModify";
import THeader from "components/layout/header/subHeader/tHeader";
import InfAdModifyView from "./infAdModify";

type IProps = {};

const LodRfdModifyView = ({}: IProps) => {
  return (
    <div className="main-cont" data-dir-col>
      <THeader
        sbTtl={"숙소환불수수료율기본 수정"}
        clsBtn
        handleReset={() => {}}
      />
      <InfModifyView />
      <InfAdModifyView />
    </div>
  );
};

export default LodRfdModifyView;
