import Select from "components/select";
import { aplyYnOptions, essYnOption, langOptions } from "util/option";
import "./term.scss";
import { prvProps } from "interface/term";
import { useLocation } from "react-router-dom";
import THeader from "components/layout/header/subHeader/tHeader";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import { useState } from "react";
import DatePick from "components/datePick";
import InTuiEditor from "components/tuiEditor/inEditot";

type IProps = {
  inf: prvProps;
  handleChange(name: string, value: string): void;
  handleCreate(): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
};

const TermCreateView = ({
  inf,
  handleChange,
  handleCreate,
  handleSelect,
}: IProps) => {
  const { pathname } = useLocation();
  const [infOpen, setInfOpen] = useState(true);

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader sbTtl={"약관내용등록"} clsBtn handleReset={() => {}} />
        <div className="tb-form">
          <ITHeader
            sbTtl={"기본정보"}
            clsBtn
            createBtn
            url={pathname}
            isClose={infOpen}
            handleCreate={handleCreate}
            handleClose={() => {
              setInfOpen(!infOpen);
            }}
          />
          <div className={infOpen ? "" : "hidden"}>
            <ul>
              <li>
                <label htmlFor="">약관명</label>
                <input
                  type="text"
                  value={inf.prvNm}
                  onChange={(e) => handleChange("", e.currentTarget.value)}
                />
              </li>
              <li>
                <label htmlFor="">약관버전명</label>
                <input
                  type="text"
                  value={inf.verNm}
                  onChange={(e) => handleChange("", e.currentTarget.value)}
                />
              </li>
              <li>
                <label htmlFor="">약관코드</label>
                <input
                  type="text"
                  value={inf.prvCd}
                  onChange={(e) => handleChange("", e.currentTarget.value)}
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">언어</label>
                <Select
                  id={"langCd"}
                  name={"langCd"}
                  option={inf.langCd}
                  options={langOptions}
                  onSelect={(target) => handleSelect(target)}
                />
              </li>
              <li>
                <label htmlFor="">필수여부</label>
                <Select
                  id={"essYn"}
                  name={"essYn"}
                  options={essYnOption}
                  isInitial
                  onSelect={(target) => handleSelect(target)}
                />
              </li>
              <li>
                <label htmlFor="">적용여부</label>
                <Select
                  id={"aplyYn"}
                  name={"aplyYn"}
                  options={aplyYnOptions}
                  isInitial
                  onSelect={(target) => handleSelect(target)}
                />
              </li>
            </ul>
            <ul>
              <li>
                <DatePick
                  label="적용일자"
                  startNm={"aplySDt"}
                  endNm={"aplyEDt"}
                  start={inf.aplySDt}
                  end={inf.aplyEDt}
                  handleChange={(name, value) => handleChange(name, value)}
                />
              </li>
            </ul>
            <ul>
              <li data-full>
                <label htmlFor="">약관설명</label>
                <input
                  type="text"
                  value={inf.prvDesc}
                  onChange={(e) =>
                    handleChange("prvDesc", e.currentTarget.value)
                  }
                />
              </li>
            </ul>
            <ul>
              <li data-full>
                <label htmlFor="">약관내용</label>
                <InTuiEditor
                  name={"trmCn"}
                  minH="300px"
                  onChange={(value) => handleChange("trmCn", value)}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermCreateView;
