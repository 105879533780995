import { IopDft, IopList } from "interface/lod";
import THeader from "components/layout/header/subHeader/tHeader";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import { useState } from "react";

type IProps = {
  dtl: IopDft;
  lopList: Array<IopList>;
  etcList: Array<IopList>;
  ropList: Array<IopList>;
  lops: Array<IopList>;
  rops: Array<IopList>;
  etcs: Array<IopList>;
};

const LodPrdInfoView = ({
  dtl,
  lopList,
  ropList,
  etcList,
  lops,
  rops,
  etcs,
}: IProps) => {
  const [infOpen, setInfOpen] = useState(true);
  const [addOpen, setAddOpen] = useState(true);

  const lodLop = lops.map((op) => {
    return lopList?.some((lp) => lp.opSeq === op.opSeq)
      ? { ...op, uyn: "Y" }
      : { ...op, uyn: "N" };
  });

  const lodRop = rops.map((op) => {
    return ropList?.some((rp) => rp.opSeq === op.opSeq)
      ? { ...op, uyn: "Y" }
      : { ...op, uyn: "N" };
  });

  const lodEtc = etcs.map((op) => {
    return etcList?.some((etc) => etc.opSeq === op.opSeq)
      ? { ...op, uyn: "Y" }
      : { ...op, uyn: "N" };
  });

  return (
    <>
      <div className="main-cont">
        <div className="inf-form" data-dir-col>
          <THeader
            sbTtl={"숙소제공정보 상세"}
            mdfyBtn
            clsBtn
            handleReset={() => {}}
          />
          <div className="tb-form">
            <ITHeader
              sbTtl={"기본정보"}
              clsBtn
              isClose={infOpen}
              handleClose={() => {
                setInfOpen(!infOpen);
              }}
            />
            <div className={infOpen ? "" : "hidden"}>
              <ul>
                <li>
                  <label htmlFor="">숙소그룹명</label>
                  <input type="text" defaultValue={dtl?.lodGrpNm} readOnly />
                </li>
                <li>
                  <label htmlFor="">숙소등급</label>
                  <input type="text" defaultValue={dtl?.lodGrdCd} readOnly />
                </li>
                <li>
                  <label htmlFor="">숙소명</label>
                  <input type="text" defaultValue={dtl?.lodNm} readOnly />
                </li>
              </ul>
            </div>
          </div>
          <div className="tb-form">
            <ITHeader
              sbTtl={"추가정보"}
              clsBtn
              isClose={addOpen}
              handleClose={() => {
                setAddOpen(!addOpen);
              }}
            />
            <div className={addOpen ? "" : "hidden"}>
              <ul>
                <li data-full data-btm-brd>
                  <label htmlFor="">공용시설</label>
                  <div className="ops">
                    {lodLop.map((lop) => (
                      <div key={lop.opSeq} className="radio-wrap">
                        <label htmlFor="">{lop.opNm}</label>
                        <span className="ms-4">
                          <input
                            type="radio"
                            name={lop.opNm}
                            id="Y"
                            checked={lop.uyn === "Y"}
                            readOnly
                          />
                          <label htmlFor="">사용</label>
                        </span>
                        <span>
                          <input
                            type="radio"
                            name={lop.opNm}
                            id="N"
                            checked={lop.uyn === "N"}
                            readOnly
                          />
                          <label htmlFor="">미사용</label>
                        </span>
                      </div>
                    ))}
                  </div>
                </li>
              </ul>
              <ul>
                <li data-full data-btm-brd>
                  <label htmlFor="">객실시설</label>
                  <div className="ops">
                    {lodRop.map((rop) => (
                      <div key={rop.opSeq} className="radio-wrap">
                        <label htmlFor="">{rop.opNm}</label>
                        <span className="ms-4">
                          <input
                            type="radio"
                            name={rop.opNm}
                            id="Y"
                            checked={rop.uyn === "Y"}
                            readOnly
                          />
                          <label htmlFor="">사용</label>
                        </span>
                        <span>
                          <input
                            type="radio"
                            name={rop.opNm}
                            id="N"
                            checked={rop.uyn === "N"}
                            readOnly
                          />
                          <label htmlFor="">미사용</label>
                        </span>
                      </div>
                    ))}
                  </div>
                </li>
              </ul>
              <ul>
                <li data-full>
                  <label htmlFor="">기타</label>
                  <div className="ops">
                    {lodEtc.map((etc) => (
                      <div key={etc.opSeq} className="radio-wrap">
                        <label htmlFor="">{etc.opNm}</label>
                        <span className="ms-4">
                          <input
                            type="radio"
                            name={etc.opNm}
                            id="Y"
                            checked={etc.uyn === "Y"}
                            readOnly
                          />
                          <label htmlFor="">사용</label>
                        </span>
                        <span>
                          <input
                            type="radio"
                            name={etc.opNm}
                            id="N"
                            checked={etc.uyn === "N"}
                            readOnly
                          />
                          <label htmlFor="">미사용</label>
                        </span>
                      </div>
                    ))}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LodPrdInfoView;
