import Pick from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";
import { LegacyRef, forwardRef } from "react";

type IProps = {
  label: string;
  name: string;
  date: string;
  handleChange(name: string, value: string): void;
};

type props = {
  value: string;
  onClick(): void;
  className: string;
};

const CustButton = forwardRef(
  (
    { value, onClick, className }: props,
    ref: LegacyRef<HTMLButtonElement> | undefined
  ) => (
    <button className={className} onClick={onClick} ref={ref}>
      {value}
    </button>
  )
);

const DatePick = ({ label, name, date, handleChange }: IProps) => {
  return (
    <>
      <div className="w-auto flex">
        <label htmlFor="">{label}</label>
        <Pick
          //   selected={startDt}
          value={date}
          dateFormat={"yyyy-MM-dd"}
          //   showTimeSelect
          showIcon
          icon="fa fa-calendar"
          onChange={(date) => {
            let offset = new Date().getTimezoneOffset() * 60000;
            let time = date?.getTime() ?? new Date().getTime();
            let dateOffset = new Date(time - offset);

            const start = dateOffset.toISOString().split("T")[0];
            handleChange(name, start);
          }}
          customInput={
            <CustButton className="" value={""} onClick={() => {}} />
          }
        />
      </div>
    </>
  );
};

export default DatePick;
