import InfCreateView from "./infCreate";
import InfAdCreateView from "./infAdCreate";
import { useRef } from "react";
import THeader from "components/layout/header/subHeader/tHeader";
import MessageCreateView from "./message";

type IProps = {};

const LodRefundCreateView = ({}: IProps) => {
  const lodNoRef = useRef("");
  const rgSeqRef = useRef(1);
  const isAbleRef = useRef(false);

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader sbTtl={"숙소환불수수료 생성"} clsBtn handleReset={() => {}} />
        <InfCreateView
          lodNoRef={lodNoRef}
          rgSeqRef={rgSeqRef}
          isAbleRef={isAbleRef}
        />
        <InfAdCreateView
          lodNoRef={lodNoRef}
          rgSeqRef={rgSeqRef}
          isAbleRef={isAbleRef}
        />
        <MessageCreateView />
      </div>
    </>
  );
};

export default LodRefundCreateView;
