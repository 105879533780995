import InfView from "./infCreate";
import AdInfView from "./adInfCreate";
import { useRef } from "react";
import THeader from "components/layout/header/subHeader/tHeader";

type IProps = {};

const LodGroupCreateView = ({}: IProps) => {
  const lodGrpNo = useRef("");

  return (
    <div className="main-cont" data-dir-col>
      <THeader sbTtl={"숙소그룹등록"} clsBtn handleReset={() => {}} />
      <InfView lodGrpNo={lodGrpNo} />
      <AdInfView lodGrpNo={lodGrpNo} />
    </div>
  );
};
export default LodGroupCreateView;
