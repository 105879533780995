import { useMutation } from "@tanstack/react-query";
import { lodListApi } from "components/api/comn";
import Select from "components/select";
import { optionProps } from "interface";
import { useEffect, useState } from "react";

type IProps = {
  option: string;
  params: string;
  disabled?: boolean;
  isArrow?: boolean;
  onSelect(target: EventTarget & HTMLLIElement): void;
};

export interface lodList {
  lodGrpNm: string;
  lodGrpNo: string;
  lodNm: string;
  lodNo: string;
}

const LodNoSelect = ({
  params,
  disabled = false,
  isArrow = false,
  option,
  onSelect,
}: IProps) => {
  const [options, setOptions] = useState<optionProps[]>([]);

  const listMutation = useMutation(lodListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          const options = body.lodList.map((li: lodList) => {
            const option = {
              value: li.lodNo,
              kr: li.lodNo,
              us: li.lodNo,
            };
            return option;
          });

          setOptions([...options]);
        }
        if (code === -1) {
          setOptions([]);
        }
      }
    },
  });

  useEffect(() => {
    if (!!params) {
      listMutation.mutate(params);
    }
    if (!params) {
      setOptions([]);
    }
  }, [params]);

  return (
    <Select
      id="lodNo"
      name="lodNo"
      disabled={disabled}
      isArrow={isArrow}
      option={option || "A"}
      params={params}
      isSusess={options.length !== 0}
      options={options}
      onSelect={onSelect}
    />
  );
};

export default LodNoSelect;
