import { Editor } from "@toast-ui/react-editor";
// import "@toast-ui/editor/toastui-editor.css";
import {
  Fragment,
  MutableRefObject,
  createElement,
  useEffect,
  useRef,
  useState,
} from "react";
import "./index.scss";
import i18n from "locales/i18n";

type Props = {
  name: string;
  info?: any;
  value?: string;
  content?: string;
  minH?: string;
  isAbleRef?: MutableRefObject<boolean>;
  onChange(value: string): void;
};

type IOptions = { id: string; label: string };

const toolbar = [
  ["heading", "bold", "italic", "strike"],
  ["hr", "quote"],
  ["ul", "ol", "task", "indent", "outdent"],
  ["table", "image", "link"],
  ["code", "codeblock"],
  ["scrollSync"],
];

const InTuiEditor = ({
  name,
  info,
  content,
  value = "",
  isAbleRef,
  minH = "44px",
  onChange,
}: Props) => {
  const editorRef = useRef<Editor>(null);
  const [lng, setLng] = useState(i18n.language);

  const [isBlur, setIsBlur] = useState(false);
  const [isKeyUp, setIsKeyUp] = useState(false);

  const options = [] as IOptions[];

  useEffect(() => {
    if (editorRef.current) {
      // const html = info[`${name}_${info.langCd}`] ?? "<p></p>";
      const html = value ?? "<p></p>";
      editorRef.current?.getInstance().setHTML(html);
    }
  }, [value]);

  useEffect(() => {
    if (isBlur && isKeyUp) {
      if (isAbleRef) {
        isAbleRef.current = true;
      }
    }
  }, [isBlur, isKeyUp]);

  useEffect(() => {
    const editor = document.querySelector(".toastui-editor-ww-container");
    const insertEL = editor?.childNodes[0].childNodes[0]
      .childNodes[0] as HTMLElement;
    // insertEL.onblur();
  }, []);

  return (
    <div className="radio-editor">
      {options.map((op) => {
        return (
          <Fragment key={op.id}>
            <input
              type="radio"
              id={name + "_" + op.id}
              name={name}
              value={op.id}
              onChange={(e) => {
                const value = e.currentTarget.value;
                setLng(value ?? "");
              }}
              defaultChecked={lng === op.id}
            />
            <label htmlFor={name + "_" + op.id} data-label={op.label}></label>
          </Fragment>
        );
      })}
      <div className="editor">
        <Editor
          initialValue={content ?? " "}
          initialEditType="wysiwyg"
          autofocus={false}
          ref={editorRef}
          toolbarItems={toolbar}
          hideModeSwitch
          language="ko"
          minHeight={minH}
          height="auto"
          onKeyup={() => {
            setIsKeyUp(true);
          }}
          onChange={() => {}}
          onBlur={() => {
            const data = editorRef.current?.getInstance().getHTML() ?? "";
            onChange(data);
            setIsBlur(true);
          }}
          //   hooks={{ addImageBlobHook: imageHandler }}
        />
      </div>
    </div>
  );
};

export default InTuiEditor;
