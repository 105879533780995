import TermListView from "components/view/terms/list";
import i18n from "locales/i18n";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setIsLoading, setIsSearch } from "store/commonSlice";

const TermListPage = () => {
  const dispatch = useDispatch();

  // const [userType, setUserType] = useState("admin");
  const [searchInfo, setSearchInfo] = useState({
    userType: "admin",
    langCd: i18n.language,
  });
  const [prvCd, setPrvCd] = useState("");
  const [prvList, setPrvList] = useState([]);
  const termRef = useRef<HTMLDivElement>(null);

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setSearchInfo({
        ...searchInfo,
        [name]: value,
      });
    }
  };

  const handleSearch = () => {
    dispatch(setIsLoading(true));
    dispatch(setIsSearch(true));
  };

  const handleReset = () => {
    setSearchInfo({
      userType: "admin",
      langCd: i18n.language,
    });
  };

  // const handlePrvList = async (userType: string) => {
  //   termListAPi({ langCd: i18n.language, userType }).then((data) => {
  //     if (data) {
  //       const {
  //         header: { code, message },
  //         body,
  //       } = data;
  //       if (code === 0) {
  //         setPrvList(body?.prvList);
  //         handlePrvDtl(body?.prvList[0]?.prvCd);
  //       }
  //       if (code === -1) {
  //         dispatch(setMessage({ message }));
  //       }
  //     }
  //   });
  // };

  // const handlePrvDtl = async (prvCd: string) => {
  //   trmDtlApi({
  //     langCd: i18n.language,
  //     prvCd,
  //   }).then((data) => {
  //     if (data) {
  //       const {
  //         header: { code, message },
  //         body,
  //       } = data;
  //       if (code === 0) {
  //         setPrvCd(body?.prvDtl?.prvCd);
  //         if (termRef.current) {
  //           termRef.current.innerHTML = body?.prvDtl?.trmCn;
  //         }
  //       }
  //       if (code === -1) {
  //         dispatch(setMessage({ message }));
  //       }
  //     }
  //   });
  // };

  // useEffect(() => {
  //   handlePrvList("admin");
  // }, []);

  return (
    <>
      <TermListView
        // prvList={prvList}
        // prvCd={prvCd}
        searchInfo={searchInfo}
        // userType={userType}
        termRef={termRef}
        handleSelect={handleSelect}
        handleSearch={handleSearch}
        handleReset={handleReset}
        // handlePrvList={handlePrvList}
        // handlePrvDtl={handlePrvDtl}
      />
    </>
  );
};

export default TermListPage;
