import { useMutation, useQueries } from "@tanstack/react-query";
import { opDtl, opModifyApi, opsApi } from "components/api/lod";
import LodPrdModifyView from "components/view/lod/prd/modify";
import { IopDft, IopList } from "interface/lod";
import i18n from "locales/i18n";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";

type opsProps = {
  opSeq: string;
  uyn: string;
};

const LodPrdModifyPage = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const lodNo = search.replace("?lodNo=", "");
  const [lopInf, setLopInf] = useState<string[]>([]);
  const [ropInf, setRopInf] = useState<string[]>([]);
  const [etcInf, setEtcInf] = useState<string[]>([]);

  const dtlMutation = useMutation(opDtl, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          let lopYns =
            body?.lopList &&
            body?.lopList
              .filter((lop: any) => {
                return lop.uyn === "Y";
              })
              .map((lop: any) => {
                return lop.opSeq + "";
              });
          setLopInf(lopYns);
          let ropYns = [];
        }

        if (code === -1) {
          dispatch(
            setMessage({
              message: message || "검색 데이터가 없습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  const query = useQueries({
    queries: [
      {
        queryKey: ["LOP"],
        queryFn: () => {
          return opsApi("LOP");
        },
      },
      {
        queryKey: ["ROP"],
        queryFn: () => {
          return opsApi("ROP");
        },
      },
      {
        queryKey: ["ETC"],
        queryFn: () => {
          return opsApi("ETC");
        },
      },
    ],
  });

  useEffect(() => {
    dtlMutation.mutate({
      lodNo,
      langCd: i18n.language,
      opCtgCd: "",
    });
  }, [search]);

  const dtl = dtlMutation.data?.body.lodDtl as IopDft;
  let lopList = dtlMutation.data?.body?.lopList as IopList[];
  let ropList = dtlMutation.data?.body.ropList as IopList[];
  let etcList = dtlMutation.data?.body.etcList as IopList[];

  const lops = query[0].data?.body?.ops || [];
  const rops = query[1].data?.body?.ops || [];
  const etcs = query[2].data?.body?.ops || [];

  const handleChange = (target: EventTarget & HTMLInputElement) => {
    const { opseq, uyn, ctgcd } = target.dataset;

    if (opseq && uyn) {
      if (ctgcd === "LOP") {
        setLopInf(
          lopInf.includes(opseq)
            ? lopInf.filter((lop) => lop !== opseq)
            : [...lopInf, opseq]
        );

        return;
      }
      if (ctgcd === "ROP") {
        setRopInf(
          ropInf.includes(opseq)
            ? ropInf.filter((rop) => rop !== opseq)
            : [...ropInf, opseq]
        );
      }
      if (ctgcd === "ETC") {
        setEtcInf(
          etcInf.includes(opseq)
            ? etcInf.filter((etc) => etc !== opseq)
            : [...etcInf, opseq]
        );
      }
    }
  };

  const adInfMutation = useMutation(opModifyApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
        }
      }
    },
  });

  const handleModify = () => {
    if (lopInf.length === 0 && ropInf.length === 0 && etcInf.length === 0) {
      return alert("추가정보를 수정하여주세요.");
    }
    let lops = [] as opsProps[];
    const lopSeqs = lopList.map((lop) => {
      return lop.opSeq + "";
    });

    lopInf.forEach((lop) => {
      if (!lopSeqs.includes(lop)) {
        lops.push({
          opSeq: lop,
          uyn: "Y",
        });
      }
    });
    lopSeqs.filter((lop) => {
      if (!lopInf.includes(lop)) {
        lops.push({
          opSeq: lop,
          uyn: "N",
        });
      }
    });

    let rops = [] as opsProps[];
    const ropSeqs = ropList.map((rop) => {
      return rop.opSeq + "";
    });
    ropInf.forEach((rop) => {
      if (!ropSeqs.includes(rop)) {
        rops.push({
          opSeq: rop,
          uyn: "Y",
        });
      }
    });
    ropSeqs.forEach((rop) => {
      if (!ropInf.includes(rop)) {
        rops.push({
          opSeq: rop,
          uyn: "N",
        });
      }
    });

    let etcs = [] as opsProps[];
    const etcSeqs = etcList.map((etc) => {
      return etc.opSeq + "";
    });
    etcInf.forEach((etc) => {
      if (!etcSeqs.includes(etc)) {
        etcs.push({
          opSeq: etc,
          uyn: "Y",
        });
      }
    });
    etcSeqs.forEach((etc) => {
      if (!etcInf.includes(etc)) {
        etcs.push({
          opSeq: etc,
          uyn: "N",
        });
      }
    });

    const params = {
      lodNo,
      langCd: i18n.language,
      lopInf: lops,
      ropInf: rops,
      etcInf: etcs,
    };

    console.log("params", params);

    dispatch(
      setMessage({
        message: "추가정보를 수정하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          adInfMutation.mutate(params);
        },
      })
    );
  };

  return (
    <LodPrdModifyView
      dtl={dtl}
      lopList={lopList}
      etcList={etcList}
      ropList={ropList}
      lops={lops}
      rops={rops}
      etcs={etcs}
      lopInf={lopInf}
      ropInf={ropInf}
      etcInf={etcInf}
      handleChange={handleChange}
      handleModify={handleModify}
    />
  );
};

export default LodPrdModifyPage;
