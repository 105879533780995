import DatePick from "components/datePick";
import THeader from "components/layout/header/subHeader/tHeader";
import Select from "components/select";
import CalculateList from "components/tableList/calculate";
import { clcPromProps } from "interface/calc";
import { useLocation } from "react-router-dom";

type IProps = {
  searchInfo: clcPromProps;
  handleChange(name: string, value: string): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleReset(): void;
};

const CalculatePromView = ({
  searchInfo,
  handleChange,
  handleReset,
  handleSelect,
}: IProps) => {
  const { pathname } = useLocation();

  const potions = [
    {
      kr: "전체",
      value: "A",
      en: "",
    },
    {
      kr: "취소",
      value: "Y",
      en: "",
    },
    {
      kr: "미취소",
      value: "N",
      en: "",
    },
  ];

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader
          sbTtl={"프로모션코드 조회"}
          rstBtn
          // newBtn
          url={pathname}
          searchBtn
          handleReset={handleReset}
        />

        <div className="tb-form" data-list>
          <ul>
            <li>
              <label htmlFor="">프로모션코드</label>
              <input
                type="text"
                value={searchInfo.promCd}
                onChange={(e) => handleChange("promCd", e.currentTarget.value)}
              />
            </li>
            <li>
              <label htmlFor="">객실예약번호</label>
              <input
                type="text"
                value={searchInfo.rmResvNo}
                onChange={(e) =>
                  handleChange("rmResvNo", e.currentTarget.value)
                }
              />
            </li>
            <li>
              <label htmlFor="">예약취소여부</label>
              <Select
                id={"resvCnlYn"}
                name={"resvCnlYn"}
                option={searchInfo.resvCnlYn || "A"}
                options={potions}
                isInitial
                onSelect={handleSelect}
              />
            </li>
          </ul>
          <ul>
            <li>
              <DatePick
                startNm={"sDt"}
                endNm={"eDt"}
                start={searchInfo.sDt}
                end={searchInfo.eDt}
                handleChange={(name, value) => handleChange(name, value)}
              />
            </li>
          </ul>
        </div>
      </div>

      <CalculateList searchInfo={searchInfo} />
    </>
  );
};

export default CalculatePromView;
