import "./checkbox.scss";

type IProps = {
  label: string;
  id: string;
  disabled?: boolean;
  checked?: boolean;
  labelStyles?: string;
  full?: boolean;
  ref?: React.MutableRefObject<null>;
  handleCheckbox(e: React.ChangeEvent<HTMLInputElement>): void;
};

const CheckBox = ({
  label,
  id,
  disabled,
  checked,
  labelStyles,
  full = true,
  // ref,
  handleCheckbox,
}: IProps) => {
  return (
    <div className="checkbox-wrap" data-full={full}>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        disabled={disabled}
        onChange={handleCheckbox}
      />
      <label htmlFor={id} className={labelStyles}>
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
