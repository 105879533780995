import ITHeader from "components/layout/header/subHeader/itemHeader";
import Select from "components/select";
import LodGrpNmSelect from "components/select/room/lodGrpNmSelect ";
import LodNmSelect from "components/select/room/lodNmSelect";
import RmNmSelect from "components/select/room/rmNmSelect";
import { rmOptCreateProps } from "interface/room";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { brkfInYnOptions, lastTimeOptions, uYnOptions } from "util/option";

type IProps = {
  opInf: rmOptCreateProps;
  handleCreate(): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
};

const InfCreate = ({ opInf, handleSelect, handleCreate }: IProps) => {
  const { pathname } = useLocation();
  const [infOpen, setInfOpen] = useState(true);

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"기본정보"}
          clsBtn
          isClose={infOpen}
          desc="* 필수입력"
          handleClose={() => {
            setInfOpen(!infOpen);
          }}
        />
        <div className={infOpen ? "" : "hidden"}>
          <ul>
            {pathname.includes("manager") && (
              <>
                <li>
                  <label htmlFor="">숙소그룹명</label>
                  <input type="text" defaultValue={opInf.lodGrpNm} readOnly />
                </li>
                <li>
                  <label htmlFor="">숙소명</label>
                  <input type="text" defaultValue={opInf.lodNm} readOnly />
                </li>
              </>
            )}
            {pathname.includes("admin") && (
              <>
                <li>
                  <label htmlFor="">숙소그룹명</label>
                  <LodGrpNmSelect
                    option={opInf.lodGrpNo}
                    onSelect={handleSelect}
                  />
                </li>
                <li>
                  <label htmlFor="">숙소명</label>
                  <LodNmSelect
                    option={opInf.lodNo}
                    params={opInf.lodGrpNo}
                    onSelect={handleSelect}
                  />
                </li>
              </>
            )}
            <li>
              <label htmlFor="" data-dot>
                객실명
              </label>
              <RmNmSelect
                option={opInf.rmNo}
                params={opInf.lodNo}
                onSelect={handleSelect}
              />
            </li>
          </ul>

          <ul>
            <li>
              <label htmlFor="" data-dot>
                객실옵션 조식포함
              </label>
              <Select
                id={"brkfInYn"}
                name={"brkfInYn"}
                wrapStyle="w-[12.5rem]"
                option={opInf.brkfInYn}
                options={brkfInYnOptions.slice(1, 3)}
                isInitial
                onSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="" data-dot>
                레이트 체크아웃시간
              </label>
              <Select
                id={"latChkOutTi"}
                name={"latChkOutTi"}
                wrapStyle="w-[12.5rem]"
                option={
                  opInf?.latChkOutTi?.replace(/\B(?=(\d{2})+(?!\d))/g, ":") ||
                  "A"
                }
                options={lastTimeOptions}
                onSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="">사용</label>
              <Select
                id={"uyn"}
                name={"uyn"}
                option={opInf.uyn}
                options={uYnOptions}
                isInitial
                onSelect={handleSelect}
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default InfCreate;
