import { useMutation } from "@tanstack/react-query";
import { rmOpListApi } from "components/api/comn";
import Select from "components/select";
import { optionProps } from "interface";
import i18n from "locales/i18n";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setRmList, setRmOpList } from "store/roomSlice";

type IProps = {
  option: string;
  disabled?: boolean;
  params: string;
  onSelect(target: EventTarget & HTMLLIElement): void;
};

const RmOpSelect = ({ params, disabled, option, onSelect }: IProps) => {
  const dispatch = useDispatch();
  const lng = i18n.language;
  const [options, setOptions] = useState<optionProps[]>([]);

  const listMutation = useMutation({
    mutationKey: ["rmOpList", params],
    mutationFn: rmOpListApi,
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;

        if (code === 0) {
          const options = body.rmOpList.map((li: any) => {
            const option = {
              value: li.rgSeq,
              kr: li.rmOpNm,
              us: li.rmOpNm,
            };

            return option;
          });

          setOptions(options);

          dispatch(setRmOpList(body.rmOpList));
        }
        if (code === -1) {
          setOptions([]);
        }
      }
    },
  });

  useEffect(() => {
    if (!!params) {
      listMutation.mutate(params);
    }
  }, [params]);

  return (
    <Select
      id="rmOpSeq"
      name="rmOpSeq"
      option={option || "A"}
      disabled={disabled}
      options={options}
      onSelect={onSelect}
    />
  );
};

export default RmOpSelect;
