import { lodRfOPdHead } from "util/option";
import { IlodRfd, lodRfdSearchInfoProps } from "interface/lod";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Dispatch,
  SetStateAction,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  isSearchSelector,
  setIsLoading,
  setIsSearch,
  setMessage,
} from "store/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import { pageNavProps } from "interface";
import Pagenation from "components/pagenation";
import { useMutation } from "@tanstack/react-query";
import { lodRefdListApi } from "components/api/lod";
import i18n from "locales/i18n";
import SearchHeader from "components/layout/header/subHeader/srchHeader";
import { dateFormat } from "util/common";

type IListProps = {
  searchInfo: lodRfdSearchInfoProps;
  memType: "admin" | "manager";
  col: number;
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};

const List = ({ searchInfo, memType, col, setPageNav }: IListProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();

  const isSearch = useSelector(isSearchSelector);

  const [list, setList] = useState([]);

  const lodRefdMutation = useMutation(lodRefdListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        dispatch(setIsLoading(false));
        dispatch(setIsSearch(false));
        if (code === 0) {
          setPageNav(body?.pageNav);
          setList(body?.lodRefdList);
        }
        if (code === -1) {
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch) {
      const params = {
        lodGrpNo: searchInfo.lodGrpNo || state?.lodGrpNo,
        lodGrpNm: searchInfo.lodGrpNm || state?.lodGrpNm,
        lodNo: searchInfo?.lodNo || state?.lodNo,
        lodNm: searchInfo.lodNm || state?.lodNm,
        lodGrdCd: searchInfo.lodGrdCd || state?.lodGrdCd,
        refdFeeTypCd: searchInfo.refdFeeTypCd || state?.refdFeeTypCd,
        uyn: searchInfo.uyn || state?.uyn,
        pageNo: page || state?.pageNo,
        langCd: i18n.language,
      };

      lodRefdMutation.mutate({
        ...params,
        uyn: params.uyn === "A" ? "" : params.uyn,
      });
    }
  }, [isSearch, searchInfo, search]);

  const pageRows = lodRefdMutation.data?.body?.pageNav?.pageRows;
  const isNotData = !pageRows;

  return (
    <>
      {list.length === 0 && (
        <tr>
          <td colSpan={col}>
            {isNotData && (
              <span className="font-bold">검색조건을 선택하여 주세요.</span>
            )}
            {!isNotData && (
              <span className="font-bold">검색결과가 없습니다.</span>
            )}
          </td>
        </tr>
      )}
      {list &&
        list?.map((li: IlodRfd, index: number) => {
          return (
            <tr
              key={index}
              onClick={(e) => {
                e.preventDefault();
                navigate(`info?lodNo=${li.lodNo}`, {
                  state: {
                    searchInfo,
                    url: pathname + search,
                    rgSeq: li.rgSeq,
                  },
                });
                dispatch(setIsSearch(false));
              }}
            >
              <td>{(Number(page) - 1) * (pageRows || 10) + index + 1}</td>
              <td>{li.lodGrpNo}</td>
              <td>{li.lodGrpNm}</td>
              <td>{li.lodNo}</td>
              <td>{li.lodNm}</td>
              <td>{li.lodGrdCd}</td>
              <td>{li.refdFeeTypCd === "01" ? "판매가" : "입금가"}</td>
              <td>
                {li.feeTypCd === "B01"
                  ? "PerNight"
                  : li.feeTypCd === "B02"
                  ? "PerBooking"
                  : "PerOneNight"}
              </td>
              <td>옵션명</td>
              <td>{li.aplyChkDay}</td>
              <td>{li.feeRti + "%"}</td>
              <td>
                <div className="flex">
                  <span>{li.monAplyYn === "Y" ? "월" : ""}</span>
                  <span>{li.tuesAplyYn === "Y" ? "화" : ""}</span>
                  <span>{li.wenAplyYn === "Y" ? "수" : ""}</span>
                  <span>{li.thurAplyYn === "Y" ? "목" : ""}</span>
                  <span>{li.friAplyYn === "Y" ? "금" : ""}</span>
                  <span>{li.satAplyYn === "Y" ? "토" : ""}</span>
                  <span>{li.sunAplyYn === "Y" ? "일" : ""}</span>
                </div>
              </td>
              <td>{dateFormat(li.aplySDt)}</td>
              <td>{dateFormat(li.aplyEDt)}</td>
              <td>{li.fstCrtDtti}</td>
              <td>{li.uyn === "Y" ? "사용" : "미사용"}</td>
              {/* <td>
                <button className="text-red-300" onClick={() => {}}>
                  등록
                </button>
              </td> */}
            </tr>
          );
        })}
    </>
  );
};

type IProps = {
  searchInfo: lodRfdSearchInfoProps;
  memType: "admin" | "manager";
};

const LodRfdOPList = ({ memType, searchInfo }: IProps) => {
  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const tableRef = useRef<HTMLDivElement>(null);
  const col = lodRfOPdHead.length;

  return (
    <div className="list-wrap" ref={tableRef}>
      <SearchHeader pageNav={pageNav} />
      <div>
        <table>
          <thead>
            {memType === "admin" && (
              <tr>
                {lodRfOPdHead.map((li) => (
                  <th key={li}>{li}</th>
                ))}
              </tr>
            )}

            {memType === "manager" && (
              <tr>
                {lodRfOPdHead.map((li) => (
                  <th key={li}>{li}</th>
                ))}
              </tr>
            )}
          </thead>

          <tbody>
            <Suspense>
              <List
                searchInfo={searchInfo}
                setPageNav={setPageNav}
                memType={memType}
                col={col}
              />
            </Suspense>
            {pageNav && pageNav?.totalRows > 0 && (
              <tr>
                <td colSpan={col}>
                  <Pagenation total={pageNav?.totalPage} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LodRfdOPList;
