import ITHeader from "components/layout/header/subHeader/itemHeader";
import THeader from "components/layout/header/subHeader/tHeader";
import Select from "components/select";
import CountrySelect from "components/select/country";
import PhoneSelect from "components/select/phone";
import { promProps } from "interface/promotion";
import { useState } from "react";
import { langOptions } from "util/option";

type IProps = {
  info: promProps;
  handleChange(name: string, value: string): void;
  handleCreate(): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
};

const PromCreateView = ({
  info,
  handleChange,
  handleCreate,
  handleSelect,
}: IProps) => {
  const isAble = true;

  const [infOpen, setInfOpen] = useState(true);

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader sbTtl={"프로모션코드생성"} clsBtn handleReset={() => {}} />
        <div className="tb-form">
          <ITHeader
            sbTtl={"기본정보"}
            clsBtn
            createBtn
            isClose={infOpen}
            desc="* 필수 입력"
            handleCreate={handleCreate}
            handleClose={() => {
              setInfOpen(!infOpen);
            }}
          />
          <div className={infOpen ? "" : "hidden"}>
            <ul>
              <li>
                <label htmlFor="" data-dot>
                  프로모션코드
                </label>
                <input
                  type="text"
                  value={info.promCd}
                  onChange={(e) =>
                    handleChange("promCd", e.currentTarget.value)
                  }
                />
              </li>
              <li>
                <label htmlFor="" data-dot>
                  프로모션명
                </label>
                <input
                  type="text"
                  value={info.promNm}
                  onChange={(e) =>
                    handleChange("promNm", e.currentTarget.value)
                  }
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="" data-dot>
                  인플루언서명
                </label>
                <input
                  type="text"
                  value={info.influNm}
                  onChange={(e) =>
                    handleChange("influNm", e.currentTarget.value)
                  }
                />
              </li>
              <li>
                <label htmlFor="" data-dot>
                  인플루언서 이메일
                </label>
                <input
                  type="text"
                  value={info.influEml}
                  onChange={(e) =>
                    handleChange("influEml", e.currentTarget.value)
                  }
                />
              </li>
              <li>
                <label htmlFor="">인플루언서 국가</label>
                <CountrySelect
                  option={info.influCnty}
                  handleSelect={handleSelect}
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">언어코드</label>
                <Select
                  id={"influLangCd"}
                  name={"influLangCd"}
                  option={info.influLangCd}
                  options={langOptions}
                  isInitial
                  onSelect={handleSelect}
                />
              </li>
              <li>
                <label htmlFor="phone">휴대폰국가번호</label>
                <div className="flex gap-x-1">
                  <PhoneSelect
                    isNm
                    mobCd={info.influMobCntyNo}
                    handleSelect={handleSelect}
                  />
                  <input
                    type="text"
                    value={info.influMobNo}
                    onChange={(e) =>
                      handleChange("influMobNo", e.currentTarget.value)
                    }
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromCreateView;
