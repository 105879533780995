import MemberListView from "components/view/member/list";
import { searchProps } from "interface/member";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setIsLoading, setIsSearch } from "store/commonSlice";
import { useLocation, useNavigate } from "react-router-dom";

const MemberListPage = () => {
  const dispatch = useDispatch();
  const navigage = useNavigate();
  const { pathname, state } = useLocation();

  const memType = pathname.includes("admin")
    ? "admin"
    : pathname.includes("manager")
    ? "manager"
    : "";

  const lodGrpInfo = localStorage.getItem("lodGrpInfo")
    ? JSON.parse(localStorage.getItem("lodGrpInfo") + "")
    : "";

  const now = new Date();
  const today =
    now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();

  const [searchInfo, setSearchInfo] = useState<Partial<searchProps>>({
    type: state?.type || memType,
    authGrpCod: state?.authGrpCod || "",
    emlId: state?.emlId || "",
    mobCntyCd: state?.mobCntyCd || "+82",
    mobNo: state?.mobNo || "",
    memStatus: state?.memStatus || "",
    lodGrpNo: state?.lodGrpNo || lodGrpInfo?.lodGrpNo,
    lodGrpNm: state?.lodGrpNm || lodGrpInfo?.lodGrpNm,
    lodNo: state?.lodNo || lodGrpInfo?.lodNo,
    joiReqDtti: state?.joiReqDtti || today,
    soDtti: state?.soDtti || today,
    soYn: state?.soYn || "",
    lckYn: state?.lckYn || "",
    joiAprYn: state?.joiAprYn || "",
    searchTyp: state?.searchTyp || "",
  });

  const handleChange = (name: string, value: string) => {
    if (name === "memTyp") {
      setSearchInfo({
        ...searchInfo,
        type: value,
      });
      navigage("");
    } else {
      setSearchInfo({
        ...searchInfo,
        [name]: value,
      });
    }
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setSearchInfo({
        ...searchInfo,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const handleReset = () => {
    setSearchInfo({
      type: memType || "manager",
      authGrpCod: "",
      emlId: "",
      mobCntyCd: "+82",
      mobNo: "",
      memStatus: "",
      lodGrpNo: lodGrpInfo?.lodGrpNo || "",
      lodGrpNm: lodGrpInfo?.lodGrpNm || "",
      lodNo: lodGrpInfo?.lodNo || "",
      joiReqDtti: "",
      soDtti: "",
      soYn: "",
      lckYn: "",
      joiAprYn: "",
    });
    dispatch(setIsLoading(false));
    dispatch(setIsSearch(false));
    navigage("");
  };

  return (
    <>
      <MemberListView
        searchInfo={searchInfo}
        handleChange={handleChange}
        handleSelect={handleSelect}
        handleReset={handleReset}
      />
    </>
  );
};

export default MemberListPage;
