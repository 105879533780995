import { useMutation } from "@tanstack/react-query";
import { rmOpCreateApi } from "components/api/room";
import RoomOptionCreateView from "components/view/room/opt/create/index";
import { rmOptCreateProps } from "interface/room";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setIsSearch, setMessage } from "store/commonSlice";

const RoomOptCreatePage = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const memType = pathname.includes("manager") ? "manager" : "admin";
  let lodGrpInfo =
    memType === "manager"
      ? JSON.parse(localStorage.getItem("lodGrpInfo") + "")
      : "";
  const [opInf, setOpInf] = useState<rmOptCreateProps>({
    lodGrpNo: lodGrpInfo?.lodGrpNo,
    lodGrpNm: lodGrpInfo?.lodGrpNm,
    lodNo: localStorage.getItem("sltLodNo") ?? "",
    lodNm: lodGrpInfo?.lodNm,
    rmNo: "",
    brkfInYn: "N",
    latChkOutTi: "",
    uyn: "Y",
    rmOpInfList: [],
  });

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;

    if (name && value) {
      setOpInf({
        ...opInf,
        [name]: value,
      });
    }
  };

  const handleAdd = (adInf: any) => {
    const diff = opInf.rmOpInfList.filter((inf) => {
      return inf.langCd !== adInf.langCd;
    });

    setOpInf({
      ...opInf,
      rmOpInfList: [...diff, adInf],
    });
  };

  const rmOpMutation = useMutation(rmOpCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: `객실옵션을 등록하였습니다.`,
              isConfirm: true,
              handleClick() {
                dispatch(setIsSearch(false));
                dispatch(setMessage({ message: null }));
                setOpInf({
                  lodGrpNo: lodGrpInfo?.lodGrpNo,
                  lodGrpNm: lodGrpInfo?.lodGrpNm,
                  lodNo: localStorage.getItem("sltLodNo") ?? "",
                  lodNm: lodGrpInfo?.lodNm,
                  rmNo: "",
                  brkfInYn: "N",
                  latChkOutTi: "",
                  uyn: "Y",
                  rmOpInfList: [],
                });
              },
            })
          );
        }

        if (code === -1) {
          dispatch(
            setMessage({
              message: message || "검색 데이터가 없습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setIsSearch(false));
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  const handleAdCreate = () => {
    if (!opInf.lodGrpNo) {
      return alert("숙소그룹명을 선택하여 주세요");
    }
    if (!opInf.lodNo) {
      return alert("숙소명을 선택하여 주세요.");
    }

    if (!opInf.rmNo) {
      return alert("객실명을 선택하여 주세요.");
    }
    if (!opInf.brkfInYn) {
      return alert("객식옵션 조식포함 여부를 선택하여 주세요.");
    }

    const params = {
      ...opInf,
      latChkOutTi: opInf.latChkOutTi.replaceAll(":", ""),
    };

    console.log("rmOp params", params);

    dispatch(
      setMessage({
        message: "객실옵션을 등록하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          dispatch(setMessage({ message: null }));
          rmOpMutation.mutate(params);
        },
      })
    );
  };

  return (
    <RoomOptionCreateView
      opInf={opInf}
      handleSelect={handleSelect}
      handleAdd={handleAdd}
      handleCreate={handleAdCreate}
    />
  );
};
export default RoomOptCreatePage;
