import THeader from "components/layout/header/subHeader/tHeader";
import LodGrpNmSelect from "components/select/room/lodGrpNmSelect ";
import LodNmSelect from "components/select/room/lodNmSelect";
import CurrentList from "components/tableList/room/crr";
import { rmCrrProps } from "interface/room";
import { Suspense } from "react";
import { useLocation } from "react-router-dom";

type Iprops = {
  searchInfo: Omit<rmCrrProps, "sellSDt" | "sellEDt">;
  memType: "admin" | "manager";
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleSearch(): void;
  handleReset(): void;
};

const RoomCrrView = ({
  searchInfo,
  memType,
  handleSelect,
  handleReset,
}: Iprops) => {
  const { pathname } = useLocation();
  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader
          sbTtl={"객실상품현황"}
          rstBtn
          url={pathname}
          searchBtn
          handleReset={handleReset}
        />
        <div className="tb-form">
          <ul>
            <li>
              <label htmlFor="">숙소그룹명</label>
              <LodGrpNmSelect
                option={searchInfo.lodGrpNo}
                isArrow={pathname.includes("manager")}
                disabled={pathname.includes("manager")}
                onSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="">숙소명</label>
              <LodNmSelect
                option={searchInfo.lodNo}
                params={searchInfo.lodGrpNo}
                isArrow={pathname.includes("manager")}
                disabled={pathname.includes("manager")}
                onSelect={handleSelect}
              />
            </li>
          </ul>
        </div>
      </div>
      <Suspense>
        <CurrentList searchInfo={searchInfo} />
      </Suspense>
    </>
  );
};

export default RoomCrrView;
