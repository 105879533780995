import Select from "components/select";
import LodGrpSelect from "components/select/room/lodGrpNoSelect";
import RoomOptionList from "components/tableList/room/option";
import { rmOptSearchProps } from "interface/room";
import { brkfInYnOptions, lastTimeOptions, lodGrpStatus } from "util/option";

import { useLocation } from "react-router-dom";
import THeader from "components/layout/header/subHeader/tHeader";
import LodGrdSelect from "components/select/room/lodGrdSelect";
import SearchSelect from "components/select/searchSelect";

type IProps = {
  memType: "admin" | "manager";
  searchInfo: rmOptSearchProps;
  handleChange(target: EventTarget & HTMLInputElement): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleReset(): void;
};

const RoomOptView = ({
  memType,
  searchInfo,
  handleChange,
  handleSelect,
  handleReset,
}: IProps) => {
  const { pathname, state } = useLocation();
  const searchOp = [
    // {
    //   kr: "객실옵션번호",
    //   value: "rmOpNo",
    //   en: "RoomOptionNo",
    // },
    {
      kr: "객실옵션명",
      value: "rmOpNm",
      en: "RoomOptionName",
    },
  ];

  const searchOps =
    memType === "admin"
      ? [
          {
            kr: "숙소그룹명",
            value: "lodGrpNm",
            en: "LodGrpName",
          },
          {
            kr: "숙소번호",
            value: "lodNo",
            en: "LodNo",
          },
        ]
      : searchOp;

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader
          sbTtl={"객실옵션 조회"}
          rstBtn
          newBtn
          url={pathname}
          searchBtn
          isSearch={!searchInfo.lodGrpNo}
          msg={!searchInfo.lodGrpNo ? "숙소그룹번호를 선택하여 주세요." : ""}
          handleReset={handleReset}
        />

        <div className="tb-form" data-list>
          {memType === "admin" && (
            <>
              <ul>
                <li>
                  <label htmlFor="" data-dot>
                    숙소그룹번호
                  </label>
                  <LodGrpSelect
                    option={searchInfo.lodGrpNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </li>
                <li>
                  <label htmlFor="">숙소등급</label>
                  <LodGrdSelect
                    option={searchInfo.lodGrpCd ?? ""}
                    onSelect={(target) => handleSelect(target)}
                  />
                </li>
              </ul>
            </>
          )}
          <ul>
            <li>
              <label htmlFor="">조식포함</label>
              <Select
                id="brkfInYn"
                name="brkfInYn"
                option={searchInfo.brkfInYn}
                options={brkfInYnOptions}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="">레이트체크아웃시간</label>
              <Select
                id="latChkOutTi"
                name="latChkOutTi"
                option={searchInfo.latChkOutTi}
                options={lastTimeOptions}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="">상태</label>
              <Select
                id="uyn"
                name="uyn"
                option={searchInfo.uyn}
                options={lodGrpStatus}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">검색조건</label>
              <SearchSelect
                id={"searchTyp"}
                name={searchInfo.searchTyp ?? ""}
                options={searchOps}
                option={searchInfo.searchTyp || "A"}
                value={
                  searchInfo[
                    `${searchInfo.searchTyp}` as keyof rmOptSearchProps
                  ] || ""
                }
                onSelect={(target) => handleSelect(target)}
                onChange={(target) => handleChange(target)}
              />
            </li>
          </ul>
        </div>
      </div>

      <RoomOptionList memType={memType} searchInfo={searchInfo} />
    </>
  );
};

export default RoomOptView;
