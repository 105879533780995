import "./signup.scss";
import { signupInfoProps } from "interface/member";
import { regEN, regEmail, regNum, regPhone } from "util/common";
import PhoneSelect from "components/select/phone";
import LodGrpNmSelect from "components/select/room/lodGrpNmSelect ";
import THeader from "components/layout/header/subHeader/tHeader";
import { useLocation } from "react-router-dom";
import Select from "components/select";
import { authOptions } from "util/option";

type IProps = {
  info: signupInfoProps;
  type: "admin" | "manager";
  handleChange(target: { name: string; value: string }): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleSignup(): void;
};

const Step1View = ({
  info,
  type,
  handleChange,
  handleSelect,
  handleSignup,
}: IProps) => {
  const { pathname } = useLocation();

  const baseAble = !!(
    info.emlId &&
    info.fstnm &&
    info.surnm &&
    info.mobCntyCd &&
    info.telNo &&
    regEmail.test(info.emlId) &&
    regPhone.test(info.telNo)
  );

  const isAble = pathname.includes("admin")
    ? baseAble && !!info.authGrpCod
    : baseAble && !!info.lodGrpNo;

  const typName = type === "admin" ? " 관리자" : "매니저";

  return (
    <>
      <div className="main-cont">
        <div className="card-form">
          <THeader sbTtl={typName + " 회원가입"} handleReset={() => {}} />
          <div className="tb-form py-2">
            <ul className="">
              {pathname.includes("manager") && (
                <li>
                  <label htmlFor="">숙박그룹</label>
                  <LodGrpNmSelect
                    option={info.lodGrpNo ?? ""}
                    onSelect={handleSelect}
                  />
                </li>
              )}
            </ul>
            <ul>
              <li>
                <label htmlFor="">{typName} 영문이름</label>
                <input
                  type="text"
                  name="fstnm"
                  value={info.fstnm}
                  onChange={(e) => {
                    const value = regEN.test(e.currentTarget.value)
                      ? e.currentTarget.value
                      : "";
                    handleChange({ name: "fstnm", value });
                  }}
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">{typName} 영문성</label>
                <input
                  type="text"
                  name="surnm"
                  value={info.surnm}
                  onChange={(e) => {
                    const value = regEN.test(e.currentTarget.value)
                      ? e.currentTarget.value
                      : "";
                    handleChange({ name: "surnm", value });
                  }}
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">{typName} 이메일</label>
                <input
                  type="text"
                  name="emlId"
                  value={info?.emlId}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    handleChange({ name: "emlId", value });
                  }}
                />
                {!regEmail.test(info.emlId) && !!info.emlId && (
                  <span
                    className={"err"}
                    //regEmail.test(info.emlId) || !info.emlId ? "opacity-0" :
                  >
                    이메일 정규식을 확인하세요.
                  </span>
                )}
              </li>
            </ul>

            {pathname.includes("admin") && (
              <ul>
                <li>
                  <label htmlFor="">관리자권한 선택</label>
                  <Select
                    id={"authGrpCod"}
                    name={"authGrpCod"}
                    option={info.authGrpCod}
                    options={authOptions}
                    onSelect={handleSelect}
                  />
                </li>
              </ul>
            )}
            <ul className="">
              <li className="pb-2">
                <label htmlFor="phone">{typName} 전화번호</label>
                <div className="flex gap-x-1 ">
                  <PhoneSelect
                    isNm
                    mobCd={info.mobCntyCd}
                    handleSelect={handleSelect}
                  />
                  <input
                    type="text"
                    name="telNo"
                    value={info.telNo}
                    maxLength={11}
                    onChange={(e) => {
                      const value = regNum.test(e.currentTarget.value)
                        ? e.currentTarget.value
                        : "";

                      handleChange({ name: "telNo", value });
                    }}
                  />
                </div>
              </li>
            </ul>
          </div>
          <div className="flex justify-center pt-5">
            <button
              className="btn-md"
              disabled={!isAble}
              onClick={handleSignup}
            >
              등록
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step1View;
