import { useMutation, useQueryClient } from "@tanstack/react-query";
import { lodAdModifyApi } from "components/api/lod";
import Modal from "components/modal/modal";
import Select from "components/select";
import InTuiEditor from "components/tuiEditor/inEditot";
import { langProps } from "interface";
import { IlodAdInf } from "interface/lod";
import i18n from "locales/i18n";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";

export type IProps = {
  adList: IlodAdInf[];
  langCd: string;
  langOp: langProps[];
  isOpen: string;
  lodNo: string;
  setLangCd: Dispatch<SetStateAction<string>>;
  setIsOpen: Dispatch<SetStateAction<string>>;
};

export const ModifyModal = ({
  adList,
  langOp,
  isOpen,
  lodNo,
  setIsOpen,
  setLangCd,
}: IProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [adInf, setAdInf] = useState<IlodAdInf>({
    langCd: langOp[0]?.value || "",
    lodNm: "",
    lodDesc: "",
    lodZipc: "",
    lodAddr1: "",
    lodAddr2: "",
    lodAddr3: "",
    lodAddr4: "",
    lodAddr: "",
    lodDtlAddr: "",
  });

  const isAbleRef = useRef(false);

  const handleReset = () => {
    setAdInf({
      langCd: langOp[0]?.value || "",
      lodNm: "",
      lodDesc: "",
      lodZipc: "",
      lodAddr1: "",
      lodAddr2: "",
      lodAddr3: "",
      lodAddr4: "",
      lodAddr: "",
      lodDtlAddr: "",
    });
  };

  const handleChange = (name: string, value: string) => {
    setAdInf({
      ...adInf,
      [name]: value,
    });
  };

  const modifyMutation = useMutation(lodAdModifyApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "추가정보가 성정공적으로 수정되었습니다.",
              isConfirm: true,
              handleClick() {
                queryClient.invalidateQueries({
                  queryKey: ["lodAdList", lodNo],
                });
                dispatch(setMessage({ message: null }));
                setIsOpen("");
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModify = () => {
    // if (!langCd) {
    //   alert("언어를 선택하여 주세요.");
    //   return;
    // }

    const adInfo = adList.find((ad) => {
      return ad.langCd === (adInf.langCd || i18n.language);
    });
    if (adInfo) {
      const isAble = Object.keys(adInfo).some((ad) => {
        return ad === "lodDesc"
          ? isAbleRef.current
          : adInfo[ad as keyof IlodAdInf] !== adInf[ad as keyof IlodAdInf];
      });

      if (!isAble) {
        return alert("내용을 수정하여 주세요.");
      }

      const params = {
        ...adInf,
        lodNo,
        lodNm: adInf.lodNm !== adInfo.lodNm ? adInf.lodNm : "",
        lodDesc: isAbleRef.current ? adInf.lodDesc : "",
        lodAddr1: adInf.lodAddr1 !== adInfo.lodAddr1 ? adInf.lodAddr1 : "",
        lodAddr2: adInf.lodAddr2 !== adInfo.lodAddr2 ? adInf.lodAddr2 : "",
        lodAddr3: adInf.lodAddr3 !== adInfo.lodAddr3 ? adInf.lodAddr3 : "",
        lodAddr4: adInf.lodAddr4 !== adInfo.lodAddr4 ? adInf.lodAddr4 : "",
        lodZipc: adInf.lodZipc !== adInfo.lodZipc ? adInf.lodZipc : "",
      };

      console.log("params", params);

      dispatch(
        setMessage({
          message: "추가정보를 수정하시겠습니까?",
          isCancel: true,
          isConfirm: true,
          handleClick() {
            dispatch(setMessage({ message: null }));
            modifyMutation.mutate(params);
          },
        })
      );
    }
  };

  useEffect(() => {
    const adInfo = adList.find((ad: IlodAdInf) => {
      return ad.langCd === (adInf.langCd || i18n.language);
    });
    if (adInfo) {
      setAdInf(adInfo);
    }

    return () => {
      handleReset();
    };
  }, [adInf.langCd]);

  return (
    <>
      <Modal
        title={"추가정보 수정"}
        styles="w-5/6"
        btnText={"수정"}
        isOpen={isOpen !== ""}
        handleCloseModal={() => {
          setIsOpen("");
          setLangCd(i18n.language);
          handleReset();
        }}
        onClick={handleModify}
      >
        <div className="tb-form mt-[3rem] " data-t-brd>
          <ul>
            <li>
              <label htmlFor="">언어선택</label>
              <Select
                id={"langCd"}
                name={"langCd"}
                option={adInf.langCd}
                options={langOp}
                isInitial
                onSelect={(target) => {
                  const { value } = target.dataset;
                  if (!!value) {
                    setAdInf({
                      ...adInf,
                      langCd: value,
                    });
                  }
                }}
              />
            </li>
          </ul>
          <ul>
            <li data-full className="">
              <label htmlFor="">숙소명</label>
              <input
                type="text"
                value={adInf.lodNm}
                onChange={(e) => handleChange("lodNm", e.currentTarget.value)}
              />
            </li>
          </ul>
          <ul className="pb-2">
            <li data-full>
              <label htmlFor="">숙소설명</label>
              <InTuiEditor
                name={"lodDesc"}
                info={{}}
                value={adInf.lodDesc}
                isAbleRef={isAbleRef}
                onChange={(value) => handleChange("lodDesc", value)}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">우편번호</label>
              <input
                type="text"
                value={adInf?.lodZipc ?? ""}
                onChange={(e) => handleChange("lodZipc", e.currentTarget.value)}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">주소1</label>
              <input
                type="text"
                value={adInf.lodAddr1}
                onChange={(e) =>
                  handleChange("lodAddr1", e.currentTarget.value)
                }
              />
            </li>
            <li>
              <label htmlFor="">주소2</label>
              <input
                type="text"
                value={adInf.lodAddr2 ?? ""}
                onChange={(e) =>
                  handleChange("lodAddr2", e.currentTarget.value)
                }
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">주소3</label>
              <input
                type="text"
                value={adInf.lodAddr3 ?? ""}
                onChange={(e) =>
                  handleChange("lodAddr3", e.currentTarget.value)
                }
              />
            </li>
            <li>
              <label htmlFor="">주소4</label>
              <input
                type="text"
                value={adInf.lodAddr4 ?? ""}
                onChange={(e) =>
                  handleChange("lodAddr4", e.currentTarget.value)
                }
              />
            </li>
          </ul>
        </div>
      </Modal>
    </>
  );
};
