import { useMutation, useQueryClient } from "@tanstack/react-query";
import { rmModifyApi } from "components/api/room";
import Modal from "components/modal/modal";
import Select from "components/select";
import InTuiEditor from "components/tuiEditor/inEditot";
import { langProps } from "interface";
import { rmAdInfoProps } from "interface/room";
import i18n from "locales/i18n";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";

export type IProps = {
  langOp: langProps[];
  isOpen: string;
  rmNo: string;
  adList: rmAdInfoProps["rmInfList"];
  setIsOpen: Dispatch<SetStateAction<string>>;
};

export const ModifyModal = ({
  // adInfo,
  adList,
  langOp,
  isOpen,
  rmNo,
  setIsOpen,
}: IProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const isAbleRef = useRef(false);
  const [adInf, setAdInf] = useState({
    langCd: langOp[0]?.value || i18n.language,
    rmNm: "",
    rmDesc: "",
  });

  const handleChange = (name: string, value: string) => {
    setAdInf({
      ...adInf,
      [name]: value,
    });
  };

  const modifyMutation = useMutation(rmModifyApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "추가정보가 성정공적으로 수정되었습니다.",
              isConfirm: true,
              handleClick() {
                queryClient.invalidateQueries({ queryKey: ["rmInf", rmNo] });
                dispatch(setMessage({ message: null }));
                setIsOpen("");
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModify = () => {
    let adInfo = adList.find((ad: any) => {
      return ad.langCd === adInf.langCd;
    });

    if (adInfo.rmNm === adInf.rmNm && isAbleRef.current) {
      return alert("추가정보를 수정하여 주세요");
    }

    const params = {
      rmNo,
      langCd: adInf.langCd,
      rmNm: adInf.rmNm,
      rmDesc: isAbleRef.current ? adInf.rmDesc : "",
    };

    dispatch(
      setMessage({
        message: "추가정보를 수정하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          dispatch(setMessage({ message: null }));
          modifyMutation.mutate(params);
        },
      })
    );
  };

  useEffect(() => {
    let adInfo = adList.find((ad: any) => {
      return ad.langCd === adInf.langCd;
    });
    setAdInf(adInfo);

    return () => {
      setAdInf({
        langCd: langOp[0]?.value || i18n.language,
        rmNm: "",
        rmDesc: "",
      });
    };
  }, [adInf.langCd]);

  return (
    <>
      <Modal
        title={"추가정보 수정"}
        styles="w-5/6"
        btnText={"수정"}
        isOpen={isOpen !== ""}
        handleCloseModal={() => {
          setIsOpen("");
          setAdInf({
            langCd: langOp[0]?.value || i18n.language,
            rmNm: "",
            rmDesc: "",
          });
        }}
        onClick={handleModify}
      >
        <div className="tb-form mt-[3rem] " data-t-brd>
          <ul>
            <li>
              <label htmlFor="">언어선택</label>
              <Select
                id={"langCd"}
                name={"langCd"}
                option={adInf.langCd}
                options={langOp}
                isInitial
                onSelect={(target) => {
                  const { value } = target.dataset;
                  setAdInf({
                    ...adInf,
                    langCd: value ?? i18n.language,
                  });
                }}
              />
            </li>
          </ul>
          <ul>
            <li data-full className="">
              <label htmlFor="">객실명</label>
              <input
                type="text"
                value={adInf.rmNm}
                onChange={(e) => handleChange("rmNm", e.currentTarget.value)}
              />
            </li>
          </ul>
          <ul className="pb-2">
            <li data-full>
              <label htmlFor="">객실설명</label>
              <InTuiEditor
                name={"rmDesc"}
                info={{}}
                value={adInf.rmDesc}
                isAbleRef={isAbleRef}
                onChange={(value) => handleChange("rmDesc", value)}
              />
            </li>
          </ul>
        </div>
      </Modal>
    </>
  );
};
