import ITHeader from "components/layout/header/subHeader/itemHeader";
import THeader from "components/layout/header/subHeader/tHeader";
import i18n from "locales/i18n";
import ReadTuiEditor from "components/tuiEditor/readEditor";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { dateFormat, inCom } from "util/common";
import { langOptions } from "util/option";
import { IrmPdAdInf, IrmPdInf } from "interface/room";
import SmallSelect from "components/select/smSelect";
import { langProps } from "interface";

type IProps = {
  memType: string;
  rmPdInf: IrmPdInf;
  rmOpNmList: IrmPdAdInf[];
};

const RmPdInfoView = ({ rmPdInf, rmOpNmList }: IProps) => {
  const { pathname } = useLocation();

  let langs = [] as langProps[];
  langOptions.forEach((lang) => {
    const isAble = rmOpNmList.some((ad) => {
      return lang.value === ad.langCd;
    });

    if (isAble) {
      langs.push(lang);
    }
  });

  const [langCd, setLangCd] = useState(i18n.language);
  const [infOpen, setInfOpen] = useState(true);
  const [adInfOpen, setAdInfOpen] = useState(true);
  const [opOpen, setOpOpen] = useState(true);
  const [pdOpen, setPdOpen] = useState(true);

  let adInf = rmOpNmList.find((ad) => {
    return ad?.langCd === langCd;
  });

  return (
    <div className="main-cont" data-dir-col>
      <THeader
        sbTtl={"객실상품 상세"}
        clsBtn
        url={pathname}
        handleReset={() => {}}
      />
      <div className="tb-form">
        <ITHeader
          sbTtl={"기본정보"}
          clsBtn
          isClose={infOpen}
          handleClose={() => setInfOpen(!infOpen)}
        />
        <div className={infOpen ? "" : "hidden"}>
          <ul>
            <li>
              <label htmlFor="">숙소그릅명</label>
              <input type="text" defaultValue={rmPdInf.lodGrpNm} readOnly />
            </li>
            <li>
              <label htmlFor="">숙소명</label>
              <input type="text" defaultValue={rmPdInf.lodNm} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">객실명</label>
              <input type="text" defaultValue={rmPdInf.rmNm} readOnly />
            </li>
            <li>
              <label htmlFor="">객실타입</label>
              <input type="text" defaultValue={rmPdInf.rmTypNm} readOnly />
            </li>
            <li>
              <label htmlFor="">객실전망</label>
              <input type="text" defaultValue={rmPdInf.rmOlkNm} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">객실상품번호</label>
              <input type="text" defaultValue={rmPdInf.rmPdNo} readOnly />
            </li>
            <li>
              <label htmlFor="">객실상품채널</label>
              <input type="text" defaultValue={""} readOnly />
            </li>
          </ul>
        </div>
      </div>
      <div className="tb-form">
        <ITHeader
          sbTtl={"추가정보"}
          clsBtn
          isClose={adInfOpen}
          handleClose={() => setAdInfOpen(!adInfOpen)}
        >
          {rmOpNmList.length > 0 && (
            <SmallSelect
              option={langCd}
              options={langs}
              handleSelect={(target) => {
                const { value } = target.dataset;
                setLangCd(value ?? i18n.language);
              }}
            />
          )}
        </ITHeader>
        <div className={adInfOpen ? "" : "hidden"}>
          <ul>
            <li data-full>
              <label htmlFor="">상품옵션명</label>
              <input type="text" defaultValue={adInf?.rmOpNm} readOnly />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">상품옵션설명</label>
              <ReadTuiEditor name={"rmOpDesc"} value={adInf?.rmOpDesc} />
            </li>
          </ul>
        </div>
      </div>
      <div className="tb-form">
        <ITHeader
          sbTtl={"옵션정보"}
          clsBtn
          isClose={opOpen}
          handleClose={() => setOpOpen(!opOpen)}
        />
        <div className={opOpen ? "" : "hidden"}>
          <ul>
            <li>
              <label htmlFor="">조식포함여부</label>
              <input
                type="text"
                defaultValue={rmPdInf.brkfInYn === "Y" ? "포함" : "미포함"}
                readOnly
              />
            </li>
            <li>
              <label htmlFor="">레이트 체크아웃시간</label>
              <input
                type="text"
                defaultValue={rmPdInf.latChkOutTi?.replace(
                  /\B(?=(\d{2})+(?!\d))/g,
                  ":"
                )}
                readOnly
              />
            </li>
            <li>
              <label htmlFor="">특가할인</label>
              <input type="text" defaultValue={""} readOnly />
            </li>
          </ul>
        </div>
      </div>
      <div className="tb-form">
        <ITHeader
          sbTtl={"상품정보"}
          clsBtn
          isClose={pdOpen}
          handleClose={() => setPdOpen(!pdOpen)}
        />
        <div className={pdOpen ? "" : "hidden"}>
          <ul>
            <li>
              <label htmlFor="">객실상품일자</label>
              <input
                type="text"
                value={dateFormat(rmPdInf.sellDt ?? "")}
                readOnly
              />
            </li>
            <li>
              <label htmlFor="">객실상품수량</label>
              <input type="text" defaultValue={rmPdInf.rmPdCt} readOnly />
            </li>
            <li>
              <label htmlFor="">객실상품판매수량</label>
              <input type="text" defaultValue={""} readOnly />
            </li>
          </ul>
          {/* <ul>
              <li>
                <label htmlFor="">객실상품요일</label>
              </li>
            </ul> */}
          <ul>
            <li>
              <label htmlFor="">객실상품금액</label>
              <input
                type="text"
                defaultValue={inCom(rmPdInf.sellPri ?? "")}
                readOnly
              />
            </li>
            <li>
              <label htmlFor="">상태</label>
              <input
                type="text"
                defaultValue={rmPdInf.sellYn === "Y" ? "판매" : "중지"}
                readOnly
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">등록일시</label>
              <input
                type="text"
                defaultValue={rmPdInf.fstCrtDtti?.split(" ")[0]}
                readOnly
              />
            </li>
            <li>
              <label htmlFor="">등록회원명</label>
              <input type="text" defaultValue={rmPdInf.fstCrtUsid} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">수정일시</label>
              <input
                type="text"
                defaultValue={rmPdInf.ltChDtti?.split(" ")[0]}
                readOnly
              />
            </li>
            <li>
              <label htmlFor="">수정회원명</label>
              <input type="text" defaultValue={rmPdInf.ltChUsid} readOnly />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RmPdInfoView;
