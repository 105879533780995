import Button from "components/button/createButtonWrap";
import Select from "components/select";
import LodChlSelect from "components/select/room/lodChlSelect";
import LodGrpSelect from "components/select/room/lodGrpNoSelect";
import LodNoSelect from "components/select/room/lodNoSelect";
import { lodChlCreateProps } from "interface/lod";
import { useNavigate } from "react-router";
import { regNum } from "util/common";
import { lodGrdCdOptions } from "util/option";

type IProps = {
  info: lodChlCreateProps;
  handleChange(name: string, value: string): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleCreate(): void;
};

const LodChlCreateView = ({
  info,
  handleChange,
  handleCreate,
  handleSelect,
}: IProps) => {
  const navigate = useNavigate();
  return (
    <div className="main-cnt">
      <div className="table-form">
        <table>
          <tbody>
            <tr>
              <th>숙소그룹명</th>
              <td>
                <LodGrpSelect
                  option={info?.lodGrpNo}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
              <th>숙소명</th>
              <td>
                <LodNoSelect
                  option={info?.lodGrpNo}
                  params={info?.lodNo}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
            </tr>
            <tr>
              <th>숙소등급</th>
              <td>
                <Select
                  id={"lodGrd"}
                  name={"lodGrd"}
                  options={lodGrdCdOptions}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
              <th>숙소채널명</th>
              <td>
                <LodChlSelect
                  option={info?.lodGrpNo}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
            </tr>
            <tr>
              <th>숙소 판매수수료율</th>
              <td>
                <input
                  type="text"
                  name="sellFeeRti"
                  value={info?.sellFeeRti}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    if (!regNum.test(value)) {
                      return;
                    }
                    if (regNum.test(value)) {
                      handleChange("sellFeeRti", e.currentTarget.value);
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>적용 시작일자</th>
              <td>
                <input
                  type="date"
                  name="aplySDt"
                  value={info?.aplySDt}
                  onChange={(e) =>
                    handleChange("aplySDt", e.currentTarget.value)
                  }
                />
              </td>
              <th>적용 종료일자</th>
              <td>
                <input
                  type="date"
                  name="aplyEDt"
                  value={info?.aplyEDt}
                  onChange={(e) =>
                    handleChange("aplySDt", e.currentTarget.value)
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Button isLoading={false} disabled={false} onClick={handleCreate} />
    </div>
  );
};

export default LodChlCreateView;
