import { useMutation, useQuery } from "@tanstack/react-query";
import { lodGrpDtlApi, lodGrpInfCreate } from "components/api/lod";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import CountrySelect from "components/select/country";
import PhoneSelect from "components/select/phone";
import { lodGrpInfProps } from "interface/lod";
import i18n from "locales/i18n";
import { MutableRefObject, useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";
import { regEmail } from "util/common";

type IProps = {
  lodGrpNo: MutableRefObject<string>;
};

const InfView = ({ lodGrpNo }: IProps) => {
  const dispatch = useDispatch();

  const [info, setInfo] = useState<lodGrpInfProps>({
    lodGrpNo: "",
    telCntyCd: "+82",
    telNo: "",
    lodGrpEml: "",
    lodGrpCntyCd: "KOR",
  });
  const [infOpen, setInfOpen] = useState(true);

  const dtlMutation = useMutation(lodGrpDtlApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          const inf = body?.lodGrpDtl;
          setInfo({
            lodGrpNo: inf?.lodGrpNo,
            telCntyCd: inf?.telCntyCd,
            telNo: inf?.telNo,
            lodGrpEml: inf?.lodGrpEml,
            lodGrpCntyCd: inf?.lodGrpCntyCd,
          });
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleChange = (name: string, value: string) => {
    setInfo({
      ...info,
      [name]: value,
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value, lng, code } = target.dataset;
    if (name === "country") {
      setInfo({
        ...info,
        lodGrpCntyCd: code ?? "",
      });
      return;
    }
    if (name && value) {
      setInfo({
        ...info,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  // 기본정보 생성
  const infMutation = useMutation(lodGrpInfCreate, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "숙소그룹이 생성되었습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                lodGrpNo.current = body?.lodGrpNo;
                dtlMutation.mutate({
                  lodGrpNo: body?.lodGrpNo ?? "",
                  langCd: i18n.language,
                });
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCreate = () => {
    if (!info.lodGrpEml) {
      return alert("이메일을 입력하여 주세요.");
    }

    if (!(info.lodGrpEml && regEmail.test(info.lodGrpEml))) {
      alert("정확한 이메일형식을 입력하여 주세요");
      return;
    }

    dispatch(
      setMessage({
        message: "숙소그룹을 생성하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          infMutation.mutate(info);
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"기본정보"}
          clsBtn
          createBtn={!dtlMutation.isSuccess}
          isClose={infOpen}
          desc="* 필수 입력"
          handleCreate={handleCreate}
          handleClose={() => {
            setInfOpen(!infOpen);
          }}
        />
        <div className={infOpen ? "" : "hidden"}>
          <ul>
            {dtlMutation.isSuccess && (
              <li>
                <label htmlFor="">숙소그룹명</label>
                <input type="text" value={info?.lodGrpNo} readOnly />
              </li>
            )}
            <li>
              <label htmlFor="">숙소그룹국가코드</label>
              <CountrySelect
                option={info?.lodGrpCntyCd}
                disabled={dtlMutation.isSuccess}
                handleSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="" data-dot>
                숙소그룹 이메일
              </label>
              <input
                type="text"
                value={info?.lodGrpEml}
                readOnly={dtlMutation.isSuccess}
                onChange={(e) =>
                  handleChange("lodGrpEml", e.currentTarget.value)
                }
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="phone">숙소그룹 전화번호</label>
              <div className="flex gap-x-1">
                <PhoneSelect
                  mobCd={info?.telCntyCd}
                  disabled={dtlMutation.isSuccess}
                  isNm
                  handleSelect={handleSelect}
                />
                <input
                  type="text"
                  value={info?.telNo}
                  readOnly={dtlMutation.isSuccess}
                  onChange={(e) => handleChange("telNo", e.currentTarget.value)}
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default InfView;
