import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import imgUploadApi from "components/api/imgUpload";
import {
  lodImgCreateApi,
  lodImgListApi,
  lodImgModifyApi,
} from "components/api/lod";
import { lodImgs } from "interface/lod";
import { ChangeEvent, useState, MouseEvent } from "react";
import { CgCloseR } from "react-icons/cg";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";
import { VscNewFile } from "react-icons/vsc";
import { DragEvent } from "react";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import { useLocation } from "react-router-dom";

type imgUrlProps = {
  name: string;
  url: string | ArrayBuffer | null;
};

type IProps = {};

const OutImgModifyView = ({}: IProps) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryClient = useQueryClient();
  const lodNo = search.replace("?lodNo=", "");
  const [outImgs, setOutImgs] = useState<lodImgs[]>([]);
  const [imgCrr, setImgCrr] = useState<imgUrlProps[]>([]);
  const [imgIdx, setImgIdx] = useState(-1);
  const [infOpen, setInfOpen] = useState(false);

  const query = useQuery({
    queryKey: ["inImgList", "I01", lodNo],
    queryFn: () => {
      return lodImgListApi({ lodNo, imgTypCd: "I01" });
    },
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });
  const imgList = query?.data?.body.lodImgList || [];

  const imgUploadMutation = useMutation(imgUploadApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setOutImgs((prev: any) => [...prev, ...body.imgList]);
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  // 이미지 업로드
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      if (outImgs?.length > 10 || outImgs?.length + files?.length > 10) {
        dispatch(setMessage({ message: "최대 10까지 등록이 가능합니다." }));
        return;
      }

      let formData = new FormData();
      formData.append("imgTypCd", "I01");

      Array.from(files).forEach((file: File) => {
        if (file.size >= 1024000) {
          dispatch(setMessage({ message: "최대 업로드 사이즈는 1MB 입니다." }));
          return;
        }

        formData.append("uploadFiles", file);
        let reader = new FileReader();

        reader.onload = () => {
          if (reader.result) {
            const img = new Image();
            img.src = reader.result as string;

            img.onload = () => {
              // let width = img.width;
              // let height = img.height;

              // if (width <= 600 || height <= 390) {
              //   dispatch(
              //     setMessage({
              //       message: "이미지 업로드 가능 사이즈 650 * 400 ",
              //     })
              //   );

              //   return;
              // }

              setImgCrr((prevList) => [
                ...prevList,
                {
                  name: file.name,
                  url: reader.result,
                },
              ]);
            };
          }
        };
        reader.readAsDataURL(file);
      });

      imgUploadMutation.mutate({
        formData,
        lodNo: lodNo ?? "",
      });

      e.currentTarget.value = "";
    }
  };

  // 이미지 등록 mutation
  const imgMutation = useMutation(lodImgCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "이미지를 등록하였습니다.",
              isConfirm: true,
              handleClick() {
                // setIsRegi(true);
                queryClient.invalidateQueries({
                  queryKey: ["inImgList", "I01", lodNo],
                });
                setImgCrr([]);
                // setOutImgs([]);
                dispatch(setMessage({ message }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  // 이미지 등록
  const handleCreate = () => {
    const params = {
      lodNo,
      lodImgList: outImgs.map((img, index) => {
        return {
          ...img,
          expsOrdV: index + 1,
        };
      }),
    };
    console.log("params", params);
    dispatch(
      setMessage({
        message: "숙소내관 이미지를 등록하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          imgMutation.mutate(params);
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  // 이미지 수정
  const handleModifyImg = (e: ChangeEvent<HTMLInputElement>) => {
    alert("숙소내관이미지 수정");
    const files = e.target.files;
    if (files) {
      if (outImgs?.length > 10 || outImgs?.length + files?.length > 10) {
        dispatch(setMessage({ message: "최대 10까지 등록이 가능합니다." }));
        return;
      }

      let formData = new FormData();
      formData.append("imgTypCd", "I01");

      const file = files[0];

      if (file.size >= 1024000) {
        dispatch(setMessage({ message: "최대 업로드 사이즈는 5MB 입니다." }));
        return;
      }

      formData.append("uploadFiles", file);
      let reader = new FileReader();

      reader.onload = () => {
        if (reader.result) {
          const img = new Image();
          img.src = reader.result as string;

          img.onload = () => {
            let width = img.width;
            let height = img.height;

            const urls = imgCrr.map((img: imgUrlProps, idx: number) => {
              return imgIdx === idx
                ? { name: file.name, url: reader.result }
                : img;
            });

            setImgCrr(urls);
          };
        }
      };
      reader.readAsDataURL(file);

      imgUploadMutation.mutate({
        formData,
        lodNo,
      });
    }

    e.currentTarget.value = "";
  };

  const imgModifyMutation = useMutation(lodImgModifyApi, {
    onSuccess(data, variables, context) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          queryClient.invalidateQueries({
            queryKey: ["inImgList", "I01", lodNo],
          });
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  // 이미지 삭제
  const handleImgRemove = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    const { idx, expsordv, rgseq, name } = e.currentTarget.dataset;

    dispatch(
      setMessage({
        message: "해당 이미지를 삭제하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          if (!!expsordv) {
            imgModifyMutation.mutate({
              lodNo,
              expsOrdV: Number(expsordv),
              rgSeq: Number(rgseq),
              uyn: "N",
            });
          }
          if (!expsordv && idx) {
            setImgCrr(
              imgCrr.filter((img) => {
                return img.name !== name;
              })
            );
            // setOutImgs
          }
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  // 이미지 Drag
  const handleDragStart = (e: DragEvent<HTMLDivElement>, index: number) => {
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("imgIndex", String(index));
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>, index: number) => {
    e.preventDefault();

    const sourceIndex = Number(e.dataTransfer.getData("imgIndex"));
    if (sourceIndex === index) return;
    const updataImgs = [...imgList];
    const [movedImgs] = updataImgs.splice(sourceIndex, 1);

    updataImgs.splice(index, 0, movedImgs);
    const params = updataImgs.map((img, index) => {
      return {
        ...img,
        rgSeq: imgList[index].rgSeq,
        expsOrdV: imgList[index].expsOrdV + index,
      };
    });

    // imgMdyfMutation.mutate({

    // })
  };

  return (
    <>
      {" "}
      <div className="tb-form">
        <ITHeader
          sbTtl={"외관 이미지"}
          clsBtn
          createBtn={imgList.length < 10}
          createBtnTxt="등록"
          handleCreate={handleCreate}
          isClose={infOpen}
          handleClose={() => {
            setInfOpen(!infOpen);
          }}
        >
          {/* <button
        className="btn-sm bg-indigo-600 text-white-0"
        onClick={() => {}}
      >
        수정
      </button> */}
        </ITHeader>
        <div className={infOpen ? "" : "hidden"}>
          <ul>
            <li>
              <label htmlFor="">이미지</label>
              <div className="flex w-full">
                <div className={"img-wrap"}>
                  {[...imgList, ...imgCrr].map((img, index) => {
                    return (
                      <div
                        className="img-item"
                        key={index}
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDragOver={(e) => {
                          e.preventDefault();
                          if (outImgs.length !== 0) {
                            return alert("이미지 등록후 순서변경이 가능합니다");
                          }
                        }}
                        onDrop={(e) => handleDrop(e, index)}
                        draggable
                      >
                        <img
                          src={img?.url || img?.resImgPhUrl}
                          alt="외관이미지"
                        />
                        <div className={"img-btn-wrap"}>
                          <button
                            data-txt="삭제"
                            data-idx={index}
                            data-name={img.name}
                            data-rgseq={img.rgSeq}
                            data-expsordv={img.expsOrdV}
                            data-imgtypecd={img.imgTypCd}
                            onClick={handleImgRemove}
                          >
                            <CgCloseR
                              size={18}
                              style={{
                                padding: 1,
                                color: "red",
                                cursor: "pointer",
                                backgroundColor: "#fff",
                              }}
                            />
                          </button>
                          {/* <div className="relative" data-idx={index}>
                    <BsPencilSquare
                      size={18}
                      style={{
                        padding: 1,
                        color: "#39405a",
                        cursor: "pointer",
                        backgroundColor: "#fff",
                      }}
                    />
                    <input
                      type="file"
                      accept="images/*"
                      required
                      id="file-img"
                      className=""
                      data-idx={index}
                      onChange={handleModifyImg}
                    />
                    <label
                      htmlFor="file-img"
                      onMouseDown={() => {
                        setImgIdx(index);
                      }}
                    />
                  </div> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                {[...imgList, ...imgCrr].length <= 10 && (
                  <span className="img-add">
                    <input
                      type="file"
                      id="file-out"
                      multiple
                      onChange={handleChange}
                    />
                    <label htmlFor="file-out" data-text="이미지">
                      <VscNewFile size={28} className="w-full mb-2" />
                      <span className="block">
                        {[...imgList, ...imgCrr].length} / 10
                      </span>
                    </label>
                  </span>
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default OutImgModifyView;
