import { useMutation } from "@tanstack/react-query";
import { rmOpInfoApi } from "components/api/room";
import RoomOptInfoView from "components/view/room/opt/info";
import i18n from "locales/i18n";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";

const RoomOptInfoPage = () => {
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();

  const memType = pathname.includes("manager") ? "manager" : "admin";

  const rmOpInfoMutation = useMutation(rmOpInfoApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === -1) {
          dispatch(
            setMessage({
              message: message || "검색 데이터가 없습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  useEffect(() => {
    rmOpInfoMutation.mutate({
      rmNo: state.rmNo,
      opRgSeq: search?.replace("?opRgSeq=", ""),
      langCd: i18n.language,
    });
  }, [search]);

  const rmOpDtl = rmOpInfoMutation?.data?.body?.rmOpDtl || {};
  const addInfList = rmOpInfoMutation?.data?.body?.addInfList || [];

  return (
    <RoomOptInfoView
      memType={memType}
      rmOpDtl={rmOpDtl}
      addInfList={addInfList}
    />
  );
};
export default RoomOptInfoPage;
