import THeader from "components/layout/header/subHeader/tHeader";
import "./signup.scss";
import { useLocation } from "react-router-dom";

type IProps = {
  handleAuthMail(): void;
};

const Step2View = ({ handleAuthMail }: IProps) => {
  const { pathname } = useLocation();
  return (
    <>
      <div className="main-cont">
        <div className="card-form" data-dir-col>
          <THeader
            sbTtl={
              (pathname.includes("admin") ? "관리자" : "매니저") +
              "회원가입 인증메일 "
            }
            handleReset={() => {}}
          />
          <div className="text-center text-base p-2 leading-8">
            본메일은 UB {pathname.includes("admin") ? "관리자" : "매니저"}{" "}
            회원가입을 위한 안내 메일입니다.
            <br />
            <b>[이메일 인증하기]</b> 버튼을 클릭한 후 <br />
            이동된 페이지를 통하여 남은 회원가입 절차를 완료하여 주시기
            바랍니다.
          </div>
          <div className="flex justify-center pt-5">
            <button className="btn-md" onClick={handleAuthMail}>
              이메일 인증하기
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step2View;
