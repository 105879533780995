import THeader from "components/layout/header/subHeader/tHeader";
import CheckBox from "./authCheck";
import ITHeader from "components/layout/header/subHeader/itemHeader";

const AuthModifyView = () => {
  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader sbTtl={"권한수정"} handleReset={() => {}} />
        <div className="tb-form">
          <ITHeader sbTtl={"기본상세"} isClose={false} handleClose={() => {}} />
          <div>
            <ul>
              <li>
                <label htmlFor="">관리자권한</label>
                <input type="text" value={""} readOnly />
              </li>
              <li>
                <label htmlFor="">관리자번호</label>
                <input type="text" name="admNo" value={""} readOnly />
              </li>
              <li>
                <label htmlFor="">매니저번호</label>
                <input type="text" name="manNo" value={""} readOnly />
              </li>
            </ul>
          </div>
          <ITHeader
            sbTtl={"권한상세"}
            clsBtn={false}
            mdfyBtn={true}
            isClose={false}
            handleModify={() => {}}
            handleClose={() => {}}
          />
          <div>
            <ul>
              <label htmlFor="">회원</label>
              <CheckBox
                id={"mem"}
                auth={"_rd__wrt__del_dwl"}
                handleChange={() => {}}
              />
              <li>
                <label htmlFor="">회원약관</label>
                <CheckBox
                  id={"term"}
                  auth={"_rd__wrt__del_dwl"}
                  handleChange={() => {}}
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">숙소그룹</label>
                <CheckBox
                  id={""}
                  auth={"_rd__wrt__del_dwl"}
                  handleChange={() => {}}
                />
              </li>
              <li>
                <label htmlFor="">숙소</label>
                <CheckBox
                  id={"lod"}
                  auth={"_rd__wrt__del_dwl"}
                  handleChange={() => {}}
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">숙소기본정보</label>
                <CheckBox
                  id={"lodDft"}
                  auth={"_rd__wrt__del_dwl"}
                  handleChange={() => {}}
                />
              </li>
              <li>
                <label htmlFor="">숙소제공정보</label>
                <CheckBox
                  id={"lodPrd"}
                  auth={"_rd__wrt__del_dwl"}
                  handleChange={() => {}}
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">숙소환불수수료</label>
                <CheckBox
                  id={"lodRfd"}
                  auth={"_rd__wrt__del_dwl"}
                  handleChange={() => {}}
                />
              </li>
              <li>
                <label htmlFor="">숙소채널</label>
                <CheckBox
                  id={"lodChl"}
                  auth={"_rd__wrt__del_dwl"}
                  handleChange={() => {}}
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">객실</label>
                <CheckBox
                  id={"rm"}
                  auth={"_rd__wrt__del_dwl"}
                  handleChange={() => {}}
                  readonly
                />
              </li>
              <li>
                <label htmlFor="">객실옵션</label>
                <CheckBox
                  id={"rmOp"}
                  auth={"_rd__wrt__del_dwl"}
                  handleChange={() => {}}
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">프로모션</label>
                <CheckBox
                  id={"prom"}
                  auth={"_rd__wrt__del_dwl"}
                  handleChange={() => {}}
                />
              </li>
              <li>
                <label htmlFor="">정산관리</label>
                <CheckBox
                  id={"calc"}
                  auth={"_rd__wrt__del_dwl"}
                  handleChange={() => {}}
                />
              </li>
            </ul>
            <ul>
              <li></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthModifyView;
