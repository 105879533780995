import { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsLoading, setIsSearch } from "store/commonSlice";
import { FaChevronDown } from "react-icons/fa6";

type IProsp = {
  createBtn?: boolean;
  createBtnTxt?: string;
  searchBtn?: boolean;
  clsBtn?: boolean;
  addBtn?: boolean;
  mdfyBtn?: boolean;
  isClose?: boolean;
  sbTtl: string;
  addBtnTxt?: string;
  children?: ReactNode;
  url?: string;
  desc?: string;
  handleModify?(): void;
  handleCreate?(): void;
  handleAdCreate?(): void;
  handleClose(): void;
};

const ITHeader = ({
  createBtn,
  createBtnTxt = "생성",
  addBtnTxt = "이미지추가",
  searchBtn,
  clsBtn,
  mdfyBtn,
  addBtn,
  isClose,
  sbTtl,
  url,
  desc,
  handleCreate,
  handleModify,
  handleAdCreate,
  handleClose,
  children,
}: IProsp) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSearch = () => {
    dispatch(setIsSearch(true));
    dispatch(setIsLoading(true));
    navigate(`?page=1`);
  };
  return (
    <>
      <div className="sb-ttl">
        <div className="flex gap-2 items-end">
          <h3>{sbTtl}</h3>
          {!!desc && <span>{desc}</span>}
        </div>

        <div className="flex gap-1">
          {children}
          {searchBtn && (
            <button
              className="btn-sm bg-indigo-600 text-white-0"
              onClick={handleSearch}
            >
              조회
            </button>
          )}
          {addBtn && (
            <button
              className="btn-sm bg-indigo-600 text-white-0"
              onClick={handleAdCreate}
            >
              {addBtnTxt}
            </button>
          )}
          {mdfyBtn && (
            <button
              className="btn-sm bg-red-400 text-white-0"
              onClick={handleModify}
            >
              수정
            </button>
          )}
          {createBtn && (
            <button
              className="btn-sm bg-red-400 text-white-0"
              onClick={handleCreate}
            >
              {createBtnTxt}
            </button>
          )}
          {clsBtn && (
            <button className="h-[1.3125rem] bg-gray-200" onClick={handleClose}>
              <FaChevronDown data-close={isClose} />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ITHeader;
