import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { signupAdminInfoApi, signupManageInfoApi } from "components/api/member";
import { signupInfoProps } from "interface/member";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { regEmail } from "util/common";
import Step1View from "components/view/signup/step1";

const Step1Page = () => {
  const dispatch = useDispatch();
  const navigete = useNavigate();
  const { pathname } = useLocation();

  const type = pathname.includes("manager") ? "manager" : "admin";

  const [info, setInfo] = useState<signupInfoProps>({
    emlId: "",
    fstnm: "",
    surnm: "",
    mobCntyCd: "+82",
    telNo: "",
    authGrpCod: "",
    lodGrpNo: "",
  });

  const handleChange = (target: { name: string; value: string }) => {
    const { name, value } = target;

    setInfo({
      ...info,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value, lng, type } = target.dataset;

    if (name === "authGrpCod") {
      setInfo({
        ...info,
        authGrpCod: value,
      });
    }
    if (name === "lodGrpNo") {
      setInfo({
        ...info,
        lodGrpNo: value === "A" ? "" : value,
      });
    }

    if (name === "mobCntyCd") {
      setInfo({
        ...info,
        mobCntyCd: value ?? "+82",
      });
    }
  };

  const adminMutation = useMutation(signupAdminInfoApi, {
    onSuccess: (data, context) => {
      if (data) {
        const { code, message } = data.header;
        if (code === 0) {
          dispatch(
            setMessage({
              message: `가입승인 요청을 완료하시려면 / 24시간 이내에 ${info.emlId}로 / 전송된 메일을 통해 인증해 주시기바랍니다.`,
            })
          );
          navigete("", { state: info.emlId, replace: true });
          setInfo({
            emlId: "",
            fstnm: "",
            surnm: "",
            mobCntyCd: "+82",
            telNo: "",
            authGrpCod: "",
            lodGrpNo: "",
          });
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
    onError: (error, variables, context) => {
      console.error("error", error, variables, context);
    },
  });

  const manageMutation = useMutation(signupManageInfoApi, {
    onSuccess(data) {
      if (data) {
        const { code, message } = data.header;
        if (code === 0) {
          dispatch(
            setMessage({
              message: `가입승인 요청을 완료하시려면 / 24시간 이내에 ${info.emlId}로 / 전송된 메일을 통해 인증해 주시기바랍니다.`,
            })
          );
          navigete("", { state: info.emlId, replace: true });
          setInfo({
            emlId: "",
            fstnm: "",
            surnm: "",
            mobCntyCd: "+82",
            telNo: "",
            authGrpCod: "",
            lodGrpNo: "",
          });
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });
  const handleSignup = () => {
    const { emlId, fstnm, surnm, mobCntyCd, telNo, authGrpCod, lodGrpNo } =
      info;
    if (!regEmail.test(emlId)) {
      return alert("정확한 이메일형식을 입력하여 주세요.");
    }

    if (pathname.includes("admin")) {
      const infoParam = {
        admFstnm: fstnm,
        admSurnm: surnm,
        admEmlId: emlId,
        mobNo: telNo,
        authGrpCod,
        mobCntyCd,
      };
      dispatch(
        setMessage({
          message: "관리자를 등록하시겠습니까?",
          isCancel: true,
          isConfirm: true,
          handleClick() {
            adminMutation.mutate(infoParam);
            dispatch(setMessage({ message: null }));
          },
        })
      );
      return;
    }

    if (pathname.includes("manager")) {
      const infoParam = {
        lodGrpNo: lodGrpNo ?? "",
        manFstnm: fstnm,
        manSurnm: surnm,
        manEmlId: emlId,
        mobCntyCd,
        mobNo: telNo,
      };

      dispatch(
        setMessage({
          message: "매니저를 등록하시겠습니까?",
          isCancel: true,
          isConfirm: true,
          handleClick() {
            manageMutation.mutate(infoParam);
            dispatch(setMessage({ message: null }));
          },
        })
      );

      return;
    }
  };

  return (
    <>
      <Step1View
        info={info}
        type={type}
        handleChange={handleChange}
        handleSelect={handleSelect}
        handleSignup={handleSignup}
      />
    </>
  );
};

export default Step1Page;
