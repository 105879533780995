import { useMutation } from "@tanstack/react-query";
import imgUploadApi from "components/api/imgUpload";
import { lodImgs } from "interface/lod";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useState,
  DragEvent,
  MutableRefObject,
  useEffect,
} from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";
import { VscNewFile } from "react-icons/vsc";
import { IoCloseCircleOutline } from "react-icons/io5";

type imgUrlProps = {
  name: string;
  url: string | ArrayBuffer | null;
};

type IProps = {
  outImgs: lodImgs[];
  lodNo: MutableRefObject<string>;
  setOutImgs: Dispatch<SetStateAction<lodImgs[]>>;
  isRegi: boolean;
  isAble: boolean;
};

const OutImgView = ({ outImgs, lodNo, setOutImgs, isRegi, isAble }: IProps) => {
  const dispatch = useDispatch();

  const [imgCrr, setImgCrr] = useState<imgUrlProps[]>([]);

  const imgUploadMutation = useMutation(imgUploadApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setOutImgs((prev: any) => [...prev, ...body.imgList]);
          // setOutImgs((prev: any) => [...prev, ...body.imgList]);
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  // 이미지 업로드
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      if (outImgs?.length > 10 || outImgs?.length + files?.length > 10) {
        dispatch(setMessage({ message: "최대 10까지 등록이 가능합니다." }));
        return;
      }

      let formData = new FormData();
      formData.append("imgTypCd", "I01");

      Array.from(files).forEach((file: File) => {
        if (file.size >= 1024000) {
          dispatch(setMessage({ message: "최대 업로드 사이즈는 1MB 입니다." }));
          return;
        }

        formData.append("uploadFiles", file);
        let reader = new FileReader();

        reader.onload = () => {
          if (reader.result) {
            const img = new Image();
            img.src = reader.result as string;

            img.onload = () => {
              // let width = img.width;
              // let height = img.height;
              // if (width <= 600 || height <= 390) {
              //   dispatch(
              //     setMessage({
              //       message: "이미지 업로드 가능 사이즈 650 * 400 ",
              //     })
              //   );
              //   return;
              // }
              setImgCrr((prevList) => [
                ...prevList,
                {
                  name: file.name,
                  url: reader.result,
                },
              ]);
            };
          }
        };
        reader.readAsDataURL(file);
      });

      imgUploadMutation.mutate({
        formData,
        lodNo: lodNo.current ?? "",
      });

      e.currentTarget.value = "";
    }
  };

  // 이미지 삭제
  const handleImgRemove = (index: number) => {
    dispatch(
      setMessage({
        message: "해당 이미지를 삭제하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          setOutImgs(
            outImgs.filter((img, idx) => {
              return index !== idx;
            })
          );
          setImgCrr(
            imgCrr.filter((img, idx) => {
              return index !== idx;
            })
          );
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };
  // 이미지 Drag
  const handleDragStart = (e: DragEvent<HTMLDivElement>, index: number) => {
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("imgIndex", String(index));
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>, index: number) => {
    e.preventDefault();

    const sourceIndex = Number(e.dataTransfer.getData("imgIndex"));
    if (sourceIndex === index) return;
    const updateOutImgs = [...outImgs];
    const updateCrr = [...imgCrr];
    const [movedOutImgs] = updateOutImgs.splice(sourceIndex, 1);
    const [moveImgCrr] = updateCrr.splice(sourceIndex, 1);

    updateOutImgs.splice(index, 0, movedOutImgs);
    updateCrr.splice(index, 0, moveImgCrr);
    setOutImgs(updateOutImgs);
    setImgCrr(updateCrr);
  };

  return (
    <>
      <div className="w-full flex-1">
        <div className={"img-wrap relative"}>
          {imgCrr.map((img: any, index) => (
            <div
              className="img-item"
              key={index}
              onDragStart={(e) => handleDragStart(e, index)}
              onDragOver={(e) => {
                e.preventDefault();
                // if (outImgs.length !== 0) {
                //   return alert("이미지 등록후 순서변경이 가능합니다");
                // }
              }}
              onDrop={(e) => handleDrop(e, index)}
              draggable
            >
              <img
                src={img?.url || img?.resImgPhUrl}
                alt={`객실 이미지_${index}`}
                draggable
              />
              <div className="">
                <button
                  data-txt="삭제"
                  onClick={() => {
                    handleImgRemove(img);
                  }}
                >
                  <IoCloseCircleOutline
                    size={24}
                    style={{
                      padding: 0,
                      color: "red",
                      cursor: "pointer",
                      borderRadius: "50%",
                      backgroundColor: "#fff",
                    }}
                  />
                </button>
              </div>
            </div>
          ))}
          <span className={outImgs.length < 10 ? "" : "hidden"}>
            <input type="file" id="file-out" multiple onChange={handleChange} />
            <label htmlFor="file-out" className="">
              <VscNewFile size={28} className="w-full mb-2" />
              <span className="block">{outImgs.length} / 10</span>
            </label>
          </span>
        </div>
      </div>
    </>
  );
};

export default OutImgView;
