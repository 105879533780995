import { useMutation } from "@tanstack/react-query";
import { lodCreateApi, lodDftDtlApi } from "components/api/lod";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import Select from "components/select";
import CountrySelect from "components/select/country";
import PhoneSelect from "components/select/phone";
import LodGrpNmSelect from "components/select/room/lodGrpNmSelect ";
import { lodProps } from "interface/lod";
import { MutableRefObject, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { setIsAdAble } from "store/lodSlice";
import { regEmail, regNum } from "util/common";
import { lodGrdCdOptions, lodTypOptions, uYnOptions } from "util/option";

type IProps = {
  lodNo: MutableRefObject<string>;
};

const LodCreateView = ({ lodNo }: IProps) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const dtlMutaion = useMutation(lodDftDtlApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          const {
            lodGrpNo,
            lodGrdCd,
            lodCntyCd,
            telCntyCd,
            telNo,
            lodEml,
            hpUrl,
            faxNo,
            faxCntyCd,
            lodPosUtc,
            rmCnt,
            resvPsbYn,
            lodTypCd,
          } = body.lodDftDtl;
          setInf({
            lodGrpNo,
            lodGrpNm: "",
            lodGrdCd,
            lodCntyCd,
            lodTypCd,
            telCntyCd,
            telNo,
            lodEml,
            faxCntyCd,
            faxNo,
            hpUrl,
            lodPosUtc,
            rmCnt,
            resvPsbYn,
            resvRsvEml: "",
            bizNo: "",
            mailOrdrBizNo: "",
            ceoNm: "",
            bizNm: "",
          });
        }
        if (code === -1) {
        }
      }
    },
  });

  const [inf, setInf] = useState<lodProps>({
    lodGrpNo: "",
    lodGrpNm: "",
    lodGrdCd: "",
    lodCntyCd: "KOR",
    lodTypCd: "",
    telCntyCd: "+82",
    telNo: "",
    lodEml: "",
    faxCntyCd: "+82",
    faxNo: "",
    hpUrl: "",
    lodPosUtc: 9,
    rmCnt: "",
    resvPsbYn: "Y",
    resvRsvEml: "",
    bizNo: "",
    mailOrdrBizNo: "",
    ceoNm: "",
    bizNm: "",
  });

  const [infOpen, setInfOpen] = useState(true);

  const handleChange = (name: string, value: string) => {
    setInf({
      ...inf,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value, code } = target.dataset;
    if (name && value) {
      if (name === "country" && code) {
        setInf({
          ...inf,
          lodCntyCd: code,
        });
        return;
      }
      setInf({
        ...inf,
        [name]: value,
      });
    }
  };

  const lodMutation = useMutation(lodCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "숙소를 성공적으로 생성하였습니다.",
              isConfirm: true,
              handleClick() {
                lodNo.current = body.lodNo;
                dtlMutaion.mutate({
                  lodNo: lodNo.current ?? "",
                });
                dispatch(setIsAdAble(true));
                dispatch(setMessage({ message: null }));
                // handleReset();
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCreate = () => {
    if (!inf.lodGrpNo) {
      return alert("숙소그룹명을 선택하여 주세요.");
    }
    if (!inf.lodCntyCd) {
      return alert("숙소국가를 선택하여 주세요.");
    }
    if (!inf.lodGrdCd) {
      return alert("숙소등급을 선택하여 주세요.");
    }
    if (!(inf.telCntyCd && inf.telNo)) {
      return alert("숙소전화번호를 입력하여 주세요.");
    }
    if (!inf.lodEml) {
      return alert("숙소이메일을 입력하여 주세요.");
    }
    if (!!inf.lodEml && !regEmail.test(inf.lodEml)) {
      return alert("숙소이메일을 정확하게 입력하여 주세요.");
    }
    if (!inf.hpUrl) {
      return alert("숙소홈페이지를 입력하에 주세요.");
    }
    if (!inf.rmCnt) {
      return alert("객실수량을 입력하여 주세요.");
    }
    const params = {
      ...inf,
      rmCnt: Number(inf.rmCnt),
    };

    console.log("params", params);

    dispatch(
      setMessage({
        message: "숙소를 생성하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          lodMutation.mutate(params);
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"기본정보"}
          clsBtn
          createBtn={!dtlMutaion.isSuccess}
          isClose={infOpen}
          desc="* 필수입력"
          handleCreate={handleCreate}
          handleClose={() => {
            setInfOpen(!infOpen);
          }}
        />
        <div className={infOpen ? "" : "hidden"}>
          <ul>
            {pathname.includes("manage") && (
              <li>
                <label htmlFor="" data-dot>
                  숙소그룹명
                </label>
                <input type="text" defaultValue={""} readOnly />
              </li>
            )}
            {pathname.includes("admin") && (
              <li>
                <label htmlFor="" data-dot>
                  숙소그룹명
                </label>
                <LodGrpNmSelect
                  option={inf.lodGrpNo ?? ""}
                  disabled={dtlMutaion.isSuccess}
                  onSelect={handleSelect}
                />
              </li>
            )}
            {dtlMutaion.isSuccess && (
              <li>
                <label htmlFor="">숙소번호</label>
                <input type="text" defaultValue={lodNo.current} readOnly />
              </li>
            )}
            <li>
              <label htmlFor="" data-dot>
                숙소국가
              </label>
              <CountrySelect
                option={inf?.lodCntyCd}
                disabled={dtlMutaion.isSuccess}
                handleSelect={handleSelect}
              />
            </li>
          </ul>

          <ul>
            <li>
              <label htmlFor="" data-dot>
                숙소이메일
              </label>
              <input
                type="text"
                defaultValue={inf.lodEml}
                readOnly={dtlMutaion.isSuccess}
                // onChange={(e) => handleChange("lodEml", e.currentTarget.value)}
                onBlur={(e) => handleChange("lodEml", e.currentTarget.value)}
              />
            </li>
            <li>
              <label htmlFor="" data-dot>
                숙소홈페이지
              </label>
              <input
                type="text"
                // name="hpUrl"
                defaultValue={inf.hpUrl}
                disabled={dtlMutaion.isSuccess}
                onChange={(e) => handleChange("hpUrl", e.currentTarget.value)}
              />
            </li>
            <li>
              <label htmlFor="" data-dot>
                숙소등급
              </label>
              <Select
                id="lodGrdCd"
                name="lodGrdCd"
                option={inf.lodGrdCd || "A"}
                options={lodGrdCdOptions}
                disabled={dtlMutaion.isSuccess}
                onSelect={handleSelect}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="" data-dot>
                숙소총객실수량
              </label>
              <input
                type="text"
                // name="rmCnt"
                defaultValue={inf.rmCnt}
                readOnly={dtlMutaion.isSuccess}
                onChange={(e) => {
                  const value = e.target.value;
                  if (regNum.test(value)) {
                    handleChange("rmCnt", e.currentTarget.value);
                  } else {
                    return;
                  }
                }}
              />
            </li>
            <li>
              <label htmlFor="">숙소유형</label>
              <Select
                id={"lodTypCd"}
                name={"lodTypCd"}
                option={inf.lodTypCd}
                options={lodTypOptions}
                disabled={dtlMutaion.isSuccess}
                onSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="">숙소UTC</label>
              <input
                type="text"
                name="utc"
                defaultValue={inf.lodPosUtc}
                readOnly={dtlMutaion.isSuccess}
                onChange={(e) => handleChange("utc", e.currentTarget.value)}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="phone" data-dot>
                숙소전화번호
              </label>
              <div className="flex gap-x-1 relative">
                <PhoneSelect
                  wrapStyle=""
                  name="telCntyCd"
                  mobCd={inf.telCntyCd}
                  isNm
                  disabled={dtlMutaion.isSuccess}
                  handleSelect={handleSelect}
                />
                <input
                  type="text"
                  className=""
                  defaultValue={inf.telNo}
                  readOnly={dtlMutaion.isSuccess}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    if (!regNum.test(value)) {
                      return;
                    }
                    handleChange("telNo", e.currentTarget.value);
                  }}
                />
              </div>
            </li>
            <li>
              <label htmlFor="phone">숙소팩스번호</label>
              <div className="flex gap-x-2 relative">
                <PhoneSelect
                  wrapStyle=""
                  name="faxCntyCd"
                  isNm
                  mobCd={inf.faxCntyCd}
                  disabled={dtlMutaion.isSuccess}
                  handleSelect={handleSelect}
                />
                <input
                  type="text"
                  className=""
                  defaultValue={inf.faxNo}
                  readOnly={dtlMutaion.isSuccess}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    if (!regNum.test(value)) {
                      return;
                    }
                    handleChange("faxNo", e.currentTarget.value);
                  }}
                />
              </div>
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">예약가능</label>
              <Select
                id={"resvPsbYn"}
                name={"resvPsbYn"}
                option={inf.resvPsbYn}
                options={uYnOptions}
                isInitial
                disabled={dtlMutaion.isSuccess}
                onSelect={handleSelect}
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default LodCreateView;
