import LodgmentListView from "components/view/lod/lod/list";
import { lodSearchProps } from "interface/lod";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setIsLoading, setIsSearch } from "store/commonSlice";
import { afterDay } from "util/common";

const LodgmentListPage = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const lodGrpInfo = localStorage.getItem("lodGrpInfo")
    ? JSON.parse(localStorage.getItem("lodGrpInfo") ?? "")
    : "";

  const lodNo = localStorage.getItem("lodNo") ?? "";
  const manInf = localStorage.getItem("manInf")
    ? JSON.parse(localStorage.getItem("manInf") ?? "")
    : null;

  const { sDt, eDt } = afterDay(7);

  const [searchInfo, setsearchInfo] = useState<lodSearchProps>({
    lodGrpNo: state?.lodGrpNo || manInf?.manLodGrpNo,
    lodNo: state?.lodNo || lodNo,
    lodGrdCd: state?.lodGrdCd || "",
    lodAddr1: state?.lodAddr1 || "",
    lodAddr2: state?.lodAddr2 || "",
    sellAmDvc: state?.sellAmDvc || "",
    contSDt: state?.contSDt || sDt,
    contEDt: state?.contEDt || eDt,
    uyn: state?.uyn || "",
    searchTyp: state?.searchTyp || "",
    lodCntyCd: state?.lodCntyCd || "KOR",
  });

  const handleChange = (name: string, value: string) => {
    setsearchInfo({
      ...searchInfo,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, lng, value, code } = target.dataset;

    if (value && lng && name) {
      if (name === "country") {
        setsearchInfo({
          ...searchInfo,
          lodCntyCd: code,
        });
        return;
      }
      setsearchInfo({
        ...searchInfo,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const handleReset = () => {
    dispatch(setIsSearch(false));
    dispatch(setIsLoading(false));
    setsearchInfo({
      lodGrpNo: manInf?.lodGrpNo ?? "",
      lodNo: lodNo,
      lodGrdCd: "",
      lodAddr1: "",
      lodAddr2: "",
      sellAmDvc: "01",
      contSDt: sDt,
      contEDt: eDt,
      uyn: "",
      searchTyp: "",
    });
  };

  return (
    <LodgmentListView
      searchInfo={searchInfo}
      handleChange={handleChange}
      handleSelect={handleSelect}
      handleReset={handleReset}
    />
  );
};

export default LodgmentListPage;
