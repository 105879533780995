import { useMutation } from "@tanstack/react-query";
import { checkPwdApi } from "components/api/member";
import Modal from "components/modal/modal";
import { Dispatch, SetStateAction, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setMessage } from "store/commonSlice";

type IProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const MemModal = ({ isOpen, setIsOpen }: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const memType = pathname.includes("admin") ? "admin" : "manager";
  const manInf = localStorage.getItem("manInf")
    ? JSON.parse(localStorage.getItem("manInf") ?? "")
    : {};

  const [info, setInfo] = useState({
    emlId: manInf?.emlId ?? "",
    lgnPwd: "",
  });

  const checkpwMutation = useMutation(checkPwdApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setIsOpen(false);
          const url =
            memType +
            "/mem/info" +
            `?${memType === "admin" ? "admNo=" : "manNo="}` +
            manInf?.manNo;
          navigate(url);
          setInfo({
            emlId: "",
            lgnPwd: "",
          });
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });
  const handleCheckPw = () => {
    dispatch(
      setMessage({
        message: "회원정보를 조회 하시겠습니까?",
        isConfirm: true,
        isCancel: true,
        handleClick() {
          dispatch(setMessage({ message: null }));
          checkpwMutation.mutate(info);
        },
      })
    );
  };
  return (
    <Modal
      title="회원정보확인"
      styles="w-[28.75rem]"
      isOpen={isOpen}
      isLoading={false}
      disabled={!(info.emlId && info.lgnPwd)}
      isOutClose={false}
      handleCloseModal={() => {
        setIsOpen(false);
        setInfo({
          emlId: "",
          lgnPwd: "",
        });
      }}
      onClick={handleCheckPw}
    >
      <div className=" tb-form mt-[3rem] px-5">
        <p className="text-xs">
          개인 정보를 위하여 <br />
          비밀번호를 입력해 주세요
        </p>

        <ul className="pt-3">
          <li data-full>
            <label htmlFor="">아이디</label>
            <input
              type="text"
              value={info.emlId}
              disabled
              onChange={(e) => {
                setInfo({
                  ...info,
                  emlId: e.currentTarget.value,
                });
              }}
            />
          </li>
        </ul>
        <ul data-n-brd>
          <li data-full>
            <label htmlFor="">비빌번호</label>
            <input
              type="password"
              value={info.lgnPwd}
              onChange={(e) => {
                setInfo({
                  ...info,
                  lgnPwd: e.currentTarget.value,
                });
              }}
            />
          </li>
        </ul>
      </div>
    </Modal>
  );
};

export default MemModal;
