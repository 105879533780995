import NotFoundView from "components/view/notFound/notfound";
import LodDftInfoPage from "page/lod/default/info";
import LodDftListPage from "page/lod/default/list";
import LodGrpListPage from "page/lod/grp/list";
import LodInfoPage from "page/lod/lod/info";
import LodListPage from "page/lod/lod/list";
import LodChannelListPage from "page/lod/chl/list";
import LoginPage from "page/login";
import MemberListPage from "page/member/list";
import RoomOptionCreatePage from "page/room/opt/create";
import RoomOptionPage from "page/room/opt/list";
import RoomeCreatePage from "page/room/rm/create";
import RoomInfoPage from "page/room/rm/info";
import RoomeListPage from "page/room/rm/list";
import SignupPage from "page/signup";
import Step1Page from "page/signup/step1";
import Step2Page from "page/signup/step2";
import Step3Page from "page/signup/step3";
import FindPwdStep1Page from "page/resetpw/step1";
import FindPwdStep2Page from "page/resetpw/step2";
import FindPwdStep3Page from "page/resetpw/step3";
import Layout from "components/layout";
import RoomBookListPage from "page/room/book/list";
import RoomCurrentPage from "page/room/crr/list";
import TermCreatePage from "page/terms/create";
import TermListPage from "page/terms/list";
import TermDtlPage from "page/terms/info";
import RoomOptionList from "page/room/opt/list";
import PromPage from "page/promotion/list";
import PromCeatePage from "page/promotion/create";
import PromInfoPage from "page/promotion/info";
import RoomBookCancelListPage from "page/room/book/cancel";
import RoomOptionInfoPage from "page/room/opt/info";
import RoomBookInfoPage from "page/room/book/info";
import RoomBookCancelInfoPage from "page/room/book/cancelInfo";
import CalculatePromPage from "page/calculate/promotion/list";
import LodGrpInfoPage from "page/lod/grp/info";
import LodChlCreatePage from "page/lod/chl/create";
import LodPrdPage from "page/lod/prd/list";
import LodRfdPage from "page/lod/rfd/list/default";
import RoomModifyPage from "page/room/rm/modify";
import MemInfoModifyPage from "page/member/modify";
import BoardListPage from "page/board/list";
import RmPdListPage from "page/room/prd/list";
import RmPdCreatePage from "page/room/prd/create";
import RmPdInfoPage from "page/room/prd/info";
import RmHstListPage from "page/room/hst/list";
import RmHstInfoPage from "page/room/hst/info";
import MemInfoPage from "page/member/info";
import ManageHomePage from "page/home/manage";
import AdminHomePage from "page/home/admin";
import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectLng } from "store/commonSlice";
import { useTranslation } from "react-i18next";
import LodModifyPage from "page/lod/lod/modify";
import LodDftInfoModifyPage from "page/lod/default/modify";
import LodDftCreatePage from "page/lod/default/create";
import LodPrdInfoPage from "page/lod/prd/info";
import LodPrdModifyPage from "page/lod/prd/modify";
import TermModifyPage from "page/terms/modify";
import LodChlModifyPage from "page/lod/chl/modify";
import PromModifyPage from "page/promotion/modify";
import LodGrpModifyPage from "page/lod/grp/modify";
import LodRfdInfoPage from "page/lod/rfd/info/default";
import LodGrpCreatePage from "page/lod/grp/create";
import LodCreatePage from "page/lod/lod/lodCreate";
import LodRfdCreatePage from "page/lod/rfd/create/default";
import LodRfdModifyPage from "page/lod/rfd/modify/default";
import RmOptModifyInfoPage from "page/room/opt/modify";
import AuthListPage from "page/setting/auth";
import LodRfdOPPage from "page/lod/rfd/list/op";
import LodRfdOpCreatePage from "page/lod/rfd/create/op";
import AuthCreatePage from "page/setting/auth/create";
import AuthModifyPage from "page/setting/auth/modify";

const App: React.FC = (): JSX.Element => {
  console.log("***** App start **** ");
  const { i18n } = useTranslation();
  const loginObject = sessionStorage.getItem("login");
  const isLogin = (loginObject && JSON.parse(loginObject ?? "")?.value) || null;
  const lng = i18n.language;
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const memTyp = pathname.includes("manager")
    ? "manager"
    : "admin" ?? localStorage.getItem("memTyp");

  // 로그인이 필요한 페이지
  const loginElement = (element: JSX.Element) => {
    return !isLogin ? <Navigate to="/" /> : element;
  };

  useEffect(() => {
    if (!lng || lng === "ko") {
      localStorage.setItem("lng", "kr");
      i18n.changeLanguage("kr");
      dispatch(setSelectLng("kr"));
    }
    if (!!lng) {
      dispatch(setSelectLng(lng));
    }
  }, []);

  const homeRoute =
    memTyp === "admin" ? <Navigate to="admin" /> : <Navigate to="manager" />;

  return (
    <Routes>
      <Route path={`/`} element={<Layout />}>
        <Route path="" key="/" element={!isLogin ? <LoginPage /> : homeRoute} />

        <Route
          path={`manager`}
          key="11"
          element={loginElement(<ManageHomePage />)}
        />
        <Route
          path={`admin`}
          key="admin"
          element={loginElement(<AdminHomePage />)}
        />
        {!isLogin && (
          <>
            <Route path="signup" key="signup" element={<SignupPage />} />
            <Route
              path={`signup/${memTyp}`}
              key={`signup/${memTyp}`}
              element={<Step1Page />}
            />

            <Route
              path={`signup/${memTyp}/mail`}
              key={`signup/${memTyp}/mail`}
              element={<Step2Page />}
            />
            <Route
              path={`signup/${memTyp}/step2`}
              key={`signup/${memTyp}/step2`}
              element={<Step3Page />}
            />

            <Route
              path={`${memTyp}/resetpw/step1`}
              key={`${memTyp}/resetpw/step1`}
              element={<FindPwdStep1Page />}
            />
            <Route
              path={`${memTyp}/resetpw/step2`}
              key={`${memTyp}/resetpw/step2`}
              element={<FindPwdStep2Page />}
            />
            <Route
              path={`${memTyp}/resetpw/step3`}
              key="114"
              element={<FindPwdStep3Page />}
            />
          </>
        )}
        {!!isLogin && (
          <Route path={`${memTyp}`}>
            <Route path="memManage">
              <Route path={"mem"}>
                <Route path="" key="30" element={<MemberListPage />} />
                <Route path="info" key="31" element={<MemInfoPage />} />
                <Route path="modify" key="32" element={<MemInfoModifyPage />} />
              </Route>
            </Route>
            <Route path="trm" key="110">
              <Route path="create" key="111" element={<TermCreatePage />} />
              <Route path="" key="112" element={<TermListPage />} />
              <Route path="info" key="114" element={<TermDtlPage />} />
              <Route path="modify" key="113" element={<TermModifyPage />} />
            </Route>
            <Route path="lodManage" key="4">
              <Route path="grp" key="41">
                {/* {pathname.includes("admin") && (
                  <> */}
                <Route path="" key="411" element={<LodGrpListPage />} />
                <Route path="create" key="412" element={<LodGrpCreatePage />} />
                <Route path="modify" key="" element={<LodGrpModifyPage />} />
                {/* </>
                )} */}
                <Route path="info" key="413" element={<LodGrpInfoPage />} />
              </Route>
              <Route path="lod" key="42">
                {pathname.includes("admin") && (
                  <>
                    <Route
                      path="create"
                      key="422"
                      element={<LodCreatePage />}
                    />
                    <Route
                      path="modify"
                      key="424"
                      element={<LodModifyPage />}
                    />
                  </>
                )}
                <Route path="" key="421" element={<LodListPage />} />
                <Route path="info" key="423" element={<LodInfoPage />} />
              </Route>
              <Route path="dflt" key="43">
                {pathname.includes("admin") && (
                  <>
                    <Route
                      path="create"
                      key=""
                      element={<LodDftCreatePage />}
                    />
                    <Route
                      path="modify"
                      key="433"
                      element={<LodDftInfoModifyPage />}
                    />
                  </>
                )}
                <Route path="" key="431" element={<LodDftListPage />} />
                <Route path="info" key="432" element={<LodDftInfoPage />} />
              </Route>
              <Route path="chl" key="44">
                <Route path="" key="441" element={<LodChannelListPage />} />
                <Route path="create" key="442" element={<LodChlCreatePage />} />
                <Route path="modify" key="443" element={<LodChlModifyPage />} />
              </Route>
              <Route path="prd" key="45">
                {pathname.includes("admin") && (
                  <Route
                    path="modify"
                    key="453"
                    element={<LodPrdModifyPage />}
                  />
                )}
                <Route path="" key="451" element={<LodPrdPage />} />
                <Route path="info" key="452" element={<LodPrdInfoPage />} />
              </Route>
              <Route path="rfd" key="46">
                <Route path="" key="461" element={<LodRfdPage />} />
                <Route path="info" key="462" element={<LodRfdInfoPage />} />
                <Route path="create" key="463" element={<LodRfdCreatePage />} />
                <Route path="modify" key="464" element={<LodRfdModifyPage />} />
              </Route>
              <Route path="rfdop" key="47">
                <Route path="" key="471" element={<LodRfdOPPage />} />
                <Route
                  path="create"
                  key="472"
                  element={<LodRfdOpCreatePage />}
                />
              </Route>
            </Route>
            <Route path="rmManage" key="5">
              <Route path="rm" key="51">
                <Route path="create" key="51" element={<RoomeCreatePage />} />
                <Route path="" key="511" element={<RoomeListPage />} />
                <Route path="info" element={<RoomInfoPage />} />
                <Route path="modify" key="512" element={<RoomModifyPage />} />
              </Route>
              <Route path="rmOp" key="52">
                <Route path="" key="521" element={<RoomOptionPage />} />
                <Route
                  path="create"
                  key="522"
                  element={<RoomOptionCreatePage />}
                />
                <Route path="list" key="523" element={<RoomOptionList />} />
                <Route path="info" key="524" element={<RoomOptionInfoPage />} />
                <Route
                  path="modify"
                  key="525"
                  element={<RmOptModifyInfoPage />}
                />
              </Route>
              <Route path="rmPd" key="54">
                <Route path="" key="541" element={<RmPdListPage />} />
                <Route path="create" key="542" element={<RmPdCreatePage />} />
                <Route path="info" key="544" element={<RmPdInfoPage />} />
              </Route>
              <Route path="current" key="55" element={<RoomCurrentPage />} />
              <Route path="resv" key="56" element={<RoomBookListPage />} />
              <Route
                path="resv/info"
                key="561"
                element={<RoomBookInfoPage />}
              />
              <Route
                path="cancel"
                key="57"
                element={<RoomBookCancelListPage />}
              />
              <Route
                path="cancel/info"
                key="571"
                element={<RoomBookCancelInfoPage />}
              />
              <Route path="rmHst" key="58">
                <Route path="" key="581" element={<RmHstListPage />} />
                <Route path="info" key="" element={<RmHstInfoPage />} />
              </Route>
            </Route>
            <Route path="promManage/prm" key="60">
              <Route path="" key="61" element={<PromPage />} />
              <Route path="create" key="62" element={<PromCeatePage />} />
              <Route path="info" key="63" element={<PromInfoPage />} />
              <Route path="modify" key="64" element={<PromModifyPage />} />
            </Route>
            <Route path="clcltManage/clclt" key="70">
              <Route path="" key="71" element={<CalculatePromPage />} />
            </Route>
            <Route path="settManage">
              <Route path="auth" key="130" element={<AuthListPage />} />
              <Route
                path="auth/create"
                key="131"
                element={<AuthCreatePage />}
              />
              <Route
                path="auth/modify"
                key="132"
                element={<AuthModifyPage />}
              />
            </Route>
            <Route path="board" key="80" element={<BoardListPage />} />
            <Route path="*" element={<NotFoundView />} />
          </Route>
        )}
        <Route path="*" element={<NotFoundView />} />
      </Route>
      <Route path={`terms/:prvCd`} key="90" element={<TermDtlPage />} />
    </Routes>
  );
};

export default App;
