import RmPdListView from "components/view/room/prd/list";
import { rmPdSearchProps } from "interface/room";
import i18n from "locales/i18n";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setIsLoading, setIsSearch } from "store/commonSlice";
import { afterDay } from "util/common";

const RmPdListPage = () => {
  const { pathname, state } = useLocation();
  const dispatch = useDispatch();
  const memType = pathname.includes("admin") ? "admin" : "manager";

  let lodNo = localStorage.getItem("lodNo") ?? "";
  let manInf = localStorage.getItem("manInf")
    ? JSON.parse(localStorage.getItem("manInf") ?? "")
    : null;
  const { sDt, eDt } = afterDay(7);

  const [searchInfo, setSearchInfo] = useState<rmPdSearchProps>({
    lodGrpNo: state?.lodGrpNo || manInf?.manLodGrpNo,
    lodNo: state?.lodNo || lodNo,
    langCd: i18n.language,
    rmNo: state?.rmNo ?? "",
    lodGrdCd: state?.lodGrdCd ?? "",
    rmNm: state?.rmNm ?? "",
    rmTypCd: state?.rmTypCd ?? "",
    rmOlkCd: state?.rmOlkCd ?? "",
    maxPerCtMin: state?.maxPerCtMin ?? "",
    maxPerCtMax: state?.maxPerCtMax ?? "",
    rmPdNo: state?.rmPdNo ?? "",
    lodChlCd: state?.lodChlCd ?? "",
    rmOpNm: state?.rmOpNm ?? "",
    sellSDt: state?.sellSDt ?? sDt,
    sellEDt: state?.sellEDt ?? eDt,
    sellYn: state?.selllYn ?? "",
    searchTyp: state?.searchTyp ?? "",
  });

  const handleChange = (name: string, value: string) => {
    setSearchInfo({
      ...searchInfo,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, lng, value } = target.dataset;
    if (name && value) {
      if (name === "lodGrpNo") {
        setSearchInfo({
          ...searchInfo,
          lodNo: "",
          rmNo: "",
          rmOlkCd: "",
          rmTypCd: "",
          [name]: value === "A" ? "" : value,
        });
        return;
      }
      if (name === "lodNo") {
        setSearchInfo({
          ...searchInfo,
          rmNo: "",
          rmOlkCd: "",
          rmTypCd: "",
          [name]: value === "A" ? "" : value,
        });
        return;
      }
      if (name === "searchTyp") {
        setSearchInfo({
          ...searchInfo,
          rmNm: "",
          maxPerCtMin: "",
          maxPerCtMax: "",
          [name]: value === "A" ? "" : value,
        });
        return;
      }
      setSearchInfo({
        ...searchInfo,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const handleReset = () => {
    setSearchInfo({
      lodGrpNo: manInf?.manLodGrpNo,
      lodNo: lodNo,
      lodGrdCd: "",
      langCd: i18n.language,
      rmNo: "",
      rmNm: "",
      rmTypCd: "",
      rmOlkCd: "",
      maxPerCtMin: "",
      maxPerCtMax: "",
      rmPdNo: "",
      lodChlCd: "",
      rmOpNm: "",
      sellSDt: sDt,
      sellEDt: eDt,
      sellYn: "Y",
      searchTyp: "",
    });
    dispatch(setIsLoading(false));
    dispatch(setIsSearch(false));
  };

  return (
    <RmPdListView
      memType={memType}
      searchInfo={searchInfo}
      handleChange={handleChange}
      handleSelect={handleSelect}
      handleReset={handleReset}
    />
  );
};

export default RmPdListPage;
