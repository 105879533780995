import { useMutation, useQueryClient } from "@tanstack/react-query";
import { lodAdDftModify } from "components/api/lod";
import Modal from "components/modal/modal";
import Select from "components/select";
import InTuiEditor from "components/tuiEditor/inEditot";
import { langProps } from "interface";
import i18n from "locales/i18n";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";

type IProps = {
  langCd: string;
  langOp: langProps[];
  isOpen: string;
  lodNo: string;
  setLangCd: Dispatch<SetStateAction<string>>;
  setIsOpen: Dispatch<SetStateAction<string>>;
};

const AddModal = ({
  langCd,
  langOp,
  isOpen,
  lodNo,
  setIsOpen,
  setLangCd,
}: IProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const isNoty = useRef(false);
  const isOpr = useRef(false);
  const isCstFvr = useRef(false);
  const isAddFac = useRef(false);
  const isBrkf = useRef(false);
  const isCok = useRef(false);
  const isCstAd = useRef(false);

  const [adInf, setAdInf] = useState({
    langCd: langOp[0]?.value || "",
    lodNotiCn: "",
    lodOprCn: "",
    lodCstFvrCn: "",
    lodAddFacCn: "",
    lodBrkfCn: "",
    lodCokCn: "",
    lodCstAdCn: "",
  });

  const handleReset = () => {
    setAdInf({
      langCd: langOp[0]?.value || "",
      lodNotiCn: "",
      lodOprCn: "",
      lodCstFvrCn: "",
      lodAddFacCn: "",
      lodBrkfCn: "",
      lodCokCn: "",
      lodCstAdCn: "",
    });
  };

  const handleChange = (name: string, value: string) => {
    setAdInf({
      ...adInf,
      [name]: value,
    });
  };

  const createMutation = useMutation(lodAdDftModify, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "추가정보를 추가하였습니다.",
              isConfirm: true,
              handleClick() {
                queryClient.invalidateQueries({
                  queryKey: ["lodAdDftList", lodNo],
                });
                dispatch(setMessage({ message: null }));
                setIsOpen("");
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleAdCreate = () => {
    const isAble = !(
      isNoty.current ||
      isAddFac.current ||
      isBrkf.current ||
      isCok.current ||
      isCstAd.current ||
      isCstFvr.current ||
      isOpr.current
    );

    if (isAble) {
      return alert("추가정보를 입력하여 주세요.");
    }

    const params = {
      lodNo,
      langCd: adInf.langCd,
      lodNotiCn: isNoty.current ? adInf.lodNotiCn : "",
      lodOprCn: isOpr.current ? adInf.lodOprCn : "",
      lodCstFvrCn: isCstFvr.current ? adInf.lodCstFvrCn : "",
      lodAddFacCn: isAddFac.current ? adInf.lodAddFacCn : "",
      lodBrkfCn: isBrkf.current ? adInf.lodBrkfCn : "",
      lodCokCn: isCok.current ? adInf.lodCokCn : "",
      lodCstAdCn: isCstAd.current ? adInf.lodCstAdCn : "",
    };

    console.log("params", params);

    dispatch(
      setMessage({
        message: "추가정보를 추가하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          createMutation.mutate(params);
        },
      })
    );
  };

  return (
    <>
      <Modal
        title={"추가정보 추가"}
        styles="w-5/6"
        btnText={"추가"}
        isOpen={isOpen !== ""}
        handleCloseModal={() => {
          setIsOpen("");
          setLangCd(i18n.language);
          handleReset();
        }}
        onClick={handleAdCreate}
      >
        <div className="tb-form mt-[3rem] mb-1" data-t-brd>
          <ul>
            <li>
              <label htmlFor="">언어선택</label>
              <Select
                id={"langCd"}
                name={"langCd"}
                option={langCd}
                options={langOp}
                isInitial
                onSelect={(target) => {
                  const { value } = target.dataset;
                  if (!!value) {
                    setLangCd(value);
                    setAdInf({
                      ...adInf,
                      langCd: value,
                    });
                    handleReset();
                  }
                }}
              />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">공지사항</label>
              <InTuiEditor
                name={"lodNotiCn"}
                isAbleRef={isNoty}
                value={adInf.lodNotiCn}
                onChange={(value) => handleChange("lodNotiCn", value)}
              />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">운영벙보</label>
              <InTuiEditor
                name={"lodOprCn"}
                value={adInf.lodOprCn}
                isAbleRef={isOpr}
                onChange={(value) => handleChange("lodOprCn", value)}
              />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">인원추가정보</label>
              <InTuiEditor
                name={"lodCstAdCn"}
                value={adInf.lodCstAdCn}
                isAbleRef={isCstAd}
                onChange={(value) => handleChange("lodCstAdCn", value)}
              />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">부대시설정보</label>
              <InTuiEditor
                name={"lodAddFacCn"}
                value={adInf.lodAddFacCn}
                isAbleRef={isAddFac}
                onChange={(value) => handleChange("lodAddFacCn", value)}
              />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">조식정보</label>
              <InTuiEditor
                name={"lodBrkfCn"}
                value={adInf.lodBrkfCn}
                isAbleRef={isBrkf}
                onChange={(value) => handleChange("lodBrkfCn", value)}
              />
            </li>
          </ul>
          <ul className="pb-1">
            <li data-full>
              <label htmlFor="">취사시설정보</label>
              <InTuiEditor
                name={"lodCokCn"}
                value={adInf.lodCokCn}
                isAbleRef={isCok}
                onChange={(value) => handleChange("lodCokCn", value)}
              />
            </li>
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default AddModal;
