import "./radio.scss";

type IProps = {
  id: string;
  name: string;
  label: string;
  checked?: boolean;
  disabled?: boolean;
  lableStyle?: string;
  wrapstyle?: string;
  readonly?: boolean;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
};

const Radio = ({
  id,
  name,
  label,
  checked,
  disabled,
  lableStyle,
  wrapstyle = "",
  readonly = false,
  onChange,
}: IProps) => {
  return (
    <div className={wrapstyle + "radio-wrap"}>
      <input
        type="radio"
        id={id}
        name={name}
        checked={checked}
        // disabled={disabled}
        readOnly={readonly}
        onChange={onChange}
      />
      <label htmlFor={id} className={lableStyle}>
        {label}
      </label>
    </div>
  );
};

export default Radio;
