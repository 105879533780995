import AuthModifyView from "components/view/setting/auth/modify";

const AuthModifyPage = () => {
  return (
    <>
      <AuthModifyView />
    </>
  );
};

export default AuthModifyPage;
