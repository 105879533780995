import { lodGrpDtlApi } from "components/api/lod";
import { useLocation } from "react-router-dom";
import i18n from "locales/i18n";
import { useDispatch } from "react-redux";
import LodGrpModifyView from "components/view/lod/grp/modify";
import { useQuery } from "@tanstack/react-query";
import { setMessage } from "store/commonSlice";

const LodGrpModifyPage = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const lodGrpNo = search.replace("?lodGrpNo=", "") ?? "";

  const dtlQuery = useQuery({
    queryKey: ["lodGrpDtl", lodGrpNo],
    queryFn: () => {
      return lodGrpDtlApi({
        lodGrpNo: search.replace("?lodGrpNo=", ""),
        langCd: i18n.language,
      });
    },
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
        }

        if (code === -1) {
          dispatch(
            setMessage({
              message: message || "검색 데이터가 없습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  const lodGrpDtl = dtlQuery.data?.body?.lodGrpDtl || {};
  const lodGrpAdList = dtlQuery.data?.body?.lodGrpAdList || [];

  return (
    <LodGrpModifyView
      lodGrpDtl={lodGrpDtl}
      lodGrpAdList={lodGrpAdList}
      inf={undefined}
    />
  );
};

export default LodGrpModifyPage;
