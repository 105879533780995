import "./checkbox.scss";

type IProps = {
  id: string;
  name: string;
  label: string;
  checked: boolean;
  readOnly?: boolean;
  onChange(target: EventTarget & HTMLInputElement): void;
};

const DayCheckBox = ({
  id,
  name,
  label,
  checked,
  readOnly = false,
  onChange,
}: IProps) => {
  return (
    <>
      <div className="checkbox-wrap">
        <input
          type="checkbox"
          id={id}
          name={name}
          checked={checked}
          readOnly={readOnly}
          onChange={(e) => onChange(e.currentTarget)}
        />
        <label htmlFor={id} data-checkbox>
          {label}
        </label>
      </div>
    </>
  );
};

export default DayCheckBox;
