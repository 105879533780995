import DatePick from "components/datePick";
import THeader from "components/layout/header/subHeader/tHeader";
import Select from "components/select";
import LodGrpSelect from "components/select/room/lodGrpNoSelect";
import LodNmSelect from "components/select/room/lodNmSelect";
import RmNoSelect from "components/select/room/rmNoSlect";
import RmOlkSelect from "components/select/room/rmOlkSelect";
import RmTypeSelect from "components/select/room/rmTypSelect";
import SearchSelect from "components/select/searchSelect";
import RoomBookCancelList from "components/tableList/room/resvCancel";
import { rmResvCnlSearchProps } from "interface/room";
import { useLocation } from "react-router-dom";
import { dateTypOptions, lodGrpStatus } from "util/option";

type IProps = {
  memType: "admin" | "manager";
  searchInfo: rmResvCnlSearchProps;
  handleChange(name: string, value: string): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleReset(): void;
};

const RoomBookCancelListView = ({
  memType,
  searchInfo,
  handleChange,
  handleSelect,
  handleReset,
}: IProps) => {
  const { pathname } = useLocation();
  const searchOp = [
    {
      kr: "객실명",
      value: "rmNm",
      en: "",
    },
    {
      kr: "최대인원수조회최소값",
      value: "min",
      en: "",
    },
    {
      kr: "최소인원수조회최대값",
      value: "",
      en: "",
    },
    {
      kr: "객실프로모션번호",
      value: "",
      en: "",
    },
    {
      kr: "객실프로모션명",
      value: "",
      en: "",
    },
    {
      kr: "객실예약번호",
      value: "rmPdNo",
      en: "",
    },
    {
      kr: "예약회원이름",
      value: "",
      en: "",
    },
    {
      kr: "예약회원전화번호",
      value: "",
      en: "",
    },
    {
      kr: "예약 고객이름",
      value: "",
      en: "",
    },
  ];

  const searchOps =
    memType === "admin"
      ? [
          {
            kr: "숙소그룹명",
            value: "lodGrpNm",
            en: "",
          },
          {
            kr: "숙소명",
            value: "lodNm",
            en: "",
          },
          ...searchOp,
        ]
      : searchOp;
  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader
          sbTtl={"객실예약 취소"}
          rstBtn
          searchBtn
          url={pathname}
          handleReset={handleReset}
        />
        <div className="tb-form" data-list>
          {memType === "admin" && (
            <ul>
              <li>
                <label htmlFor="">숙소그룹번호</label>
                <LodGrpSelect
                  option={searchInfo.lodGrpNo}
                  onSelect={(target) => handleSelect(target)}
                />
              </li>
              <li>
                <label htmlFor="">숙소번호</label>
                <LodNmSelect
                  disabled={!searchInfo.lodGrpNo}
                  params={searchInfo.lodGrpNo}
                  option={searchInfo.lodNo}
                  onSelect={(target) => handleSelect(target)}
                />
              </li>
              <li>
                <label htmlFor="">숙소등급</label>
                <Select
                  id={"lodGrpCd"}
                  name={"lodGrpCd"}
                  option={searchInfo.lodGrpCd || "A"}
                  options={lodGrpStatus}
                  onSelect={(target) => handleSelect(target)}
                />
              </li>
            </ul>
          )}
          <ul>
            <li>
              <label htmlFor="">객실번호</label>
              <RmNoSelect
                disabled={!searchInfo.lodNo}
                params={searchInfo.lodNo}
                option={searchInfo.rmNo}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="">객실타입</label>
              <RmTypeSelect
                option={searchInfo.rmTypCd}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="">객실전망</label>
              <RmOlkSelect
                option={searchInfo.rmOlkCd}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">객실상품채널</label>
              <Select
                id={"lodChlSeq"}
                name={"lodChlSeq"}
                option={searchInfo.lodChlSeq + "" || "A"}
                options={lodGrpStatus}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="">검색조건</label>
              <SearchSelect
                id={"searchTyp"}
                name={searchInfo.searchTyp ?? ""}
                options={searchOps}
                option={searchInfo.searchTyp || "A"}
                value={
                  searchInfo[
                    `${searchInfo.searchTyp}` as keyof rmResvCnlSearchProps
                  ] || ""
                }
                onSelect={handleSelect}
                onChange={() => {}}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">조회기준일자</label>
              <Select
                id={"dateTyp"}
                name={"dateTyp"}
                isInitial
                option={searchInfo.dateTyp}
                options={dateTypOptions}
                onSelect={handleSelect}
              />
            </li>
            <li>
              <DatePick
                startNm={"SDt"}
                endNm={"EDt"}
                start={searchInfo.sellSDt}
                end={searchInfo.sellEDt}
                handleChange={(name, value) => handleChange(name, value)}
              />
            </li>
          </ul>
        </div>
      </div>
      <RoomBookCancelList memType={memType} searchInfo={searchInfo} />
    </>
  );
};

export default RoomBookCancelListView;
