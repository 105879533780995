import { ReactNode, RefObject } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setIsLoading, setIsSearch } from "store/commonSlice";

type IProsp = {
  first?: boolean;
  last?: boolean;
  rstBtn?: boolean;
  newBtn?: boolean;
  searchBtn?: boolean;
  clsBtn?: boolean;
  mdfyBtn?: boolean;
  isSearch?: boolean;
  sbTtl: string;
  children?: ReactNode;
  url?: string;
  isBack?: boolean;
  msg?: string;
  searchFormRef?: RefObject<HTMLDivElement>;
  handleReset(): void;
};

const THeader = ({
  first = false,
  last = false,
  rstBtn,
  newBtn,
  searchBtn,
  clsBtn,
  mdfyBtn,
  isSearch,
  sbTtl,
  url,
  msg,
  isBack = false,
  children,
  handleReset,
}: IProsp) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, state, search } = useLocation();

  const handleSearch = () => {
    if (isSearch) {
      return alert(msg);
    }
    dispatch(setIsSearch(true));
    dispatch(setIsLoading(true));
    navigate(`?page=1`, {
      state,
    });

    // const searchForm = searchFormRef?.current;

    // let searchInfo = {} as any;

    // const inputValues = searchForm?.getElementsByTagName(
    //   "input"
    // ) as ArrayLike<HTMLInputElement>;

    // Array.from(inputValues).forEach((input) => {
    //   const name = input.name;
    //   const value = input.value;

    //   searchInfo = {
    //     ...searchInfo,
    //     [name]: value,
    //   };
    // });

    // const buttonValues = searchForm?.getElementsByTagName(
    //   "button"
    // ) as ArrayLike<HTMLButtonElement>;

    // Array.from(buttonValues).forEach((btn) => {
    //   const { name, value } = btn.dataset;

    //   if (name && value) {
    //     if (name === "country") {
    //       searchInfo = {
    //         ...searchInfo,
    //         lodGrpCntyCd: value,
    //       };
    //       return;
    //     }
    //     searchInfo = {
    //       ...searchInfo,
    //       [name]: value,
    //     };
    //   }
    // });

    // navigate("?page=1", {
    //   state: searchInfo,
    // });
  };

  return (
    <>
      <div className="sb-ttl">
        <h2>{sbTtl}</h2>
        <div className="flex gap-1">
          {first && children}
          {rstBtn && (
            <button className="btn-sm bg-gray-200" onClick={handleReset}>
              초기화
            </button>
          )}
          {searchBtn && (
            <button
              className="btn-sm bg-indigo-600 text-white-0"
              onClick={handleSearch}
            >
              조회
            </button>
          )}
          {newBtn && (
            <button
              className="btn-sm bg-red-400 text-white-0"
              onClick={() =>
                navigate(url + "/create", {
                  state: {
                    url: url,
                  },
                })
              }
            >
              신규
            </button>
          )}
          {mdfyBtn && (
            <button
              className="btn-sm bg-red-400 text-white-0"
              onClick={() =>
                navigate(pathname.replace("info", "modify") + search, {
                  state: state,
                })
              }
            >
              수정
            </button>
          )}
          {clsBtn && (
            <button
              className="btn-sm bg-gray-200"
              onClick={() => {
                if (isBack) {
                  navigate(-1);
                  return;
                }
                navigate(state.url ?? "", {
                  state: state?.searchInfo,
                });
                dispatch(setIsSearch(true));
              }}
            >
              닫기
            </button>
          )}
          {last && children}
        </div>
      </div>
    </>
  );
};

export default THeader;
