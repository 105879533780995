import { promProps, searchProps } from "interface/promotion";
import api from "./api";

export const promListApi = async (params: searchProps) => {
  const res = await api({
    method: "post",
    url: "admin/prom/promList",
    data: params,
  });
  return res;
};

export const promCreateApi = async (
  params: Omit<promProps, "promCd" | "promNm">
) => {
  const res = await api({
    method: "post",
    url: "admin/prom/create",
    data: params,
  });

  return res;
};

export const promInfoApi = async (promCd: string) => {
  const res = await api({
    method: "get",
    url: `admin/prom/promDtl/${promCd}`,
  });
  return res;
};

export const promModifyApi = async (params: Omit<promProps, "promNm">) => {
  const res = await api({
    method: "post",
    url: `admin/prom/modify`,
    data: params,
  });
  return res;
};
