import { useMutation, useQueryClient } from "@tanstack/react-query";
import { lodGrpModifyApi } from "components/api/lod";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import CountrySelect from "components/select/country";
import PhoneSelect from "components/select/phone";
import { IlodGrpDtl, lodGrpInfProps } from "interface/lod";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { setLodNo } from "store/lodSlice";
import { regEmail } from "util/common";

type IProps = {
  lodGrpDtl: IlodGrpDtl;
};

const InfModifyView = ({ lodGrpDtl }: IProps) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queClient = useQueryClient();
  const lodGrpNo = search.replace("?lodGrpNo=", "") ?? "";

  const [info, setInfo] = useState<lodGrpInfProps>({
    telCntyCd: "",
    telNo: "",
    lodGrpEml: "",
    lodGrpCntyCd: "",
  });

  const [infOpen, setInfOpen] = useState(true);

  const handleChange = (name: string, value: string) => {
    setInfo({
      ...info,
      [name]: value,
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value, lng, code } = target.dataset;
    if (name === "country") {
      setInfo({
        ...info,
        lodGrpCntyCd: code ?? "",
      });
      return;
    }
    setInfo({
      ...info,
      telCntyCd: value ?? "+82",
    });
  };

  // 기본정보 수정
  const infMutation = useMutation(lodGrpModifyApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "숙소그룹기본정보가 수정되었습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                // dispatch(setIsAdAble(true));
                dispatch(setLodNo(body?.lodGrpNo));
                queClient.invalidateQueries(["lodGrpDtl", lodGrpNo]);
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModify = () => {
    if (!!info.lodGrpEml) {
      if (!regEmail.test(info.lodGrpEml)) {
        alert("정확한 이메일형식을 입력하여 주세요");
        return;
      }
    }

    dispatch(
      setMessage({
        message: "숙소그룹기본 정보를 수정 하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          infMutation.mutate({
            ...info,
            lodGrpNo,
          });
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"기본정보"}
          clsBtn
          mdfyBtn
          isClose={infOpen}
          handleModify={handleModify}
          handleClose={() => {
            setInfOpen(!infOpen);
          }}
        />
        <div className={infOpen ? "" : "hidden"}>
          <ul>
            <li>
              <label htmlFor="">숙소그룹번호</label>
              <input
                type="text"
                defaultValue={search.replace("?lodGrpNo=", "")}
                readOnly
              />
            </li>
            <li>
              <label htmlFor="">숙소그룹국가코드</label>
              <CountrySelect
                option={info.lodGrpCntyCd || lodGrpDtl?.lodGrpCntyCd}
                handleSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="phone">숙소그룹 전화번호</label>
              <div className="flex gap-x-1 ">
                <PhoneSelect
                  mobCd={info.telCntyCd || lodGrpDtl?.telCntyCd}
                  isNm
                  handleSelect={(target) => handleSelect(target)}
                />
                <input
                  type="text"
                  defaultValue={info.telNo || lodGrpDtl?.telNo}
                  onChange={(e) => handleChange("telNo", e.currentTarget.value)}
                />
              </div>
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">숙소그룹 이메일</label>
              <input
                type="text"
                value={info.lodGrpEml || lodGrpDtl?.lodGrpEml}
                onChange={(e) =>
                  handleChange("lodGrpEml", e.currentTarget.value)
                }
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default InfModifyView;
