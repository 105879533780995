import { useMutation, useQueryClient } from "@tanstack/react-query";
import { rmModifyApi } from "components/api/room";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import Select from "components/select";
import BedTypeSelect from "components/select/room/bedTypSelect";
import RmOlkSelect from "components/select/room/rmOlkSelect";
import RmTypeSelect from "components/select/room/rmTypSelect";
import { IRmInfo } from "interface/room";
import i18n from "locales/i18n";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";
import { uYnOptions } from "util/option";

type IProps = {
  rmDtl: IRmInfo;
  rmNo: string;
};

const InfModify = ({ rmDtl, rmNo }: IProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [inf, setInf] = useState({
    rmTypCd: "",
    rmOlkCd: "",
    bedTypCd: "",
    rmOlkNm: "",
    bedTypNm: "",
    rmAreaDesc: "",
    rmMaxPerCt: null,
    rmMinPerCt: null,
    babyFreePerCt: null,
    chldFreePerCt: null,
    uyn: "",
  });

  const [infOpen, setInfOpen] = useState(true);

  const handleChange = (name: string, value: string) => {
    setInf({
      ...inf,
      [name]: value,
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setInf({
        ...inf,
        [name]: value,
      });
    }
  };

  const modifyMutation = useMutation(rmModifyApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "기본정보가 성정공적으로 수정되었습니다.",
              isConfirm: true,
              handleClick() {
                queryClient.invalidateQueries({ queryKey: ["rmInf"] });
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModify = () => {
    const isAble = Object.values(inf).some((inf) => {
      return !!inf;
    });

    if (!isAble) {
      return alert("객실 기본정보를 수정하여 주세요.");
    }

    const params = {
      rmNo: rmNo ?? "",
      langCd: i18n.language,
      ...inf,
    };

    dispatch(
      setMessage({
        message: "기본정보를 수정하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          dispatch(setMessage({ message: null }));
          modifyMutation.mutate(params);
        },
      })
    );
  };

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"기본정보"}
          mdfyBtn
          clsBtn
          isClose={infOpen}
          handleModify={handleModify}
          handleClose={() => {
            setInfOpen(!infOpen);
          }}
        />

        <div className={infOpen ? "" : "hidden"}>
          <ul>
            <li>
              <label htmlFor="">숙소그룹명</label>
              <input type="text" defaultValue={rmDtl.lodGrpNm} readOnly />
            </li>
            <li>
              <label htmlFor="">숙소명</label>
              <input type="text" defaultValue={rmDtl.lodNm} readOnly />
            </li>
            <li>
              <label htmlFor="">객실타입</label>
              <RmTypeSelect
                wrapStyle="w-[12.5rem]"
                option={inf.rmTypCd || rmDtl?.rmTypCd}
                onSelect={handleSelect}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">객실전망</label>
              <RmOlkSelect
                wrapStyle="w-[12.5rem]"
                option={inf.rmOlkCd || rmDtl?.rmOlkCd}
                onSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="">객실침대종류</label>
              <BedTypeSelect
                wrapStyle="w-[12.5rem]"
                option={inf.bedTypCd || rmDtl?.bedTypCd}
                onSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="">객실크기</label>
              <input
                type="text"
                name="rmAreaDesc"
                defaultValue={inf.rmAreaDesc || rmDtl?.rmAreaDesc}
                onChange={(e) =>
                  handleChange("rmAreaDesc", e.currentTarget.value)
                }
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">객실최소인원수</label>
              <input
                type="text"
                name="rmMinPerCt"
                defaultValue={inf.rmMinPerCt || rmDtl?.minPerCt}
                onChange={(e) =>
                  handleChange("rmMinPerCt", e.currentTarget.value)
                }
              />
            </li>
            <li>
              <label htmlFor="">객실최대인원수</label>
              <input
                type="text"
                name="rmMaxPerCt"
                defaultValue={inf.rmMaxPerCt || rmDtl?.maxPerCt}
                onChange={(e) =>
                  handleChange("rmMaxPerCt", e.currentTarget.value)
                }
              />
            </li>
            <li>
              <label htmlFor="">유아무료인원수</label>
              <input
                type="text"
                name="babyFreePerCt"
                defaultValue={inf.babyFreePerCt || rmDtl?.babyFreePerCt}
                onChange={(e) =>
                  handleChange("babyFreePerCt", e.currentTarget.value)
                }
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">아동무료인원수</label>
              <input
                type="text"
                name="chldFreePerCt"
                defaultValue={inf.chldFreePerCt || rmDtl?.chldFreePerCt}
                onChange={(e) =>
                  handleChange("chldFreePerCt", e.currentTarget.value)
                }
              />
            </li>
            <li>
              <label htmlFor="">상태</label>
              <Select
                id={"uyn"}
                name={"uyn"}
                wrapStyle="w-[12.5rem]"
                option={inf.uyn || "Y"}
                options={uYnOptions}
                onSelect={handleSelect}
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default InfModify;
