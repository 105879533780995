import { useMutation, useQueryClient } from "@tanstack/react-query";
import { modifyImgApi } from "components/api/comn";
import imgUploadApi from "components/api/imgUpload";
import { rmImgUploadApi } from "components/api/room";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import { rmImgList } from "interface/room";
import { ChangeEvent, useState, DragEvent } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";
import { VscNewFile } from "react-icons/vsc";
import { IoCloseCircleOutline } from "react-icons/io5";

type imgUrlProps = {
  name: string;
  url: string | ArrayBuffer | null;
};

type IProps = {
  rmNo: string;
  imgList: rmImgList[];
};

const ImgModify = ({ rmNo, imgList }: IProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const lodGrpInf = localStorage.getItem("lodGrpInfo")
    ? JSON.parse(localStorage.getItem("lodGrpInfo") ?? "")
    : "";

  const [imgs, setImgs] = useState<any>([]);
  const [imgUrls, setImgUrls] = useState<imgUrlProps[]>([]);
  const [imgIdx, setImgIdx] = useState(-1);
  const [isOpen, setIsOpen] = useState(true);

  // 이미지 등록
  const rmImgUpMutation = useMutation(rmImgUploadApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "이미지를 등록하였습니다.",
              isConfirm: true,
              handleClick() {
                queryClient.invalidateQueries({ queryKey: ["rmInf"] });
                setImgUrls([]);
                setImgs([]);
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  // 이미지 삭제
  const imgMdyfMutation = useMutation(modifyImgApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "이미지를 삭제하였습니다.",
              isConfirm: true,
              handleClick() {
                queryClient.invalidateQueries({ queryKey: ["rmInf"] });
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  // 이미지 생성
  const imgUploadMutation = useMutation(imgUploadApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;

        if (code === 0) {
          const imgList = body?.imgList;
          setImgs([...imgs, ...imgList]);
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      if (imgUrls?.length > 10 || imgUrls?.length + files?.length > 10) {
        dispatch(setMessage({ message: "최대 10까지 등록이 가능합니다." }));
        return;
      }

      let formData = new FormData();
      formData.append("imgTypCd", "I11");

      Array.from(files).forEach((file: File) => {
        // if (file.size >= 1024000) {
        //   dispatch(setMessage({ message: "최대 업로드 사이즈는 1MB 입니다." }));
        //   return;
        // }

        formData.append("uploadFiles", file);

        let reader = new FileReader();

        reader.onload = () => {
          if (reader.result) {
            const img = new Image();
            img.src = reader.result as string;

            img.onload = () => {
              setImgUrls((prevList) => [
                ...prevList,
                {
                  name: file.name,
                  url: reader.result,
                },
              ]);
            };
          }
        };
        reader.readAsDataURL(file);
      });

      if (formData.get("uploadFiles")) {
        imgUploadMutation.mutate({
          formData,
          lodNo: lodGrpInf?.lodNo,
        });
      }

      e.currentTarget.value = "";
    }
  };

  // 이미지 삭제
  const handleImgRemove = (image: any) => {
    dispatch(
      setMessage({
        message: "이미지를 삭제하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          if (!image?.rgSeq) {
            dispatch(setMessage({ message: null }));
            return setImgUrls(
              imgUrls.filter((img) => {
                return image.name !== img.name;
              })
            );
          }

          if (image?.rgSeq) {
            imgMdyfMutation.mutate({
              rmNo: image.rmNo,
              rgSeq: image.rgSeq,
              imgTypCd: "I11",
              uyn: "N",
            });
            dispatch(setMessage({ message: null }));

            return;
          }
        },
      })
    );
  };

  const handleCreate = () => {
    if (imgUrls.length === 0) {
      return alert("이미지를 추가하여주세요.");
    }
    dispatch(
      setMessage({
        message: "이미지를 등록하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          rmImgUpMutation.mutate({
            rmNo,
            rmImgList: imgs,
          });
        },
      })
    );
  };

  // 이미지 Drag
  const handleDragStart = (e: DragEvent<HTMLDivElement>, index: number) => {
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("imgIndex", String(index));
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>, index: number) => {
    e.preventDefault();

    const sourceIndex = Number(e.dataTransfer.getData("imgIndex"));
    if (sourceIndex === index) return;
    const updataImgs = [...imgList];
    const [movedImgs] = updataImgs.splice(sourceIndex, 1);

    updataImgs.splice(index, 0, movedImgs);
    const params = updataImgs.map((img, index) => {
      return {
        ...img,
        rgSeq: imgList[index].rgSeq,
        expsOrdV: imgList[index].expsOrdV + index,
      };
    });

    // imgMdyfMutation.mutate({

    // })
  };

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"객실이미지"}
          clsBtn
          isClose={isOpen}
          createBtn
          createBtnTxt="등록"
          handleCreate={handleCreate}
          handleClose={() => {
            setIsOpen(!isOpen);
          }}
        ></ITHeader>
        <div className={isOpen ? "" : "hidden"}>
          <ul>
            <li className={imgUrls.length === 0 ? "w-full" : ""}>
              <label htmlFor="" data-end>
                이미지
              </label>
              <div className="w-full">
                <div className={"img-wrap relative"}>
                  {[...imgList, ...imgUrls].map((img: any, index) => (
                    <div
                      className="img-item"
                      key={index}
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => {
                        e.preventDefault();
                        if (imgs.length !== 0) {
                          return alert("이미지 등록후 순서변경이 가능합니다");
                        }
                      }}
                      onDrop={(e) => handleDrop(e, index)}
                      draggable
                    >
                      <img
                        src={img?.url || img?.resImgPhUrl}
                        alt={`객실 이미지_${index}`}
                        draggable
                      />
                      <div className="">
                        <button
                          data-txt="삭제"
                          onClick={() => {
                            handleImgRemove(img);
                          }}
                        >
                          <IoCloseCircleOutline
                            size={24}
                            style={{
                              padding: 0,
                              color: "red",
                              cursor: "pointer",
                              borderRadius: "50%",
                              backgroundColor: "#fff",
                            }}
                          />
                        </button>
                      </div>
                    </div>
                  ))}
                  <span
                    className={
                      [...imgList, ...imgUrls].length < 10 ? "" : "hidden"
                    }
                  >
                    <input
                      type="file"
                      id="file-in"
                      multiple
                      onChange={handleChange}
                    />
                    <label htmlFor="file-in" className="">
                      <VscNewFile size={28} className="w-full mb-2" />
                      <span className="block">
                        {[...imgList, ...imgUrls].length} / 10
                      </span>
                    </label>
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ImgModify;
