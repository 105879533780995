import ITHeader from "components/layout/header/subHeader/itemHeader";
import Modal from "components/modal/modal";
import Select from "components/select";
import SmallSelect from "components/select/smSelect";
import InTuiEditor from "components/tuiEditor/inEditot";
import ReadTuiEditor from "components/tuiEditor/readEditor";
import { langProps } from "interface";
import { useState } from "react";

const MessageCreateView = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [adOpen, setAdOpen] = useState(true);

  let isLangOp = [] as langProps[];
  let addOp = [] as langProps[];

  const handleChange = (name: string, value: string) => {};
  const handleCreate = () => {};

  return (
    <div className="tb-form">
      <ITHeader
        sbTtl={"메시지"}
        createBtn
        createBtnTxt="등록"
        handleClose={() => {}}
      >
        {isLangOp.length > 0 && (
          <SmallSelect
            option={isLangOp[0]?.value}
            options={isLangOp}
            handleSelect={(target) => {
              const { value } = target.dataset;
            }}
          />
        )}
      </ITHeader>
      <div className={adOpen ? "" : "hidden"}>
        <ul>
          <li data-full>
            <label htmlFor="">기본메시지</label>
            <ReadTuiEditor name={"refdDesc"} value={""} />
          </li>
        </ul>
      </div>
      <Modal
        title={"추가정보"}
        isOpen={isOpen}
        styles="w-[90%]"
        btnText="추가"
        handleCloseModal={() => {
          setIsOpen(false);
        }}
        onClick={handleCreate}
      >
        <div className="tb-form mt-[3rem]">
          <ul data-brd-top>
            <li>
              <label htmlFor="">언어선택</label>
              <Select
                id={"langCd"}
                name={"langCd"}
                option={""}
                options={addOp}
                isInitial
                onSelect={(target) => {
                  const { value } = target.dataset;
                  // setAdInf({
                  //   ...adInf,
                  //   langCd: value ?? i18n.language,
                  // });
                }}
              />
            </li>
          </ul>

          <ul>
            <li data-full>
              <label htmlFor="">기본메시지</label>
              <InTuiEditor
                name={"refdDesc"}
                value={""}
                onChange={(value) => handleChange("refdDesc", value)}
              />
            </li>
          </ul>
        </div>
      </Modal>
    </div>
  );
};

export default MessageCreateView;
