import LodRfdView from "components/view/lod/rfd/list/defalut";
import { lodRfdSearchInfoProps } from "interface/lod";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setIsLoading, setIsSearch } from "store/commonSlice";

const LodRfdPage = () => {
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const memType = pathname.includes("admin") ? "admin" : "manager";
  const manInf = localStorage.getItem("manInf")
    ? JSON.parse(localStorage.getItem("manInf") ?? "")
    : null;
  const lodNo = localStorage.getItem("lodNo") ?? "";
  const [searchInfo, setSearchInfo] = useState<lodRfdSearchInfoProps>({
    lodGrpNo: state?.lodGrpNo || manInf?.manLodGrpNo,
    lodNo: state?.lodNo || lodNo,
    lodGrdCd: state?.lodGrdCd || "",
    refdFeeTypCd: state?.refdFeeTypCd || "",
    uyn: state?.uyn || "A",
  });
  const handleChange = (name: string, value: string) => {
    setSearchInfo({
      ...searchInfo,
      [name]: value,
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setSearchInfo({
        ...searchInfo,
        [name]: value === "A" ? "" : value,
      });
    }
  };
  const handleReset = () => {
    dispatch(setIsLoading(false));
    dispatch(setIsSearch(false));
    setSearchInfo({
      lodGrpNo: manInf?.manLodGrpNo || "",
      lodNo: lodNo,
      lodGrdCd: "",
      refdFeeTypCd: "",
      uyn: "",
    });
  };

  return (
    <LodRfdView
      memType={memType}
      searchInfo={searchInfo}
      handleChange={handleChange}
      handleSelect={handleSelect}
      handleReset={handleReset}
    />
  );
};

export default LodRfdPage;
