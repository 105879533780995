import { ImemInf } from "interface/member";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import Modal from "components/modal/modal";
import THeader from "components/layout/header/subHeader/tHeader";
import { langOptions } from "util/option";
import i18n from "locales/i18n";
import { langProps } from "components/radio/radioEditor";

type IProps = {
  title: string;
  memInfo: ImemInf;
  handleSttPwd(): void;
  handleWthdw(): void;
};

const MemInfoView = ({ memInfo, handleSttPwd }: IProps) => {
  const { state, search } = useLocation();

  const [isOpen, setIsOpen] = useState({
    pwd: false,
    wthdw: false,
  });

  const typeNm = search?.includes("admNo")
    ? "관리자"
    : search?.includes("manNo")
    ? "매니저"
    : "회원";

  const name = memInfo.surnm + memInfo.fstnm;
  const langCd = langOptions.find((lng) => {
    return lng.value === memInfo.langCd;
  });
  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader
          sbTtl={`${typeNm}상세 조회`}
          mdfyBtn
          clsBtn
          // isBack
          url={state?.url}
          handleReset={() => {}}
        />
        <div className="tb-form">
          <ul>
            <li>
              <label htmlFor="">회원번호</label>
              <input type="text" defaultValue={memInfo.memNo} readOnly />
            </li>
            <li>
              <label htmlFor="">회원 영문이름</label>
              <input type="text" defaultValue={memInfo.fstnm} readOnly />
            </li>
            <li>
              <label htmlFor="">회원 영문성</label>
              <input type="text" defaultValue={memInfo.surnm} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">회원소셜연동</label>
              <input type="text" defaultValue={""} readOnly />
            </li>
            <li>
              <label htmlFor="">회원이메일</label>
              <input type="text" defaultValue={memInfo.emlId} readOnly />
            </li>
            <li>
              <label htmlFor="">회원사용자명</label>
              <input type="text" defaultValue={name} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">회원국가번호</label>
              <input type="text" defaultValue={memInfo.mobCntyCd} readOnly />
            </li>
            <li>
              <label htmlFor="">회원전화번호</label>
              <input type="text" defaultValue={memInfo.mobNo} readOnly />
            </li>
          </ul>
          <ul>
            {search.includes("admNo") && (
              <li>
                <label htmlFor="">회원권한</label>
                <input type="text" defaultValue={memInfo.authGrpNm} readOnly />
              </li>
            )}
            <li>
              <label htmlFor="">회원언어</label>
              <input
                type="text"
                defaultValue={
                  langCd ? langCd[i18n.language as keyof langProps] : ""
                }
                readOnly
              />
            </li>
            <li>
              <label htmlFor="">회원통화</label>
              <input type="text" defaultValue={memInfo.currCd} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">회원가입일자</label>
              <input type="text" defaultValue={memInfo.joiDtti} readOnly />
            </li>
            <li>
              <label htmlFor="">회원탈퇴일자</label>
              <input type="text" defaultValue={memInfo.lckDtti} readOnly />
            </li>
            <li>
              <label htmlFor="">회원상태</label>
              {!search.includes("userNo") && (
                <input
                  type="text"
                  value={
                    memInfo.soYn === "Y"
                      ? "탈퇴"
                      : memInfo.joiAprYn === "Y"
                      ? "정상"
                      : "신청"
                  }
                  readOnly
                />
              )}
              {search.includes("userNo") && (
                <input
                  type="text"
                  value={
                    memInfo.soYn === "Y"
                      ? "탈퇴"
                      : memInfo.joiAprYn === "Y"
                      ? "정상"
                      : ""
                  }
                  readOnly
                />
              )}
            </li>
          </ul>
        </div>
      </div>
      <Modal
        title={"비밀번호 변경"}
        btnText="변경"
        isOpen={isOpen.pwd}
        handleCloseModal={() => {
          setIsOpen({
            ...isOpen,
            pwd: false,
          });
        }}
        onClick={handleSttPwd}
      >
        <div className="py-2">
          <label className="w-full text-left pb-2" htmlFor="">
            비밀번호
          </label>
          <input type="text" className="mb-2" value={""} />
          <label className="w-full text-left pb-2" htmlFor="">
            비밀번호 확인
          </label>
          <input type="text" value="" />
        </div>
      </Modal>
    </>
  );
};

export default MemInfoView;
