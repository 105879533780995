import { useLocation } from "react-router-dom";
import AdInfModifyView from "./adInfModify";
import THeader from "components/layout/header/subHeader/tHeader";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import { useState } from "react";

type IProps = {
  dtl: {
    lodNo: string;
    lodNm: string;
    lodGrpNm: string;
    lodGrdCd: string;
  };
};

const LodDefaultModifyView = ({ dtl }: IProps) => {
  const { pathname, search, state } = useLocation();

  const [infOpen, setInfOpen] = useState(true);

  return (
    <div className="main-cont" data-dir-col>
      <THeader sbTtl={"숙소기본정보수정"} clsBtn handleReset={() => {}} />
      <div className="tb-form">
        <ITHeader
          sbTtl={"기본정보"}
          clsBtn
          isClose={infOpen}
          handleClose={() => {
            setInfOpen(!infOpen);
          }}
        />
        <div className={infOpen ? "" : "hidden"}>
          <ul>
            <li>
              <label htmlFor="">숙소그룹명</label>
              <input type="text" defaultValue={dtl?.lodGrpNm ?? ""} readOnly />
            </li>
            <li>
              <label htmlFor="">숙소명</label>
              <input type="text" defaultValue={dtl?.lodNm} readOnly />
            </li>
            <li>
              <label htmlFor="">숙소등급</label>
              <input type="text" defaultValue={dtl?.lodGrdCd ?? ""} readOnly />
            </li>
          </ul>
        </div>
        <AdInfModifyView />
      </div>
    </div>
  );
};

export default LodDefaultModifyView;
