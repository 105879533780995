import RmHstView from "components/view/room/hst/list";
import { rmHstSearhInfo } from "interface/room";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setIsLoading, setIsSearch } from "store/commonSlice";
import { afterDay } from "util/common";

const RmHstListPage = () => {
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const memType = pathname.includes("admin") ? "admin" : "manager";
  let lodNo = localStorage.getItem("lodNo") ?? "";
  let manInf = localStorage.getItem("manInf")
    ? JSON.parse(localStorage.getItem("manInf") ?? "")
    : null;

  const { sDt, eDt } = afterDay(7);

  const [searchInfo, setSearchInfo] = useState<rmHstSearhInfo>({
    lodGrpNo: state?.lodGrpNo || manInf?.manLodGrpNo,
    lodNo: state?.lodNo || lodNo,
    lodGrdCd: state?.lodGrdCd ?? "",
    lodChlCd: state?.lodChlCd ?? "",
    opRgSeq: state?.opRgSeq ?? 0,
    rmNo: state?.rmNo ?? "",
    rmOpNm: state?.rmOpNm ?? "",
    rmOlkCd: state?.rmOlkcd ?? "",
    rmPdNo: state?.rmPdNo ?? "",
    rmTypCd: state?.rmTypCd ?? "",
    sellEDt: state?.sellEDt ?? eDt,
    sellSDt: state?.sellSDt ?? sDt,
    uyn: state?.uyn ?? "",
    searchTyp: state?.searchTyp ?? "",
  });

  const handleChange = (name: string, value: string) => {
    setSearchInfo({
      ...searchInfo,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      if (name === "lodGrpNo") {
        setSearchInfo({
          ...searchInfo,
          lodNo: "",
          rmNo: "",

          [name]: value === "A" ? "" : value,
        });
        return;
      }
      if (name === "lodNo") {
        setSearchInfo({
          ...searchInfo,
          rmNo: "",
          [name]: value === "A" ? "" : value,
        });
        return;
      }
      if (name === "searchTyp") {
        setSearchInfo({
          ...searchInfo,
          [name]: value,
        });
        return;
      }
      setSearchInfo({
        ...searchInfo,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const handleReset = () => {
    setSearchInfo({
      lodGrpNo: manInf?.manLodgGrpNo ?? "",
      lodNo: lodNo,
      lodGrdCd: "",
      lodChlCd: "",
      rmNo: "",
      rmOpNm: "",
      opRgSeq: 0,
      rmOlkCd: "",
      rmPdNo: "",
      rmTypCd: "",
      sellEDt: "",
      sellSDt: "",
      uyn: "",
      searchTyp: "",
    });
    dispatch(setIsSearch(false));
    dispatch(setIsLoading(false));
  };

  return (
    <RmHstView
      memType={memType}
      searchInfo={searchInfo}
      handleChange={handleChange}
      handleSelect={handleSelect}
      handleReset={handleReset}
    />
  );
};

export default RmHstListPage;
