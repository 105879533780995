import "./radio.scss";

type IProps = {
  value: string;
  readonly?: boolean;
  handleChange(target: EventTarget & HTMLInputElement): void;
};

const UYNRadio = ({ readonly, value, handleChange }: IProps) => {
  return (
    <div className="radio-wrap">
      <div>
        <input
          type="radio"
          name="uyn"
          id="Y"
          checked={value === "Y"}
          readOnly={readonly}
          onChange={(e) => handleChange(e.target)}
        />
        <label htmlFor="Y" className="sm">
          사용
        </label>
      </div>
      <div>
        <input
          type="radio"
          name="uyn"
          id="N"
          checked={value === "N"}
          readOnly={readonly}
          onChange={(e) => handleChange(e.target)}
        />
        <label htmlFor="N" className="sm">
          미사용
        </label>
      </div>
    </div>
  );
};

export default UYNRadio;
