import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { lodInfDtlApi, lodInfModifyApi } from "components/api/lod";
import DatePick from "components/datePick";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import Modal from "components/modal/modal";
import Radio from "components/radio/radio";
import Select from "components/select";
import { lodInfProps } from "interface/lod";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { lastTimeOptions, sllPayOption, vatInYnOption } from "util/option";

type IProps = {
  infDtl: lodInfProps;
};

const InfModifyView = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ["lodInfDtl"],
    queryFn: () => {
      return lodInfDtlApi({
        lodNo: search.replace("?lodNo=", ""),
      });
    },
  });

  const infDtl = query?.data?.body?.lodInfDtl || {};

  const [inf, setInf] = useState<lodInfProps>({
    uyn: "",
    sellAmDvc: "",
    sellAmVatInYn: "",
    contSDt: "",
    contEDt: "",
    resvETi: "",
    refdFeeTypCd: "",
    babyAgeStndV: "",
    chldAgeStndV: "",
    chkInTi: "",
    chkOutTi: "",
  });

  const isRegi = useRef(false);

  const handleChange = (name: string, value: string) => {
    setInf({
      ...inf,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setInf({
        ...inf,
        [name]: value,
      });
    }
  };

  const infMutation = useMutation(lodInfModifyApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "숙소정보를 수정하였습니다.",
              isConfirm: true,
              handleClick() {
                isRegi.current = true;
                queryClient.invalidateQueries(["lodInfDtl"]);
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });
  const handleModify = () => {
    const { lodNo, ...res } = inf;
    const isAble = Object.values(res).some((able) => {
      return !!able;
    });

    if (!isAble) {
      return alert("숙소정보를 수정하여 주세요.");
    }

    const params = {
      ...inf,
      lodNo: search.replace("?lodNo=", ""),
      rgSeq: infDtl.rgSeq,
      resvETi: inf.resvETi.replaceAll(":", ""),
      chkInTi: inf.chkInTi.replaceAll(":", ""),
      chkOutTi: inf.chkOutTi.replaceAll(":", ""),
      contSDt: inf.contSDt.replaceAll("-", ""),
      contEDt: inf.contEDt.replaceAll("-", ""),
      babyAgeStndV: inf.babyAgeStndV ? Number(inf.babyAgeStndV) : undefined,
      chldAgeStndV: inf.chldAgeStndV ? Number(inf.chldAgeStndV) : undefined,
    };

    console.log("params", params);

    dispatch(
      setMessage({
        message: "숙소정보를 수정하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          infMutation.mutate(params);
        },
      })
    );
  };

  const [infOpen, setInfOpen] = useState(true);

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"숙소정보"}
          clsBtn
          mdfyBtn
          isClose={infOpen}
          handleModify={handleModify}
          handleClose={() => {
            setInfOpen(!infOpen);
          }}
        />
        <div className={infOpen ? "" : "hidden"}>
          <ul>
            <li>
              <label htmlFor="">판매금액구분</label>
              <Select
                id={"sellAmDvc"}
                name={"sellAmDvc"}
                option={inf.sellAmDvc || infDtl?.sellAmDvc}
                options={sllPayOption}
                onSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="">VAT포함여부</label>
              <Select
                id={"sellAmVatInYn"}
                name={"sellAmVatInYn"}
                option={inf.sellAmVatInYn || infDtl?.sellAmVatInYn}
                options={vatInYnOption}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
          </ul>

          <ul>
            <li>
              <label htmlFor="">판매당일예약마감시간</label>
              <Select
                id="resvETi"
                name="resvETi"
                option={
                  inf.resvETi ||
                  infDtl?.resvETi?.replace(/\B(?=(\d{2})+(?!\d))/g, ":")
                }
                options={lastTimeOptions}
                onSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="">유아나이기준</label>
              <input
                type="text"
                name="babyAgeStndV"
                defaultValue={inf.babyAgeStndV || infDtl?.babyAgeStndV}
                onChange={(e) =>
                  handleChange("babyAgeStndV", e.currentTarget.value)
                }
              />
            </li>
            <li>
              <label htmlFor="">아동나이기준</label>
              <input
                type="text"
                name="chldAgeStndV"
                defaultValue={inf.chldAgeStndV || infDtl?.chldAgeStndV}
                onChange={(e) =>
                  handleChange("chldAgeStndV", e.currentTarget.value)
                }
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">체크인시간</label>
              <Select
                id={"chkInTi"}
                name={"chkInTi"}
                option={
                  inf.chkInTi ||
                  infDtl?.chkInTi?.replace(/\B(?=(\d{2})+(?!\d))/g, ":")
                }
                options={lastTimeOptions}
                onSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="">체크아웃시간</label>
              <Select
                id={"chkOutTi"}
                name={"chkOutTi"}
                option={
                  inf.chkOutTi ||
                  infDtl?.chkOutTi?.replace(/\B(?=(\d{2})+(?!\d))/g, ":")
                }
                options={lastTimeOptions}
                onSelect={handleSelect}
              />
            </li>
            {/* <li>
              <DatePick
                startNm={""}
                endNm={""}
                start={""}
                end={""}
                handleChange={(name, value) => handleChange(name, value)}
              />
            </li> */}
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">환불수수료부과방식</label>
              <div className="w-full flex flex-col gap-y-4 ">
                <span className="text-xs mx-[13.25rem]">
                  취소 수수료율은 취소를 접수한 날짜를 기준으로 적용
                </span>
                <span className="w-full flex justify-start">
                  <Radio
                    id="01"
                    name="refdFeeTypCd"
                    label="PerNight"
                    lableStyle="w-[12.5rem]"
                    checked={
                      inf.refdFeeTypCd === "01" || infDtl?.refdFeeTypCd === "01"
                    }
                    onChange={(e) =>
                      handleChange("refdFeeTypCd", e.currentTarget.id)
                    }
                  />
                  <span className="text-xs">
                    예약 일자별 판매금액에 취소수수료 부과 <br /> 일별판매금액 x
                    일별취소수수료율 (연박 시 각 일별취소수수료 합산)
                  </span>
                </span>

                <span className="w-full flex justify-start">
                  <Radio
                    id="02"
                    name="refdFeeTypCd"
                    label="PerBooking"
                    lableStyle="w-[12.5rem]"
                    checked={
                      inf.refdFeeTypCd === "02" || infDtl?.refdFeeTypCd === "02"
                    }
                    onChange={(e) =>
                      handleChange("refdFeeTypCd", e.currentTarget.id)
                    }
                  />
                  <span className="text-xs">
                    총판매금액에 취소수수료 부과
                    <br /> 총판매금엑 x 예약1일차취소수수료율 (연박 시 동일)
                  </span>
                </span>

                <span className="w-full flex justify-start">
                  <Radio
                    id="03"
                    name="refdFeeTypCd"
                    label="PerOneNight"
                    lableStyle="w-[12.5rem]"
                    checked={
                      inf.refdFeeTypCd === "03" || infDtl?.refdFeeTypCd === "03"
                    }
                    onChange={(e) =>
                      handleChange("refdFeeTypCd", e.currentTarget.id)
                    }
                  />
                  <span className="text-xs">
                    예약1일차판매금액에 취소수수료 부과
                    <br /> 예약1일차판매금액 x 예약1일차취소수수료율 (연박 시
                    동일)
                  </span>
                </span>
                <button
                  className="w-full italic underline text-right"
                  onClick={() => {
                    //   setIsOpen(true);
                  }}
                >
                  취소 수수료 부과 방식 예시
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <Modal
        title={"취소 수수료 부과 방식 예시"}
        isOpen={false}
        handleCloseModal={() => {
          //   setIsOpen(false);
        }}
        onClick={() => {}}
      >
        <div className="w-full m-4">
          <span className="text-left font-bold">
            기본 수수료 정책만 설정했을 경우
          </span>
          <span className="text-left text-sm">취소수수료 예시</span>
        </div>
      </Modal>
    </>
  );
};

export default InfModifyView;
