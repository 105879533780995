import SingupEntryView from "components/view/signup/intro";

const SignupPage = () => {
  return (
    <>
      <SingupEntryView />
    </>
  );
};

export default SignupPage;
