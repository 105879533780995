import "../list.scss";
import { userListHead } from "util/option";
import { IuserList, searchProps } from "interface/member";
import Pagenation from "components/pagenation";
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pageNavProps } from "interface";
import { useMutation } from "@tanstack/react-query";
import {
  setIsLoading,
  setIsSearch,
  setMessage,
  isSearchSelector,
} from "store/commonSlice";

import { useLocation, useNavigate } from "react-router-dom";
import { userListApi } from "components/api/member";
import SearchHeader from "components/layout/header/subHeader/srchHeader";

type ListProps = {
  col: number;
  searchInfo: Partial<searchProps>;
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};
const List = ({ col, searchInfo, setPageNav }: ListProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search, state } = useLocation();
  const isSearch = useSelector(isSearchSelector);
  const [list, setList] = useState<IuserList[]>([]);

  const userMutation = useMutation(userListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setPageNav(body?.pageNav);
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));

          setList(body?.userList);
        }
        if (code === -1) {
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));

          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));

      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch) {
      const params = {
        mobCntyCd: searchInfo.mobCntyCd || state?.mobCntyCd,
        mobNo: searchInfo.mobNo || state?.mobNo,
        emlId: searchInfo.emlId || state?.emlId,
        pageNo: page,
      };

      userMutation.mutate(params);
    }
  }, [isSearch, searchInfo, search]);

  const pageRows = userMutation.data?.body?.pageNav?.pageRows;
  const isNotData = !pageRows;

  return (
    <>
      {list.length === 0 && (
        <tr>
          <td colSpan={col}>
            {isNotData && (
              <span className="font-bold">검색조건을 선택하여 주세요.</span>
            )}
            {!isNotData && (
              <span className="font-bold">검색결과가 없습니다.</span>
            )}
          </td>
        </tr>
      )}
      {list.map((li, index) => {
        return (
          <tr
            key={li.memNo}
            className={li.soYn === "Y" ? "drop" : ""}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`info?memNo=${li.memNo}`, {
                state: {
                  searchInfo,
                  url: pathname + search,
                  memNo: li.memNo,
                },
              });
            }}
          >
            <td>{(Number(page) - 1) * (pageRows || 10) + index + 1}</td>
            <td>{li.memNo}</td>
            {/* <td>{"회원 등급"}</td> */}
            <td>{li.memFstnm}</td>
            <td>{li.memSurnm}</td>
            <td>{li.emlId}</td>
            <td>{li.mobNo}</td>
            <td>
              {li.lckYn === "Y" ? "잠김" : li.soYn === "Y" ? "탈퇴" : "사용"}
            </td>
          </tr>
        );
      })}
    </>
  );
};

type Iprops = {
  searchInfo: Partial<searchProps>;
};

const UserList = ({ searchInfo }: Iprops) => {
  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const headList = userListHead.map((head) => {
    return head;
  });

  const col = userListHead.length;

  return (
    <div className="list-wrap">
      <SearchHeader pageNav={pageNav} />

      <div>
        <table>
          <thead>
            <tr>
              {headList.map((li) => (
                <th key={li}>{li}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            <Suspense>
              <List col={col} searchInfo={searchInfo} setPageNav={setPageNav} />
            </Suspense>
            {pageNav && pageNav?.totalRows > 0 && (
              <tr>
                <td colSpan={col}>
                  <Pagenation total={pageNav?.totalPage} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserList;
