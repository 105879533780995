import { useMutation, useQueryClient } from "@tanstack/react-query";
import { lodRefdAdModifyApi } from "components/api/lod";
import Modal from "components/modal/modal";
import Select from "components/select";
import InTuiEditor from "components/tuiEditor/inEditot";
import { langProps } from "interface";
import { IlodRfdAd } from "interface/lod";
import i18n from "locales/i18n";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";

export type IProps = {
  // adInfo: IlodRfdAd;
  langOp: langProps[];
  isOpen: string;
  lodNo: string;
  adList: IlodRfdAd[];
  setIsOpen: Dispatch<SetStateAction<string>>;
};

export const ModifyModal = ({
  // adInfo,
  langOp,
  isOpen,
  lodNo,
  adList,
  setIsOpen,
}: IProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [adInf, setAdInf] = useState({
    lodNo,
    rgSeq: 1,
    langCd: langOp[0]?.value || i18n.language,
    refdInfNm: "",
    refdDesc: "",
  });

  const isAbleRef = useRef(false);

  const handleReset = () => {
    setAdInf({
      lodNo,
      rgSeq: 1,
      langCd: langOp[0]?.value || i18n.language,
      refdInfNm: "",
      refdDesc: "",
    });
  };

  const handleChange = (name: string, value: string) => {
    setAdInf({
      ...adInf,
      [name]: value,
    });
  };

  const modifyMutation = useMutation(lodRefdAdModifyApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "추가정보가 성정공적으로 수정되었습니다.",
              isConfirm: true,
              handleClick() {
                queryClient.invalidateQueries({
                  queryKey: ["lodRdfAdList", lodNo],
                });
                dispatch(setMessage({ message: null }));
                setIsOpen("");
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModify = () => {
    const adInfo = adList.find((ad) => {
      return ad.langCd === (adInf.langCd || i18n.language);
    });

    if (adInfo?.refdInfNm === adInf.refdInfNm && !isAbleRef.current) {
      return alert("추가정보를 수정하여 주세요.");
    }

    const params = {
      lodNo,
      langCd: adInf.langCd,
      rgSeq: adInf.rgSeq || 1,
      refdInfNm: adInf.refdInfNm !== adInfo?.refdInfNm ? adInf.refdInfNm : "",
      refdDesc: isAbleRef.current ? adInf.refdDesc : "",
    };

    console.log("params", params);

    dispatch(
      setMessage({
        message: "추가정보를 수정하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          dispatch(setMessage({ message: null }));
          modifyMutation.mutate(params);
        },
      })
    );
  };

  useEffect(() => {
    const adInfo = adList.find((ad: any) => {
      return ad.langCd === (adInf.langCd || i18n.language);
    });
    if (adInfo) {
      setAdInf(adInfo);
    }
  }, [adInf.langCd]);

  useEffect(() => {
    const adInfo = adList.find((ad: any) => {
      return ad.langCd === (adInf.langCd || i18n.language);
    });
    if (adInfo) {
      setAdInf(adInfo);
    }

    return () => {
      handleReset();
    };
  }, [adInf.langCd]);

  return (
    <>
      <Modal
        title={"추가정보 수정"}
        styles="w-5/6"
        btnText={"수정"}
        isOpen={isOpen !== ""}
        handleCloseModal={() => {
          setIsOpen("");
          handleReset();
        }}
        onClick={handleModify}
      >
        <div className="tb-form mt-[3rem] " data-t-brd>
          <ul>
            <li>
              <label htmlFor="">언어선택</label>
              <Select
                id={"langCd"}
                name={"langCd"}
                option={adInf.langCd}
                options={langOp}
                isInitial
                onSelect={(target) => {
                  const { value } = target.dataset;
                  setAdInf({
                    ...adInf,
                    langCd: value ?? "",
                  });
                }}
              />
            </li>
          </ul>
          <ul>
            <li data-full className="">
              <label htmlFor="">환불명</label>
              <input
                type="text"
                value={adInf?.refdInfNm}
                onChange={(e) =>
                  handleChange("refdInfNm", e.currentTarget.value)
                }
              />
            </li>
          </ul>
          <ul className="pb-2">
            <li data-full>
              <label htmlFor="">환불설명</label>
              <InTuiEditor
                name={"refdDesc"}
                value={adInf.refdDesc}
                onChange={(value) => handleChange("refdDesc", value)}
                isAbleRef={isAbleRef}
              />
            </li>
          </ul>
        </div>
      </Modal>
    </>
  );
};
