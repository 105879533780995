import ITHeader from "components/layout/header/subHeader/itemHeader";
import THeader from "components/layout/header/subHeader/tHeader";
import ReadTuiEditor from "components/tuiEditor/readEditor";
import SmallSelect from "components/select/smSelect";
import { langProps } from "interface";
import { IlodGrpAddInf, IlodGrpDtl } from "interface/lod";
import i18n from "locales/i18n";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { langOptions } from "util/option";

type IProps = {
  lodGrpDtl: IlodGrpDtl;
  lodGrpAdList: Array<IlodGrpAddInf>;
};

const LodGroupInfoView = ({ lodGrpDtl, lodGrpAdList }: IProps) => {
  const { pathname } = useLocation();

  let langs = [] as langProps[];
  langOptions.forEach((lang) => {
    const isAble = lodGrpAdList.some((ad) => {
      return lang.value === ad.langCd;
    });

    if (isAble) {
      langs.push(lang);
    }
  });

  const [infOpen, setInfOpen] = useState(true);
  const [adOpen, setAdOpen] = useState(true);
  const [langCd, setLangCd] = useState("");

  let adInf = lodGrpAdList.find((ad) => {
    const lng = langCd || langs[0]?.value;
    return ad?.langCd === lng;
  });

  let telNo = lodGrpDtl.telCntyCd ?? "_" + lodGrpDtl.telNo ?? "-";

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader
          sbTtl={"숙소그룹상세 조회"}
          mdfyBtn={pathname.includes("admin")}
          clsBtn={pathname.includes("admin")}
          handleReset={() => {}}
        />
        <div className="tb-form">
          <ITHeader
            sbTtl={"기본정보"}
            clsBtn
            isClose={infOpen}
            handleClose={() => {
              setInfOpen(!infOpen);
            }}
          />
          <div className={infOpen ? "" : "hidden"}>
            <ul>
              <li>
                <label htmlFor="">숙소그룹번호</label>
                <input type="text" value={lodGrpDtl?.lodGrpNo ?? ""} readOnly />
              </li>
              <li>
                <label htmlFor="">숙소그룹 이메일</label>
                <input
                  type="text"
                  defaultValue={lodGrpDtl.lodGrpEml}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">숙소그룹 전화번호</label>
                <input type="text" value={telNo || ""} readOnly />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">샹태</label>
                <input
                  type="text"
                  value={lodGrpDtl.uyn === "Y" ? "사용" : "미사용"}
                  readOnly
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="tb-form">
          <ITHeader
            sbTtl={"추가정보"}
            clsBtn
            isClose={adOpen}
            desc={
              langs.length > 1
                ? `언어를 선택하여 언어별 추가정보를 확인하세요.`
                : ""
            }
            handleClose={() => {
              setAdOpen(!adOpen);
            }}
          >
            {lodGrpAdList.length !== 0 && (
              <SmallSelect
                option={langCd || langs[0]?.value || i18n.language}
                options={langs}
                handleSelect={(target) => {
                  const { value } = target.dataset;
                  setLangCd(value ?? i18n.language);
                }}
              />
            )}
          </ITHeader>
          <div className={adOpen ? "" : "hidden"}>
            <ul>
              <li data-full>
                <label htmlFor="">숙소그룹명</label>
                <input type="text" defaultValue={adInf?.lodGrpNm} readOnly />
              </li>
            </ul>
            <ul>
              <li data-full>
                <label htmlFor="">숙소그룹설명</label>
                <ReadTuiEditor name={"lodGrpDesc"} value={adInf?.lodGrpDesc} />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">우편번호</label>
                <input
                  type="text"
                  defaultValue={adInf?.lodGrpZipc ?? ""}
                  readOnly
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">주소1</label>
                <input type="text" defaultValue={adInf?.lodGrpAddr1} readOnly />
              </li>
              <li>
                <label htmlFor="">주소2</label>
                <input type="text" defaultValue={adInf?.lodGrpAddr2} readOnly />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">주소3</label>
                <input type="text" defaultValue={adInf?.lodGrpAddr3} readOnly />
              </li>
              <li>
                <label htmlFor="">주소4</label>
                <input type="text" defaultValue={adInf?.lodGrpAddr4} readOnly />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default LodGroupInfoView;
