import { useMutation } from "@tanstack/react-query";
import { lodPayCreateApi, lodPayDtlApi } from "components/api/lod";
import DatePick from "components/datePick/datePick";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import Select from "components/select";
import { lodPayProps } from "interface/lod";
import { MutableRefObject, useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";
import { bankOptions, moneyOptions } from "util/option";

type IProps = {
  lodNo: MutableRefObject<string>;
};

const BkCreateView = ({ lodNo }: IProps) => {
  const dispatch = useDispatch();

  const dtlMutation = useMutation(lodPayDtlApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          const {
            uyn,
            calcInBnkCd,
            calcAcntNo,
            calcCurrCd,
            calcInBnkNm,
            calcInDt,
          } = body?.lodPayDtl;

          setInf({
            rgSeq: 1,
            uyn,
            calcInBnkCd,
            calcAcntNo,
            calcCurrCd,
            calcInBnkNm,
            calcInDt,
            aplySDt: "",
            aplyEDt: "",
          });
        }
        if (code === -1) {
        }
      }
    },
  });

  const [inf, setInf] = useState<lodPayProps>({
    rgSeq: 1,
    uyn: "Y",
    calcInDt: "",
    calcInBnkNm: "",
    calcInBnkCd: "",
    calcAcntNo: "",
    calcCurrCd: "KRW",
    aplySDt: "",
    aplyEDt: "",
  });

  const [infOpen, setInfOpen] = useState(false);

  const handleChange = (name: string, value: string) => {
    setInf({
      ...inf,
      [name]: value,
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value, lng } = target.dataset;
    if (name && value) {
      if (name === "calcInBnkCd") {
        setInf({
          ...inf,
          calcInBnkCd: value,
          calcInBnkNm: lng ?? "",
        });
        return;
      }

      setInf({
        ...inf,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const bkMutation = useMutation(lodPayCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "결제정보를 등록하였습니다.",
              isConfirm: true,
              handleClick() {
                dtlMutation.mutate({
                  lodNo: lodNo.current ?? "",
                });
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });
  const handleCreate = () => {
    if (!lodNo.current) {
      return alert("기본정보를 등록후 결제정보를 등록하여 주세요.");
    }

    if (!inf.calcCurrCd) {
      return alert("은행을 선택하여 주세요.");
    }

    if (!inf.calcInDt) {
      return alert("정산입금일을 입력하여 주세요.");
    }

    if (!inf.calcAcntNo) {
      return alert("정산은행계좌번호를 입력하여 주세요.");
    }

    const params = {
      ...inf,
      lodNo: lodNo.current ?? "",
      calcAcntNo: inf.calcAcntNo.replaceAll("-", ""),
      aplySDt: inf.aplySDt.replaceAll("-", ""),
      aplyEDt: inf.aplyEDt.replaceAll("-", ""),
    };

    console.log("params", params);

    dispatch(
      setMessage({
        message: "결제정보를 등록하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          bkMutation.mutate(params);
        },
      })
    );
  };

  let isSuccess = bkMutation.isSuccess;

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"결제정보"}
          clsBtn
          createBtn={!isSuccess}
          createBtnTxt="등록"
          desc="* 기본정보 등록후 결제정보 등록이 가능합니다."
          isClose={infOpen}
          handleCreate={handleCreate}
          handleClose={() => {
            setInfOpen(!infOpen);
          }}
        />
        <div className={infOpen ? "" : "hidden"}>
          <ul>
            <li>
              <label htmlFor="" data-dot>
                정산입금일
              </label>
              <input
                type="text"
                // name="calcInDt"
                defaultValue={inf.calcInDt}
                readOnly={isSuccess}
                onChange={(e) =>
                  handleChange("calcInDt", e.currentTarget.value)
                }
              />
            </li>
            <li>
              <label htmlFor="" data-dot>
                정산은행
              </label>
              <Select
                id="bnk"
                name="calcInBnkCd"
                option={inf.calcInBnkCd || "A"}
                options={bankOptions}
                disabled={isSuccess}
                onSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="" data-dot>
                정산은행계좌번호
              </label>
              <input
                type="text"
                // name="calcAcntNo"
                defaultValue={inf.calcAcntNo}
                readOnly={isSuccess}
                onChange={(e) =>
                  handleChange("calcAcntNo", e.currentTarget.value)
                }
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">숙소정산통화</label>
              <Select
                id={""}
                name={"calcCurrCd"}
                option={inf.calcCurrCd}
                options={moneyOptions}
                isInitial
                disabled={isSuccess}
                onSelect={handleSelect}
              />
            </li>
            <li>
              {/* <DatePick
                handleChange={() => {}}
                label={""}
                name={""}
                date={""}
              /> */}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default BkCreateView;
