import { useMutation } from "@tanstack/react-query";
import { signupApi } from "components/api/member";
import Step3View from "components/view/signup/step3";
import { passProps } from "interface/member";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setMessage } from "store/commonSlice";

const Step3Page = () => {
  const { pathname, state: certEncParam } = useLocation();

  const dispath = useDispatch();
  const navigate = useNavigate();

  // const [isOpen, setIsOpen] = useState<boolean>(false);
  const [info, setInfo] = useState<passProps>({
    pwd: "",
    check_pwd: "",
  });

  const handleChange = (target: { name: string; value: string }) => {
    const { name, value } = target;

    setInfo({
      ...info,
      [name]: value,
    });
  };

  // const handleCloseModal = () => {
  //   setIsOpen(false);
  // };

  const signupMutation = useMutation(signupApi, {
    onSuccess(data, context) {
      if (data) {
        const { code, message } = data.header;
        if (code === 0) {
          // setIsOpen(!isOpen);
          dispath(
            setMessage({
              message:
                "환영합니다. / 회원으로 가입해 주셔서 감사합니다. / 회원가입요청을 완료하였습니다. / 승인완료후 로그인이 가능합니다.  /  승인이 완료되면 회원가입완료 메일을 보내 드립니다.",
              isCancel: true,
              isConfirm: true,
              cnfrmBtnText: "로그인하기",
              handleClick() {
                navigate("/");
                dispath(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispath(setMessage({ message }));
        }
      }
    },
    onError(error, variables, context) {
      console.error("error", error, variables, context);
      // dispath(setMessage({ message: "회원가입 실패" }));
    },
  });

  const handleSignup = () => {
    const params = {
      certEncParam: decodeURIComponent(certEncParam.split("=")[1]),
      lgnPwd: info.pwd,
      prvList: [
        {
          prvGrpCd: "A01",
          prvSeq: "1",
          required: true,
          prvAgYn: "Y",
        },
        {
          prvGrpCd: "B01",
          prvSeq: "2",
          required: true,
          prvAgYn: "Y",
        },
        {
          prvGrpCd: "C01",
          prvSeq: "3",
          required: true,
          prvAgYn: "Y",
        },
        {
          prvGrpCd: "D01",
          prvSeq: "4",
          required: true,
          prvAgYn: "Y",
        },
      ],
    };

    const url = pathname.includes("admin")
      ? "admin/join/adminCertJoin"
      : "manager/join/manCertJoin";

    dispath(
      setMessage({
        message: "회원가입하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          signupMutation.mutate({ data: params, url });
          dispath(setMessage({ message: null }));
        },
      })
    );
  };

  return (
    <Step3View
      info={info}
      // isOpen={isOpen}
      isLoading={signupMutation.isLoading}
      // handleCloseModal={handleCloseModal}
      handleChange={handleChange}
      handleSignup={handleSignup}
    />
  );
};

export default Step3Page;
