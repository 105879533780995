import { useMutation, useQueryClient } from "@tanstack/react-query";
import { lodGrpAdInfCreate, lodRefdAdCreateApi } from "components/api/lod";
import Modal from "components/modal/modal";
import Select from "components/select";
import InTuiEditor from "components/tuiEditor/inEditot";
import { langProps } from "interface";
import { IlodRfdAd } from "interface/lod";
import i18n from "locales/i18n";
import { Dispatch, SetStateAction, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";

type IProps = {
  // langCd: string;
  langOp: langProps[];
  isOpen: string;
  lodNo: string;
  // setLangCd: Dispatch<SetStateAction<string>>;
  setIsOpen: Dispatch<SetStateAction<string>>;
};

const AddModal = ({
  // langCd,
  langOp,
  isOpen,
  lodNo,
  setIsOpen,
}: // setLangCd,
IProps) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const queryClient = useQueryClient();

  const [adInf, setAdInf] = useState<IlodRfdAd>({
    lodNo,
    rgSeq: state?.rgSeq || 1,
    langCd: langOp[0]?.value || i18n.language,
    refdInfNm: "",
    refdDesc: "",
  });
  const handleReset = () => {
    setAdInf({
      lodNo,
      rgSeq: state?.rgSeq || 1,
      langCd: langOp[0]?.value || i18n.language,
      refdInfNm: "",
      refdDesc: "",
    });
  };

  const handleChange = (name: string, value: string) => {
    setAdInf({
      ...adInf,
      [name]: value,
    });
  };

  const createMutation = useMutation(lodRefdAdCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "추가정보를 추가하였습니다.",
              isConfirm: true,
              handleClick() {
                queryClient.invalidateQueries({
                  queryKey: ["lodRdfAdList", lodNo],
                });
                dispatch(setMessage({ message: null }));
                setIsOpen("");
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleAdCreate = () => {
    if (!adInf.refdInfNm || !adInf.refdDesc) {
      return alert("추가정보를 입력하여 주세요.");
    }

    const params = {
      ...adInf,
    };

    console.log("params", params);

    dispatch(
      setMessage({
        message: "추가정보를 추가하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          createMutation.mutate(params);
        },
      })
    );
  };

  return (
    <>
      <Modal
        title={"추가정보 추가"}
        styles="w-5/6"
        btnText={"추가"}
        isOpen={isOpen !== ""}
        handleCloseModal={() => {
          setIsOpen("");
          // setLangCd(i18n.language);
          handleReset();
        }}
        onClick={handleAdCreate}
      >
        <div className="tb-form mt-[3rem] " data-t-brd>
          <ul>
            <li>
              <label htmlFor="">언어선택</label>
              <Select
                id={"langCd"}
                name={"langCd"}
                option={adInf.langCd}
                options={langOp}
                isInitial
                onSelect={(target) => {
                  const { value } = target.dataset;
                  setAdInf({
                    ...adInf,
                    langCd: value ?? "",
                  });
                }}
              />
            </li>
          </ul>
          <ul>
            <li data-full className="">
              <label htmlFor="">환불명</label>
              <input
                type="text"
                value={adInf.refdInfNm}
                onChange={(e) =>
                  handleChange("refdInfNm", e.currentTarget.value)
                }
              />
            </li>
          </ul>
          <ul className="pb-2">
            <li data-full>
              <label htmlFor="">환불설명</label>
              <InTuiEditor
                name={"lodGrpDesc"}
                value={adInf.refdDesc}
                onChange={(value) => handleChange("refdDesc", value)}
              />
            </li>
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default AddModal;
