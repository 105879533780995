import THeader from "components/layout/header/subHeader/tHeader";
import Select from "components/select";
import { authOptions } from "util/option";
import CheckBox from "./authCheck";
import { useState } from "react";
import { IAuthList } from "interface/sett";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";

const AuthCreateView = () => {
  const dispatch = useDispatch();
  const [authGrpCod, setAthGrpCod] = useState("");
  const [menInfo, setMenInfo] = useState({
    manNo: "",
    admNo: "",
  });
  const [info, setInfo] = useState<Omit<IAuthList, "authGrpCod">>({
    mem: "",
    memNo: "",
    memNm: "",
    lodGrp: "",
    lod: "",
    lodDflt: "",
    lodPrd: "",
    lodRfd: "",
    lodChl: "",
    rm: "",
    rmOp: "",
    // rmPrHst: "",
    rmCurr: "",
    // rmBk: "",
    rmCnl: "",
    prm: "",
    ccl: "",
    term: "",
    authStt: "",
  });

  const handleChange = (name: string, id: string) => {
    const value = info[name as keyof Omit<IAuthList, "authGrpCod">];
    setInfo({
      ...info,
      [name]: value?.includes(id)
        ? value?.replace("_" + id, "")
        : value?.concat("_" + id),
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      if (name === "authGrpCod") {
        setAthGrpCod(value);

        // 해당 관리자 권한의 관리자 리스트 가져오기

        return;
      }
      setMenInfo({
        ...menInfo,
        [name]: value,
      });
    }
  };

  const handleCreate = () => {
    if (!authGrpCod && !menInfo.admNo && !menInfo.manNo) {
      return alert("권한상대를 선택 및 입력하여 주세요.");
    }
    const isAble = Object.values(info).some((inf) => {
      return !!inf;
    });

    if (!isAble) {
      return alert("권한을 설정하여 주세요.");
    }

    if (isAble) {
      dispatch(
        setMessage({
          message: "권한을 설정하시겠습니까?",
          isCancel: true,
          isConfirm: true,
          handleClick() {
            dispatch(setMessage({ message: null }));
          },
        })
      );
    }
  };

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader sbTtl={"권한설정"} first clsBtn handleReset={() => {}}>
          <button
            className="btn-sm bg-red-400 text-white-0"
            onClick={handleCreate}
          >
            등록
          </button>
        </THeader>
        <div className="tb-form">
          <ul className="pb-[6px]">
            <li>
              <label htmlFor="">관리자권한</label>
              <Select
                id={"authGrpCod"}
                name={"authGrpCod"}
                option={authGrpCod}
                options={authOptions}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="">관리자번호</label>
              <input
                type="text"
                name="admNo"
                value={menInfo.admNo}
                placeholder="관리자번호 입력"
                onChange={(e) => handleChange("admNo", e.target.value)}
              />
            </li>
            <li>
              <label htmlFor="">매니저번호</label>
              <input
                type="text"
                name="manNo"
                value={menInfo.manNo}
                placeholder="매니저번호 입력"
                onChange={(e) => handleChange("manNo", e.target.value)}
              />
            </li>
          </ul>
          <ul data-brd-top>
            <li>
              <label htmlFor="">숙소그룹</label>
              <CheckBox
                id="lodGrp"
                auth={info.lodGrp}
                handleChange={handleChange}
              />
            </li>
            <li>
              <label htmlFor="">숙소</label>
              <CheckBox id="lod" auth={info.lod} handleChange={handleChange} />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">숙소기본정보</label>
              <CheckBox
                id="lodDflt"
                auth={info.lodDflt}
                handleChange={handleChange}
              />
            </li>
            <li>
              <label htmlFor="">숙소제공정보</label>
              <CheckBox
                id="lodPrd"
                auth={info.lodPrd}
                handleChange={handleChange}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">숙소환불수수료</label>
              <CheckBox
                id="lodRfd"
                auth={info.lodRfd}
                handleChange={handleChange}
              />
            </li>
            <li>
              <label htmlFor="">숙소채널</label>
              <CheckBox
                id="lodChl"
                auth={info.lodChl}
                handleChange={handleChange}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">객실</label>
              <CheckBox id={"rm"} auth={info.rm} handleChange={handleChange} />
            </li>
            <li>
              <label htmlFor="">객실옵션</label>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AuthCreateView;
