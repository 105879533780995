import CalculatePromView from "components/view/calculate/promotion/list";
import { clcPromProps } from "interface/calc";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { afterDay } from "util/common";

const CalculatePromPage = () => {
  const { state } = useLocation();
  const { sDt, eDt } = afterDay(7);
  const [searchInfo, setSearchInfo] = useState<clcPromProps>({
    promCd: state?.promCd ?? "",
    rmResvNo: state?.rmResvNo ?? "",
    resvCnlYn: state?.resvCnlYn ?? "",
    sDt: state?.sDt ?? sDt,
    eDt: state?.eDt ?? eDt,
  });

  const handleChange = (name: string, value: string) => {
    setSearchInfo({
      ...searchInfo,
      [name]: value,
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setSearchInfo({
        ...searchInfo,
        [name]: value,
      });
    }
  };

  const handleReset = () => {
    setSearchInfo({
      promCd: "",
      rmResvNo: "",
      resvCnlYn: "",
      sDt: sDt,
      eDt: eDt,
    });
  };

  return (
    <>
      <CalculatePromView
        searchInfo={searchInfo}
        handleChange={handleChange}
        handleReset={handleReset}
        handleSelect={handleSelect}
      />
    </>
  );
};

export default CalculatePromPage;
