import DatePick from "components/datePick";
import THeader from "components/layout/header/subHeader/tHeader";
import Select from "components/select";
import LodChlSelect from "components/select/room/lodChlSelect";
import LodGrpSelect from "components/select/room/lodGrpNoSelect";
import LodNmSelect from "components/select/room/lodNmSelect";
import RmNoSelect from "components/select/room/rmNoSlect";
import RmOlkSelect from "components/select/room/rmOlkSelect";
import RmTypeSelect from "components/select/room/rmTypSelect";
import SearchSelect from "components/select/searchSelect";
import RoomBookList from "components/tableList/room/resv";
import { rmResvSearchProps } from "interface/room";
import { useLocation } from "react-router-dom";
import { dateTypOptions } from "util/option";

type IProps = {
  memType: "admin" | "manager";
  searchInfo: rmResvSearchProps;
  handleChange(name: string, value: string): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleReset(): void;
};

const RoomBookListView = ({
  memType,
  searchInfo,
  handleChange,
  handleSelect,
  handleReset,
}: IProps) => {
  const { pathname } = useLocation();

  const searchOp = [
    {
      kr: "객실명",
      value: "rmNm",
      en: "RoomName",
    },
    {
      kr: "최대인원수",
      value: "max",
      en: "",
    },
    {
      kr: "최소인원수",
      value: "",
      en: "",
    },
    {
      kr: "객실상품번호",
      value: "rmPdNo",
      en: "",
    },
    {
      kr: "객실예약회원이름",
      value: "",
      en: "",
    },
    {
      kr: "객실예약회원전호번호",
      value: "",
      en: "",
    },
    {
      kr: "객실예약고객이름",
      value: "",
      en: "",
    },
    {
      kr: "객실예약고객전화",
      value: "",
      en: "",
    },
  ];
  const searchOps =
    memType === "admin"
      ? [
          {
            kr: "숙소그룹명",
            value: "lodGrpNm",
            en: "LodGrpName",
          },
          {
            kr: "숙소명",
            value: "lodNm",
            en: "LodName",
          },
          ...searchOp,
        ]
      : searchOp;
  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader
          sbTtl={"객실예약"}
          rstBtn
          searchBtn
          url={pathname}
          handleReset={handleReset}
        />
        <div className="tb-form" data-list>
          {memType === "admin" && (
            <ul>
              <li>
                <label htmlFor="">숙소그룹번호</label>
                <LodGrpSelect
                  option={searchInfo.lodGrpNo}
                  onSelect={(target) => handleSelect(target)}
                />
              </li>
              <li>
                <label htmlFor="">숙소번호</label>
                <LodNmSelect
                  disabled={!searchInfo.lodGrpNo}
                  params={searchInfo.lodGrpNo}
                  option={searchInfo.lodNo}
                  onSelect={(target) => handleSelect(target)}
                />
              </li>
            </ul>
          )}
          <ul>
            <li>
              <label htmlFor="">객실번호</label>
              <RmNoSelect
                disabled={!searchInfo.lodNo}
                params={searchInfo.lodNo}
                option={searchInfo.rmNo}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="">객실타입</label>
              <RmTypeSelect
                option={searchInfo.rmTypCd}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="">객실전망</label>
              <RmOlkSelect
                option={searchInfo.rmOlkCd}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">객실상품채널</label>
              <LodChlSelect
                option={searchInfo.lodChlCd + ""}
                onSelect={(target) => handleSelect(target)}
              />
            </li>

            <li>
              <label htmlFor="">검색조건</label>
              <SearchSelect
                id={"searchTyp"}
                name={searchInfo.searchTyp ?? ""}
                options={searchOps}
                option={searchInfo.searchTyp || "A"}
                value={
                  searchInfo[
                    `${searchInfo.searchTyp}` as keyof rmResvSearchProps
                  ] || ""
                }
                onSelect={handleSelect}
                onChange={(target) => handleChange(target.name, target.value)}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">조회기준일자</label>
              <Select
                id={""}
                name={"dateTyp"}
                isInitial
                option={searchInfo?.dateTyp}
                options={dateTypOptions.slice(0, 2)}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <DatePick
                startNm={"SDt"}
                endNm={"EDt"}
                start={searchInfo.sellSDt}
                end={searchInfo.sellEDt}
                handleChange={(name, value) => handleChange(name, value)}
              />
            </li>
          </ul>
        </div>
      </div>
      <RoomBookList memType={memType} searchInfo={searchInfo} />
    </>
  );
};

export default RoomBookListView;
