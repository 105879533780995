import PromView from "components/view/promotion/list";
import { searchProps } from "interface/promotion";
import { useState } from "react";

const PromPage = () => {
  const [searchInfo, setSearchInfo] = useState<searchProps>({
    promCd: "",
    influNm: "",
    influCnty: "KOR",
  });

  const handleChange = (name: string, value: string) => {
    setSearchInfo({
      ...searchInfo,

      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value, code } = target.dataset;
    if (name && code) {
      setSearchInfo({
        ...searchInfo,
        influCnty: code,
      });
    }
  };
  const handleReset = () => {
    setSearchInfo({
      promCd: "",
      influNm: "",
      influCnty: "82",
    });
  };

  return (
    <>
      <PromView
        searchInfo={searchInfo}
        handleChange={handleChange}
        handleSelect={handleSelect}
        handleReset={handleReset}
      />
    </>
  );
};

export default PromPage;
