import { useMutation, useQueryClient } from "@tanstack/react-query";
import { lodAdDftModify } from "components/api/lod";
import Modal from "components/modal/modal";
import Select from "components/select";
import InTuiEditor from "components/tuiEditor/inEditot";
import { langProps } from "interface";
import i18n from "locales/i18n";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";

export type IProps = {
  adInfo: any;
  langCd: string;
  langOp: langProps[];
  isOpen: string;
  lodNo: string;
  setLangCd: Dispatch<SetStateAction<string>>;
  setIsOpen: Dispatch<SetStateAction<string>>;
};

export const ModifyModal = ({
  adInfo,
  langCd,
  langOp,
  isOpen,
  lodNo,
  setIsOpen,
  setLangCd,
}: IProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [adInf, setAdInf] = useState({
    langCd: langOp[0]?.value || i18n.language,
    lodNotiCn: "",
    lodOprCn: "",
    lodCstFvrCn: "",
    lodAddFacCn: "",
    lodBrkfCn: "",
    lodCokCn: "",
    lodCstAdCn: "",
  });

  const isNoty = useRef(false);
  const isOpr = useRef(false);
  const isCstFvr = useRef(false);
  const isAddFac = useRef(false);
  const isBrkf = useRef(false);
  const isCok = useRef(false);
  const isCstAd = useRef(false);

  const handleReset = () => {
    setAdInf({
      langCd: langOp[0]?.value || i18n.language,
      lodNotiCn: "",
      lodOprCn: "",
      lodCstFvrCn: "",
      lodAddFacCn: "",
      lodBrkfCn: "",
      lodCokCn: "",
      lodCstAdCn: "",
    });
  };

  const handleChange = (name: string, value: string) => {
    setAdInf({
      ...adInf,
      [name]: value,
    });
  };

  const modifyMutation = useMutation(lodAdDftModify, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "추가정보가 성정공적으로 수정되었습니다.",
              isConfirm: true,
              handleClick() {
                queryClient.invalidateQueries({
                  queryKey: ["lodAdDftList", lodNo],
                });
                dispatch(setMessage({ message: null }));
                setIsOpen("");
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModify = () => {
    const isAble = !(
      isNoty.current ||
      isOpr.current ||
      isCstFvr.current ||
      isCstAd.current ||
      isAddFac.current ||
      isCok.current ||
      isCstAd.current
    );

    if (isAble) {
      return alert("추가정보를 수정하여 주세요.");
    }

    const prams = {
      lodNo,
      langCd,
      lodNotiCn: isNoty.current ? adInf.lodNotiCn : "",
      lodOprCn: isOpr.current ? adInf.lodOprCn : "",
      lodCstFvrCn: isCstFvr.current ? adInf.lodCstFvrCn : "",
      lodAddFacCn: isAddFac.current ? adInf.lodAddFacCn : "",
      lodBrkfCn: isBrkf.current ? adInf.lodBrkfCn : "",
      lodCokCn: isCok.current ? adInf.lodCokCn : "",
      lodCstAdCn: isCstAd.current ? adInf.lodCstAdCn : "",
      // ...adInf,
    };

    console.log("params", prams);

    dispatch(
      setMessage({
        message: "추가정보를 수정하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          dispatch(setMessage({ message: null }));
          modifyMutation.mutate(prams);
        },
      })
    );
  };

  useEffect(() => {
    handleReset();
  }, []);

  // useEffect(() => {
  //   setAdInf(adInfo);

  //   return () => {
  //     handleReset();
  //   };
  // }, [adInfo]);

  return (
    <>
      <Modal
        title={"추가정보 수정"}
        styles="w-5/6"
        btnText={"수정"}
        isOpen={isOpen !== ""}
        handleCloseModal={() => {
          setIsOpen("");
          setLangCd(i18n.language);
          handleReset();
        }}
        onClick={handleModify}
      >
        <div className="tb-form mt-[3rem] mb-1" data-t-brd>
          <ul>
            <li>
              <label htmlFor="">언어선택</label>
              <Select
                id={"langCd"}
                name={"langCd"}
                option={langCd}
                options={langOp}
                isInitial
                onSelect={(target) => {
                  const { value } = target.dataset;
                  if (!!value) {
                    setLangCd(value);
                    handleReset();
                  }
                }}
              />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">공지사항</label>
              <InTuiEditor
                name={"lodNotiCn"}
                value={adInfo?.lodNotiCn || adInf.lodNotiCn}
                onChange={(value) => handleChange("lodNotiCn", value)}
                isAbleRef={isNoty}
              />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">운영벙보</label>
              <InTuiEditor
                name={"lodOprCn"}
                value={adInfo?.lodOprCn || adInf.lodOprCn}
                onChange={(value) => handleChange("lodOprCn", value)}
                isAbleRef={isOpr}
              />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">인원추가정보</label>
              <InTuiEditor
                name={"lodCstAdCn"}
                value={adInfo?.lodCstAdCn || adInf?.lodCstAdCn}
                isAbleRef={isCstAd}
                onChange={(value) => handleChange("lodCstAdCn", value)}
              />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">부대시설정보</label>
              <InTuiEditor
                name={"lodAddFacCn"}
                value={adInfo?.lodAddFacCn || adInf?.lodAddFacCn}
                onChange={(value) => handleChange("lodAddFacCn", value)}
                isAbleRef={isAddFac}
              />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">조식정보</label>
              <InTuiEditor
                name={"lodBrkfCn"}
                value={adInfo?.lodBrkfCn || adInf?.lodBrkfCn}
                onChange={(value) => handleChange("lodBrkfCn", value)}
                isAbleRef={isBrkf}
              />
            </li>
          </ul>
          <ul className="pb-1">
            <li data-full>
              <label htmlFor="">취사시설정보</label>
              <InTuiEditor
                name={"lodCokCn"}
                value={adInfo?.lodCokCn || adInf?.lodCokCn}
                onChange={(value) => handleChange("lodCokCn", value)}
                isAbleRef={isCok}
              />
            </li>
          </ul>
        </div>
      </Modal>
    </>
  );
};
