import { optionProps } from "interface";
import i18n from "locales/i18n";
import { useEffect, useRef, useState } from "react";
import { iniSelect } from "util/common";
import "./select.scss";

type IProps = {
  id: string;
  name: string;
  options: any[];
  option: string;
  wrapStyle?: string;
  ulStyle?: string;
  disabled?: boolean;
  value: string | number;
  onSelect(target: EventTarget & HTMLLIElement): void;
  onChange(target: EventTarget & HTMLInputElement): void;
};

const SearchSelect = ({
  id,
  name,
  options,
  option,
  wrapStyle = "",
  ulStyle = "",
  disabled = false,
  value = "",
  onSelect,
  onChange,
}: IProps) => {
  const ulRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  let lng = i18n.language || "kr";

  const outSideClick = (e: any) => {
    if (ulRef.current && !ulRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", outSideClick);

    return () => {
      document.removeEventListener("click", outSideClick);
    };
  }, [ulRef]);

  const newOptions = [iniSelect, ...options];

  const opNm =
    options &&
    newOptions.find((op) => {
      return op.value + "" === option + "";
    });

  return (
    <div className="search-in-wrap">
      <div className={`${wrapStyle} select-wrap`} ref={ulRef}>
        <button
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className={isOpen ? "on" : ""}
          disabled={disabled}
        >
          {opNm[lng as keyof optionProps]}
        </button>

        <ul className={isOpen ? `${ulStyle} on` : "hidden"}>
          {newOptions.map((op, index) => (
            <li
              key={index}
              data-name={id}
              data-lng={op[lng as keyof optionProps]}
              data-value={op.value}
              data-events={op?.events}
              data-selected={option === op.value}
              onClick={(e) => {
                onSelect(e.currentTarget);
                setIsOpen(!isOpen);
              }}
            >
              {op[lng as keyof optionProps]}
            </li>
          ))}
        </ul>
      </div>
      <input
        type="text"
        name={name}
        placeholder="검색조건 선택 후 입력가능"
        value={value}
        readOnly={option === "" || option === "A"}
        onChange={(e) => onChange(e.currentTarget)}
      />
    </div>
  );
};
export default SearchSelect;
