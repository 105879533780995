import { useMutation } from "@tanstack/react-query";
import { rmPdDtlApi } from "components/api/room";
import RmPdInfoView from "components/view/room/prd/info";
import { IrmPdAdInf, IrmPdInf } from "interface/room";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";

const RmPdInfoPage = () => {
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();
  const rmPdNo = search.replace("?rmPdNo=", "");
  const memType = pathname.includes("admin") ? "admin" : "manager";

  const rmPdMutation = useMutation(rmPdDtlApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;

        if (code === -1) {
          dispatch(
            setMessage({
              message: message || "검색 데이터가 없습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  useEffect(() => {
    rmPdMutation.mutate({ rmPdNo });
  }, [search]);

  const rmPdInf = rmPdMutation.data?.body?.rmPdInf || ({} as IrmPdInf);
  const rmOpNmList =
    rmPdMutation.data?.body?.rmOpNmList || ([] as Array<IrmPdAdInf>);

  return (
    <>
      <RmPdInfoView
        memType={memType}
        rmPdInf={rmPdInf}
        rmOpNmList={rmOpNmList}
      />
    </>
  );
};

export default RmPdInfoPage;
