import { useMutation, useQueryClient } from "@tanstack/react-query";
import { lodGrpAdInfCreate } from "components/api/lod";
import Modal from "components/modal/modal";
import Select from "components/select";
import InTuiEditor from "components/tuiEditor/inEditot";
import { langProps } from "interface";
import { IlodGrpAddInf } from "interface/lod";
import i18n from "locales/i18n";
import { Dispatch, SetStateAction, useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";

type IProps = {
  langCd: string;
  langOp: langProps[];
  isOpen: string;
  lodGrpNo: string;
  lodGrpZipc: string;
  setLangCd: Dispatch<SetStateAction<string>>;
  setIsOpen: Dispatch<SetStateAction<string>>;
};

const AddModal = ({
  langCd,
  langOp,
  isOpen,
  lodGrpNo,
  lodGrpZipc,
  setIsOpen,
  setLangCd,
}: IProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [adInf, setAdInf] = useState<IlodGrpAddInf>({
    lodGrpNm: "",
    lodGrpDesc: "",
    lodGrpCntyCd: "",
    lodGrpZipc: "",
    lodGrpAddr1: "",
    lodGrpAddr2: "",
    lodGrpAddr3: "",
    lodGrpAddr4: "",
  });

  const handleReset = () => {
    setAdInf({
      lodGrpNm: "",
      lodGrpDesc: "",
      lodGrpCntyCd: "",
      lodGrpZipc: "",
      lodGrpAddr1: "",
      lodGrpAddr2: "",
      lodGrpAddr3: "",
      lodGrpAddr4: "",
    });
  };

  const handleChange = (name: string, value: string) => {
    setAdInf({
      ...adInf,
      [name]: value,
    });
  };

  const createMutation = useMutation(lodGrpAdInfCreate, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "추가정보를 추가하였습니다.",
              isConfirm: true,
              handleClick() {
                queryClient.invalidateQueries({
                  queryKey: ["lodGrpAdList", lodGrpNo],
                });
                dispatch(setMessage({ message: null }));
                setIsOpen("");
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleAdCreate = () => {
    if (!langCd) {
      return alert("언어를 선택하여 주세요.");
    }

    const params = {
      lodGrpNo,
      langCd,
      ...adInf,
      lodGrpZipc: lodGrpZipc || adInf.lodGrpZipc,
    };

    console.log("params", params);

    dispatch(
      setMessage({
        message: "추가정보를 추가하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          createMutation.mutate(params);
        },
      })
    );
  };

  return (
    <>
      <Modal
        title={"추가정보 추가"}
        styles="w-5/6"
        btnText={"추가"}
        isOpen={isOpen !== ""}
        handleCloseModal={() => {
          setIsOpen("");
          setLangCd(i18n.language);
          handleReset();
        }}
        onClick={handleAdCreate}
      >
        <div className="tb-form mt-[3rem] " data-t-brd>
          <ul>
            <li>
              <label htmlFor="">언어선택</label>
              <Select
                id={"langCd"}
                name={"langCd"}
                option={langCd}
                options={langOp}
                isInitial
                onSelect={(target) => {
                  const { value } = target.dataset;
                  if (!!value) {
                    setLangCd(value);
                    handleReset();
                  }
                }}
              />
            </li>
          </ul>
          <ul>
            <li data-full className="">
              <label htmlFor="">숙소그룹명</label>
              <input
                type="text"
                value={adInf.lodGrpNm}
                onChange={(e) =>
                  handleChange("lodGrpNm", e.currentTarget.value)
                }
              />
            </li>
          </ul>
          <ul className="pb-2">
            <li data-full>
              <label htmlFor="">숙소그룹설명</label>
              <InTuiEditor
                name={"lodGrpDesc"}
                value={adInf.lodGrpDesc}
                onChange={(value) => handleChange("lodGrpDesc", value)}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">우편번호</label>
              <input
                type="text"
                value={lodGrpZipc || adInf.lodGrpZipc}
                onChange={(e) =>
                  handleChange("lodGrpZipc", e.currentTarget.value)
                }
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">주소1</label>
              <input
                type="text"
                value={adInf.lodGrpAddr1}
                onChange={(e) =>
                  handleChange("lodGrpAddr1", e.currentTarget.value)
                }
              />
            </li>
            <li>
              <label htmlFor="">주소2</label>
              <input
                type="text"
                value={adInf.lodGrpAddr2}
                onChange={(e) =>
                  handleChange("lodGrpAddr2", e.currentTarget.value)
                }
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">주소3</label>
              <input
                type="text"
                value={adInf.lodGrpAddr3}
                onChange={(e) =>
                  handleChange("lodGrpAddr3", e.currentTarget.value)
                }
              />
            </li>
            <li>
              <label htmlFor="">주소4</label>
              <input
                type="text"
                value={adInf.lodGrpAddr4}
                onChange={(e) =>
                  handleChange("lodGrpAddr4", e.currentTarget.value)
                }
              />
            </li>
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default AddModal;
