import ITHeader from "components/layout/header/subHeader/itemHeader";
import THeader from "components/layout/header/subHeader/tHeader";
import { IrmResvInfo } from "interface/room";
import i18n from "locales/i18n";
import { useEffect, useRef, useState } from "react";
import { dateFormat, getDay, inCom } from "util/common";

type IProps = {
  rmResvCnlDtl: IrmResvInfo;
};

const RoomBookCancelInfoView = ({ rmResvCnlDtl }: IProps) => {
  const txtRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (txtRef.current) {
      txtRef.current.innerHTML = rmResvCnlDtl?.rmOpDesc ?? "";
    }
  }, []);

  const perCt =
    rmResvCnlDtl?.adltPerCt || rmResvCnlDtl?.chldPerCt
      ? rmResvCnlDtl?.adltPerCt + rmResvCnlDtl?.chldPerCt
      : "0";

  const resvNm =
    rmResvCnlDtl?.memSurnm && rmResvCnlDtl?.memFstnm
      ? rmResvCnlDtl?.memSurnm + rmResvCnlDtl?.memFstnm
      : "";

  const cstNm =
    rmResvCnlDtl?.resvCstSurnm && rmResvCnlDtl?.resvCstFstnm
      ? rmResvCnlDtl?.resvCstSurnm + rmResvCnlDtl?.resvCstFstnm
      : "";

  const refTyp =
    rmResvCnlDtl?.refTyp == "T"
      ? "전체취소"
      : rmResvCnlDtl?.refTyp == "P"
      ? "부분취소"
      : "";

  const cnlDtti =
    dateFormat(rmResvCnlDtl?.cnlDtti?.slice(0, 8)) +
    rmResvCnlDtl?.cnlDtti?.slice(8, 10) +
    ":" +
    rmResvCnlDtl?.cnlDtti?.slice(10, 12) +
    ":" +
    rmResvCnlDtl?.cnlDtti?.slice(12, 14);

  const payAmt = inCom(rmResvCnlDtl?.payAmt ?? "") ?? "";
  const refAmt = inCom(rmResvCnlDtl?.refAmt ?? "") ?? "";

  const [infOpen, setInfOpen] = useState(true);
  const [pdOpen, setPdOpen] = useState(true);
  const [bkOpen, setBkOpen] = useState(true);
  const [payOpen, setPayOpen] = useState(true);

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader sbTtl={"객실예약취소 조회"} clsBtn handleReset={() => {}} />
        <div className="tb-form">
          <ITHeader
            sbTtl={"기본정보"}
            clsBtn
            isClose={infOpen}
            handleClose={() => {
              setInfOpen(!infOpen);
            }}
          />
          <div className={infOpen ? "" : "hidden"}>
            <ul>
              <li>
                <label htmlFor="">숙소그룹명</label>
                <input
                  type="text"
                  defaultValue={rmResvCnlDtl?.lodGrpNm ?? ""}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">숙소명</label>
                <input
                  type="text"
                  defaultValue={rmResvCnlDtl?.lodNm ?? ""}
                  readOnly
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">객실명</label>
                <input
                  type="text"
                  defaultValue={rmResvCnlDtl?.rmNm ?? ""}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">객실타입</label>
                <input
                  type="text"
                  defaultValue={rmResvCnlDtl?.rmTypNm ?? ""}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">객실전망</label>
                <input
                  type="text"
                  defaultValue={rmResvCnlDtl?.rmOlkNm ?? ""}
                  readOnly
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">객실상품번호</label>
                <input
                  type="text"
                  defaultValue={rmResvCnlDtl?.rmResvNo ?? ""}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">객실상품채널</label>
                <input
                  type="text"
                  defaultValue={rmResvCnlDtl?.lodChlNm ?? ""}
                  readOnly
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">조식포함</label>
                <input
                  type="text"
                  defaultValue={
                    rmResvCnlDtl?.brkfInYn === "Y" ? "포함" : "미포함"
                  }
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">레이트 체크아웃시간</label>
                <input
                  type="text"
                  defaultValue={
                    rmResvCnlDtl?.latChkOutTi?.replace(
                      /\B(?=(\d{2})+(?!\d))/g,
                      ":"
                    ) ?? ""
                  }
                  readOnly
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="tb-form">
          <ITHeader
            sbTtl={"상품정보"}
            clsBtn
            isClose={pdOpen}
            handleClose={() => {
              setPdOpen(!pdOpen);
            }}
          />
          <div className={pdOpen ? "" : "hidden"}>
            <ul>
              <li>
                <label htmlFor="">객실상품일지</label>
                <input
                  type="text"
                  defaultValue={rmResvCnlDtl?.sellDt ?? ""}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">객실상품요일</label>
                <input
                  type="text"
                  defaultValue={getDay(
                    rmResvCnlDtl?.sellDt ?? "",
                    i18n.language
                  )}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">객실상품금액</label>
                <input
                  type="text"
                  defaultValue={inCom(rmResvCnlDtl?.rmSellPri ?? "")}
                  readOnly
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">프로모션번호</label>
                <input type="text" defaultValue={""} readOnly />
              </li>
              <li>
                <label htmlFor="">프로모션명</label>
                <input type="text" defaultValue={""} readOnly />
              </li>
              <li>
                <label htmlFor="">프로모션금액</label>
                <input type="text" defaultValue={""} readOnly />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">객실상품특가</label>
                <input type="text" defaultValue={""} readOnly />
              </li>
            </ul>
          </div>
        </div>
        <div className="tb-form">
          <ITHeader
            sbTtl={"상품정보"}
            clsBtn
            isClose={bkOpen}
            handleClose={() => {
              setBkOpen(!bkOpen);
            }}
          />
          <div className={bkOpen ? "" : "hidden"}>
            <ul>
              <li>
                <label htmlFor="">객실예약번호</label>
                <input
                  type="text"
                  defaultValue={rmResvCnlDtl?.rmResvNo ?? ""}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">예약시작일자</label>
                <input
                  type="text"
                  defaultValue={dateFormat(
                    rmResvCnlDtl?.resvSDt?.slice(0, 8) ?? ""
                  )}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="  ">예약종료일자</label>
                <input
                  type="text"
                  defaultValue={dateFormat(
                    rmResvCnlDtl?.resvEDt?.slice(0, 8) ?? ""
                  )}
                  readOnly
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">예약일수</label>
                <input
                  type="text"
                  defaultValue={rmResvCnlDtl?.resvDayCt ?? ""}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">예약수량</label>
                <input
                  type="text"
                  defaultValue={rmResvCnlDtl?.resvRmCt ?? ""}
                  readOnly
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">예약 전체인원인수</label>
                <input type="text" defaultValue={perCt} readOnly />
              </li>
              <li>
                <label htmlFor="">예약 성인인원수</label>
                <input
                  type="text"
                  defaultValue={rmResvCnlDtl?.adltPerCt ?? ""}
                  readOnly
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">예약 아동인원수</label>
                <input
                  type="text"
                  defaultValue={rmResvCnlDtl?.chldPerCt ?? ""}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">예약 무료인원수</label>
                <input
                  type="text"
                  defaultValue={rmResvCnlDtl?.basPerCt}
                  readOnly
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">예약이메일 발송일시</label>
                <input type="text" readOnly />
              </li>
              <li>
                <label htmlFor="">예약팩스 발송일시</label>
                <input type="text" readOnly />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">예약 회원이름</label>
                <input type="text" defaultValue={resvNm} readOnly />
              </li>
              <li>
                <label htmlFor="">예약 회원전화번호</label>
                <input
                  type="text"
                  defaultValue={rmResvCnlDtl?.resvCstMblNo ?? ""}
                  readOnly
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">예약 고객이름</label>
                <input type="text" defaultValue={cstNm} readOnly />
              </li>
              <li>
                <label htmlFor="">예약 고객전화번호</label>
                <input
                  type="text"
                  defaultValue={rmResvCnlDtl?.resvCstMblNo ?? ""}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">고객 이메일</label>
                <input
                  type="text"
                  defaultValue={rmResvCnlDtl?.resvCstEml ?? ""}
                  readOnly
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">정산금액</label>
                <input type="text" defaultValue={payAmt} readOnly />
              </li>
            </ul>
            <ul>
              <li data-full>
                <label htmlFor="">객실예약 요청사항</label>
                <input
                  type="text"
                  defaultValue={rmResvCnlDtl?.reqCn ?? ""}
                  readOnly
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="tb-form">
          <ITHeader
            sbTtl={"상품정보"}
            clsBtn
            isClose={payOpen}
            handleClose={() => {
              setPayOpen(!payOpen);
            }}
          />
          <div className={payOpen ? "" : "hidden"}>
            <ul>
              <li>
                <label htmlFor="">결제취소일시</label>
                <input type="text" defaultValue={cnlDtti} readOnly />
              </li>
              <li>
                <label htmlFor="">등록일련번호</label>
                <input
                  type="text"
                  defaultValue={rmResvCnlDtl?.cnlRgSeq}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">환불유형</label>
                <input type="text" defaultValue={refTyp} readOnly />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">결제금액</label>
                <input type="text" defaultValue={payAmt} readOnly />
              </li>
              <li>
                <label htmlFor="">환불금액</label>
                <input type="text" defaultValue={refAmt} readOnly />
              </li>
              <li>
                <label htmlFor="">잔여금액</label>
                <input
                  type="text"
                  defaultValue={inCom(rmResvCnlDtl?.balAmt ?? "") ?? ""}
                  readOnly
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">통화코드</label>
                <input
                  type="text"
                  defaultValue={rmResvCnlDtl?.currCd}
                  readOnly
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoomBookCancelInfoView;
