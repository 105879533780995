import { admRmPdListHead, manRmPdListHead } from "util/option";
import { useLocation, useNavigate } from "react-router-dom";
import Pagenation from "components/pagenation";
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import {
  isSearchSelector,
  setIsLoading,
  setIsSearch,
  setMessage,
} from "store/commonSlice";
import { rmPdSearchProps } from "interface/room";
import { pageNavProps } from "interface";
import { useSelector } from "react-redux";
import { rmPdListApi } from "components/api/room";
import { dateFormat, getDay, inCom } from "util/common";
import i18n from "locales/i18n";
import SearchHeader from "components/layout/header/subHeader/srchHeader";

type IProps = {
  memType: "admin" | "manager";
  searchInfo: rmPdSearchProps;
};

type IListProps = {
  memType: "admin" | "manager";
  col: number;
  searchInfo: rmPdSearchProps;
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};
const List = ({ memType, searchInfo, col, setPageNav }: IListProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();
  const isSearch = useSelector(isSearchSelector);
  const [list, setList] = useState([]);

  const rmPdMutation = useMutation(rmPdListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setPageNav(body?.pageNav);
          setList(body?.rmPdList);
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));
        }
      }
    },
  });

  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch) {
      const {
        langCd,
        rmNo,
        rmNm,
        rmOlkCd,
        rmOpNm,
        rmPdNo,
        rmTypCd,
        maxPerCtMax,
        maxPerCtMin,
        lodChlCd,
        sellEDt,
        sellSDt,
        sellYn,
      } = searchInfo;
      const params = {
        langCd: langCd || state?.langCd,
        rmNo: rmNo || state?.rmNo,
        rmNm: rmNm || state?.rmNm,
        rmTypCd: rmTypCd || state?.rmTypCd,
        rmOlkCd: rmOlkCd || state?.rmOlkCd,
        maxPerCtMax: maxPerCtMax || state?.maxPerCtMax || -1,
        maxPerCtMin: maxPerCtMin || state?.maxPerCtMin || -1,
        rmPdNo: rmPdNo || state?.rmPdNo,
        lodChlCd: lodChlCd || state?.lodChlCd,
        rmOpNm: rmOpNm || state?.rmOpNm,
        sellSDt: (sellSDt || state?.sellSDt)?.replaceAll("-", ""),
        sellEDt: (sellEDt || state?.sellEDt)?.replaceAll("-", ""),
        sellYn: sellYn || state?.sellYn,
        pageNo: Number(page) || 1,
      };

      rmPdMutation.mutate(params);
    }
  }, [isSearch, searchInfo, search]);

  const tdy = new Date().toISOString().split("T")[0].replaceAll("-", "");

  const pageRows = rmPdMutation.data?.body?.pageNav?.pageRows;
  const isNotData = !pageRows;
  return (
    <>
      {list.length === 0 && (
        <tr>
          <td colSpan={col}>
            {isNotData && (
              <span className="font-bold">검색조건을 선택하여 주세요.</span>
            )}
            {!isNotData && (
              <span className="font-bold">검색결과가 없습니다.</span>
            )}
          </td>
        </tr>
      )}
      {list.map((li: any, index: number) => {
        const isSts =
          Number(tdy) > Number(li.sellDt)
            ? "done"
            : li.sellYn === "N"
            ? "n-use"
            : "sll";

        return (
          <tr
            key={index}
            onClick={() => {
              navigate(`info?rmPdNo=${li.rmPdNo}`, {
                state: { searchInfo, url: pathname + search },
              });
            }}
          >
            <td>{(Number(page) - 1) * (pageRows || 10) + index + 1}</td>
            {memType === "admin" && (
              <>
                <td>{li.lodGrpNo}</td>
                <td>{li.lodGrpNm}</td>
                <td>{li.lodNo}</td>
                <td>{li.lodNm}</td>
                <td>{li.rmNo}</td>
                <td>{li.rmNm}</td>
                <td>{li.rmPdNo}</td>
                <td>{li.rmTypNm}</td>
                <td>{li.rmOlkNm}</td>
                <td>{li.lodChlCd}</td>
                <td>{li.rmOpNm}</td>
                <td>{li.brkfInYn === "N" ? "미포함" : "포함"}</td>
                <td>{li.latChkOutTi.replace(/\B(?=(\d{2})+(?!\d))/g, ":")}</td>
                <td></td>
                <td>{dateFormat(li.sellDt)}</td>
                <td>{getDay(dateFormat(li.sellDt), i18n.language)}</td>
                <td>{li.rmPdCt}</td>
                <td></td>
                <td>{inCom(li.sellPri ?? "")}</td>
              </>
            )}
            {memType === "manager" && (
              <>
                <td>{li.rmNo}</td>
                <td>{li.rmNm}</td>
                <td>{li.rmPdNo}</td>
                <td>{li.lodChlCd}</td>
                <td>{li.rmOpNm}</td>
                <td>{dateFormat(li.sellDt)}</td>
                <td>{getDay(dateFormat(li.sellDt), i18n.language)}</td>
                <td>{li.rmPdCt}</td>
                <td>{inCom(li.sellPri ?? "")}</td>
              </>
            )}

            <td>
              <button className={isSts} disabled={true} onClick={() => {}}>
                {isSts === "done"
                  ? "마감"
                  : isSts === "n-use"
                  ? "중지"
                  : "판매"}
              </button>
            </td>
          </tr>
        );
      })}
    </>
  );
};

const RoomProductList = ({ memType, searchInfo }: IProps) => {
  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const col =
    memType === "admin" ? admRmPdListHead.length : manRmPdListHead.length;

  return (
    <div className="list-wrap">
      <SearchHeader pageNav={pageNav} />
      <div className="h-[23.5rem]" data-brd={!!pageNav?.totalRows}>
        <table>
          <thead>
            {memType === "admin" && (
              <tr>
                {admRmPdListHead.map((li) => (
                  <th key={li}>{li}</th>
                ))}
              </tr>
            )}
            {memType === "manager" && (
              <tr>
                {manRmPdListHead.map((li) => (
                  <th key={li}>{li}</th>
                ))}
              </tr>
            )}
          </thead>

          <tbody>
            <Suspense>
              <List
                searchInfo={searchInfo}
                memType={memType}
                col={col}
                setPageNav={setPageNav}
              />
            </Suspense>
            {pageNav && pageNav?.totalRows > 0 && (
              <tr>
                <td colSpan={col}>
                  <Pagenation total={pageNav?.totalPage} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RoomProductList;
