import { useMutation } from "@tanstack/react-query";
import { lodInfCreateApi, lodInfDtlApi } from "components/api/lod";
import DatePick from "components/datePick";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import Modal from "components/modal/modal";
import Radio from "components/radio/radio";
import Select from "components/select";
import { lodInfProps } from "interface/lod";
import { MutableRefObject, useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";
import { setRgSeq } from "store/lodSlice";
import { afterDay } from "util/common";
import { lastTimeOptions, sllPayOption, vatInYnOption } from "util/option";

type IProps = {
  lodNo: MutableRefObject<string>;
};

const InfCreateView = ({ lodNo }: IProps) => {
  const dispatch = useDispatch();

  const dtlMutation = useMutation(lodInfDtlApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          const {
            rgSeq,
            uyn,
            sellAmDvc,
            sellAmVatInYn,
            contSDt,
            contEDt,
            resvETi,
            refdFeeTypCd,
            babyAgeStndV,
            chldAgeStndV,
            chkInTi,
            chkOutTi,
          } = body?.lodInfDtl;
          setInf({
            rgSeq,
            uyn,
            sellAmDvc,
            sellAmVatInYn,
            contSDt,
            contEDt,
            resvETi,
            refdFeeTypCd,
            babyAgeStndV,
            chldAgeStndV,
            chkInTi,
            chkOutTi,
          });
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const { sDt, eDt } = afterDay(120);

  const [inf, setInf] = useState<lodInfProps>({
    rgSeq: 1,
    uyn: "Y",
    sellAmDvc: "01",
    sellAmVatInYn: "Y",
    contSDt: sDt ?? "",
    contEDt: eDt ?? "",
    resvETi: "2000",
    refdFeeTypCd: "01",
    babyAgeStndV: 0,
    chldAgeStndV: 7,
    chkInTi: "1300",
    chkOutTi: "1100",
  });

  const [infOpen, setInfOpen] = useState(true);

  const handleChange = (name: string, value: string) => {
    setInf({
      ...inf,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setInf({
        ...inf,
        [name]: value,
      });
    }
  };

  const infMutation = useMutation(lodInfCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "숙소정보를 등록하였습니다.",
              isConfirm: true,
              handleClick() {
                dtlMutation.mutate({
                  lodNo: lodNo.current ?? "",
                });
                dispatch(setRgSeq(body?.data?.rgSeq));
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCreate = () => {
    if (!lodNo.current) {
      return alert("숙소기본정보를 등록후 숙소정보를 등록하여 주세요.");
    }
    const params = {
      ...inf,
      lodNo: lodNo.current ?? "",
      resvETi: inf.resvETi.replaceAll(":", ""),
      chkInTi: inf.chkInTi.replaceAll(":", ""),
      chkOutTi: inf.chkOutTi.replaceAll(":", ""),
      contSDt: inf.contSDt.replaceAll("-", ""),
      contEDt: inf.contEDt.replaceAll("-", ""),
      babyAgeStndV: Number(inf.babyAgeStndV),
      chldAgeStndV: Number(inf.chldAgeStndV),
    };

    console.log("params", params);

    dispatch(
      setMessage({
        message: "숙소정보를 등록하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          infMutation.mutate(params);
        },
      })
    );
  };

  let isSuccess = infMutation.isSuccess;

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"숙소정보"}
          clsBtn
          createBtn={!isSuccess}
          createBtnTxt="등록"
          desc="* 기본정보 등록후 숙소정보 등록이 가능합니다."
          handleCreate={handleCreate}
          isClose={infOpen}
          handleClose={() => {
            setInfOpen(!infOpen);
          }}
        />
        <div className={infOpen ? "" : "hidden"}>
          <ul>
            <li>
              <label htmlFor="">판매금액구분</label>
              <Select
                id={"sellAmDvc"}
                name={"sellAmDvc"}
                option={inf.sellAmDvc}
                options={sllPayOption}
                isInitial
                disabled={isSuccess}
                onSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="">VAT포함여부</label>
              <Select
                id={"sellAmVatInYn"}
                name={"sellAmVatInYn"}
                option={inf.sellAmVatInYn}
                options={vatInYnOption}
                isInitial
                disabled={isSuccess}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
          </ul>

          <ul>
            <li>
              <label htmlFor="">판매당일예약마감시간</label>
              <Select
                id="resvETi"
                name="resvETi"
                option={inf.resvETi?.replace(/\B(?=(\d{2})+(?!\d))/g, ":")}
                options={lastTimeOptions}
                disabled={isSuccess}
                onSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="">유아나이기준</label>
              <input
                type="text"
                name="babyAgeStndV"
                defaultValue={inf.babyAgeStndV}
                readOnly={isSuccess}
                onChange={(e) =>
                  handleChange("babyAgeStndV", e.currentTarget.value)
                }
              />
            </li>
            <li>
              <label htmlFor="">아동나이기준</label>
              <input
                type="text"
                name="chldAgeStndV"
                defaultValue={inf.chldAgeStndV}
                readOnly={isSuccess}
                onChange={(e) =>
                  handleChange("chldAgeStndV", e.currentTarget.value)
                }
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">체크인시간</label>
              <Select
                id={"chkInTi"}
                name={"chkInTi"}
                option={
                  inf.chkInTi?.replace(/\B(?=(\d{2})+(?!\d))/g, ":") || "A"
                }
                options={lastTimeOptions}
                disabled={isSuccess}
                onSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="">체크아웃시간</label>
              <Select
                id={"chkOutTi"}
                name={"chkOutTi"}
                option={
                  inf.chkOutTi?.replace(/\B(?=(\d{2})+(?!\d))/g, ":") || "A"
                }
                options={lastTimeOptions}
                disabled={isSuccess}
                onSelect={handleSelect}
              />
            </li>
          </ul>
          <ul>
            <li>
              <DatePick
                label="계약일자"
                startNm={"contSDt"}
                endNm={"conteDt"}
                start={inf.contSDt}
                end={inf.contEDt}
                handleChange={(name, value) => handleChange(name, value)}
              />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">환불수수료부과방식</label>
              <div className="w-full flex flex-col gap-y-4 ">
                <span className="text-xs mx-[13.25rem]">
                  취소 수수료율은 취소를 접수한 날짜를 기준으로 적용
                </span>
                <span className="w-full flex justify-start">
                  <Radio
                    id="01"
                    name="refdFeeTypCd"
                    label="PerNight"
                    lableStyle="w-[12.5rem]"
                    checked={inf.refdFeeTypCd === "01"}
                    readonly={isSuccess}
                    onChange={(e) => {
                      if (!isSuccess) {
                        handleChange("refdFeeTypCd", e.currentTarget.id);
                        return;
                      }
                    }}
                  />
                  <span className="text-xs">
                    예약 일자별 판매금액에 취소수수료 부과 <br /> 일별판매금액 x
                    일별취소수수료율 (연박 시 각 일별취소수수료 합산)
                  </span>
                </span>

                <span className="w-full flex justify-start">
                  <Radio
                    id="02"
                    name="refdFeeTypCd"
                    label="PerBooking"
                    lableStyle="w-[12.5rem]"
                    checked={inf.refdFeeTypCd === "02"}
                    readonly={isSuccess}
                    onChange={(e) => {
                      if (!isSuccess) {
                        handleChange("refdFeeTypCd", e.currentTarget.id);
                        return;
                      }
                    }}
                  />
                  <span className="text-xs">
                    총판매금액에 취소수수료 부과
                    <br /> 총판매금엑 x 예약1일차취소수수료율 (연박 시 동일)
                  </span>
                </span>

                <span className="w-full flex justify-start">
                  <Radio
                    id="03"
                    name="refdFeeTypCd"
                    label="PerOneNight"
                    lableStyle="w-[12.5rem]"
                    checked={inf.refdFeeTypCd === "03"}
                    readonly={isSuccess}
                    onChange={(e) => {
                      if (!isSuccess) {
                        handleChange("refdFeeTypCd", e.currentTarget.id);
                        return;
                      }
                    }}
                  />
                  <span className="text-xs">
                    예약1일차판매금액에 취소수수료 부과
                    <br /> 예약1일차판매금액 x 예약1일차취소수수료율 (연박 시
                    동일)
                  </span>
                </span>
                <button
                  className="w-full italic underline text-right"
                  onClick={() => {
                    //   setIsOpen(true);
                  }}
                >
                  취소 수수료 부과 방식 예시
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <Modal
        title={"취소 수수료 부과 방식 예시"}
        isOpen={false}
        handleCloseModal={() => {
          //   setIsOpen(false);
        }}
        onClick={() => {}}
      >
        <div className="w-full m-4">
          <span className="text-left font-bold">
            기본 수수료 정책만 설정했을 경우
          </span>
          <span className="text-left text-sm">취소수수료 예시</span>
        </div>
      </Modal>
    </>
  );
};

export default InfCreateView;
