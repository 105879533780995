import { useMutation } from "@tanstack/react-query";
import { promCreateApi } from "components/api/promotion";
import PromCreateView from "components/view/promotion/create";
import { promProps } from "interface/promotion";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";
import { regEmail } from "util/common";

const PromCreatePage = () => {
  const dispatch = useDispatch();
  const [info, setInfo] = useState<promProps>({
    promCd: "",
    promNm: "",
    influNm: "",
    influEml: "",
    influCnty: "KOR",
    influLangCd: "kr",
    influMobCntyNo: "+82",
    influMobNo: "",
  });

  const handleChange = (name: string, value: string) => {
    setInfo({
      ...info,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name === "mobCntyCd") {
      setInfo({
        ...info,
        influMobCntyNo: value + "",
      });
    }
    if (name === "country") {
      setInfo({
        ...info,
        influCnty: value + "",
      });
    }
    if (name === "influLangCd") {
      setInfo({
        ...info,
        influLangCd: value ?? "",
      });
    }
  };
  const promMutation = useMutation(promCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "프로모션코드를 생성하였습니다. ",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                setInfo({
                  promCd: "",
                  promNm: "",
                  influNm: "",
                  influEml: "",
                  influCnty: "KOR",
                  influLangCd: "kr",
                  influMobCntyNo: "+82",
                  influMobNo: "",
                });
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCreate = () => {
    if (!info.promCd) {
      return alert("프로모션 코드를 입력하여 주세요.");
    }
    if (!info.promNm) {
      return alert("프로모션명을 입력하여 주세요.");
    }
    if (!info.influNm) {
      return alert("인플루언서명을 입력하여 주세요.");
    }
    if (!info.influEml || !regEmail.test(info.influEml)) {
      return alert("정확한 이메일을 입력하여주세요.");
    }

    dispatch(
      setMessage({
        message: "프로모션코드를 생성하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          promMutation.mutate(info);
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  return (
    <PromCreateView
      info={info}
      handleSelect={handleSelect}
      handleChange={handleChange}
      handleCreate={handleCreate}
    />
  );
};

export default PromCreatePage;
