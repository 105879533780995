import AdInfCreateView from "./adInfCreate";
import ManCreateView from "./manCreate";
import BkCreateView from "./payCreate";
import InfCreateView from "./infCreate";
import LodInfCreateView from "./lodCreate";
import LodImgView from "./imgCreate";
import { useRef } from "react";
import THeader from "components/layout/header/subHeader/tHeader";

const LodCreateView = () => {
  const lodNo = useRef("");

  return (
    <div className="main-cont" data-dir-col>
      <THeader sbTtl={"숙소생성"} clsBtn handleReset={() => {}} />
      <LodInfCreateView lodNo={lodNo} />
      <InfCreateView lodNo={lodNo} />
      <ManCreateView lodNo={lodNo} />
      <BkCreateView lodNo={lodNo} />
      <AdInfCreateView lodNo={lodNo} />
      <LodImgView lodNo={lodNo} />
    </div>
  );
};

export default LodCreateView;
