import { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import "../list.scss";
import { managerListHead } from "util/option";
import { useMutation } from "@tanstack/react-query";
import { ImanagerList, searchProps } from "interface/member";
import { setMemNo, setIsSearchMember } from "store/memberSlice";
import Pagenation from "components/pagenation";
import { managerListApi, modifyManagerApi } from "components/api/member";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  setIsSearch,
  isSearchSelector,
  setIsLoading,
  setMessage,
} from "store/commonSlice";
import { pageNavProps } from "interface";
import SearchHeader from "components/layout/header/subHeader/srchHeader";

type Iprops = {
  searchInfo: Partial<searchProps>;
};

type IListProps = {
  col: number;
  searchInfo: Partial<searchProps>;
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};
const List = ({ col, searchInfo, setPageNav }: IListProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search, state } = useLocation();
  const isSearch = useSelector(isSearchSelector);
  const [list, setList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const manMutation = useMutation(managerListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setPageNav(body?.pageNav);
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));
          setList(body?.manList);
        }
        if (code === -1) {
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });
  const statusMutation = useMutation(modifyManagerApi, {
    onSuccess(data) {
      if (data) {
        const { code, message } = data.header;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "신청을 수락하였습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                dispatch(setIsSearchMember(false));
                dispatch(setIsSearch(true));
                setIsOpen(false);
                dispatch(setMemNo(""));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModifyState = ({
    manFstnm,
    manSurnm,
    manNo,
    joiAprYn,
  }: {
    manFstnm: string;
    manSurnm: string;
    manNo: string;
    joiAprYn: "N" | "Y";
  }) => {
    dispatch(
      setMessage({
        message: `${manSurnm}${manFstnm}님의 신청을 수락하시겠습니까?`,
        isCancel: true,
        isConfirm: true,
        handleClick() {
          statusMutation.mutate({
            manNo,
            joiAprYn,
          });
        },
      })
    );
  };

  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch) {
      const params = {
        lodNo: searchInfo?.lodNo || undefined,
        mobCntyCd: searchInfo.mobCntyCd || undefined,
        mobNo: searchInfo.mobNo || undefined,
        emlId: searchInfo.emlId || undefined,
        soYn:
          searchInfo.memStatus === "R"
            ? "Y"
            : searchInfo.memStatus === "D"
            ? "N"
            : undefined,
        joiAprYn: searchInfo.memStatus === "D" ? "Y" : undefined,
        lckYn: searchInfo.memStatus === "S" ? "Y" : undefined,
      };

      console.log("params", params);

      manMutation.mutate(params);
    }
  }, [isSearch, searchInfo, search]);

  const pageRows = manMutation.data?.body?.pageNav?.pageRows;
  const isNotData = !pageRows;

  return (
    <>
      {list.length === 0 && (
        <tr>
          <td colSpan={col}>
            {isNotData && (
              <span className="font-bold">검색조건을 선택하여 주세요.</span>
            )}
            {!isNotData && (
              <span className="font-bold">검색결과가 없습니다.</span>
            )}
          </td>
        </tr>
      )}
      {list &&
        list?.map((li: ImanagerList, index: number) => {
          return (
            <tr
              key={li.manNo}
              className={li.soYn === "Y" ? "drop" : ""}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`info?manNo=${li.manNo}`, {
                  state: {
                    searchInfo,
                    url: pathname + search,
                    manNo: li.manNo,
                  },
                });
              }}
            >
              <td>{index + 1}</td>
              <td>{li.manNo}</td>
              <td>{li.lodGrpNm}</td>
              <td>{li.manFstnm}</td>
              <td>{li.manSurnm}</td>
              <td>{li.emlId}</td>
              <td>{li.mobNo}</td>
              <td>
                <span
                  className={
                    li.soYn === "Y"
                      ? "soYn"
                      : li.joiAprYn === "N"
                      ? "joiAprYn"
                      : ""
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    handleModifyState({
                      manFstnm: li.manFstnm,
                      manSurnm: li.manSurnm,
                      manNo: li.manNo,
                      joiAprYn: "Y",
                    });
                  }}
                >
                  {li.soYn === "Y"
                    ? "탈퇴"
                    : li.joiAprYn === "N"
                    ? "신청"
                    : "정상"}
                </span>
              </td>
            </tr>
          );
        })}
    </>
  );
};

const ManagerList = ({ searchInfo }: Iprops) => {
  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const headList = managerListHead.map((head) => {
    return head;
  });

  const col = managerListHead.length;

  return (
    <div className="list-wrap">
      <SearchHeader pageNav={pageNav} />
      <div>
        <table>
          <thead>
            <tr>
              {headList.map((li) => (
                <th key={li}>{li}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            <Suspense>
              <List searchInfo={searchInfo} setPageNav={setPageNav} col={col} />
            </Suspense>
            {pageNav && pageNav?.totalRows > 0 && (
              <tr>
                <td colSpan={col}>
                  <Pagenation total={pageNav?.totalPage} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManagerList;

{
  /* <AgGrid
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={true}
          isLoading={isLoading}
        /> */
}
