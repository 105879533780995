import THeader from "components/layout/header/subHeader/tHeader";
import { Suspense, useState } from "react";
import Select from "components/select";
import { authOptions } from "util/option";
import { useLocation } from "react-router-dom";
import AuthInfo from "./info";
import { useDispatch } from "react-redux";
import { setIsSearch } from "store/commonSlice";

type authProps = {
  lodGrp: string;
  lod: string;
  lodDflt: string;
  lodPrd: string;
  lodRfd: string;
  lodChl: string;
  rm: string;
  rmOp: string;
};

const AuthSettView = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [searchInfo, setSearchInfo] = useState({
    authGrp: "",
  });

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { value, name } = target.dataset;
    if (value && name) {
      setSearchInfo({
        ...searchInfo,
        [name]: value,
      });

      // 관리자 권한 선택시 이미 설정한 권한
    }
  };

  const handleSearch = () => {
    dispatch(setIsSearch(true));
  };

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader
          sbTtl={"권한조회"}
          first={true}
          newBtn
          url={pathname}
          handleReset={() => {}}
        >
          <button
            className="btn-sm bg-indigo-600 text-white-0"
            onClick={handleSearch}
          >
            조회
          </button>
        </THeader>
        <div className="tb-form">
          <ul className="pb-[6px]">
            <li>
              <label htmlFor="">관리자권한</label>
              <Select
                id={"authGrp"}
                name={"authGrp"}
                option={searchInfo.authGrp}
                options={authOptions}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="">관리자명</label>
              <input type="text" name="admNm" value={""} onChange={(e) => {}} />
            </li>
            <li>
              <label htmlFor="">매니저명</label>
              <input type="text" name="manNm" value={""} onChange={(e) => {}} />
            </li>
          </ul>
        </div>

        <Suspense>
          <AuthInfo searchInfo={searchInfo} />
        </Suspense>
      </div>
      {/* <AuthList searchInfo={searchInfo} /> */}
    </>
  );
};

export default AuthSettView;
