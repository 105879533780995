import THeader from "components/layout/header/subHeader/tHeader";
import Select from "components/select";
import LodGrdSelect from "components/select/room/lodGrdSelect";
import LodGrpNmSelect from "components/select/room/lodGrpNmSelect ";
import LodGrpNoSelect from "components/select/room/lodGrpNoSelect";
import LodNmSelect from "components/select/room/lodNmSelect";
import LodNoSelect from "components/select/room/lodNoSelect";
import SearchSelect from "components/select/searchSelect";
import LodPrdList from "components/tableList/lod/prd";
import { lodPrdSearchProps } from "interface/lod";
import { useLocation } from "react-router-dom";
import { lodGrpStatus } from "util/option";

type IProps = {
  memType: "admin" | "manager";
  searchInfo: lodPrdSearchProps;
  handleChange(name: string, value: string): void;
  handleSearch(): void;
  handleReset(): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
};

const LodPrdView = ({
  searchInfo,
  memType,
  handleChange,
  handleSelect,
  handleReset,
  handleSearch,
}: IProps) => {
  const { pathname } = useLocation();

  const searchOp = [
    {
      kr: "숙소그룹명",
      value: "lodGrpNm",
      en: "LodGrpName",
    },
    {
      kr: "숙소명",
      value: "lodNm",
      en: "LodName",
    },
  ];

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader
          sbTtl={"숙소제공 정보"}
          rstBtn
          url={pathname}
          searchBtn
          handleReset={handleReset}
        />

        <div className="tb-form">
          <ul>
            <li>
              <label htmlFor="">숙소그룹명</label>
              <LodGrpNmSelect
                option={searchInfo?.lodGrpNo}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="">숙소명</label>
              <LodNmSelect
                option={searchInfo?.lodNo}
                params={searchInfo?.lodGrpNo}
                disabled={!searchInfo.lodGrpNo}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="">숙소등급</label>
              <LodGrdSelect
                option={searchInfo.lodGrdCd}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">숙소등급</label>
              <Select
                id=""
                name=""
                options={lodGrpStatus}
                onSelect={() => {}}
              />
            </li>
            <li>
              <label htmlFor="">검색조건</label>
              <SearchSelect
                id={"searchTyp"}
                name={searchInfo.searchTyp ?? ""}
                options={searchOp}
                option={searchInfo.searchTyp || "A"}
                value={
                  searchInfo[
                    `${searchInfo.searchTyp}` as keyof lodPrdSearchProps
                  ] || ""
                }
                onSelect={handleSelect}
                onChange={(target) => handleChange(target.name, target.value)}
              />
            </li>
          </ul>
        </div>
      </div>
      <LodPrdList memType={memType} searchInfo={searchInfo} />
    </>
  );
};

export default LodPrdView;
