import ITHeader from "components/layout/header/subHeader/itemHeader";
import THeader from "components/layout/header/subHeader/tHeader";
import Select from "components/select";
import CountrySelect from "components/select/country";
import PhoneSelect from "components/select/phone";
import { promProps } from "interface/promotion";
import { useState } from "react";
import { langOptions } from "util/option";

type IProps = {
  isLoading: boolean;
  info: Omit<promProps, "promCd" | "promNm">;
  promInf: promProps;
  handleChange(name: string, value: string): void;
  handleModify(): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
};

const PromModifyView = ({
  info,
  isLoading,
  promInf,
  handleChange,
  handleModify,
  handleSelect,
}: IProps) => {
  const [infOpen, setInfOpen] = useState(true);

  return (
    <div className="main-cont" data-dir-col>
      <THeader sbTtl={"프로모션정보 수정"} clsBtn handleReset={() => {}} />
      <div className="tb-form">
        <ITHeader
          sbTtl={"기본정보"}
          clsBtn
          mdfyBtn
          isClose={infOpen}
          handleModify={handleModify}
          handleClose={() => {
            setInfOpen(!infOpen);
          }}
        />
        <div className={infOpen ? "" : "hidden"}>
          <ul>
            <li>
              <label htmlFor="">프로모션코드</label>
              <input type="text" defaultValue={promInf?.promCd} readOnly />
            </li>
            <li>
              <label htmlFor="">프로모션명</label>
              <input type="text" defaultValue={promInf?.promNm} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">최초생성일</label>
              <input
                type="text"
                defaultValue={promInf?.fstCrtDtti?.split(" ")[0]}
                readOnly
              />
            </li>
            <li>
              <label htmlFor="">최초생성사용자</label>
              <input type="text" defaultValue={promInf?.fstCrtUsid} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">최초수정일</label>
              <input
                type="text"
                defaultValue={promInf?.ltChDtti?.split(" ")[0]}
                readOnly
              />
            </li>
            <li>
              <label htmlFor="">최초수정사용자</label>
              <input type="text" defaultValue={promInf?.ltChUsid} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">인플루언서명</label>
              <input
                type="text"
                value={info.influNm || (promInf?.influNm ?? "")}
                onChange={(e) => handleChange("", e.currentTarget.value)}
              />
            </li>
            <li>
              <label htmlFor="">인플루언서 이메일</label>
              <input
                type="text"
                value={info?.influEml || promInf?.influEml}
                onChange={(e) => handleChange("", e.currentTarget.value)}
              />
            </li>
            <li>
              <label htmlFor="">인플루언서 국가</label>
              <CountrySelect handleSelect={handleSelect} />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">언어코드</label>
              <Select
                id={"influLangCd"}
                name={"influLangCd"}
                option={info.influLangCd || (promInf?.influLangCd ?? "")}
                options={langOptions}
                onSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="phone">휴대폰번호</label>
              <div className="flex gap-x-1 ">
                <PhoneSelect
                  mobCd={info.influMobCntyNo || (promInf?.influMobCntyNo ?? "")}
                  isNm
                  handleSelect={(target) => handleSelect(target)}
                />
                <input
                  type="text"
                  value={info.influMobNo || (promInf?.influMobNo ?? "")}
                  onChange={(e) =>
                    handleChange("influMobNo", e.currentTarget.value)
                  }
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PromModifyView;
