import DefaultModal from "../default";
import { ReactNode } from "react";
import "../modal.scss";
import { loadingBtnSelector } from "store/commonSlice";
import LoadingButton from "components/button/loadingButton";
import { useSelector } from "react-redux";

type IProps = {
  title: string | undefined;
  canclBntText?: string;
  cnfrmBtnText?: string;
  isConfirm?: boolean;
  isCancel?: boolean;
  isOpen: boolean;
  handleCloseModal(): void;
  handleClick?(): void;
  handleCancel?(): void;
  handleKeyDown?(e: React.KeyboardEvent<HTMLInputElement>): void;
  children: ReactNode;
};

const NotiModal = ({
  title,
  canclBntText = "취소",
  cnfrmBtnText = "확인",
  isCancel = false,
  isConfirm = false,
  isOpen,
  handleCloseModal,
  handleClick,
  handleCancel,
  children,
}: IProps) => {
  const { isLoading, disabled } = useSelector(loadingBtnSelector);

  return (
    <DefaultModal
      isOpen={isOpen}
      styles="max-w-[31.25rem]"
      isbckgrd={false}
      handleCloseModal={handleCloseModal}
    >
      <div className="title">
        <h4 className="text-base">{title}</h4>
        <button onClick={handleCloseModal} />
      </div>
      <div className="">{children}</div>
      <div className="flex justify-center mt-2 gap-5">
        <LoadingButton
          txt={cnfrmBtnText}
          styles="btn-md"
          isLoading={isLoading}
          disabled={disabled}
          onClick={!isConfirm ? handleCloseModal : handleClick}
        />

        <button
          className={isCancel ? "btn-md gray" : "hidden"}
          onClick={handleCancel ? handleCancel : handleCloseModal}
        >
          {canclBntText}
        </button>
      </div>
    </DefaultModal>
  );
};

export default NotiModal;
