import { Editor } from "@toast-ui/react-editor";
import "@toast-ui/editor/toastui-editor.css";
import { Fragment, useEffect, useRef, useState } from "react";
import "./index.scss";
import i18n from "locales/i18n";
import { lngLabel } from "util/option";

type Props = {
  name: string;
  info: any;
  content?: string;
  //   onChange(value: string): void;
};

type IOptions = { id: string; label: string };

const toolbar = [
  ["heading", "bold", "italic", "strike"],
  ["hr", "quote"],
  ["ul", "ol", "task", "indent", "outdent"],
  ["table", "image", "link"],
  ["code", "codeblock"],
  ["scrollSync"],
];

const RedTuiEditor = ({ name, info, content }: Props) => {
  const editorRef = useRef<Editor>(null);
  const [lng, setLng] = useState(i18n.language);

  const options = [] as IOptions[];

  lngLabel.forEach((lng) => {
    const labels = info[`${name}_${lng.id}`];
    if (!!labels) {
      options.push(lng);
    }
  });

  useEffect(() => {
    if (editorRef.current) {
      const html = info[`${name}_${info.langCd}`] ?? "<p></p>";
      editorRef.current?.getInstance().setHTML(html);
    }
  }, [info]);

  useEffect(() => {
    if (editorRef.current) {
      const element = editorRef.current.getRootElement() as HTMLDivElement;
      element.style.pointerEvents = "none";
      element.style.cursor = "no-drop";
    }
  }, []);

  return (
    <div className="radio-editor">
      {options.map((op) => {
        return (
          <Fragment key={op.id}>
            <input
              type="radio"
              id={name + "_" + op.id}
              name={name}
              value={op.id}
              onChange={(e) => {
                const value = e.currentTarget.value;
                setLng(value ?? "");
              }}
              defaultChecked={lng === op.id}
            />
            <label htmlFor={name + "_" + op.id} data-label={op.label}></label>
          </Fragment>
        );
      })}
      <Editor
        initialValue={content ?? " "}
        initialEditType="wysiwyg"
        autofocus={false}
        ref={editorRef}
        toolbarItems={toolbar}
        hideModeSwitch
        language="ko"
        minHeight="44px"
        height="auto"

        // onBlur={() => {
        //   const data = editorRef.current?.getInstance().getHTML();
        //   onChange(data ?? "");
        // }}
        //   hooks={{ addImageBlobHook: imageHandler }}
      />
    </div>
  );
};

export default RedTuiEditor;
