import { IChkInfo } from "interface/home";
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import { homeChkInfoHead } from "util/option";
import Loading from "../loading";
import { useMutation } from "@tanstack/react-query";
import { homeManagerDayChkInfoApi } from "components/api/home";
import { useDispatch } from "react-redux";
import { setIsLoading, setMessage } from "store/commonSlice";
import Modal from "components/modal/modal";
import RmDtlInfoView from "./rmDtlInfo";

type IProps = {
  lodNo: string;
  list: IChkInfo[];
};

type IListProps = {
  list: IChkInfo[];
  setInfo: Dispatch<
    SetStateAction<{
      rmPdNo: string;
      isOpen: boolean;
    }>
  >;
};

const List = ({ list, setInfo }: IListProps) => {
  return (
    <>
      {list.map((li: IChkInfo, index: number) => (
        <tr
          key={index}
          className="cursor-pointer"
          onClick={() => {
            setInfo({
              isOpen: true,
              rmPdNo: li.rmPdNo,
            });
          }}
        >
          <td>
            {li?.resvCstSurnm}
            {li?.resvCstFstnm}
          </td>
          <td>{li?.resvCstMblNo}</td>
          <td>{li?.rmNm}</td>
          <td>
            {li?.resvSDt}/{li?.resvEDt}
          </td>
          <td>{li?.resvDayCt}박</td>
        </tr>
      ))}
    </>
  );
};

const ChkInList = ({ list, lodNo }: IProps) => {
  const dispatch = useDispatch();
  const now = new Date();
  const [isSuccess, setIsSuccess] = useState(false);

  const [chkList, setChkList] = useState<IChkInfo[] | null>(null);
  const [info, setInfo] = useState({
    rmPdNo: "", //240426000601
    isOpen: false,
  });

  const chkInMutation = useMutation(homeManagerDayChkInfoApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        setIsSuccess(true);
        if (code === 0) {
          setChkList(body?.dayChkInList);
          dispatch(setIsLoading(false));
        }
        if (code === -1) {
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });
  const handleReload = () => {
    dispatch(setIsLoading(true));
    const date = now.toISOString()?.split("T")[0]?.replaceAll("-", "");
    const params = {
      lodNo,
      date,
    };

    chkInMutation.mutate(params);
    setIsSuccess(false);
  };

  useEffect(() => {
    setIsSuccess(true);
  }, []);

  return (
    <>
      <div className="flex justify-between">
        <h4>오늘 예약 고객</h4>
        <button disabled={chkInMutation.isLoading} onClick={handleReload}>
          더보기
        </button>
      </div>
      <table>
        <thead>
          <tr>
            {homeChkInfoHead.map((cst) => {
              return <th key={cst}>{cst}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={5}>
              <span className="font-bold">검색결과가 없습니다.</span>
            </td>
          </tr>
          <Suspense fallback={<Loading type="middle" />}>
            <List list={list || chkList} setInfo={setInfo} />
          </Suspense>
        </tbody>
      </table>
      <Modal
        title={"예약상세"}
        isOpen={info.isOpen}
        btnText=""
        styles="max-w-[100rem] w-[90%] overflow-hidden"
        handleCloseModal={() => {
          setInfo({
            rmPdNo: "",
            isOpen: false,
          });
        }}
        onClick={() => {
          setInfo({
            rmPdNo: "",
            isOpen: false,
          });
        }}
      >
        <div className="overflow-auto max-h-[50rem]">
          <RmDtlInfoView rmPdNo={info.rmPdNo} />
        </div>
      </Modal>
    </>
  );
};

export default ChkInList;
