import { useMutation, useQuery } from "@tanstack/react-query";
import { rmInfoApi } from "components/api/room";
import RoomModifyView from "components/view/room/rm/modify/index";
import i18n from "locales/i18n";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";

const RoomModifyPage = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const rmNo = search.replace("?rmNo=", "");
  const params = {
    rmNo: rmNo ?? "",
    langCd: i18n.language,
    imgTypCd: "I11",
  };

  const infMutation = useQuery({
    queryKey: ["rmInf", rmNo],
    queryFn: () => {
      return rmInfoApi(params);
    },
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
        }

        if (code === -1) {
          dispatch(
            setMessage({
              message: message || "검색 데이터가 없습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  let rmDtl = infMutation?.data?.body?.rmDtl || {};
  let rmImgList = infMutation?.data?.body?.rmImgList || ([] as any[]);
  let rmAddInfList = infMutation?.data?.body?.addInfList || [];

  return (
    <RoomModifyView
      rmDtl={rmDtl}
      rmImgList={rmImgList}
      rmAddInfList={rmAddInfList}
    />
  );
};

export default RoomModifyPage;
