import { useMutation, useQuery } from "@tanstack/react-query";
import {
  rmInfoApi,
  rmOpInfoApi,
  rmProductCreateApi,
} from "components/api/room";
import RmPdCreateView from "components/view/room/prd/create";
import { rmPrdCreateProps } from "interface/room";
import i18n from "locales/i18n";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setIsSearch, setMessage } from "store/commonSlice";
import { afterDay } from "util/common";

const RmPdCreatePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const memType = pathname.includes("admin") ? "admin" : "manager";

  let lodGrpInfo =
    memType === "manager"
      ? JSON.parse(localStorage.getItem("lodGrpInfo") + "")
      : "";

  const { sDt, eDt } = afterDay(92);

  const [info, setInfo] = useState<rmPrdCreateProps>({
    lodGrpNo: lodGrpInfo?.lodGrpNo || "",
    lodGrpNm: lodGrpInfo?.lodGrpNm || "",
    lodNo: localStorage.getItem("sltLodNo") ?? "",
    lodNm: lodGrpInfo?.lodNm,
    lodChlCd: "",
    rmNo: "",
    rmOpSeq: null,
    rmPdSDt: sDt ?? "",
    rmPdEDt: eDt ?? "",
    rmPdAplyDow: "YYYYYYY",
    rmPdCt: "",
    rmPdSellPri: "",
  });

  const handleChange = (name: string, value: string) => {
    setInfo({
      ...info,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, lng, value } = target.dataset;

    if (name && value) {
      setInfo({
        ...info,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const rmPrdMutation = useMutation(rmProductCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "객실상품을 생성하였습니다.",
              isConfirm: true,
              isCancel: true,
              cnfrmBtnText: "뒤로가기",
              canclBntText: "제품추가",
              handleCancel() {
                dispatch(setMessage({ message: null }));
                setInfo({
                  lodGrpNo: lodGrpInfo?.lodGrpNo || "",
                  lodGrpNm: lodGrpInfo?.lodGrpNm || "",
                  lodNo: localStorage.getItem("sltLodNo") ?? "",
                  lodNm: lodGrpInfo?.lodNm,
                  lodChlCd: "",
                  rmNo: "",
                  rmOpSeq: null,
                  rmPdSDt: sDt ?? "",
                  rmPdEDt: eDt ?? "",
                  rmPdAplyDow: "YYYYYYY",
                  rmPdCt: "",
                  rmPdSellPri: "",
                });
              },
              handleClick() {
                navigate(-1);
                dispatch(setMessage({ message: null }));
                dispatch(setIsSearch(true));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCreate = () => {
    const { lodGrpNo, rmOpSeq, rmPdCt, rmPdSDt, rmPdEDt, rmPdSellPri, ...res } =
      info;
    if (!info.rmNo) {
      return alert("객실명을 선택하여주세요.");
    }
    if (!info.rmOpSeq) {
      return alert("객실옵션을 선택하여 주세요.");
    }
    if (!info.lodChlCd) {
      return alert("객실상품채널을 선택하여 주세요.");
    }

    if (!info.rmPdCt) {
      return alert("객실상품수량을 입력하여 주세요.");
    }

    if (!info.rmPdSellPri) {
      return alert("상품금액을 입력하여 주세요.");
    }

    const params = {
      ...res,
      rmPdCt: Number(rmPdCt),
      rmOpSeq: Number(rmOpSeq),
      rmPdSellPri: rmPdSellPri.replaceAll(",", ""),
      rmPdSDt: rmPdSDt.replaceAll("-", ""),
      rmPdEDt: rmPdEDt.replaceAll("-", ""),
    };

    dispatch(
      setMessage({
        message: "객실상품을 생성하시겠습니까?",
        isConfirm: true,
        isCancel: true,
        handleClick() {
          rmPrdMutation.mutate(params);
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  const { data: rmInfquery } = useQuery({
    queryKey: ["rmInf", info.rmNo],
    queryFn: () => {
      return rmInfoApi({
        rmNo: info.rmNo,
        langCd: i18n.language,
        imgTypCd: "I11",
      });
    },
    enabled: !!info.rmNo,
  });

  const { data: rmOpInfquery } = useQuery({
    queryKey: ["rmOpInf", info.rmOpSeq],
    queryFn: () => {
      return rmOpInfoApi({
        rmNo: info.rmNo,
        opRgSeq: info.rmOpSeq + "",
        langCd: i18n.language,
      });
    },
    enabled: !!info.rmOpSeq,
  });

  let rmInf = {
    rmTypNm: rmInfquery?.body?.rmDtl?.rmTypNm,
    rmOlkNm: rmInfquery?.body?.rmDtl?.rmOlkNm,
  };

  let rmOpInf = {
    brkfInYn: rmOpInfquery?.body?.rmOpDtl?.brkfInYn,
    latChkOutTi: rmOpInfquery?.body?.rmOpDtl?.latChkOutTi,
  };

  return (
    <>
      <RmPdCreateView
        info={info}
        rmInf={rmInf}
        rmOpInf={rmOpInf}
        handleChange={handleChange}
        handleSelect={handleSelect}
        handleCreate={handleCreate}
      />
    </>
  );
};

export default RmPdCreatePage;
