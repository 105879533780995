import { useQuery } from "@tanstack/react-query";
import { trmDtlApi } from "components/api/term";
import TrmDtlView from "components/view/terms/info";
import i18n from "locales/i18n";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";

const TrmDtlPage = () => {
  const dispatch = useDispatch();
  const { search, state } = useLocation();

  const { data } = useQuery({
    queryKey: ["trmInf"],
    queryFn: () => {
      return trmDtlApi({
        langCd: state?.langCd ?? i18n.language,
        prvCd: search.replace("?prvCd=", ""),
      });
    },
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
        }
        if (code === -1) {
          dispatch(
            setMessage({
              message: message || "검색 데이터가 없습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  return <TrmDtlView trmDtl={data?.body?.prvDtl} />;
};

export default TrmDtlPage;
