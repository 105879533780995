import { ImemInf } from "interface/member";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { langOptions } from "util/option";
import THeader from "components/layout/header/subHeader/tHeader";
import Select from "components/select";
import PhoneSelect from "components/select/phone";
import { langProps } from "interface";
import i18n from "locales/i18n";
type IProps = {
  info: {
    mobNo: string | null;
    mobCntyCd: string;
    joiAprYn: string | undefined;
  };
  memInfo: ImemInf;
  // isLoading: boolean;
  handleChange(name: string, value: string): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleModify(): void;
};

const MemInfoModifyView = ({
  info,
  memInfo,
  handleChange,
  handleSelect,
  handleModify,
}: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search, state } = useLocation();

  const authName =
    memInfo?.authGrpNm === "OTAOWENER01"
      ? "소유자"
      : memInfo?.authGrpNm === "OTAMAN01"
      ? "관리자"
      : memInfo?.authGrpNm === "OTADEV01"
      ? "개발자"
      : "리포터";

  const typeNm = search.includes("manNo")
    ? "매니저"
    : search.includes("admNo")
    ? "관리자"
    : search.includes("memNo")
    ? "사용자"
    : "";

  const name = memInfo.surnm + memInfo.fstnm;
  const langCd = langOptions.find((lng) => {
    return lng.value === memInfo.langCd;
  });

  const memStatusOp = [
    { kr: "정상", value: "D", en: "Normal" },
    { kr: "탈퇴", value: "S", en: "Drop" },
  ];

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader
          sbTtl={`${typeNm}수정`}
          // mdfyBtn
          first
          clsBtn
          isBack
          handleReset={() => {}}
        >
          <button
            className="btn-sm bg-red-400 text-white-0"
            onClick={handleModify}
          >
            수정
          </button>
        </THeader>

        <div className="tb-form">
          <ul>
            <li>
              <label htmlFor="">회원번호</label>
              <input type="text" defaultValue={memInfo.memNo} readOnly />
            </li>
            <li>
              <label htmlFor="">회원 영문이름</label>
              <input type="text" defaultValue={memInfo.fstnm} readOnly />
            </li>
            <li>
              <label htmlFor="">회원 영문성</label>
              <input type="text" defaultValue={memInfo.surnm} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">회원소셜연동</label>
              <input type="text" defaultValue={""} readOnly />
            </li>
            <li>
              <label htmlFor="">회원이메일</label>
              <input type="text" defaultValue={memInfo.emlId} readOnly />
            </li>
            <li>
              <label htmlFor="">회원사용자명</label>
              <input type="text" defaultValue={name} readOnly />
            </li>
          </ul>

          <ul>
            {search.includes("admNo") && (
              <li>
                <label htmlFor="">회원권한</label>
                <input type="text" defaultValue={memInfo.authGrpNm} readOnly />
              </li>
            )}
            <li>
              <label htmlFor="">회원언어</label>
              <input
                type="text"
                defaultValue={
                  langCd ? langCd[i18n.language as keyof langProps] : ""
                }
                readOnly
              />
            </li>
            <li>
              <label htmlFor="">회원통화</label>
              <input type="text" defaultValue={memInfo.currCd} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">회원가입일자</label>
              <input type="text" defaultValue={memInfo.joiDtti} readOnly />
            </li>
            <li>
              <label htmlFor="">회원탈퇴일자</label>
              <input type="text" defaultValue={memInfo.lckDtti} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">회원국가번호</label>
              <PhoneSelect
                mobCd={info.mobCntyCd || memInfo.mobCntyCd}
                handleSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="">회원전화번호</label>
              <input
                type="text"
                defaultValue={info.mobNo || memInfo.mobNo}
                onChange={(e) => handleChange("mobNo", e.currentTarget.value)}
              />
            </li>
            <li>
              <label htmlFor="">회원상태</label>
              {/* <input type="text" defaultValue={} /> */}
              <Select
                id={"joiAprYn"}
                name={"joiAprYn"}
                option={
                  info.joiAprYn ||
                  (memInfo.soYn === "Y"
                    ? "S"
                    : memInfo.joiAprYn === "Y"
                    ? "D"
                    : "")
                }
                isInitial
                options={memStatusOp}
                onSelect={handleSelect}
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default MemInfoModifyView;
