import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { lodDftDtlApi, lodModifyApi } from "components/api/lod";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import Select from "components/select";
import CountrySelect from "components/select/country";
import PhoneSelect from "components/select/phone";
import { lodProps } from "interface/lod";
import i18n from "locales/i18n";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { regNum } from "util/common";
import { lodGrdCdOptions, lodTypOptions, uYnOptions } from "util/option";

type IProps = {
  lodDtl: lodProps & {
    lodNo: string;
  };
};

const LodInfModifyView = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ["lodDftDtl"],
    queryFn: () => {
      return lodDftDtlApi({
        lodNo: search.replace("?lodNo=", ""),
        langCd: i18n.language,
      });
    },
  });

  let lodDtl = query?.data?.body?.lodDftDtl || {};

  const [inf, setInf] = useState<lodProps>({
    lodGrdCd: "",
    lodCntyCd: "",
    lodTypCd: "",
    telCntyCd: "",
    telNo: "",
    lodEml: "",
    faxCntyCd: "",
    faxNo: "",
    hpUrl: "",
    lodPosUtc: "",
    rmCnt: "",
    resvPsbYn: "",
    resvRsvEml: "",
    bizNo: "",
    mailOrdrBizNo: "",
    ceoNm: "",
    bizNm: "",
  });

  const handleChange = (name: string, value: string) => {
    setInf({
      ...inf,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, id, value, lng } = target.dataset;
    if (name && value) {
      setInf({
        ...inf,
        [name]: value,
      });
    }
  };

  const lodMutation = useMutation(lodModifyApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "숙소기본정보를 수정하였습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                queryClient.invalidateQueries(["lodDftDtl"]);
                setInf({
                  lodGrpNo: "",
                  lodGrpNm: "",
                  lodGrdCd: "",
                  lodCntyCd: "",
                  lodTypCd: "",
                  telCntyCd: "",
                  telNo: "",
                  lodEml: "",
                  faxCntyCd: "",
                  faxNo: "",
                  hpUrl: "",
                  lodPosUtc: "",
                  rmCnt: "",
                  resvPsbYn: "",
                  resvRsvEml: "",
                  bizNo: "",
                  mailOrdrBizNo: "",
                  ceoNm: "",
                  bizNm: "",
                });
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModify = () => {
    const isAble = Object.values(inf).some((able) => {
      return !!able;
    });

    if (!isAble) {
      return alert("기본정보를 수정하여 주세요.");
    }

    const params = {
      ...inf,
      lodNo: search.replace("?lodNo=", ""),
      rmCnt: Number(inf.rmCnt),
    };

    dispatch(
      setMessage({
        message: "숙소기본정보를 수정하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          lodMutation.mutate(params);
          dispatch(setMessage({ message: null }));
          // dispatch(setLod)
        },
      })
    );
  };

  const isAble = !!(
    inf.lodGrpNo &&
    inf.lodCntyCd &&
    inf.lodGrdCd &&
    inf.telCntyCd &&
    inf.telNo &&
    inf.lodEml &&
    inf.hpUrl &&
    inf.rmCnt
  );

  const [infOpen, setInfOpen] = useState(true);

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"기본정보"}
          clsBtn
          mdfyBtn
          isClose={infOpen}
          handleModify={handleModify}
          handleClose={() => {
            setInfOpen(!infOpen);
          }}
        />
        <div className={infOpen ? "" : "hidden"}>
          <ul>
            <li>
              <label htmlFor="">숙소그룹명</label>
              <input type="text" defaultValue={lodDtl?.lodGrpNm} readOnly />
            </li>
            <li>
              <label htmlFor="">숙소국가</label>
              <CountrySelect
                option={lodDtl?.lodCntyCd}
                disabled
                handleSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="">숙소등급</label>
              <Select
                id="lodGrdCd"
                name="lodGrdCd"
                option={inf.lodGrdCd || lodDtl?.lodGrdCd}
                options={lodGrdCdOptions}
                onSelect={handleSelect}
              />
            </li>
          </ul>

          <ul>
            <li>
              <label htmlFor="">숙소이메일</label>
              <input
                type="text"
                defaultValue={inf.lodEml || lodDtl?.lodEml}
                onChange={(e) => handleChange("lodEml", e.currentTarget.value)}
              />
            </li>
            <li>
              <label htmlFor="">숙소홈페이지</label>
              <input
                type="text"
                name="hpUrl"
                defaultValue={inf.hpUrl || lodDtl?.hpUrl}
                onChange={(e) => handleChange("hpUrl", e.currentTarget.value)}
              />
            </li>
            <li>
              <label htmlFor="">숙소UTC</label>
              <input
                type="text"
                name="utc"
                defaultValue={inf.lodPosUtc || lodDtl?.lodPosUtc}
                onChange={(e) => handleChange("utc", e.currentTarget.value)}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">숙소총객실수량</label>
              <input
                type="text"
                name="rmCnt"
                defaultValue={inf.rmCnt || lodDtl?.rmCnt}
                onChange={(e) => {
                  const value = e.target.value;
                  if (regNum.test(value)) {
                    handleChange("rmCnt", e.currentTarget.value);
                  } else {
                    return;
                  }
                }}
              />
            </li>
            <li>
              <label htmlFor="">숙소유형</label>
              <Select
                id={"lodTypCd"}
                name={"lodTypCd"}
                option={inf.lodTypCd || lodDtl?.lodTypCd}
                options={lodTypOptions}
                onSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="">예약가능</label>
              <Select
                id={"resvPsbYn"}
                name={"resvPsbYn"}
                option={inf.resvPsbYn || lodDtl?.resvPsbYn}
                options={uYnOptions}
                onSelect={handleSelect}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="phone">숙소전화번호</label>
              <div className="flex gap-x-1 relative">
                <PhoneSelect
                  wrapStyle=""
                  name="telCntyCd"
                  isNm
                  mobCd={inf.telCntyCd || lodDtl?.telCntyCd}
                  handleSelect={handleSelect}
                />
                <input
                  type="text"
                  className=""
                  defaultValue={inf.telNo || lodDtl?.telNo}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    if (!regNum.test(value)) {
                      return;
                    }
                    handleChange("telNo", e.currentTarget.value);
                  }}
                />
              </div>
            </li>
            <li>
              <label htmlFor="phone">숙소팩스번호</label>
              <div className="flex gap-x-2 relative">
                <PhoneSelect
                  wrapStyle=""
                  name="faxCntyCd"
                  isNm
                  mobCd={inf.faxCntyCd || lodDtl?.faxCntyCd}
                  handleSelect={handleSelect}
                />
                <input
                  type="text"
                  className=""
                  defaultValue={inf.faxNo || lodDtl?.faxNo}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    if (!regNum.test(value)) {
                      return;
                    }
                    handleChange("faxNo", e.currentTarget.value);
                  }}
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default LodInfModifyView;
