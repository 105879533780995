import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { trmDtlApi, termModifyApi } from "components/api/term";
import TrmModifyView from "components/view/terms/modify";
import { prvProps } from "interface/term";
import i18n from "locales/i18n";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";

const TermModifyPage = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const langCd = i18n.language;
  const { search, state } = useLocation();

  const { data } = useQuery({
    queryKey: ["trmInf"],
    queryFn: () => {
      return trmDtlApi({
        langCd: state?.langCd ?? i18n.language,
        prvCd: search.replace("?prvCd=", ""),
      });
    },
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
        }
        if (code === -1) {
          dispatch(
            setMessage({
              message: message || "검색 데이터가 없습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  const prvDtl = data?.body?.prvDtl;

  const [inf, setInf] = useState<prvProps>({
    // prvCd: state?.prvCd,
    langCd: langCd,
    prvNm: "",
    prvDesc: "",
    essYn: "",
    aplyYn: "",
    aplySDt: "",
    aplyEDt: "",
    trmCn: "",
    rgSeq: "",
    verNm: "",
    uyn: "",
  });

  const handleChange = (name: string, value: string) => {
    setInf({
      ...inf,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, lng, value } = target.dataset;
    if (name && value) {
      setInf({
        ...inf,
        [name]: value,
      });
    }
  };

  const termMutation = useMutation(termModifyApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "성공적으로 약관을 수정하였습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                queryClient.invalidateQueries({ queryKey: ["termDtl"] });
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModify = () => {
    const params = {
      ...inf,
      rgSeq: prvDtl?.rgSeq,
      verNm: prvDtl?.verNm,
      prvCd: prvDtl?.prvCd,
    };

    console.log("params", params);

    dispatch(
      setMessage({
        message: "약관내용을 수정하시겠습니까?",
        isConfirm: true,
        isCancel: true,
        handleClick() {
          termMutation.mutate(params);
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  useEffect(() => {
    setInf({
      ...inf,
      trmCn: "",
    });
  }, []);

  return (
    <TrmModifyView
      inf={inf}
      prvDtl={prvDtl}
      handleChange={handleChange}
      handleModify={handleModify}
      handleSelect={handleSelect}
    />
  );
};

export default TermModifyPage;
