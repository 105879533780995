import { IPayInfo } from "interface/home";
import { Suspense, useState } from "react";
import { homePayInfoHead } from "util/option";
import Loading from "../loading";
import { useMutation } from "@tanstack/react-query";
import { homeManagerDayPayInfoApi } from "components/api/home";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";

type IProps = {
  lodNo: string;
  payInfo: IPayInfo;
};

type IListProps = {
  payInfo: IPayInfo;
  col: number;
};

const PayInfo = ({ payInfo, col }: IListProps) => {
  return (
    <>
      {!payInfo.resvDt && (
        <>
          <tr>
            <td colSpan={col}>검색결과가 없습니다.</td>
          </tr>
        </>
      )}
      {!!payInfo.resvDt && (
        <tr>
          <td>{payInfo?.resvDt}</td>
          <td>{payInfo?.resvCt}</td>
          <td>{payInfo?.resvPayAmt}</td>
          <td>{payInfo?.resvCnlAmt}</td>
          <td>{payInfo?.resvCalAmt}</td>
        </tr>
      )}
    </>
  );
};

const PayList = ({ payInfo, lodNo }: IProps) => {
  const dispatch = useDispatch();
  const now = new Date();

  const [info, setInfo] = useState<IPayInfo | null>(null);
  const payInfoMutation = useMutation(homeManagerDayPayInfoApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setInfo(body?.dayPayInfo);
        }
        if (code === -1) {
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  const handleReload = () => {
    const date = now.toISOString().split("T")[0].replaceAll("-", "");
    const params = {
      lodNo,
      date,
    };
    payInfoMutation.mutate(params);
  };
  return (
    <div className="pb-[1.25rem] max-w-[50rem]">
      <div className="flex justify-between">
        <h4>예약 현황</h4>
        <button onClick={handleReload}>더보기</button>
      </div>
      <table>
        <thead>
          <tr>
            {homePayInfoHead.map((bk) => (
              <th key={bk}>{bk}</th>
            ))}
          </tr>
        </thead>
        <Suspense fallback={<Loading type="middle" />}>
          <tbody>
            <PayInfo payInfo={info || payInfo} col={homePayInfoHead.length} />
          </tbody>
        </Suspense>
      </table>
    </div>
  );
};

export default PayList;
