import { useMutation } from "@tanstack/react-query";
import { rmHstDtlApi } from "components/api/room";
import RoomHstInfoView from "components/view/room/hst/info";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";

const RmHstInfoPage = () => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const memType = pathname.includes("manager") ? "manager" : "admin";
  const rmPdHistNo = search.replace("?rmPdHistNo=", "");

  const histMutation = useMutation(rmHstDtlApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;

        if (code === -1) {
          dispatch(
            setMessage({
              message: message || "검색 데이터가 없습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  useEffect(() => {
    histMutation.mutate({ rmPdHistNo });
  }, [search]);

  const histDtl = histMutation?.data?.body?.rmPdHistDtl || {};

  return <RoomHstInfoView histDtl={histDtl} memType={memType} />;
};

export default RmHstInfoPage;
