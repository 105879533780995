import { IopDft, IopList } from "interface/lod";
import { useState } from "react";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import THeader from "components/layout/header/subHeader/tHeader";

type IProps = {
  dtl: IopDft;
  lopList: Array<IopList>;
  etcList: Array<IopList>;
  ropList: Array<IopList>;
  lops: Array<IopList>;
  rops: Array<IopList>;
  etcs: Array<IopList>;
  lopInf: Array<string>;
  ropInf: Array<string>;
  etcInf: Array<string>;
  handleModify(): void;
  handleChange(target: EventTarget & HTMLInputElement): void;
};

const LodPrdModifyView = ({
  dtl,
  lopList,
  ropList,
  etcList,
  lops,
  rops,
  etcs,
  lopInf,
  ropInf,
  etcInf,
  handleChange,
  handleModify,
}: IProps) => {
  const [infOpen, setInfOpen] = useState(true);
  const [addOpen, setAddOpen] = useState(true);

  const lodLop = lops.map((op) => {
    return lopList?.some((lp) => lp.opSeq === op.opSeq)
      ? { ...op, uyn: "Y" }
      : { ...op, uyn: "N" };
  });

  const lodRop = rops.map((op) => {
    return ropList?.some((rp) => rp.opSeq === op.opSeq)
      ? { ...op, uyn: "Y" }
      : { ...op, uyn: "N" };
  });

  const lodEtc = etcs.map((op) => {
    return etcList?.some((etc) => etc.opSeq === op.opSeq)
      ? { ...op, uyn: "Y" }
      : { ...op, uyn: "N" };
  });

  return (
    <div className="main-cont">
      <div className="inf-form" data-dir-col>
        <THeader sbTtl={"숙소제공정보 수정"} clsBtn handleReset={() => {}} />
        <div className="tb-form">
          <ITHeader
            sbTtl={"기본정보"}
            clsBtn
            isClose={infOpen}
            handleClose={() => {
              setInfOpen(!infOpen);
            }}
          />
          <div className={infOpen ? "" : "hidden"}>
            <ul>
              <li>
                <label htmlFor="">숙소그룹명</label>
                <input type="text" defaultValue={dtl?.lodGrpNm} readOnly />
              </li>
              <li>
                <label htmlFor="">숙소등급</label>
                <input type="text" defaultValue={dtl?.lodGrdCd} readOnly />
              </li>
              <li>
                <label htmlFor="">숙소명</label>
                <input type="text" defaultValue={dtl?.lodNm} readOnly />
              </li>
            </ul>
          </div>
        </div>
        <div className="tb-form">
          <ITHeader
            sbTtl={"추가정보"}
            clsBtn
            mdfyBtn
            isClose={addOpen}
            handleModify={handleModify}
            handleClose={() => {
              setAddOpen(!addOpen);
            }}
          />

          <div className={addOpen ? "" : "hidden"}>
            <ul>
              <li data-full data-btm-brd>
                <label htmlFor="">공용시설</label>
                <div className="ops">
                  {lodLop.map((lop, index) => {
                    const name = lop.opSeq + "";

                    return (
                      <div key={lop.opSeq} className="radio-wrap">
                        <label htmlFor="">{lop.opNm}</label>
                        <span className="ms-4">
                          <input
                            type="radio"
                            name={name}
                            id={name + "_Y"}
                            data-opseq={name}
                            data-uyn="Y"
                            data-ctgcd={lop.opCtgCd}
                            checked={lopInf.includes(lop.opSeq + "")}
                            onChange={(e) => {
                              handleChange(e.target);
                            }}
                          />
                          <label htmlFor={name + "_Y"}>사용</label>
                        </span>
                        <span>
                          <input
                            type="radio"
                            name={name}
                            id={name + "_N"}
                            data-uyn="N"
                            data-opseq={name}
                            data-ctgcd={lop.opCtgCd}
                            checked={!lopInf.includes(lop.opSeq + "")}
                            onChange={(e) => {
                              handleChange(e.target);
                            }}
                          />
                          <label htmlFor={name + "_N"}>미사용</label>
                        </span>
                      </div>
                    );
                  })}
                </div>
              </li>
            </ul>
            <ul>
              <li data-full data-btm-brd>
                <label htmlFor="">객실시설</label>
                <div className="ops">
                  {lodRop.map((rop, index) => {
                    const name = rop.opSeq + "";
                    return (
                      <div key={rop.opSeq} className="radio-wrap">
                        <label htmlFor="">{rop.opNm}</label>
                        <span className="ms-4">
                          <input
                            type="radio"
                            name={name}
                            id={name + "_Y"}
                            data-uyn="Y"
                            data-opseq={name}
                            data-ctgcd={rop.opCtgCd}
                            checked={ropInf.includes(rop.opSeq + "")}
                            onChange={(e) => handleChange(e.target)}
                          />
                          <label htmlFor={name + "_Y"}>사용</label>
                        </span>
                        <span>
                          <input
                            type="radio"
                            name={name}
                            id={name + "_N"}
                            data-uyn="N"
                            data-opseq={name}
                            data-ctgcd={rop.opCtgCd}
                            checked={!ropInf.includes(rop.opSeq + "")}
                            onChange={(e) => handleChange(e.target)}
                          />
                          <label htmlFor={name + "_N"}>미사용</label>
                        </span>
                      </div>
                    );
                  })}
                </div>
              </li>
            </ul>
            <ul>
              <li data-full>
                <label htmlFor="">기타</label>
                <div className="ops">
                  {lodEtc.map((etc, index) => {
                    const name = etc.opSeq + "";
                    return (
                      <div key={etc.opSeq} className="radio-wrap">
                        <label htmlFor="">{etc.opNm}</label>
                        <span className="ms-4">
                          <input
                            type="radio"
                            name={name}
                            id={name + "_Y"}
                            data-uyn="Y"
                            data-opseq={name}
                            data-ctgcd={etc.opCtgCd}
                            checked={etcInf.includes(etc.opSeq + "")}
                            onChange={(e) => handleChange(e.target)}
                          />
                          <label htmlFor={name + "_Y"}>사용</label>
                        </span>
                        <span>
                          <input
                            type="radio"
                            name={name}
                            id={name + "_N"}
                            data-uyn="N"
                            data-opseq={name}
                            data-ctgcd={etc.opCtgCd}
                            checked={!etcInf.includes(etc.opSeq + "")}
                            onChange={(e) => handleChange(e.target)}
                          />
                          <label htmlFor={name + "_N"}>미사용</label>
                        </span>
                      </div>
                    );
                  })}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LodPrdModifyView;
