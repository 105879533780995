import DefaultModal from "../default";
import { ReactNode, useEffect } from "react";
import "../modal.scss";
import LoadingButton from "components/button/loadingButton";

type IProps = {
  title: string;
  btnText?: string;
  cnlText?: string;
  disabled?: boolean;
  isLoading?: boolean;
  isOpen: boolean;
  styles?: string;
  handleCloseModal(): void;
  onClick(): void;
  children: ReactNode;
  isOutClose?: boolean;
};

const Modal = ({
  title,
  btnText = "확인",
  cnlText = "",
  disabled = false,
  isLoading = false,
  isOpen = false,
  styles,
  handleCloseModal,
  onClick,
  children,
  isOutClose,
}: IProps) => {
  return (
    <DefaultModal
      styles={styles}
      isOpen={isOpen}
      isOutClose={isOutClose}
      handleCloseModal={handleCloseModal}
    >
      <div className="title" data-fixed>
        <h3>{title}</h3>
        <button onClick={handleCloseModal} className="modal-close" />
      </div>
      <>{children}</>
      <div className="flex justify-center gap-x-4 mt-4">
        {!!btnText && (
          <LoadingButton
            txt={btnText}
            disabled={disabled}
            styles="btn-md"
            isLoading={isLoading}
            onClick={onClick}
          />
        )}
        {!!cnlText && (
          <button className="btn-reset" onClick={handleCloseModal}>
            {cnlText}
          </button>
        )}
      </div>
    </DefaultModal>
  );
};

export default Modal;
