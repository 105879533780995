import {
  admSignupInfoProps,
  loginProps,
  signupProps,
  manageSignupInfoProps,
  userStatusProps,
  findPwdProps,
  admFindPwdProps,
  searchProps,
  mdfyInfProps,
} from "interface/member";
import api from "./api";

export const loginApi = async (params: loginProps) => {
  const { memType, ...param } = params;
  console.log("login params", params);
  const res = await api({
    method: "post",
    url: `${memType}/login`,
    data: param,
  });

  return res;
};

export const signupAdminInfoApi = async (params: admSignupInfoProps) => {
  const res = await api({
    method: "post",
    url: "admin/join/administrator",
    data: params,
  });

  return res;
};

export const signupManageInfoApi = async (params: manageSignupInfoProps) => {
  const res = await api({
    method: "post",
    url: "manager/join/apply",
    data: params,
  });

  return res;
};

export const authMailApi = async (url: string) => {
  const res = await api({
    method: "get",
    url,
  });

  return res;
};

export const signupApi = async (params: { data: signupProps; url: string }) => {
  const { data, url } = params;
  const res = await api({
    method: "post",
    url,
    data,
  });

  return res;
};

export const checkPwdApi = async (params: {
  emlId: string;
  lgnPwd: string;
}) => {
  const res = await api({
    method: "post",
    url: "manager/lgnPwdChk",
    data: params,
  });

  return res;
};

export const lodGrpListApi = async () => {
  const res = await api({
    method: "get",
    url: "manager/join/lodGrpList",
  });

  return res;
};

export const admListApi = async (params: any) => {
  console.log("admList prams", params);
  const res = await api({
    method: "post",
    url: "admin/manage/admList",
    data: params,
  });
  return res;
};

export const admInfoApi = async (admNo: string) => {
  const res = await api({
    method: "get",
    url: `admin/manage/admDtl/${admNo}`,
  });

  return res;
};

export const modifyAdmInfoApi = async (params: mdfyInfProps) => {
  const res = await api({
    method: "post",
    url: "admin/manage/admInfo/modify",
    data: params,
  });

  return res;
};

export const managerListApi = async (params: Partial<searchProps>) => {
  const res = await api({
    method: "post",
    url: "manager/manManage/manList",
    data: params,
  });
  return res;
};

export const managerInfoApi = async (manNo: string) => {
  const res = await api({
    method: "get",
    url: `manager/manManage/manDtlInfo/${manNo}`,
  });

  return res;
};

export const modifyManagerApi = async (params: mdfyInfProps) => {
  const res = await api({
    method: "post",
    url: `manager/manManage/manInfoModify`,
    data: params,
  });

  return res;
};

export const userListApi = async (params: any) => {
  const res = await api({
    method: "post",
    url: `admin/user/userList`,
    data: params,
  });
  return res;
};

export const userInfoApi = async (userNo: string) => {
  const res = await api({
    method: "get",
    url: `admin/user/userDtl/${userNo}`,
  });

  return res;
};

export const userModifyApi = async (params: mdfyInfProps) => {
  const res = await api({
    method: "post",
    url: `admin/user/userModify`,
    data: params,
  });
  return res;
};

export const modifyUserStatusApi = async (params: userStatusProps) => {
  const res = await api({
    method: "post",
    url: "admin/manage/admJoin/approval",
    data: params,
  });

  return res;
};

export const dropUserApi = async (params: { check: boolean }) => {
  const res = await api({
    method: "post",
    url: "",
    data: params,
  });

  return res;
};

export const logOutApi = async (memType: string) => {
  const res = await api({
    method: "get",
    url: `${memType}/logout`,
  });
  return res;
};

export const findPindApi = async (params: findPwdProps | admFindPwdProps) => {
  const { memTyp, ...data } = params;
  const res = await api({
    method: "post",
    url: `${memTyp}/lgnPwdReset`,
    data: data,
  });

  return res;
};

export const certLgnPwdApi = async (params: {
  certEncParam: string;
  newLgnPwd: string;
}) => {
  const res = await api({
    method: "post",
    url: `manager/manCertLgnPwd`,
    data: params,
  });

  return res;
};

export const admCertLgnPwdApi = async (params: {
  certEncParam: string;
  newLgnPwd: string;
}) => {
  const res = await api({
    method: "post",
    url: `admin/admCertLgnPwd`,
    data: params,
  });

  return res;
};

export const findPwdAuthMailApi = async () => {
  const res = await api({
    method: "get",
    url: ``,
  });

  return res;
};

export const chkMemApi = async (params: { pwd: string }) => {
  const res = await api({
    method: "post",
    url: ``,
    data: params,
  });

  return res;
};
