import { useMutation } from "@tanstack/react-query";
import { findPindApi } from "components/api/member";
import Step1View from "components/view/resetpw/step1";
import { manageSignupInfoProps } from "interface/member";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { regEmail } from "util/common";

const Step1Page = () => {
  const { pathname, search, state } = useLocation();
  const dispatch = useDispatch();
  const [info, setInfo] = useState<Omit<manageSignupInfoProps, "lodGrpNo">>({
    manEmlId: "",
    manFstnm: "",
    manSurnm: "",
    mobCntyCd: "+82",
    mobNo: "",
    authGrpCod: "",
    memTyp: pathname.includes("admin") ? "admin" : "manager",
  });

  const handleChange = (name: string, value: string) => {
    setInfo({
      ...info,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setInfo({
        ...info,
        [name]: value,
      });
    }
  };

  const findPwdMutation = useMutation(findPindApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: `가입승인 요청을 완료하시려면 / 24시간 이내에 ${info.manEmlId}로 / 전송된 메일을 통해 인증해 주시기바랍니다.`,
              isConfirm: true,
              handleClick() {
                // navigate(pathname.replace("step1", "step2"), {
                //   state: {
                //     emlId: info.manEmlId,
                //   },
                // });
                dispatch(setMessage({ message: null }));
                setInfo({
                  manEmlId: "",
                  manFstnm: "",
                  manSurnm: "",
                  mobCntyCd: "+82",
                  mobNo: "",
                  authGrpCod: "",
                  memTyp: pathname.includes("admin") ? "admin" : "manager",
                });
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCheck = () => {
    if (!regEmail.test(info?.manEmlId)) {
      dispatch(
        setMessage({ message: "정확한 형식의 이메일을 입력하여주세요." })
      );
      return;
    }
    if (pathname.includes("manager")) {
      findPwdMutation.mutate(info);
      return;
    }
    if (pathname.includes("admin")) {
      const params = {
        admEmlId: info.manEmlId,
        admFstnm: info.manFstnm,
        admSurnm: info.manSurnm,
        mobCntyCd: info.mobCntyCd,
        mobNo: info.mobNo,
        memTyp: info.memTyp,
        authGrpCod: info.authGrpCod === "A" ? "" : info.authGrpCod ?? "",
      };
      findPwdMutation.mutate(params);
    }
  };

  return (
    <Step1View
      info={info}
      handleChange={handleChange}
      handleSelect={handleSelect}
      handleCheck={handleCheck}
    />
  );
};

export default Step1Page;
