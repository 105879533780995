import ITHeader from "components/layout/header/subHeader/itemHeader";
import THeader from "components/layout/header/subHeader/tHeader";
import SmallSelect from "components/select/smSelect";
import i18n from "locales/i18n";
import { useState } from "react";
import { langOptions } from "util/option";
import ReadTuiEditor from "components/tuiEditor/readEditor";
import { IrmInfo, rmImgList } from "interface/room";
import { langProps } from "interface";

type IProps = {
  rmNo: string;
  rmDtl: any;
  rmAddInfList: IrmInfo["rmInfList"];
  rmImgList: rmImgList[];
};

export const RoomInfoView = ({
  rmNo,
  rmDtl,
  rmImgList,
  rmAddInfList,
}: IProps) => {
  const rmInfo = {
    lodGrpNo: rmDtl?.lodGrpNo,
    lodGrpNm: rmDtl?.lodGrpNm,
    lodNo: rmDtl.lodNo,
    lodNm: rmDtl.lodNm,
    rmTypCd: rmDtl.rmTypCd,
    rmTypNm: rmDtl.rmTypNm,
    rmOlkCd: rmDtl.rmOlkCd,
    rmOlkNm: rmDtl.rmOlkNm,
    rmAreaDesc: rmDtl.rmAreaDesc,
    bedTypCd: rmDtl.bedTypCd,
    bedTypNm: rmDtl.bedTypNm,
    rmMinPerCt: rmDtl.minPerCt,
    rmMaxPerCt: rmDtl.maxPerCt,
    babyFreePerCt: rmDtl.babyFreePerCt,
    chldFreePerCt: rmDtl.chldFreePerCt,
  };

  let langs = [] as langProps[];
  langOptions.forEach((lang) => {
    const isAble = rmAddInfList.some((ad) => {
      return lang.value === ad.langCd;
    });

    if (isAble) {
      langs.push(lang);
    }
  });

  const [infOpen, setInfOpen] = useState(true);
  const [addOpen, setAddOpen] = useState(true);
  const [imgOpen, setImgOpen] = useState(true);
  const [langCd, setLangCd] = useState(i18n.language);

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setLangCd(value);
    }
  };

  const adInf = rmAddInfList.find((ad) => {
    return ad?.langCd === langCd;
  });

  return (
    <div className="main-cont" data-dir-col>
      <THeader sbTtl={"객실상세 조회"} mdfyBtn clsBtn handleReset={() => {}} />
      <div className="tb-form">
        <ITHeader
          sbTtl={"기본정보"}
          clsBtn
          isClose={infOpen}
          handleClose={() => {
            setInfOpen(!infOpen);
          }}
        />
        <div className={infOpen ? "" : "hidden"}>
          <ul>
            <li>
              <label htmlFor="">숙소그룹명</label>
              <input type="text" defaultValue={rmInfo.lodGrpNm} readOnly />
            </li>
            <li>
              <label htmlFor="">숙소명</label>
              <input type="text" defaultValue={rmInfo.lodNm} readOnly />
            </li>
            <li>
              <label htmlFor="">객실타입</label>
              <input type="text" defaultValue={rmInfo.rmTypNm} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">객실전망</label>
              <input type="text" defaultValue={rmInfo.rmOlkNm} readOnly />
            </li>
            <li>
              <label htmlFor="">객실침대종류</label>
              <input type="text" defaultValue={rmInfo.bedTypNm} readOnly />
            </li>
            <li>
              <label htmlFor="">객실크기</label>
              <input
                type="text"
                name="rmAreaDesc"
                defaultValue={rmInfo.rmAreaDesc}
                readOnly
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">객실최소인원수</label>
              <input
                type="text"
                name="rmMinPerCt"
                defaultValue={rmInfo.rmMinPerCt}
                readOnly
              />
            </li>
            <li>
              <label htmlFor="">객실최대인원수</label>
              <input
                type="text"
                name="rmMaxPerCt"
                defaultValue={rmInfo?.rmMaxPerCt ?? ""}
                readOnly
              />
            </li>
            <li>
              <label htmlFor="">유아무료인원수</label>
              <input
                type="text"
                name="babyFreePerCt"
                defaultValue={rmInfo?.babyFreePerCt ?? ""}
                readOnly
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">아동무료인원수</label>
              <input
                type="text"
                name="chldFreePerCt"
                defaultValue={rmInfo?.chldFreePerCt ?? ""}
                readOnly
              />
            </li>
            <li>
              <label htmlFor="">상태</label>
              <input
                type="text"
                defaultValue={
                  rmDtl?.uyn === "Y"
                    ? "사용"
                    : rmDtl?.uyn === "N"
                    ? "미사용"
                    : ""
                }
                readOnly
              />
            </li>
          </ul>
        </div>
      </div>
      <div className="tb-form">
        <ITHeader
          sbTtl={"추가정보"}
          desc={
            langs.length > 1
              ? "언어를 선택하여 언어별 추가정보를 확인하세요."
              : ""
          }
          clsBtn
          isClose={addOpen}
          handleClose={() => {
            setAddOpen(!addOpen);
          }}
        >
          {rmAddInfList.length > 0 && (
            <SmallSelect
              option={langCd}
              options={langs}
              handleSelect={handleSelect}
            />
          )}
        </ITHeader>
        <div className={addOpen ? "" : "hidden"}>
          <ul>
            <li data-full>
              <label htmlFor="" data-center>
                객실명
              </label>
              <input
                type="text"
                defaultValue={adInf?.rmNm || ""}
                readOnly
                onChange={() => {}}
              />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="" data-center>
                객실설명
              </label>
              <ReadTuiEditor name={"rmDesc"} value={adInf?.rmDesc} />
            </li>
          </ul>
        </div>
      </div>
      <div className="tb-form">
        <ITHeader
          sbTtl={"객실이미지"}
          clsBtn
          isClose={imgOpen}
          handleClose={() => {
            setImgOpen(!imgOpen);
          }}
        />
        <div className={imgOpen ? "" : "hidden"}>
          <ul>
            {rmImgList.length === 0 && (
              <li data-full>
                <label htmlFor="">이미지</label>
                <div className="w-full">
                  {rmImgList.length === 0 && (
                    <p className="h-[12.5rem] text-sm font-bold flex justify-center items-center bg-gray-700">
                      등록된 이미지가 없습니다.
                    </p>
                  )}
                </div>
              </li>
            )}
            {rmImgList.length !== 0 && (
              <li>
                <label htmlFor="" data-center>
                  이미지
                </label>
                <div className="img-wrap">
                  {rmImgList.map((img) => (
                    <div className="img-item" key={img?.expsOrdV}>
                      <img src={img?.resImgPhUrl} alt="객실이미지" />
                    </div>
                  ))}
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};
