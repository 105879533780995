import { useQuery } from "@tanstack/react-query";
import { lodGrpAdList } from "components/api/lod";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import SmallSelect from "components/select/smSelect";
import ReadTuiEditor from "components/tuiEditor/readEditor";
import { IlodGrpAddInf } from "interface/lod";
import i18n from "locales/i18n";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { langOptions } from "util/option";
import { ModifyModal } from "./modal/modifyModal";
import { langProps } from "interface";
import AddModal from "./modal/addModal";

type IProps = {
  lodGrpAddInf: Array<IlodGrpAddInf>;
};

const AdInfModifyView = ({ lodGrpAddInf }: IProps) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const lodGrpNo = search.replace("?lodGrpNo=", "") ?? "";

  const adQuery = useQuery({
    queryKey: ["lodGrpAdList", lodGrpNo],
    queryFn: () => {
      return lodGrpAdList({
        lodGrpNo: search.replace("?lodGrpNo=", ""),
        langCd: "",
      });
    },
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
        }

        if (code === -1) {
          dispatch(
            setMessage({
              message: message || "검색 데이터가 없습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  let adList = adQuery?.data?.body?.lodGrpAdList || lodGrpAddInf;
  let mdfyOp = [] as langProps[];
  let addOp = [] as langProps[];
  langOptions.forEach((lang) => {
    const isAble = adList.some((ad: IlodGrpAddInf) => {
      return lang.value === ad.langCd;
    });

    if (isAble) {
      mdfyOp.push(lang);
    }
    if (!isAble) {
      addOp.push(lang);
    }
  });
  const [langCd, setLangCd] = useState(mdfyOp[0]?.value || "");

  const adInfo = adList.find((ad: IlodGrpAddInf) => {
    const lng = langCd || mdfyOp[0]?.value;
    return ad?.langCd === lng;
  });

  let langs = [] as langProps[];
  langOptions.forEach((lang) => {
    const isAble = adList.some((ad: any) => {
      return lang.value === ad.langCd;
    });

    if (isAble) {
      langs.push(lang);
    }
  });

  const [addOpen, setAddOpen] = useState(true);
  const [isOpen, setIsOpen] = useState("");

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"추가정보"}
          clsBtn
          mdfyBtn={mdfyOp.length > 0}
          addBtn={langOptions.length > mdfyOp.length}
          addBtnTxt="언어추가"
          isClose={addOpen}
          desc={
            mdfyOp.length > 1
              ? "언어를 선택하여 언어별 추가정보를 확인하세요."
              : "언어추가 버튼 클릭후 추가정보를 등록하여 주세요."
          }
          handleAdCreate={() => {
            const isKr = mdfyOp.some((lng) => {
              return lng.value === i18n.language;
            });

            setLangCd(isKr ? i18n.language : addOp[0]?.value);
            setIsOpen("add");
          }}
          handleModify={() => {
            setIsOpen("mdfy");
            const isKr = mdfyOp.some((lng) => {
              return lng.value === i18n.language;
            });
            setLangCd(isKr ? i18n.language : mdfyOp[0]?.value);
          }}
          handleClose={() => {
            setAddOpen(!addOpen);
          }}
        >
          {adList.length > 0 && (
            <SmallSelect
              option={langCd}
              options={mdfyOp}
              handleSelect={(target) => {
                const { value } = target.dataset;
                setLangCd(value ?? i18n.language);
              }}
            />
          )}
        </ITHeader>
        <div className={addOpen ? "" : "hidden"}>
          <ul>
            <li data-full>
              <label htmlFor="">숙소그룹명</label>
              <input type="text" value={adInfo?.lodGrpNm ?? ""} readOnly />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">숙소그룹설명</label>
              <ReadTuiEditor name={"lodGrpDesc"} value={adInfo?.lodGrpDesc} />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">우편번호</label>
              <input type="text" value={adInfo?.lodGrpZipc ?? ""} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">주소1</label>
              <input type="text" value={adInfo?.lodGrpAddr1 ?? ""} readOnly />
            </li>
            <li>
              <label htmlFor="">주소2</label>
              <input type="text" value={adInfo?.lodGrpAddr2 ?? ""} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">주소3</label>
              <input type="text" value={adInfo?.lodGrpAddr3 ?? ""} readOnly />
            </li>
            <li>
              <label htmlFor="">주소4</label>
              <input type="text" value={adInfo?.lodGrpAddr4 ?? ""} readOnly />
            </li>
          </ul>
        </div>
      </div>
      {isOpen === "mdfy" && (
        <ModifyModal
          adInfo={adInfo}
          adList={adList}
          langCd={langCd}
          langOp={mdfyOp}
          isOpen={isOpen}
          lodGrpNo={lodGrpNo}
          setLangCd={setLangCd}
          setIsOpen={setIsOpen}
        />
      )}
      {isOpen === "add" && (
        <AddModal
          lodGrpZipc={adInfo?.lodGrpZipc}
          langCd={addOp[0].value}
          langOp={addOp}
          isOpen={isOpen}
          lodGrpNo={lodGrpNo}
          setLangCd={setLangCd}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
};

export default AdInfModifyView;
