import DatePick from "components/datePick";
import THeader from "components/layout/header/subHeader/tHeader";
import Select from "components/select";
import LodChlSelect from "components/select/room/lodChlSelect";
import LodGrpSelect from "components/select/room/lodGrpNoSelect";
import LodNoSelect from "components/select/room/lodNoSelect";
import RmNoSelect from "components/select/room/rmNoSlect";
import RmOlkSelect from "components/select/room/rmOlkSelect";
import RmTypeSelect from "components/select/room/rmTypSelect";
import SearchSelect from "components/select/searchSelect";
import RoomProductList from "components/tableList/room/product";
import { rmPdSearchProps } from "interface/room";
import { useLocation } from "react-router-dom";
import { uYnOptions } from "util/option";

type IProps = {
  memType: "admin" | "manager";
  searchInfo: rmPdSearchProps;
  handleChange(name: string, value: string): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleReset(): void;
};

const RmPdListView = ({
  memType,
  searchInfo,
  handleChange,
  handleSelect,
  handleReset,
}: IProps) => {
  const { pathname } = useLocation();
  const searchOp = [
    {
      kr: "객실명",
      value: "rmNm",
      en: "RoomName",
    },
    {
      kr: "조회최소값",
      value: "minPerCtMin",
      en: "",
    },
    {
      kr: "조회최대값",
      value: "maxPerCtMin",
      en: "",
    },
  ];

  const searchOps =
    memType === "admin"
      ? [
          {
            kr: "숙소그룹명",
            value: "lodGrpNm",
            en: "LodGrpName",
          },
          {
            kr: "숙소명",
            value: "lodNm",
            en: "",
          },
          ...searchOp,
        ]
      : searchOp;

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader
          sbTtl={"객실상품"}
          rstBtn
          newBtn
          url={pathname}
          searchBtn
          handleReset={handleReset}
        />
        <div className="tb-form" data-list>
          {memType === "admin" && (
            <ul>
              <li>
                <label htmlFor="">숙소그룹번호</label>
                <LodGrpSelect
                  option={searchInfo.lodGrpNo}
                  onSelect={(target) => handleSelect(target)}
                />
              </li>
              <li>
                <label htmlFor="">숙소번호</label>
                <LodNoSelect
                  disabled={!searchInfo.lodGrpNo}
                  params={searchInfo.lodGrpNo}
                  option={searchInfo.lodNo}
                  onSelect={(target) => handleSelect(target)}
                />
              </li>
            </ul>
          )}
          <ul>
            <li>
              <label htmlFor="">객실번호</label>
              <RmNoSelect
                disabled={!searchInfo.lodNo}
                params={searchInfo.lodNo}
                option={searchInfo.rmNo}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="">객실전망</label>
              <RmOlkSelect
                option={searchInfo.rmOlkCd}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="">객실타입</label>
              <RmTypeSelect
                option={searchInfo.rmTypCd}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">객실상품채널</label>
              <LodChlSelect
                option={searchInfo?.lodChlCd}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="">상태</label>
              <Select
                id={"sellYn"}
                name={"sellYn"}
                option={searchInfo.sellYn}
                options={uYnOptions}
                onSelect={handleSelect}
              />
            </li>
            <li>
              <DatePick
                startNm="sellSDt"
                endNm={"sellEDt"}
                start={searchInfo.sellSDt ?? ""}
                end={searchInfo.sellEDt}
                handleChange={(name, value) => handleChange(name, value)}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">검색조건</label>
              <SearchSelect
                id={"searchTyp"}
                name={searchInfo.searchTyp ?? ""}
                option={searchInfo?.searchTyp || "A"}
                options={searchOps}
                value={
                  searchInfo[
                    `${searchInfo.searchTyp}` as keyof rmPdSearchProps
                  ] || ""
                }
                onSelect={handleSelect}
                onChange={(target) => handleChange(target.name, target.value)}
              />
            </li>
          </ul>
        </div>
      </div>
      <RoomProductList memType={memType} searchInfo={searchInfo} />
    </>
  );
};

export default RmPdListView;
