import { t } from "i18next";
import i18n from "locales/i18n";
import { MutableRefObject, useEffect, useState } from "react";

type IProps = {
  name: string;
  inStyle?: string;
  value?: string;
  langCd?: string;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  full?: boolean;
  isRef?: MutableRefObject<boolean>;
  onChange(value: string): void;
};

const InContet = ({
  name = "",
  inStyle,
  placeholder = "",
  value = "",
  langCd = i18n.language,
  disabled,
  readonly,
  full = false,
  isRef,
  onChange,
}: IProps) => {
  const [txt, setTxt] = useState("");

  useEffect(() => {
    setTxt(value);

    return () => {
      setTxt("");
    };
  }, [value]);

  return (
    <>
      <input
        type="text"
        name={name}
        className={inStyle}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readonly}
        data-full={full}
        defaultValue={txt}
        data-mb-1
        onChange={(e) => {}}
        onBlur={(e) => {
          setTxt(e.currentTarget.value);
          onChange(e.currentTarget.value);
        }}
      />
    </>
  );
};

export default InContet;
