import { useMutation, useQueryClient } from "@tanstack/react-query";
import { rmOPInfModifyApi } from "components/api/room";
import Modal from "components/modal/modal";
import Select from "components/select";
import InTuiEditor from "components/tuiEditor/inEditot";
import { langProps } from "interface";
import i18n from "locales/i18n";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";

export type IProps = {
  adInfo: { langCd: string; rmOpNm: string; rmOpDesc: string };
  langCd: string;
  langOp: langProps[];
  isOpen: string;
  rmNo: string;
  opRgSeq: string;

  setLangCd: Dispatch<SetStateAction<string>>;
  setIsOpen: Dispatch<SetStateAction<string>>;
};

export const ModifyModal = ({
  adInfo,
  langCd,
  langOp,
  isOpen,
  rmNo,
  opRgSeq,
  setIsOpen,
  setLangCd,
}: IProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  // const [isDscAble, setIsDscAble] = useState(false);
  const isAbleRef = useRef(false);
  const [adInf, setAdInf] = useState({
    rmOpNm: "",
    rmOpDesc: "",
  });

  const handleChange = (name: string, value: string) => {
    setAdInf({
      ...adInf,
      [name]: value,
    });
  };

  const modifyMutation = useMutation(rmOPInfModifyApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "추가정보가 성정공적으로 수정되었습니다.",
              isConfirm: true,
              handleClick() {
                queryClient.invalidateQueries({ queryKey: ["rmOpInfo"] });
                dispatch(setMessage({ message: null }));
                setIsOpen("");
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModify = () => {
    if (!langCd) {
      alert("언어를 선택하여 주세요.");
      return;
    }

    if (!adInf.rmOpNm || !adInf.rmOpDesc) {
      alert("추갖정보를 수정하여 주세요.");
      return;
    }

    const params = {
      rmNo,
      langCd: adInfo.langCd,
      opRgSeq,
      rmOpNm: adInf.rmOpNm,
      rmOpDesc: isAbleRef.current ? adInf.rmOpDesc : "",
    };

    dispatch(
      setMessage({
        message: "추가정보를 수정하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          dispatch(setMessage({ message: null }));
          modifyMutation.mutate(params);
        },
      })
    );
  };

  useEffect(() => {
    setAdInf(adInfo);
    return () => {
      setAdInf({
        rmOpNm: "",
        rmOpDesc: "",
      });
    };
  }, [adInfo]);

  return (
    <>
      <Modal
        title={"추가정보 수정"}
        styles="w-5/6"
        btnText={"수정"}
        isOpen={isOpen !== ""}
        handleCloseModal={() => {
          setIsOpen("");
          setAdInf({
            rmOpNm: "",
            rmOpDesc: "",
          });
          setLangCd(i18n.language);
        }}
        onClick={handleModify}
      >
        <div className="tb-form mt-[3rem] " data-t-brd>
          <ul>
            <li>
              <label htmlFor="">언어선택</label>
              <Select
                id={"langCd"}
                name={"langCd"}
                option={langCd}
                options={langOp}
                isInitial
                onSelect={(target) => {
                  const { value } = target.dataset;
                  if (!!value) {
                    setLangCd(value);
                    setAdInf({
                      rmOpNm: "",
                      rmOpDesc: "",
                    });
                  }
                }}
              />
            </li>
          </ul>
          <ul>
            <li data-full className="">
              <label htmlFor="">객실옵션명</label>
              <input
                type="text"
                value={adInf.rmOpNm}
                onChange={(e) => handleChange("rmOpNm", e.currentTarget.value)}
              />
            </li>
          </ul>
          <ul className="pb-2">
            <li data-full>
              <label htmlFor="">객실옵션설명</label>
              <InTuiEditor
                name={"rmOpDesc"}
                info={{}}
                value={adInf.rmOpDesc}
                isAbleRef={isAbleRef}
                onChange={(value) => handleChange("rmOpDesc", value)}
              />
            </li>
          </ul>
        </div>
      </Modal>
    </>
  );
};
