import { useQuery } from "@tanstack/react-query";
import { promInfoApi } from "components/api/promotion";
import PromInfoView from "components/view/promotion/info";
import { useLocation } from "react-router-dom";

const PromInfoPage = () => {
  const { search } = useLocation();
  const query = useQuery({
    queryKey: ["promDtl"],
    queryFn: () => {
      return promInfoApi(search.replace("?prmCd=", ""));
    },
  });

  const promDtl = query.data?.body?.promDtl || null;

  return (
    <>
      <PromInfoView promInf={promDtl} />
    </>
  );
};

export default PromInfoPage;
