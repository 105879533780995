import ITHeader from "components/layout/header/subHeader/itemHeader";
import SmallSelect from "components/select/smSelect";
import ReadTuiEditor from "components/tuiEditor/readEditor";
import { langProps } from "interface";
import i18n from "locales/i18n";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { langOptions } from "util/option";
import AddModal from "./modal/addModal";
import { rmAdInfoProps } from "interface/room";
import { setIsSearch } from "store/commonSlice";
import { ModifyModal } from "./modal/modifyModal";

type IProps = {
  rmNo: string;
  adInfList: rmAdInfoProps["rmInfList"];
};

export const AdInfModify = ({ rmNo, adInfList }: IProps) => {
  const dispatch = useDispatch();

  let mdfyOp = [] as langProps[];
  let addOp = [] as langProps[];

  langOptions.forEach((lang) => {
    const isAble = adInfList.some((ad) => {
      return lang.value === ad.langCd;
    });

    if (isAble) {
      mdfyOp.push(lang);
    }
    if (!isAble) {
      addOp.push(lang);
    }
  });

  const [langCd, setLangCd] = useState(mdfyOp[0]?.value || i18n.language);
  const [isOpen, setIsOpen] = useState("");
  const [addOpen, setAddOpen] = useState(true);

  let adInfo = adInfList.find((ad) => {
    return ad.langCd === langCd;
  });

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"추가정보"}
          clsBtn
          mdfyBtn={mdfyOp.length > 0}
          addBtn={langOptions.length > mdfyOp.length}
          addBtnTxt="언어추가"
          desc={
            mdfyOp.length > 1
              ? "언어를 선택하여 언어별 추가정보를 확인하세요."
              : ""
          }
          isClose={addOpen}
          handleAdCreate={() => {
            setIsOpen("add");
            setLangCd(i18n.language);
          }}
          handleModify={() => {
            setIsOpen("mdfy");
            setLangCd(i18n.language);
          }}
          handleClose={() => {
            setAddOpen(!addOpen);
            dispatch(setIsSearch(true));
          }}
        >
          {adInfList.length > 0 && (
            <SmallSelect
              option={langCd}
              options={mdfyOp}
              handleSelect={(target) => {
                const { value } = target.dataset;
                if (!!value) {
                  setLangCd(value);
                }
              }}
            />
          )}
        </ITHeader>
        <div className={addOpen ? "" : "hidden"}>
          <ul>
            <li data-full>
              <label htmlFor="" data-center>
                객실명
              </label>
              <input
                type="text"
                value={adInfo?.rmNm || ""}
                readOnly
                onChange={() => {}}
              />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="" data-center>
                객실설명
              </label>
              <ReadTuiEditor name={"rmDesc"} value={adInfo?.rmDesc} />
            </li>
          </ul>
        </div>
      </div>
      {isOpen === "add" && (
        <AddModal
          langCd={langCd}
          langOp={addOp}
          isOpen={isOpen}
          rmNo={rmNo}
          setIsOpen={setIsOpen}
          setLangCd={setLangCd}
        />
      )}
      {isOpen === "mdfy" && (
        <ModifyModal
          adList={adInfList}
          langOp={mdfyOp}
          isOpen={isOpen}
          rmNo={rmNo}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
};
