import { useMutation } from "@tanstack/react-query";
import { rmInfoApi } from "components/api/room";
import RmCreateView from "components/view/room/rm/create/inex";
import i18n from "locales/i18n";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { isSearchSelector, setIsSearch, setMessage } from "store/commonSlice";

const RoomeCreatePage = () => {
  const dispatch = useDispatch();

  const rmNoRef = useRef("");
  const isSearch = useSelector(isSearchSelector);

  const rmMutation = useMutation(rmInfoApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(setIsSearch(false));
        }

        if (code === -1) {
          dispatch(
            setMessage({
              message: message || "검색 데이터가 없습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setIsSearch(false));
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  useEffect(() => {
    const params = {
      rmNo: rmNoRef.current ?? "",
      langCd: i18n.language,
      imgTypCd: "I11",
    };

    if (isSearch) {
      rmMutation.mutate(params);
    }
  }, [isSearch]);

  return <RmCreateView rmNoRef={rmNoRef} data={rmMutation?.data?.body} />;
};

export default RoomeCreatePage;
