import { useMutation } from "@tanstack/react-query";
import { certLgnPwdApi } from "components/api/member";
import Step2View from "components/view/resetpw/step2";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setMessage } from "store/commonSlice";

const Step2Page = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const authMutation = useMutation(certLgnPwdApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;

        if (code === 0) {
          navigate(pathname.replace("step3", "step4"), {
            state: {
              param: search.replace("?param=", ""),
            },
          });
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleAuthMail = () => {
    navigate(pathname.replace("step3", "step4"), {
      state: {
        param: search.replace("?param=", ""),
      },
    });
    // authMutation.mutate({
    //   certEncParam: search.replace("?param=", ""),
    //   newLgnPwd: "",
    // });
  };

  return <Step2View handleAuthMail={handleAuthMail} />;
};

export default Step2Page;
