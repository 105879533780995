import THeader from "components/layout/header/subHeader/tHeader";
import Select from "components/select";
import PhoneSelect from "components/select/phone";
import { manageSignupInfoProps } from "interface/member";
import { useLocation } from "react-router-dom";
import { regNum } from "util/common";
import { authOptions } from "util/option";

type IProps = {
  info: Omit<manageSignupInfoProps, "lodGrpNo">;
  handleChange(name: string, value: string): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleCheck(): void;
};

const Step1View = ({
  info,
  handleChange,
  handleSelect,
  handleCheck,
}: IProps) => {
  const { pathname } = useLocation();

  const isAble = pathname.includes("manager")
    ? !(
        info?.manFstnm &&
        info?.manSurnm &&
        info?.manEmlId &&
        info?.mobNo &&
        info?.mobCntyCd
      )
    : !(
        info?.manFstnm &&
        info?.manSurnm &&
        info?.manEmlId &&
        info?.mobNo &&
        info?.mobCntyCd &&
        info?.authGrpCod
      );

  return (
    <>
      <div className="main-cont">
        <div className="card-form">
          <THeader
            sbTtl={
              pathname.includes("admin") ? "관리자" : "매니저" + "비밀번호 찾기"
            }
            handleReset={() => {}}
          />
          <div className="tb-form">
            <p className="bg-gray-200 p-2 m-2">
              이메일 주소로 비밀번호 설정 링크가 발송됩니다. <br />
              링크를 통하여 새로운 비밀번호를 설정하세요.
            </p>
            <ul>
              <li>
                <label htmlFor="">영문이름</label>
                <input
                  type="text"
                  name=""
                  value={info?.manFstnm}
                  onChange={(e) =>
                    handleChange("manFstnm", e.currentTarget.value)
                  }
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">영문성</label>
                <input
                  type="text"
                  name=""
                  value={info?.manSurnm}
                  onChange={(e) =>
                    handleChange("manSurnm", e.currentTarget.value)
                  }
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">이메일</label>
                <input
                  type="text"
                  name=""
                  value={info?.manEmlId}
                  onChange={(e) =>
                    handleChange("manEmlId", e.currentTarget.value)
                  }
                />
              </li>
              {pathname.includes("admin") && (
                <li>
                  <label htmlFor="">관리자권한 선택</label>
                  <Select
                    id={"authGrpCod"}
                    name={"authGrpCod"}
                    option={info?.authGrpCod}
                    options={authOptions}
                    onSelect={handleSelect}
                  />
                </li>
              )}
            </ul>

            <ul className="pb-3">
              <li>
                <label htmlFor="phone">전화번호</label>
                <div className="flex gap-x-1">
                  <PhoneSelect
                    mobCd={info.mobCntyCd}
                    isNm
                    handleSelect={(target) => handleSelect(target)}
                  />
                  <input
                    type="text"
                    name="telNo"
                    value={info?.mobNo}
                    maxLength={11}
                    onChange={(e) => {
                      const value = regNum.test(e.currentTarget.value)
                        ? e.currentTarget.value
                        : "";

                      handleChange("mobNo", value);
                    }}
                  />
                </div>
              </li>
            </ul>
          </div>
          <div>
            <button
              className="btn-md mt-3"
              disabled={isAble}
              onClick={handleCheck}
            >
              등록
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step1View;
