import LodGrpNmSelect from "components/select/room/lodGrpNmSelect ";
import LodNmSelect from "components/select/room/lodNmSelect";
import RedTuiEditor from "components/tuiEditor/redEditor";
import { lodDftInfoProps } from "interface/lod";
import { useState } from "react";
import { useLocation } from "react-router-dom";

type IProps = {
  info: lodDftInfoProps;
  handleChange(name: string, value: string): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleCreate(): void;
};

const LodDftInfoCreateView = ({
  info,
  handleChange,
  handleSelect,
  handleCreate,
}: IProps) => {
  const { state } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="main-cnt">
      <div className="table-form" data-dir-col>
        <div className="sb-title">
          <h4>추가정보</h4>
          <button className="btn-mdfy" onClick={() => setIsOpen(true)}>
            언어추가
          </button>
        </div>
        <table>
          <tbody>
            <tr>
              <th>숙소그룹명</th>
              <td>
                <LodGrpNmSelect
                  option={info?.lodGrpNo}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
              <th>숙소명</th>
              <td>
                <LodNmSelect
                  option={""}
                  params={""}
                  onSelect={handleSelect}
                  disabled
                />
              </td>
            </tr>
            <tr>
              <th>공지사항</th>
              <td colSpan={3}>
                <RedTuiEditor
                  name={"noti"}
                  info={{}}
                  // onChange={(name, value) => handleChange(name, value)}
                />
              </td>
            </tr>
            <tr>
              <th>운영정보</th>
              <td colSpan={3}>
                <RedTuiEditor
                  name={"operate"}
                  info={{}}
                  // onChange={(name, value) => handleChange(name, value)}
                />
              </td>
            </tr>
            <tr>
              <th>인원추가정보</th>
              <td colSpan={3}>
                <RedTuiEditor
                  name={"people"}
                  info={{}}
                  // onChange={(name, value) => handleChange(name, value)}
                />
              </td>
            </tr>
            <tr>
              <th>투숙고객혜택정보</th>
              <td colSpan={3}>
                <RedTuiEditor
                  name={"guest"}
                  info={{}}
                  // onChange={(name, value) => handleChange(name, value)}
                />
              </td>
            </tr>
            <tr>
              <th>부대시설정보</th>
              <td colSpan={3}>
                <RedTuiEditor
                  name={"facilit"}
                  info={{}}
                  //
                  // onChange={(name, value) => handleChange(name, value)}
                />
              </td>
            </tr>
            <tr>
              <th>조식정보</th>
              <td colSpan={3}>
                <RedTuiEditor
                  name={"breakfast"}
                  info={{}}
                  //
                  // onChange={(name, value) => handleChange(name, value)}
                />
              </td>
            </tr>
            <tr>
              <th>취사시설정보</th>
              <td colSpan={3}>
                <RedTuiEditor
                  name={"cook"}
                  info={{}}
                  // onChange={(name, value) => handleChange(name, value)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* <Button
      txt="생성"
      isLoading={false}
      disabled={false}
      onClick={handleCreate}
    /> */}
    </div>
  );
};

export default LodDftInfoCreateView;
