type IProps = {
  id: string;
  auth: string;
  readonly?: boolean;
  handleChange(name: string, id: string): void;
};

const CheckBox = ({ id, auth, readonly = false, handleChange }: IProps) => {
  return (
    <div className="checkbox-wrap">
      <input
        type="checkbox"
        id={id + "_rd"}
        name={"rd"}
        checked={auth.includes("rd")}
        readOnly={readonly}
        onChange={(e) => handleChange(id, "rd")}
      />
      <label htmlFor={id + "_rd"} data-checkbox>
        읽기
      </label>
      <input
        type="checkbox"
        id={id + "_wrt"}
        name={"wrt"}
        checked={auth.includes("wrt")}
        readOnly={readonly}
        onChange={(e) => handleChange(id, "wrt")}
      />
      <label htmlFor={id + "_wrt"} data-checkbox>
        쓰기
      </label>
      <input
        type="checkbox"
        id={id + "_del"}
        name={"del"}
        checked={auth.includes("del")}
        readOnly={readonly}
        onChange={(e) => handleChange(id, "del")}
      />
      <label htmlFor={id + "_del"} data-checkbox>
        삭제
      </label>
      <input
        type="checkbox"
        id={id + "_dwl"}
        name={"dwl"}
        checked={auth.includes("dwl")}
        readOnly={readonly}
        onChange={(e) => handleChange(id, "dwl")}
      />
      <label htmlFor={id + "_dwl"} data-checkbox>
        다운로드
      </label>
    </div>
  );
};

export default CheckBox;
