import Radio from "components/radio/radio";
import { IlodAdInf, IlodDtl, IlodImg } from "interface/lod";
import i18n from "locales/i18n";
import { useLocation } from "react-router-dom";
import THeader from "components/layout/header/subHeader/tHeader";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import { useState } from "react";
import { langProps } from "interface";
import { langOptions } from "util/option";
import SmallSelect from "components/select/smSelect";
import ReadTuiEditor from "components/tuiEditor/readEditor";

type IProps = {
  lodDftDtl: IlodDtl;
  lodInfDtl: IlodDtl;
  lodManDtl: IlodDtl;
  lodPayDtl: IlodDtl;
  lodAdList: Array<IlodAdInf>;
  lodImgList: Array<IlodImg>;
};

const LodInfoView = ({
  lodDftDtl,
  lodInfDtl,
  lodManDtl,
  lodPayDtl,
  lodAdList = [],
  lodImgList,
}: IProps) => {
  const { pathname } = useLocation();

  let langs = [] as langProps[];
  langOptions.forEach((lang) => {
    const isAble = lodAdList.some((ad) => {
      return lang.value === ad.langCd;
    });

    if (isAble) {
      langs.push(lang);
    }
  });

  const [langCd, setLangCd] = useState("");
  const [infOpen, setInfOpen] = useState(true);
  const [addOpen, setAddOpen] = useState(true);
  const [imgOpen, setImgOpen] = useState(true);
  const [payOpen, setPayOpen] = useState(true);
  const [manOpen, setManOpen] = useState(true);

  let adInf = lodAdList?.find((ad) => {
    const lng = langCd || langs[0]?.value;
    return ad.langCd === lng;
  });

  let inImg = lodImgList?.filter((img: IlodImg) => {
    return img.imgTypCd === "I02";
  });

  let outImg = lodImgList?.filter((img: IlodImg) => {
    return img.imgTypCd === "I01";
  });

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader
          sbTtl={"숙소상세 조회"}
          mdfyBtn={pathname.includes("admin")}
          clsBtn
          handleReset={() => {}}
        />
        <div className="tb-form">
          <ITHeader
            sbTtl={"기본정보"}
            clsBtn
            isClose={infOpen}
            handleClose={() => {
              setInfOpen(!infOpen);
            }}
          />
          <div className={infOpen ? "" : "hidden"}>
            <ul>
              <li>
                <label htmlFor="">숙소그룹명</label>
                <input
                  type="text"
                  defaultValue={lodDftDtl?.lodGrpNm}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">숙소등급</label>
                <input
                  type="text"
                  defaultValue={lodDftDtl?.lodGrdCd}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">숙소국가</label>
                <input
                  type="text"
                  defaultValue={lodDftDtl?.lodCntyCd}
                  readOnly
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">숙소전화번호</label>
                <input type="text" defaultValue={lodDftDtl?.telNo} readOnly />
              </li>
              <li>
                <label htmlFor="">숙소팩스번호</label>
                <input type="text" defaultValue={lodDftDtl?.faxNo} readOnly />
              </li>
              <li>
                <label htmlFor="">숙소이메일</label>
                <input type="text" defaultValue={lodDftDtl?.lodEml} readOnly />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">숙소홈페이지</label>
                <input type="text" defaultValue={lodDftDtl?.hpUrl} readOnly />
              </li>
              <li>
                <label htmlFor="">숙소총객실수량</label>
                <input type="text" defaultValue={lodDftDtl?.rmCnt} readOnly />
              </li>
              <li>
                <label htmlFor="">숙소UTC</label>
                <input
                  type="text"
                  defaultValue={lodDftDtl?.lodPosUtc}
                  readOnly
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">숙소유형</label>
                <input
                  type="text"
                  defaultValue={lodDftDtl?.lodTypCd}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">예약가능여부</label>
                <input
                  type="text"
                  defaultValue={
                    lodDftDtl?.resvPsbYn === "Y" ? "예약가능" : "예약불가"
                  }
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">판매금액구분</label>
                <input
                  type="text"
                  defaultValue={
                    lodDftDtl?.sellAmDvc === "01" ? "판매가" : "입금가"
                  }
                  readOnly
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="tb-form">
          <ITHeader
            sbTtl={"숙소 정보"}
            clsBtn
            isClose={manOpen}
            handleClose={() => {
              setManOpen(!manOpen);
            }}
          />
          <div className={manOpen ? "" : "hidden"}>
            <ul>
              <li>
                <label htmlFor="">VAT포함여부</label>
                <input
                  type="text"
                  defaultValue={
                    lodInfDtl?.sellAmVatInYn === "Y" ? "포함" : "미포함"
                  }
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">예약마감시간</label>
                <input
                  type="text"
                  value={
                    lodInfDtl?.resvETi?.replace(/\B(?=(\d{2})+(?!\d))/g, ":") ??
                    ""
                  }
                  readOnly
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">유아니아기준</label>
                <input
                  type="text"
                  defaultValue={lodInfDtl?.babyAgeStndV}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">아동 나이기준 </label>
                <input
                  type="text"
                  defaultValue={lodInfDtl?.chldAgeStndV}
                  readOnly
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">체크인 시간</label>
                <input
                  type="text"
                  defaultValue={
                    lodInfDtl?.chkInTi?.replace(/\B(?=(\d{2})+(?!\d))/g, ":") ??
                    ""
                  }
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">체크아웃시간</label>
                <input
                  type="text"
                  defaultValue={
                    lodInfDtl?.chkOutTi?.replace(
                      /\B(?=(\d{2})+(?!\d))/g,
                      ":"
                    ) ?? ""
                  }
                  readOnly
                />
              </li>
            </ul>
            <ul>
              <li data-full>
                <label htmlFor="">환불수수료</label>
                <div className="w-full flex flex-col gap-y-2 mt-3">
                  <span className="text-xs text-start font-bold text-red-400">
                    * 취소 수수료율은 취소를 접수한 날짜를 기준으로 적용
                  </span>
                  <span className="w-full flex justify-start">
                    <Radio
                      id="PerNight"
                      name="refund"
                      label="PerNight"
                      lableStyle="w-[12.5rem]"
                      readonly
                      checked={lodInfDtl?.refdFeeTypCd === "01"}
                      onChange={() => {}}
                    />
                    <span className="text-xs">
                      예약 일자별 판매금액에 취소수수료 부과 <br /> 일별판매금액
                      x 일별취소수수료율 (연박 시 각 일별취소수수료 합산)
                    </span>
                  </span>

                  <span className="w-full flex justify-start">
                    <Radio
                      id="PerBooking"
                      name="refund"
                      label="PerBooking"
                      lableStyle="w-[12.5rem]"
                      readonly
                      checked={lodInfDtl?.refdFeeTypCd === "02"}
                      onChange={() => {}}
                    />
                    <span className="text-xs">
                      총판매금액에 취소수수료 부과
                      <br /> 총판매금엑 x 예약1일차취소수수료율 (연박 시 동일)
                    </span>
                  </span>

                  <span className="w-full flex justify-start">
                    <Radio
                      id="PerOneNight"
                      name="refund"
                      label="PerOneNight"
                      lableStyle="w-[12.5rem]"
                      readonly
                      checked={lodInfDtl?.refdFeeTypCd === "03"}
                      onChange={() => {}}
                    />
                    <span className="text-xs">
                      예약1일차판매금액에 취소수수료 부과
                      <br /> 예약1일차판매금액 x 예약1일차취소수수료율 (연박 시
                      동일)
                    </span>
                  </span>
                  <span className="w-full italic underline text-right">
                    취소 수수료 부과 방식 예시
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="tb-form">
          <ITHeader
            sbTtl={"담당자 정보"}
            clsBtn
            isClose={manOpen}
            handleClose={() => {
              setManOpen(!manOpen);
            }}
          />
          <div className={manOpen ? "" : "hidden"}>
            <ul>
              <li>
                <label htmlFor="">담당자 영문이름</label>
                <input
                  type="text"
                  name="manSurnm"
                  defaultValue={lodManDtl?.manFstnm}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">담당자 영문성</label>
                <input
                  type="text"
                  name="manFstnm"
                  defaultValue={lodManDtl?.manSurnm}
                  readOnly
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">담당자 직급</label>
                <input
                  type="text"
                  name="man"
                  defaultValue={lodManDtl?.manGrd}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">담당자 전화번호</label>
                <input type="text" defaultValue={lodManDtl?.telNo} readOnly />
              </li>
            </ul>
          </div>
        </div>
        <div className="tb-form">
          <ITHeader
            sbTtl={"결제정보"}
            clsBtn
            isClose={payOpen}
            handleClose={() => {
              setPayOpen(!payOpen);
            }}
          />
          <div className={payOpen ? "" : "hidden"}>
            <ul>
              <li>
                <label htmlFor="">정산입금일</label>
                <input
                  type="text"
                  defaultValue={lodPayDtl?.calcInDt}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">정산은행</label>
                <input
                  type="text"
                  defaultValue={lodPayDtl?.calcInBnkNm}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">정산은행계좌번호</label>
                <input
                  type="text"
                  defaultValue={lodPayDtl?.calcAcntNo}
                  readOnly
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">정산통화</label>
                <input
                  type="text"
                  defaultValue={lodPayDtl?.calcCurrCd}
                  readOnly
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="tb-form">
          <ITHeader
            sbTtl={"추가정보"}
            clsBtn
            isClose={addOpen}
            desc={
              langs.length > 1
                ? `언어를 선택하여 언어별 추가정보를 확인하세요.`
                : ""
            }
            handleClose={() => {
              setAddOpen(!addOpen);
            }}
          >
            {langs.length > 0 && (
              <SmallSelect
                option={langCd || langs[0]?.value}
                options={langs}
                handleSelect={(target) => {
                  const { value } = target.dataset;
                  setLangCd(value ?? i18n.language);
                }}
              />
            )}
          </ITHeader>
          <div className={addOpen ? "" : "hidden"}>
            <ul>
              <li data-full>
                <label htmlFor="">숙소명</label>
                <input type="text" defaultValue={adInf?.lodNm} readOnly />
              </li>
            </ul>
            <ul>
              <li data-full>
                <label htmlFor="">숙소설명</label>
                <ReadTuiEditor name={"lodDesc"} value={adInf?.lodDesc} />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">숙소우편번호</label>
                <input
                  type="text"
                  name="lodZipc"
                  defaultValue={adInf?.lodZipc}
                  readOnly
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">숙소주소1</label>
                <input type="text" defaultValue={adInf?.lodAddr1} readOnly />
              </li>
              <li>
                <label htmlFor="">숙소주소2</label>
                <input type="text" defaultValue={adInf?.lodAddr2} readOnly />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">숙소주소3</label>
                <input type="text" defaultValue={""} readOnly />
              </li>
              <li>
                <label htmlFor="">숙소주소4</label>
                <input type="text" defaultValue={""} readOnly />
              </li>
            </ul>
          </div>
        </div>
        <div className="tb-form">
          <ITHeader
            sbTtl={"이미지"}
            clsBtn
            isClose={imgOpen}
            handleClose={() => {
              setImgOpen(!imgOpen);
            }}
          />
          <div className={imgOpen ? "" : "hidden"}>
            <ul>
              <li data-full>
                <label htmlFor="">내관 이미지</label>
                <div className={inImg?.length !== 0 ? "img-wrap" : "hidden"}>
                  {inImg &&
                    inImg.map((img: IlodImg) => {
                      return (
                        <div className="img-item" key={img.rgSeq}>
                          <img
                            src={img?.resImgPhUrl}
                            alt={`숙소내관이미지_${img.expsOrdV}`}
                          />
                        </div>
                      );
                    })}
                </div>
                {inImg?.length === 0 && (
                  <p className="w-full flex-1 h-[12.5rem] text-sm font-bold flex justify-center items-center bg-gray-700">
                    등록된 이미지가 없습니다.
                  </p>
                )}
              </li>
            </ul>
            <ul data-brd-top>
              <li data-full>
                <label htmlFor="">외관 이미지</label>
                <div className={outImg?.length !== 0 ? "img-wrap" : "hidden"}>
                  {outImg &&
                    outImg.map((img: IlodImg) => {
                      return (
                        <div className="img-item" key={img.expsOrdV}>
                          <img
                            src={img?.resImgPhUrl}
                            alt={`숙소내관이미지_${img.expsOrdV}`}
                          />
                        </div>
                      );
                    })}
                </div>

                {outImg?.length === 0 && (
                  <p className="flex-1 w-full h-[12.5rem] text-sm font-bold flex justify-center items-center bg-gray-700">
                    등록된 이미지가 없습니다.
                  </p>
                )}
                {/* </div> */}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default LodInfoView;
