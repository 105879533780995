import Select from "components/select";
import LodGrpSelect from "components/select/room/lodGrpNoSelect";
import RmNoSelect from "components/select/room/rmNoSlect";
import { rmSearchProps } from "interface/room";
import { lodGrdCdOptions, lodGrpStatus } from "util/option";
import RmTypeSelect from "components/select/room/rmTypSelect";
import RmOlkSelect from "components/select/room/rmOlkSelect";
import RoomList from "components/tableList/room/list";
import SearchSelect from "components/select/searchSelect";
import { useLocation } from "react-router-dom";
import THeader from "components/layout/header/subHeader/tHeader";
import LodNoSelect from "components/select/room/lodNoSelect";
import LodNmSelect from "components/select/room/lodNmSelect";
import LodGrpNmSelect from "components/select/room/lodGrpNmSelect ";

type IProps = {
  memType: "admin" | "manager";
  searchInfo: rmSearchProps;
  handleChange(target: EventTarget & HTMLInputElement): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleReset(): void;
};

const RoomeListView = ({
  memType,
  searchInfo,
  handleChange,
  handleSelect,
  handleReset,
}: IProps) => {
  const { pathname, search } = useLocation();
  const searchOp = [
    {
      kr: "객실명",
      value: "rmNm",
      en: "RoomName",
    },
    {
      kr: "최대인원수 최소값",
      value: "minPerCt",
      en: "MinPerCount",
    },
    {
      kr: "최대인원수 최대값",
      value: "maxPerCt",
      en: "MaxPerCount",
    },
  ];
  const searchOps =
    memType === "admin"
      ? [
          ...searchOp,
          { kr: "숙소그룹명", value: "logGrpNm", en: "LodGroupName" },
        ]
      : searchOp;

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader
          sbTtl={"객실조회"}
          rstBtn
          newBtn
          url={pathname}
          searchBtn
          handleReset={handleReset}
        />

        <div className="tb-form" data-list>
          {memType === "admin" && (
            <ul>
              <li>
                <label htmlFor="">숙소그룹명</label>
                <LodGrpNmSelect
                  option={searchInfo.lodGrpNo}
                  onSelect={(target) => handleSelect(target)}
                />
              </li>
              <li>
                <label htmlFor="">숙소명</label>
                <LodNmSelect
                  params={searchInfo.lodGrpNo}
                  option={searchInfo.lodNo}
                  onSelect={(target) => handleSelect(target)}
                />
              </li>
              <li>
                <label htmlFor="">숙소등급</label>
                <Select
                  id="lodGrpCd"
                  name="lodGrpCd"
                  option={searchInfo.lodGrpCd || "A"}
                  options={lodGrdCdOptions}
                  onSelect={(target) => handleSelect(target)}
                />
              </li>
            </ul>
          )}
          <ul>
            <li>
              <label htmlFor="">객실번호</label>
              <RmNoSelect
                disabled={!searchInfo.lodNo}
                params={searchInfo.lodNo}
                option={searchInfo.rmNo}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="">객실타입</label>
              <RmTypeSelect
                option={searchInfo.rmTypCd}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="">객실전망</label>
              <RmOlkSelect
                option={searchInfo.rmOlkCd}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">상세</label>
              <Select
                id="uyn"
                name="uyn"
                option={searchInfo.uyn || "A"}
                options={lodGrpStatus}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="">검색조건</label>
              <SearchSelect
                id={"searchTyp"}
                name={searchInfo.searchTyp ?? ""}
                option={searchInfo.searchTyp || "A"}
                value={
                  searchInfo[
                    `${searchInfo.searchTyp}` as keyof rmSearchProps
                  ] || ""
                }
                options={searchOps}
                onSelect={(target) => handleSelect(target)}
                onChange={(target) => handleChange(target)}
              />
            </li>
          </ul>
        </div>
        <RoomList memType={memType} searchInfo={searchInfo} />
      </div>
    </>
  );
};

export default RoomeListView;
