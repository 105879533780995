import { searchProps } from "interface/member";
import { authOptions, memStatus } from "util/option";
import PhoneSelect from "components/select/phone";
import Select from "components/select";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMemNo } from "store/memberSlice";
import AdminList from "components/tableList/member/adminList";
import ManagerList from "components/tableList/member/managerList";
import UserList from "components/tableList/member/userList";
import { useLocation } from "react-router-dom";
import THeader from "components/layout/header/subHeader/tHeader";
import Redio from "components/radio";
import SearchSelect from "components/select/searchSelect";
import DatePick from "components/datePick/datePick";

type IProps = {
  searchInfo: Partial<searchProps>;
  handleChange(name: string, value: string): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleReset(): void;
};

const MemberListView = ({
  searchInfo,
  handleChange,
  handleSelect,
  handleReset,
}: IProps) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const memType = pathname.includes("admin")
    ? "admin"
    : pathname.includes("manager")
    ? "manager"
    : "";

  const memtype =
    searchInfo.type === "admin"
      ? "관리자"
      : searchInfo.type === "manager"
      ? "매니저"
      : "회원";

  useEffect(() => {
    dispatch(setMemNo(""));
  }, []);

  const searchOp = [
    {
      kr: "이메일",
      value: "emlId",
      en: "EMAIL",
    },
    {
      kr: "전화번호",
      value: "mobNo",
      en: "PHONENO",
    },
  ];

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader
          sbTtl={memtype}
          rstBtn
          url={pathname}
          searchBtn
          handleReset={handleReset}
        />

        <div className="tb-form" data-list>
          <ul>
            <li>
              <label htmlFor="">회원타입</label>
              <div className="flex">
                <Redio
                  id={"admin"}
                  name={"memTyp"}
                  label={"관리자"}
                  checked={searchInfo.type === "admin"}
                  onChange={(name, value) => handleChange(name, value)}
                />
                <Redio
                  id={"manager"}
                  name={"memTyp"}
                  label={"매니저"}
                  checked={searchInfo.type === "manager"}
                  onChange={(name, value) => handleChange(name, value)}
                />
                {/* <Redio
                  id={"ub"}
                  name={"memTyp"}
                  label={"유버"}
                  checked={searchInfo.type === "ub"}
                  onChange={(name, value) => handleChange(name, value)}
                /> */}
                <Redio
                  id={"user"}
                  name={"memTyp"}
                  label={"회원"}
                  checked={searchInfo.type === "user"}
                  onChange={(name, value) => handleChange(name, value)}
                />
              </div>
            </li>
          </ul>
          <ul>
            {searchInfo.type === "admin" && (
              <li>
                <label htmlFor="">관리자 권한</label>
                <Select
                  id={"authGrpCod"}
                  name={"authGrpCod"}
                  option={searchInfo.authGrpCod}
                  options={authOptions}
                  onSelect={handleSelect}
                />
              </li>
            )}
            <li>
              <label htmlFor="">국가번호</label>
              <PhoneSelect
                handleSelect={handleSelect}
                mobNo={searchInfo.mobNo}
                mobCd={searchInfo.mobCntyCd}
              />
            </li>
            <li>
              <label htmlFor="">회원상태</label>
              <Select
                option={searchInfo.memStatus}
                options={memStatus}
                onSelect={handleSelect}
                isInitial
                id={"memStatus"}
                name={"memStatus"}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">검색조건</label>
              <SearchSelect
                id={"searchTyp"}
                name={searchInfo.searchTyp ?? ""}
                options={searchOp}
                option={searchInfo.searchTyp || "A"}
                value={
                  searchInfo[`${searchInfo.searchTyp}` as keyof searchProps] ||
                  ""
                }
                onSelect={handleSelect}
                onChange={(target) => handleChange(target.name, target.value)}
              />
            </li>
          </ul>

          {/* {searchInfo.type === "user" && (
            <ul>
              <li>
                <DatePick
                  label={"회원가입일조회자"}
                  name="joiReqDtti"
                  date={searchInfo.joiReqDtti ?? ""}
                  handleChange={(name, value) => handleChange(name, value)}
                />
              </li>
              <li>
                <DatePick
                  label={"회원탈퇴조회일자"}
                  name="soDtti"
                  date={searchInfo.soDtti ?? ""}
                  handleChange={(name, value) => handleChange(name, value)}
                />
              </li>
            </ul>
          )} */}
        </div>
      </div>

      {searchInfo?.type === "admin" && <AdminList searchInfo={searchInfo} />}
      {searchInfo?.type === "manager" && (
        <ManagerList searchInfo={searchInfo} />
      )}
      {searchInfo?.type === "user" && <UserList searchInfo={searchInfo} />}
    </>
  );
};

export default MemberListView;
