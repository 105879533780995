import { useMutation } from "@tanstack/react-query";
import { calcListApi } from "components/api/calc";
import SearchHeader from "components/layout/header/subHeader/srchHeader";
import Pagenation from "components/pagenation";
import { pageNavProps } from "interface";
import { IcalcListProps } from "interface/calc";
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { isSearchSelector, setIsLoading, setIsSearch } from "store/commonSlice";
import { dateFormat } from "util/common";
import { calcPromHead } from "util/option";

type IProps = {
  searchInfo: any;
};

type IListProps = {
  col: number;
  searchInfo: any;
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};

const List = ({ col, searchInfo, setPageNav }: IListProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();
  const isSearch = useSelector(isSearchSelector);
  const [list, setList] = useState<IcalcListProps[]>([]);

  const calcMutation = useMutation(calcListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setList(body?.calcList);
          setPageNav(body?.pageNav);
        }
        if (code === -1) {
        }
      }
    },
  });

  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch) {
      const params = {
        promCd: searchInfo.promCd || state?.promCd,
        rmResvNo: searchInfo.rmResvNo || state?.rmResvNo,
        resvCnlYn: searchInfo.resvCnlYn || state?.resvCnlYn,
        sDt:
          searchInfo.sDt.replaceAll("-", "") || state?.sDt.replaceAll("-", ""),
        eDt:
          searchInfo.eDt.replaceAll("-", "") || state?.eDt.replaceAll("-", ""),
        pageNo: page || state?.pageNo,
      };
      console.log("calulate params", params);
      calcMutation.mutate(params);
    }
  }, [isSearch, searchInfo, search]);

  const pageRows = calcMutation.data?.body?.pageNav;
  const isNotData = !pageRows;

  return (
    <>
      {list.length === 0 && (
        <tr>
          <td colSpan={col}>
            {isNotData && (
              <span className="font-bold">검색조건을 선택하여 주세요.</span>
            )}
            {!isNotData && (
              <span className="font-bold">검색결과가 없습니다.</span>
            )}
          </td>
        </tr>
      )}
      {list &&
        list.map((li: IcalcListProps, index) => {
          return (
            <tr
              key={index}
              onClick={(e) => {
                e.preventDefault();
                navigate(`info?promCd=${li.promCd}`, {
                  state: {
                    searchInfo,
                    url: pathname + search,
                  },
                });
                dispatch(setIsSearch(false));
              }}
            >
              <td>{(Number(page) - 1) * (pageRows || 10) + index + 1}</td>
              <td>{li?.promCd}</td>
              <td>{li?.rmResvNo}</td>
              <td>{li.resvCnlYn === "Y" ? "취소" : ""}</td>
              <td>{dateFormat(li?.fstCrtDtti ?? "")}</td>
              <td>{dateFormat(li?.ltChDtti ?? "")}</td>
            </tr>
          );
        })}
    </>
  );
};

const CalculateList = ({ searchInfo }: IProps) => {
  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const col = calcPromHead.length;

  return (
    <>
      <div className="list-wrap">
        <SearchHeader pageNav={pageNav} />
        <div>
          <table>
            <thead>
              <tr>
                {calcPromHead.map((li, index) => (
                  <th
                    key={index}
                    className={index === calcPromHead.length - 1 ? "auto" : ""}
                  >
                    {li}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <Suspense>
                <List
                  col={col}
                  searchInfo={searchInfo}
                  setPageNav={setPageNav}
                />
              </Suspense>
              {pageNav && pageNav?.totalRows > 0 && (
                <tr>
                  <td colSpan={col}>
                    <Pagenation total={pageNav?.totalPage} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CalculateList;
