import { clcPromProps } from "interface/calc";
import api from "./api";

export const calcListApi = async (params: clcPromProps) => {
  const res = await api({
    method: "post",
    url: "admin/calc/calcList",
    data: params,
  });

  return res;
};
