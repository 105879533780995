import { useMutation } from "@tanstack/react-query";
import { lodDtlApi } from "components/api/lod";
import LodInfoView from "components/view/lod/lod/info";
import i18n from "locales/i18n";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";

const LodInfoPage = () => {
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const lodNo = search.replace("?lodNo=", "");

  const lodMutation = useMutation(lodDtlApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  useEffect(() => {
    lodMutation.mutate({
      lodNo,
      langCd: i18n.language,
      imgTypCd: "",
      uyn: "Y",
    });
  }, []);

  const lodDftDtl = lodMutation.data?.body?.lodDftDtl;
  const lodInfDtl = lodMutation.data?.body?.lodInfDtl;
  const lodManDtl = lodMutation.data?.body?.lodManDtl;
  const lodPayDtl = lodMutation.data?.body?.lodPayDtl;
  const lodAdList = lodMutation.data?.body?.lodAdList;
  const lodImgList = lodMutation.data?.body?.lodImgList;

  return (
    <>
      <LodInfoView
        lodDftDtl={lodDftDtl}
        lodInfDtl={lodInfDtl}
        lodManDtl={lodManDtl}
        lodPayDtl={lodPayDtl}
        lodAdList={lodAdList}
        lodImgList={lodImgList}
      />
    </>
  );
};

export default LodInfoPage;
