import THeader from "components/layout/header/subHeader/tHeader";
import { useLocation } from "react-router-dom";

type IProps = {
  isLoading: boolean;
  info: {
    pwd: string;
    pwd_chk: string;
  };
  handleChange(name: string, value: string): void;
  handleSetting(): void;
};

const Step4View = ({
  isLoading,
  info,
  handleChange,
  handleSetting,
}: IProps) => {
  const { pathname } = useLocation();
  const type = pathname.includes("admin") ? "admin" : "manager";

  return (
    <>
      <div className="main-cont">
        <div className="card-form" data-dir-col>
          <THeader
            sbTtl={
              (pathname.includes("admin") ? "관리자" : "매니저") +
              "비밀번호 설정"
            }
            handleReset={() => {}}
          />
          <div className="tb-form flex flex-col items-center max-w-[50rem]">
            <p className="p-2 m-2 text-xs bg-gray-200 w-full leading-5">
              비밀번호는 8자리 이상 20자리 이내로 등록해주세요. <br />
              비밀번호 구성은 영문, 숫자, 특수문자를 포함해야 합니다. <br />
              특수문자로 허용되는 문자는 !, @, #, $, %, ^, &, * 입니다.
            </p>
            <ul className="">
              <li>
                <label htmlFor="">비밀번호</label>
                <input
                  type="password"
                  name="pwd"
                  maxLength={20}
                  value={info.pwd}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    handleChange("pwd", value);
                  }}
                />
              </li>
            </ul>
            <ul data-n-brd className="pb-3">
              <li>
                <label htmlFor="">비밀번호 확인</label>
                <input
                  type="password"
                  name="pwd_chk"
                  value={info.pwd_chk}
                  data-err={info.pwd !== info.pwd_chk}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    handleChange("pwd_chk", value);
                  }}
                />
              </li>
            </ul>
          </div>
          <div className="pt-3 lex justify-center  border-t-2">
            <button className="btn-md" onClick={handleSetting}>
              비밀번호 설정
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step4View;
