import THeader from "components/layout/header/subHeader/tHeader";
import CountrySelect from "components/select/country";
import PromotionList from "components/tableList/promotion/list";
import { useLocation } from "react-router-dom";

type IProps = {
  searchInfo: any;
  handleReset(): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleChange(name: string, value: string): void;
};

const PromView = ({
  searchInfo,
  handleChange,
  handleSelect,
  handleReset,
}: IProps) => {
  const { pathname } = useLocation();
  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader
          sbTtl={"프로모션조회"}
          rstBtn
          newBtn
          url={pathname}
          searchBtn
          handleReset={handleReset}
        />

        <div className="tb-form" data-list>
          <ul>
            <li>
              <label htmlFor="">프로모션코드</label>
              <input
                type="text"
                value={searchInfo.promCd}
                onChange={(e) => handleChange("promCd", e.currentTarget.value)}
              />
            </li>
            <li>
              <label htmlFor="">인플루언서명</label>
              <input
                type="text"
                value={searchInfo.influNm}
                onChange={(e) => handleChange("influNm", e.currentTarget.value)}
              />
            </li>
            <li>
              <label htmlFor="">인플루언서국가</label>
              <CountrySelect
                option={searchInfo.influCnty}
                handleSelect={handleSelect}
              />
            </li>
          </ul>
        </div>
      </div>
      <PromotionList searchInfo={searchInfo} />
    </>
  );
};

export default PromView;
