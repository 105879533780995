import "./term.scss";
import { RefObject } from "react";
import { useLocation } from "react-router-dom";
import Select from "components/select";
import { langOptions, userTypOptions } from "util/option";
import TermList from "components/tableList/term/list";
import THeader from "components/layout/header/subHeader/tHeader";

type IProps = {
  searchInfo: {
    userType: string;
    langCd: string;
  };
  termRef: RefObject<HTMLDivElement>;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleSearch(): void;
  handleReset(): void;
};

const TermListView = ({
  termRef,
  searchInfo,
  handleSelect,
  handleReset,
  handleSearch,
}: // handlePrvList,
IProps) => {
  const { pathname } = useLocation();

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader
          sbTtl={"약관조회"}
          rstBtn
          newBtn
          url={pathname}
          searchBtn
          handleReset={handleReset}
        />
        <div className="tb-form" data-list>
          <ul>
            <li>
              <label htmlFor="">회원타입</label>
              <Select
                id={"userType"}
                name={"userType"}
                option={searchInfo.userType}
                options={userTypOptions}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="">언어</label>
              <Select
                id={"langCd"}
                name={"langCd"}
                option={searchInfo.langCd}
                options={langOptions}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
          </ul>
        </div>
      </div>

      <TermList searchInfo={searchInfo} />
    </>
  );
};

export default TermListView;
