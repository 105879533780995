import { useMutation, useQueryClient } from "@tanstack/react-query";
import { rmAddCreateApi } from "components/api/room";
import InContet from "components/inContent";
import Modal from "components/modal/modal";
import Select from "components/select";
import InTuiEditor from "components/tuiEditor/inEditot";
import { langProps } from "interface";
import i18n from "locales/i18n";
import { Dispatch, SetStateAction, useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";

type IProps = {
  langCd: string;
  langOp: langProps[];
  isOpen: string;
  rmNo: string;
  setLangCd: Dispatch<SetStateAction<string>>;
  setIsOpen: Dispatch<SetStateAction<string>>;
};

const AddModal = ({
  langCd,
  langOp,
  isOpen,
  rmNo,
  setIsOpen,
  setLangCd,
}: IProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [adInf, setAdInf] = useState({
    rmNm: "",
    rmDesc: "",
  });

  const handleChange = (name: string, value: string) => {
    setAdInf({
      ...adInf,
      [name]: value,
    });
  };

  const createMutation = useMutation(rmAddCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "추가정보를 추가하였습니다.",
              isConfirm: true,
              handleClick() {
                queryClient.invalidateQueries({ queryKey: ["rmInf"] });
                dispatch(setMessage({ message: null }));
                setIsOpen("");
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleAdCreate = () => {
    if (!langCd) {
      return alert("언어를 선택하여 주세요.");
    }

    if (!adInf.rmDesc || !adInf.rmNm) {
      return alert("추가정보를 입력하여 주세요.");
    }

    const params = {
      rmNo,
      rmInfList: [
        {
          ...adInf,
          langCd,
        },
      ],
    };

    console.log("params", params);

    dispatch(
      setMessage({
        message: "추가정보를 추가하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          createMutation.mutate(params);
        },
      })
    );
  };

  return (
    <>
      <Modal
        title={"추가정보 추가"}
        styles="w-5/6"
        btnText={"추가"}
        isOpen={isOpen !== ""}
        handleCloseModal={() => {
          setIsOpen("");
          setAdInf({
            rmNm: "",
            rmDesc: "",
          });
          setLangCd(i18n.language);
        }}
        onClick={handleAdCreate}
      >
        <div className="tb-form mt-[3rem] " data-t-brd>
          <ul>
            <li>
              <label htmlFor="">언어선택</label>
              <Select
                id={"langCd"}
                name={"langCd"}
                option={langCd}
                options={langOp}
                isInitial
                onSelect={(target) => {
                  const { value } = target.dataset;
                  if (!!value) {
                    setLangCd(value);
                  }
                }}
              />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">객실명</label>
              <InContet
                name={"rmNm"}
                value={adInf.rmNm}
                onChange={(value) => handleChange("rmNm", value)}
              />
            </li>
          </ul>
          <ul className="pb-2">
            <li data-full>
              <label htmlFor="">객실설명</label>
              <InTuiEditor
                name={"rmDesc"}
                value={adInf.rmDesc}
                onChange={(value) => handleChange("rmDesc", value)}
              />
            </li>
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default AddModal;
