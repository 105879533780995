import HomeView from "components/view/home";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setIsSelect } from "store/commonSlice";

const ManageHomePage = () => {
  const dispatch = useDispatch();

  const [lodNo, setLodNo] = useState<string>(
    localStorage.getItem("lodNo") ?? ""
  );

  // const { data } = useQuery({
  //   queryKey: ["homeInfo", "manager"],
  //   queryFn: () => {
  //     return homeManagerInfoApi();
  //   },
  //   enabled: !!lodNo,
  //   onSuccess(data) {
  //     if (data) {
  //       const {
  //         header: { code, message },
  //         body,
  //       } = data;
  //       if (code === 0) {
  //       }
  //       if (code === -1) {
  //         dispatch(
  //           setMessage({
  //             message: message || "검색 데이터가 없습니다.",
  //             isConfirm: true,
  //             handleClick() {
  //               dispatch(setMessage({ message: null }));
  //             },
  //           })
  //         );
  //       }
  //     }
  //   },
  // });

  // const lodList = data?.body?.lodList || [];

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const value = target.dataset.value + "";

    setLodNo(value === "A" ? "" : value);

    if (value !== "A") {
      localStorage.setItem("lodNo", value);
    }

    if (value === "A") {
      localStorage.removeItem("lodNo");
      dispatch(setIsSelect(false));

      return;
    }
  };

  return <HomeView lodNo={lodNo} data={{}} handleSelect={handleSelect} />;
};

export default ManageHomePage;
