import Header from "./header";
import "./layout.scss";
import GBN from "./gbn";
import { useSelector } from "react-redux";
import {
  messageSelector,
  setIsLoading,
  setIsSearch,
  setMessage,
} from "store/commonSlice";
import { useDispatch } from "react-redux";
import NotiModal from "components/modal/notiModal";
import { Outlet, useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";

type IProps = {
  isLog?: boolean;
  memInf?: any;
};

const Layout = ({ isLog, memInf }: IProps) => {
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();

  const mainRef = useRef<HTMLElement>(null);

  const loginObject = sessionStorage.getItem("login");
  const isLogin = (loginObject && JSON.parse(loginObject).value) || null;
  const memTyp = localStorage.getItem("memTyp") ?? "";

  const {
    title,
    canclBntText,
    cnfrmBtnText,
    message,
    isCancel,
    isConfirm,
    handleCancel,
    handleClick,
  } = useSelector(messageSelector);

  const handleCloseModal = () => {
    dispatch(setMessage({ message: null }));
  };

  useEffect(() => {
    dispatch(setIsSearch(false));
    dispatch(setIsLoading(false));
  }, [pathname]);

  const msg = message?.split("/");

  useEffect(() => {
    mainRef.current?.style.setProperty(
      "--main-pd-left",
      !!isLogin ? "220px" : "0px"
    );
    mainRef.current?.style.setProperty(
      "--main-pd-right",
      !!isLogin ? "40px" : "0px"
    );

    return () => {
      mainRef.current?.style.removeProperty("--main-pd-left");
      mainRef.current?.style.removeProperty("--main-pd-right");
    };
  }, [isLogin]);

  return (
    <div className="flex flex-col">
      <Header />
      <section>
        {!!isLogin && <GBN />}
        <main ref={mainRef}>
          <Outlet context={memInf} />
          <NotiModal
            title={title}
            isOpen={!!message}
            canclBntText={canclBntText}
            cnfrmBtnText={cnfrmBtnText}
            isCancel={isCancel}
            isConfirm={isConfirm}
            handleCloseModal={handleCloseModal}
            handleClick={handleClick}
            handleCancel={handleCancel}
          >
            <div>
              {msg?.map((msg) => (
                <span key={msg}>{msg}</span>
              ))}
            </div>
          </NotiModal>
        </main>
      </section>
    </div>
  );
};

export default Layout;
