import "./select.scss";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { countryCode } from "../countryCode";
import { setOpenPhoneSelect } from "store/commonSlice";
import i18n from "locales/i18n";
import { langOptions } from "interface";

type IProps = {
  wrapStyle?: string;
  optionStyle?: string;
  btnStyle?: string;
  inpStyle?: string;
  disabled?: boolean;
  mobCd?: string;
  direct?: "up" | "down";
  mobNo?: string;
  isNum?: boolean;
  isNm?: boolean;
  name?: string;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleChange?(target: EventTarget & HTMLInputElement): void;
};

export interface codeProps {
  kr: string;
  en: string;
  phone: string;
  code: string;
}

const PhoneSelect = ({
  wrapStyle = "relative",
  optionStyle = "bg-white",
  btnStyle = "",
  inpStyle,
  disabled = false,
  mobCd = "+82",
  mobNo,
  isNum = false,
  isNm = false,
  name = "mobCntyCd",
  handleSelect,
  handleChange,
}: IProps) => {
  const dispatch = useDispatch();
  const lng = i18n.language;
  const ulRef = useRef<HTMLDivElement>(null);

  const optionRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const mobCntNm = countryCode.find((cnt) => {
    return "+" + cnt.phone === mobCd;
  });

  const outSideClick = (e: any) => {
    if (ulRef.current && !ulRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", outSideClick);

    return () => {
      document.removeEventListener("click", outSideClick);
    };
  }, [ulRef]);

  return (
    <div className={`${wrapStyle} phone-select-wrap`} ref={ulRef}>
      <button
        className={`${btnStyle} ${isOpen ? "on" : ""}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        disabled={disabled}
      >
        <span className="text-xs">
          {mobCd}
          {!isNm && (
            <span className={"ps-2"}>
              ( {lng === "kr" ? mobCntNm?.kr : mobCntNm?.en} )
            </span>
          )}
        </span>
      </button>

      {isNum && (
        <input
          type="text"
          // placeholder={t("plchl_nm_cmm")}
          placeholder="숫자만 입력"
          value={mobNo}
          name="mobNo"
          maxLength={11}
          className={inpStyle}
          onChange={(e) => {
            const isNum = !/[^0-9]/.test(e.currentTarget.value);
            if (isNum || !e.currentTarget.value) {
              handleChange && handleChange(e.currentTarget);
            } else {
              return;
            }
          }}
        />
      )}

      <div
        className={`${optionStyle}  option-wrap ${isOpen ? `on` : ""} `}
        ref={optionRef}
        onClick={(e) => {
          e.stopPropagation();
          dispatch(setOpenPhoneSelect(false));
        }}
      >
        <ul className={`${isOpen ? ` on` : ""}`}>
          <li className="list-title">
            {!isNm && <span>국가명</span>}
            <span>국가번호</span>
            {/* <span>국가코드</span> */}
            {/* <span>{t("sb_ttl_phnm_cmm")}</span> */}
            {/* <span>{t("sb_ttl_phno_cmm")}</span> */}
            {/* <span>{t("sb_ttl_phcd_cmm")}</span> */}
          </li>
          {countryCode.map((op: codeProps) => (
            <li
              key={op.kr}
              data-name={name}
              data-lng={op[lng as keyof codeProps]}
              data-value={"+" + op.phone}
              data-code={op.code}
              data-selected={mobCntNm?.phone === op.phone}
              className={""}
              onClick={(e) => {
                handleSelect(e.currentTarget);
                setIsOpen(!isOpen);
              }}
            >
              {!isNm && <span>{op[lng as keyof codeProps]}</span>}
              {/* <span>{op[lng as keyof codeProps]}</span> */}
              <span>+{op.phone} </span>
              {/* <span> {op.code}</span> */}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PhoneSelect;
