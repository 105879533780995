import { lodGrpHead } from "util/option";
import { IlodGrpList, lodGrpSearchProps } from "interface/lod";
import { useLocation, useNavigate } from "react-router-dom";
import Pagenation from "components/pagenation";
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setIsLoading,
  setIsSearch,
  setMessage,
  isSearchSelector,
} from "store/commonSlice";
import { useSelector } from "react-redux";
import { pageNavProps } from "interface";
import { useMutation } from "@tanstack/react-query";
import { lodGrpListApi, lodGrpStatusApi } from "components/api/lod";
import i18n from "locales/i18n";
import SearchHeader from "components/layout/header/subHeader/srchHeader";

type IProps = {
  memType: "admin" | "manager";
  searchInfo: Omit<lodGrpSearchProps, "pageNo" | "langCd">;
};

type IListProps = {
  searchInfo: Omit<lodGrpSearchProps, "pageNo" | "langCd">;
  col: number;
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};

const List = ({ searchInfo, col, setPageNav }: IListProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search, state, pathname } = useLocation();
  const isSearch = useSelector(isSearchSelector);

  const [list, setList] = useState([]);

  const lodGrpMutation = useMutation(lodGrpListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;

        dispatch(setIsLoading(false));
        dispatch(setIsSearch(false));

        if (code === 0) {
          setPageNav(body?.pageNav);
          setList(body?.lodGrpList);
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const statusMutation = useMutation(lodGrpStatusApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "숙소그룹 상태를 변경하였습니다,",
              isConfirm: true,
              handleClick() {
                dispatch(setIsSearch(true));
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleStatus = (lodGrpNo: string, uyn: string) => {
    dispatch(
      setMessage({
        message: "숙소그룹 상태를 변경하시겠습니까?",
        isConfirm: true,
        isCancel: true,
        handleClick() {
          statusMutation.mutate({
            lodGrpNo,
            uyn: uyn === "Y" ? "N" : "Y",
          });
        },
      })
    );
  };

  let page = search.includes("?page=") ? search.replace("?page=", "") : null;

  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch) {
      const params = {
        lodGrpNo: searchInfo?.lodGrpNo || state?.lodGrpNo,
        lodGrpNm: searchInfo?.lodGrpNm || state?.lodGrpNm,
        langCd: i18n.language ?? "kr",
        lodGrpAddr1: searchInfo?.lodGrpAddr1 || state?.lodGrpAddr1,
        lodGrpAddr2: searchInfo?.lodGrpAddr2 || state?.lodGrpAddr2,
        lodGrpCntyCd: searchInfo?.lodGrpCntyCd || state?.lodGrpCntyCd,
        uyn: searchInfo?.uyn || state?.uyn,
        pageNo: page || state?.pageNo,
      };
      console.log("lodGrplist params", params);

      lodGrpMutation.mutate(params);
    }
  }, [isSearch, searchInfo, page]);

  const pageRows = lodGrpMutation.data?.body?.pageNav?.pageRows;
  const isNotData = !pageRows;

  return (
    <>
      {list.length === 0 && (
        <tr>
          <td colSpan={col}>
            {isNotData && (
              <span className="font-bold">검색조건을 선택하여 주세요.</span>
            )}
            {!isNotData && (
              <span className="font-bold">검색결과가 없습니다.</span>
            )}
          </td>
        </tr>
      )}
      {list &&
        list?.map((li: IlodGrpList, index: number) => {
          return (
            <tr
              key={index}
              onClick={(e) => {
                e.preventDefault();
                navigate(`info?lodGrpNo=${li.lodGrpNo}`, {
                  state: {
                    searchInfo,
                    url: pathname + search,
                  },
                });
                dispatch(setIsSearch(false));
              }}
            >
              <td>{(Number(page) - 1) * (pageRows || 10) + index + 1}</td>
              <td>
                <button>{li.lodGrpNo}</button>
              </td>
              <td>{li.lodGrpNm}</td>
              <td>{li.lodGrpCntyCd}</td>
              <td>{li.lodGrpAddr1 ?? "-"}</td>
              <td>{li.lodGrpAddr2 ?? "-"}</td>
              <td>
                <button
                  className={li.uyn === "Y" ? "green" : "red"}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleStatus(li.lodGrpNo, li.uyn);
                  }}
                >
                  {li.uyn === "Y" ? "사용" : "미 사용"}
                </button>
              </td>
            </tr>
          );
        })}
    </>
  );
};

const LodGrpList = ({ searchInfo }: IProps) => {
  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const headList = lodGrpHead.map((head) => {
    return head;
  });

  // const [No, lodGrpNm, lodGrpCd, lodNm, ...res] = headList;
  const col = lodGrpHead.length;

  return (
    <div className="list-wrap">
      <SearchHeader pageNav={pageNav} />
      <div>
        <table>
          <thead>
            <tr>
              {headList.map((li) => (
                <th key={li}>{li}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            <Suspense>
              <List searchInfo={searchInfo} col={col} setPageNav={setPageNav} />
            </Suspense>
            {pageNav && pageNav?.totalRows > 0 && (
              <tr>
                <td colSpan={col}>
                  <Pagenation total={pageNav?.totalPage} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LodGrpList;
