import { useMutation } from "@tanstack/react-query";
import { rmAddCreateApi } from "components/api/room";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import Modal from "components/modal/modal";
import InContet from "components/inContent";
import Select from "components/select";
import InTuiEditor from "components/tuiEditor/inEditot";
import RedTuiEditor from "components/tuiEditor/redEditor";
import i18n from "locales/i18n";
import { MutableRefObject, useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";
import { langOptions } from "util/option";
import SmallSelect from "components/select/smSelect";
import { langProps } from "interface";
import { IrmInfo } from "interface/room";
import { setIsSearch } from "store/commonSlice";

type IProsp = {
  rmNoRef: MutableRefObject<string>;
  adList: IrmInfo["rmInfList"];
};

const AdInfCreate = ({ rmNoRef, adList }: IProsp) => {
  const dispatch = useDispatch();

  let isLangOp = [] as langProps[];
  let addOp = [] as langProps[];

  langOptions.forEach((lang) => {
    const isAble = adList?.some((ad) => {
      return lang.value === ad.langCd;
    });

    if (isAble) {
      isLangOp.push(lang);
    }
    if (!isAble) {
      addOp.push(lang);
    }
  });

  const [adInf, setAdInf] = useState({
    langCd: i18n.language,
    rmNm: "",
    rmDesc: "",
  });

  const [adOpen, setAdOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const [langCd, setLangCd] = useState(i18n.language);

  const handleChange = (name: string, value: string) => {
    setAdInf({
      ...adInf,
      [name]: value,
    });
  };

  const rmAddCreateMutaion = useMutation(rmAddCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "추가정보를 등록하였습니다.",
              isConfirm: true,
              handleClick() {
                // queryClient.invalidateQueries({ queryKey: ["rmInf"] });
                dispatch(setIsSearch(true));
                dispatch(setMessage({ message: null }));
                setAdOpen(false);
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCreate = () => {
    if (!adInf.langCd) {
      return alert("추가정보 언어를 선택하여 주세요.");
    }

    if (!adInf.rmDesc || !adInf.rmNm) {
      return alert("추가정보를 입력하여 주세요.");
    }
    const params = {
      rmNo: rmNoRef.current,
      rmInfList: [adInf],
    };

    console.log("rm params", params);

    dispatch(
      setMessage({
        message: "정보를 추가하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          rmAddCreateMutaion.mutate(params);
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"추가정보"}
          createBtn
          createBtnTxt="언어추가"
          clsBtn
          desc={
            isLangOp.length === 0
              ? ""
              : "언어를 선택하여 언어별 추가정보를 확인하세요."
          }
          isClose={isOpen}
          handleCreate={() => {
            setAdOpen(true);
          }}
          handleClose={() => {
            setIsOpen(false);
          }}
        >
          {isLangOp.length > 0 && (
            <SmallSelect
              handleSelect={(target) => {
                const { value } = target.dataset;
                setLangCd(value ?? "");
              }}
              option={langCd}
            />
          )}
        </ITHeader>
        <div className={isOpen ? "" : "hidden"}>
          <ul>
            <li data-full>
              <label htmlFor="" data-end>
                객실명
              </label>
              <input type="text" value={""} readOnly onChange={() => {}} />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="" data-end>
                객실설명
              </label>
              <RedTuiEditor name={"rmDesc"} info={{}} />
            </li>
          </ul>
        </div>
      </div>
      <Modal
        title={"추가정보"}
        isOpen={adOpen}
        btnText="추가"
        styles="w-[90%]"
        handleCloseModal={() => setAdOpen(false)}
        onClick={handleCreate}
      >
        <div className="tb-form mt-[3rem]">
          <ul data-brd-top>
            <li>
              <label htmlFor="">언어선택</label>
              <Select
                id={"langCd"}
                name={"langCd"}
                option={adInf.langCd}
                options={langOptions}
                isInitial
                onSelect={(target) => {
                  const { value } = target.dataset;
                  setAdInf({
                    ...adInf,
                    langCd: value ?? "",
                  });
                }}
              />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="" data-end>
                객실명
              </label>
              <InContet
                name={"rmNm"}
                full
                value={adInf.rmNm}
                onChange={(value) => {
                  handleChange("rmNm", value);
                }}
                placeholder={"객실명 입력하세요"}
              />
            </li>
          </ul>
          <ul className="pb-2">
            <li data-full>
              <label htmlFor="" data-end>
                객실설명
              </label>
              <InTuiEditor
                name={"rmDesc"}
                value={adInf.rmDesc}
                onChange={(value) => {
                  handleChange("rmDesc", value);
                }}
              />
            </li>
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default AdInfCreate;
