import { useMutation } from "@tanstack/react-query";
import { lodAdCreateApi, lodAdListApi } from "components/api/lod";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import Modal from "components/modal/modal";
import { lodAdProps } from "interface/lod";
import i18n from "locales/i18n";
import { MutableRefObject, useState } from "react";
import { useDispatch } from "react-redux";
import { setIsSuccess, setMessage } from "store/commonSlice";
import { langOptions } from "util/option";
import ReadTuiEditor from "components/tuiEditor/readEditor";
import { langProps } from "interface";
import InTuiEditor from "components/tuiEditor/inEditot";
import SmallSelect from "components/select/smSelect";
import Select from "components/select";

type IProps = {
  lodNo: MutableRefObject<string>;
};

const AdInfCreateView = ({ lodNo }: IProps) => {
  const dispatch = useDispatch();

  let isLangOp = [] as langProps[];
  let addOp = [] as langProps[];

  const [adList, setAdList] = useState<lodAdProps[]>([]);
  const [langCd, setLangCd] = useState(i18n.language);

  const adListMutation = useMutation(lodAdListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setAdList(body?.lodAdList);
          setLangCd(body?.lodAdList[0].langCd);
          dispatch(setIsSuccess(false));
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  langOptions.forEach((lang) => {
    const isAble = adList.some((ad) => {
      return lang.value === ad.langCd;
    });

    if (isAble) {
      isLangOp.push(lang);
    }

    if (!isAble) {
      addOp.push(lang);
    }
  });

  let adInfo = adList.find((ad) => {
    return ad.langCd === langCd;
  });

  const [adInf, setAdInf] = useState<Omit<lodAdProps, "lodNo">>({
    langCd: addOp[0]?.value ?? i18n.language,
    lodNm: "",
    lodDesc: "",
    lodZipc: "",
    lodCntyCd: "KOR",
    lodAddr: "",
    lodAddr1: "",
    lodAddr2: "",
    lodAddr3: "",
    lodAddr4: "",
    lodDtlAddr: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [infOpen, setInfOpen] = useState(false);

  const handleChange = (name: string, value: string) => {
    setAdInf({
      ...adInf,
      [name]: value,
    });
  };

  const adMutation = useMutation(lodAdCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "추가정보를 등록하였습니다.",
              isConfirm: true,
              handleClick() {
                setIsOpen(false);
                adListMutation.mutate({
                  lodNo: lodNo.current,
                });
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleClose = () => {
    setIsOpen(false);
    setAdInf({
      langCd: addOp[0]?.value ?? i18n.language,
      lodNm: "",
      lodDesc: "",
      lodZipc: "",
      lodCntyCd: "KOR",
      lodAddr: "",
      lodAddr1: "",
      lodAddr2: "",
      lodAddr3: "",
      lodAddr4: "",
      lodDtlAddr: "",
    });
  };

  const handleCreate = () => {
    if (!lodNo.current) {
      return alert("기본정보 등록후 추가정보를 등록하여 주세요.");
    }

    if (!adInf.langCd) {
      return alert("언어를 선택하여 주세요.");
    }

    const params = {
      ...adInf,
      lodNo: lodNo.current,
    };

    console.log("params", params);

    dispatch(
      setMessage({
        message: "추가정보를 추가하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          adMutation.mutate(params);
        },
      })
    );
  };

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"추가정보"}
          clsBtn
          addBtn={addOp.length > 0}
          addBtnTxt="언어추가"
          desc="* 기본정보 등록후 추가정보 등록이 가능합니다."
          isClose={infOpen}
          handleAdCreate={() => {
            setIsOpen(true);
            setLangCd(isLangOp[0]?.value ?? i18n.language);
            setAdInf({
              langCd: addOp[0]?.value || i18n.language,
              lodNm: "",
              lodDesc: "",
              lodZipc: "",
              lodCntyCd: "KOR",
              lodAddr: "",
              lodAddr1: "",
              lodAddr2: "",
              lodAddr3: "",
              lodAddr4: "",
              lodDtlAddr: "",
            });
          }}
          handleClose={() => {
            setInfOpen(!infOpen);
          }}
        >
          {isLangOp.length > 0 && (
            <SmallSelect
              option={isLangOp[0]?.value ?? langCd}
              options={isLangOp}
              handleSelect={(target) => {
                const { value } = target.dataset;
                setLangCd(value ?? i18n.language);
              }}
            />
          )}
        </ITHeader>
        <div className={infOpen ? "" : "hidden"}>
          <ul>
            <li data-full>
              <label htmlFor="">숙소명</label>
              <input type="text" defaultValue={adInfo?.lodNm} readOnly />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">숙소설명</label>
              <ReadTuiEditor name={"lodDesc"} value={adInfo?.lodDesc} />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">우편번호</label>
              <input
                type="text"
                defaultValue={adList[0]?.lodZipc || adInfo?.lodZipc}
                readOnly
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">주소1</label>
              <input type="text" defaultValue={adInfo?.lodAddr1} readOnly />
            </li>
            <li>
              <label htmlFor="">주소2</label>
              <input type="text" defaultValue={adInfo?.lodAddr2} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">주소3</label>
              <input type="text" defaultValue={adInfo?.lodAddr3} readOnly />
            </li>
            <li>
              <label htmlFor="">주소4</label>
              <input type="text" defaultValue={adInfo?.lodAddr4} readOnly />
            </li>
          </ul>
        </div>
      </div>

      <Modal
        title={`추가정보 `}
        isOpen={isOpen}
        btnText={"추가"}
        styles="w-[90%]"
        handleCloseModal={handleClose}
        onClick={handleCreate}
      >
        <div className={"tb-form mt-[3rem]"}>
          <ul data-brd-top>
            <li>
              <label htmlFor="">언어선택</label>
              <Select
                id={"langCd"}
                name={"langCd"}
                option={adInf.langCd}
                options={addOp}
                isInitial
                onSelect={(target) => {
                  const { value } = target.dataset;
                  // setLangCd(value ?? i18n.language);
                  setAdInf({
                    ...adInf,
                    langCd: value ?? i18n.language,
                  });
                  // handleClose();
                }}
              />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">숙소명</label>
              <input
                type="text"
                value={adInf?.lodNm}
                onChange={(e) => handleChange("lodNm", e.currentTarget.value)}
              />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">숙소설명</label>
              <InTuiEditor
                name={"lodDesc"}
                value={adInf?.lodDesc}
                onChange={(value) => handleChange("lodDesc", value)}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">우편번호</label>
              <input
                type="text"
                value={adInf?.lodZipc || adInfo?.lodZipc}
                onChange={(e) => handleChange("lodZipc", e.currentTarget.value)}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">주소1</label>
              <input
                type="text"
                value={adInf?.lodAddr1}
                onChange={(e) =>
                  handleChange("lodAddr1", e.currentTarget.value)
                }
              />
            </li>
            <li>
              <label htmlFor="">주소2</label>
              <input
                type="text"
                value={adInf?.lodAddr2}
                onChange={(e) =>
                  handleChange("lodAddr2", e.currentTarget.value)
                }
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">주소3</label>
              <input
                type="text"
                value={adInf?.lodAddr3}
                onChange={(e) =>
                  handleChange("lodAddr3", e.currentTarget.value)
                }
              />
            </li>
            <li>
              <label htmlFor="">주소4</label>
              <input
                type="text"
                value={adInf?.lodAddr4}
                onChange={(e) =>
                  handleChange("lodAddr4", e.currentTarget.value)
                }
              />
            </li>
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default AdInfCreateView;
