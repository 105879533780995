import DatePick from "components/datePick";
import THeader from "components/layout/header/subHeader/tHeader";
import Select from "components/select";
import CountrySelect from "components/select/country";
import LodGrdSelect from "components/select/room/lodGrdSelect";
import LodGrpNmSelect from "components/select/room/lodGrpNmSelect ";
import LodGrpSelect from "components/select/room/lodGrpNoSelect";
import LodNmSelect from "components/select/room/lodNmSelect";
import LodNoSelect from "components/select/room/lodNoSelect";
import SearchSelect from "components/select/searchSelect";
import LodList from "components/tableList/lod/list";
import { lodSearchProps } from "interface/lod";
import { useLocation } from "react-router-dom";
import { lodGrpStatus, lodSellAmdvcOptions } from "util/option";

type IProps = {
  searchInfo: lodSearchProps;
  handleChange(name: string, value: string): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleReset(): void;
};

const LodListView = ({
  searchInfo,
  handleChange,
  handleSelect,
  handleReset,
}: IProps) => {
  const { pathname } = useLocation();
  const memType = pathname.includes("admin") ? "admin" : "manager";
  const searchOp = [
    {
      kr: "숙소명",
      value: "lodNm",
      en: "",
    },
  ];

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader
          sbTtl={"숙소조회"}
          rstBtn
          newBtn
          url={pathname}
          searchBtn
          handleReset={handleReset}
        />
        <div className="tb-form" data-list>
          <ul>
            {memType === "admin" && (
              <li>
                <label htmlFor="">숙소그룹명</label>
                <LodGrpNmSelect
                  option={searchInfo.lodGrpNo}
                  onSelect={(target) => handleSelect(target)}
                />
              </li>
            )}
            <li>
              <label htmlFor="">숙소명</label>
              <LodNmSelect
                isArrow={pathname.includes("manager")}
                disabled={pathname.includes("manager") || !searchInfo?.lodGrpNo}
                option={searchInfo?.lodNo}
                params={searchInfo?.lodGrpNo}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="">숙소등급</label>
              <LodGrdSelect
                option={searchInfo.lodGrdCd}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">숙소주소국가</label>
              <CountrySelect
                option={searchInfo.lodCntyCd}
                handleSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="">판매금액구분</label>
              <Select
                id="sellAmDvc"
                name="sellAmDvc"
                option={searchInfo.sellAmDvc || "A"}
                options={lodSellAmdvcOptions}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
            <li>
              <label htmlFor="">숙소상태</label>
              <Select
                id="uyn"
                name="uyn"
                options={lodGrpStatus}
                option={searchInfo.uyn || "A"}
                onSelect={(target) => handleSelect(target)}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">검색조건</label>
              <SearchSelect
                id={"searchTyp"}
                name={searchInfo.searchTyp ?? ""}
                options={searchOp}
                option={searchInfo.searchTyp || "A"}
                value={
                  searchInfo[
                    `${searchInfo.searchTyp}` as keyof lodSearchProps
                  ] || ""
                }
                onSelect={handleSelect}
                onChange={(target) => handleChange(target.name, target.value)}
              />
            </li>
            <li>
              <DatePick
                startNm={"contSDt"}
                endNm={"conteDt"}
                start={searchInfo.contSDt}
                end={searchInfo.contEDt}
                handleChange={(name, value) => handleChange(name, value)}
              />
            </li>
          </ul>
        </div>
      </div>

      <LodList memType={memType} searchInfo={searchInfo} />
    </>
  );
};

export default LodListView;
