import { useQuery } from "@tanstack/react-query";
import { lodAdListApi } from "components/api/lod";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import { IlodAdInf } from "interface/lod";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { langOptions } from "util/option";
import ReadTuiEditor from "components/tuiEditor/readEditor";
import { langProps } from "interface";
import SmallSelect from "components/select/smSelect";
import AddModal from "./modal/addModal";
import { ModifyModal } from "./modal/modifyModal";

const AdInfModifyView = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const lodNo = search.replace("?lodNo=", "");

  const query = useQuery({
    queryKey: ["lodAdList", lodNo],
    queryFn: () => {
      return lodAdListApi({
        lodNo: lodNo,
      });
    },
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
        }
        if (code === -1) {
          if (code === -1) {
            dispatch(
              setMessage({
                message: message || "검색 데이터가 없습니다.",
                isConfirm: true,
                handleClick() {
                  dispatch(setMessage({ message: null }));
                },
              })
            );
          }
        }
      }
    },
  });

  let adList = query?.data?.body?.lodAdList || [];
  let mdfyOp = [] as langProps[];
  let addOp = [] as langProps[];

  let langs = [] as langProps[];

  langOptions.forEach((lang) => {
    const isAble = adList.some((ad: IlodAdInf) => {
      return lang.value === ad.langCd;
    });

    if (isAble) {
      langs.push(lang);
    }
  });
  langOptions.forEach((lang) => {
    const isAble = adList.some((ad: IlodAdInf) => {
      return lang.value === ad.langCd;
    });

    if (isAble) {
      mdfyOp.push(lang);
    }
    if (!isAble) {
      addOp.push(lang);
    }
  });
  const [langCd, setLangCd] = useState(mdfyOp[0]?.value || "");
  let adInfo = adList?.find((ad: IlodAdInf) => {
    const lng = langCd || mdfyOp[0]?.value;
    return ad.langCd === lng;
  });

  const [isOpen, setIsOpen] = useState("");
  const [infOpen, setInfOpen] = useState(false);
  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"추가정보"}
          clsBtn
          mdfyBtn={mdfyOp.length > 0}
          addBtn={addOp.length > 0}
          addBtnTxt="언어추가"
          desc={
            langs.length > 1
              ? `언어를 선택하여 언어별 추가정보를 확인하세요.`
              : ""
          }
          isClose={infOpen}
          handleAdCreate={() => setIsOpen("add")}
          handleModify={() => setIsOpen("mdfy")}
          handleClose={() => {
            setInfOpen(!infOpen);
          }}
        >
          {adList.length > 0 && (
            <SmallSelect
              option={langCd}
              options={langs}
              handleSelect={(target) => {
                const { value } = target.dataset;
                if (!!value) {
                  setLangCd(value);
                }
              }}
            />
          )}
        </ITHeader>
        <div className={infOpen ? "" : "hidden"}>
          <ul>
            <li data-full>
              <label htmlFor="">숙소명</label>
              <input type="text" value={adInfo?.lodNm ?? ""} readOnly />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">숙소설명</label>
              <ReadTuiEditor name={"lodDesc"} value={adInfo?.lodDesc} />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">우편번호</label>
              <input type="text" value={adInfo?.lodZipc ?? ""} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">숙소주소1</label>
              <input type="text" value={adInfo?.lodAddr1 ?? ""} readOnly />
            </li>
            <li>
              <label htmlFor="">숙소주소2</label>
              <input type="text" value={adInfo?.lodAddr2 ?? ""} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">숙소주소3</label>
              <input type="text" value={adInfo?.lodAddr3 ?? ""} readOnly />
            </li>
            <li>
              <label htmlFor="">숙소주소4</label>
              <input type="text" value={adInfo?.lodAddr4 ?? ""} readOnly />
            </li>
          </ul>
        </div>
      </div>
      {isOpen === "add" && (
        <AddModal
          langCd={langCd}
          langOp={addOp}
          isOpen={isOpen}
          lodNo={lodNo}
          setLangCd={setLangCd}
          setIsOpen={setIsOpen}
        />
      )}
      {isOpen === "mdfy" && (
        <ModifyModal
          adList={adList}
          langCd={langCd}
          langOp={mdfyOp}
          isOpen={isOpen}
          lodNo={lodNo}
          setLangCd={setLangCd}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
};

export default AdInfModifyView;
