import { pageNavProps } from "interface";

type IProps = {
  pageNav: pageNavProps | null;
};

const SearchHeader = ({ pageNav }: IProps) => {
  return (
    <div className="table-title">
      <div className="flex items-end gap-4">
        <h3>조회내역</h3>
        <p>
          검색결과
          <span className="text-red-350 text-[14px]">
            총<b>{pageNav?.totalRows || 0}</b>개
          </span>
        </p>
      </div>
      <button></button>
    </div>
  );
};

export default SearchHeader;
