import { useMutation } from "@tanstack/react-query";
import { lodImgCreateApi } from "components/api/lod";
import { lodImgs } from "interface/lod";
import { MutableRefObject, useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";
import InImgView from "./inImgCreate";
import OutImgView from "./outImgCreate";
import { useSelector } from "react-redux";
import { isAbleSelector } from "store/lodSlice";
import ITHeader from "components/layout/header/subHeader/itemHeader";

type imgUrlProps = {
  name: string;
  url: string | ArrayBuffer | null;
};

type IProps = {
  lodNo: MutableRefObject<string>;
};

const LodImgView = ({ lodNo }: IProps) => {
  const dispatch = useDispatch();
  const [imgs, setImgs] = useState<lodImgs[]>([]);
  const [inImgs, setInImgs] = useState<lodImgs[]>([]);
  const [outImgs, setOutImgs] = useState<lodImgs[]>([]);
  const [isRegi, setIsRegi] = useState(false);
  const isAdAble = useSelector(isAbleSelector);
  const [isOpen, setIsOpen] = useState(false);

  const imgMutation = useMutation(lodImgCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "이미지를 등록하였습니다.",
              isConfirm: true,
              handleClick() {
                setIsRegi(true);
                dispatch(setMessage({ message }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCreate = () => {
    const imgs = [...inImgs, ...outImgs];

    if (imgs.length === 0) {
      return alert("이미지를 추가하여 주세요.");
    }
    const params = {
      lodNo: lodNo.current ?? "",
      lodImgList: imgs.map((img, index) => {
        return {
          ...img,
          rgSeq: index + 1,
          expsOrdV: index + 1,
        };
      }),
    };

    console.log("params", params);

    dispatch(
      setMessage({
        message: "이미지를 등록하겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          dispatch(setMessage({ message: null }));
          imgMutation.mutate(params);
        },
      })
    );
  };

  const isAble = isAdAble ? isRegi : !isAdAble;

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"이미지"}
          clsBtn
          isClose={isOpen}
          createBtn
          createBtnTxt="등록"
          desc="* 기본정보 등록후 추가정보 등록이 가능합니다."
          handleCreate={handleCreate}
          handleClose={() => {
            setIsOpen(!isOpen);
          }}
        />
        <div className={isOpen ? "" : "hidden"}>
          <ul>
            <li>
              <label htmlFor="">숙소내관</label>
              <InImgView
                inImgs={inImgs}
                setInImgs={setInImgs}
                lodNo={lodNo}
                isRegi={isRegi}
                isAble={isAble}
              />
            </li>
          </ul>
          <ul data-brd-top>
            <li>
              <label htmlFor="">숙소외관</label>
              <OutImgView
                outImgs={outImgs}
                setOutImgs={setOutImgs}
                lodNo={lodNo}
                isRegi={isRegi}
                isAble={isAble}
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default LodImgView;
