import RoomBookCancelListView from "components/view/room/book/cancel";
import { rmResvCnlSearchProps } from "interface/room";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setIsLoading, setIsSearch } from "store/commonSlice";
import { afterDay } from "util/common";
import { dateTypOptions } from "util/option";

const RoomBookCancelListPage = () => {
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const memType = pathname.includes("admin") ? "admin" : "manager";
  let lodNo = localStorage.getItem("lodNo") ?? "";
  let manInf = localStorage.getItem("manInf")
    ? JSON.parse(localStorage.getItem("manInf") ?? "")
    : null;

  const { sDt, eDt } = afterDay(7);

  const [searchInfo, setSearchInfo] = useState<rmResvCnlSearchProps>({
    lodGrpNo: manInf?.manLodGrpNo,
    lodNo: lodNo,
    lodGrpCd: state?.loGrpCd ?? "",
    rmNo: state?.rmNo ?? "",
    rmNm: state?.rmNm ?? "",
    rmTypCd: state?.rmTypCd ?? "",
    rmOlkCd: state?.rmOlkCd ?? "",
    minPerCt: state?.minPerCt ?? "",
    maxPerCt: state?.maxPerCt ?? "",
    rmPdNo: state?.rmPdNo ?? "",
    lodChlSeq: state?.locChlSeq ?? "",
    rmResvNo: state?.rmResvNo ?? "",
    resvCstMblCntyCd: state?.resvCstMblCntCd ?? "",
    resvCstMblNo: state?.resvCstMblNo ?? "",
    resvCstnm: state?.resvCstnm ?? "",
    mobCntyCd: state?.mobCntCd ?? "",
    memMobNo: state?.memMobNo ?? "",
    memFstnm: state?.memFstnm ?? "",
    resvCstFstnm: state?.resvCstFstnm ?? "",
    promCd: state?.memSurnm ?? "",
    promNm: state?.promNm ?? "",
    sellSDt: state?.sellSDt ?? sDt,
    sellEDt: state?.sellEDt ?? eDt,
    cnlSDt: state?.cnlSDt ?? sDt,
    cnlEDt: state?.cnlEDt ?? eDt,
    resvSDt: state?.resvSDt ?? sDt,
    resvEDt: state?.resvEDt ?? eDt,
    dateTyp: state?.dateTyp ?? dateTypOptions[0]?.value,
    searchTyp: state?.searchTyp ?? "",
  });

  const handleChange = (name: string, value: string) => {
    if (name === "SDt" || name === "EDt") {
      if (searchInfo?.dateTyp === "rmPdDt") {
        setSearchInfo({
          ...searchInfo,
          [`sell${name}`]: value,
        });

        return;
      }
      if (searchInfo?.dateTyp === "rmResvDt") {
        setSearchInfo({
          ...searchInfo,
          [`resv${name}`]: value,
        });
        return;
      }
      if (searchInfo?.dateTyp === "rmCnlResvDt") {
        setSearchInfo({
          ...searchInfo,
          [`cnl${name}`]: value,
        });
        return;
      }
    }

    setSearchInfo({
      ...searchInfo,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { lng, name, value } = target.dataset;
    if (name && value) {
      if (name === "dateTyp") {
        setSearchInfo({
          ...searchInfo,
          [name]: value,
          // sellEDt: "",
          // sellSDt: "",
          // resvEDt: "",
          // resvSDt: "",
          // cnlEDt: "",
          // cnlSDt: "",
        });
        return;
      }
      if (name === "lodGrpNo") {
        setSearchInfo({
          ...searchInfo,
          lodNo: "",
          rmNo: "",
          rmOlkCd: "",
          rmTypCd: "",
          [name]: value === "A" ? "" : value,
        });
        return;
      }

      setSearchInfo({
        ...searchInfo,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const handleReset = () => {
    setSearchInfo({
      lodGrpNo: manInf?.manLodGrpNo,
      lodNo: lodNo,
      lodGrpCd: "",
      rmNo: "",
      rmNm: "",
      rmTypCd: "",
      rmOlkCd: "",
      minPerCt: "",
      maxPerCt: "",
      rmPdNo: "",
      lodChlSeq: "",
      rmResvNo: "",
      resvCstMblCntyCd: "",
      resvCstMblNo: "",
      resvCstnm: "",
      mobCntyCd: "",
      memMobNo: "",
      memFstnm: "",
      resvCstFstnm: "",
      promCd: "",
      promNm: "",
      sellSDt: sDt,
      sellEDt: eDt,
      cnlSDt: sDt,
      cnlEDt: eDt,
      resvSDt: sDt,
      resvEDt: eDt,
      dateTyp: dateTypOptions[0]?.value,
    });
    dispatch(setIsLoading(false));
    dispatch(setIsSearch(false));
  };

  return (
    <>
      <RoomBookCancelListView
        memType={memType}
        searchInfo={searchInfo}
        handleChange={handleChange}
        handleSelect={handleSelect}
        handleReset={handleReset}
      />
    </>
  );
};

export default RoomBookCancelListPage;
