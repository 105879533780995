import { promnHead } from "util/option";
import { useLocation, useNavigate } from "react-router-dom";
import Pagenation from "components/pagenation";
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  isSearchSelector,
  setIsLoading,
  setIsSearch,
  setMessage,
} from "store/commonSlice";
import { pageNavProps } from "interface";
import SearchHeader from "components/layout/header/subHeader/srchHeader";
import { useMutation } from "@tanstack/react-query";
import { promListApi } from "components/api/promotion";
import { IPromList } from "interface/promotion";
import { dateFormat } from "util/common";

type IProps = {
  searchInfo: any;
};

type IListProps = {
  col: number;
  searchInfo: any;
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};

const List = ({ col, searchInfo, setPageNav }: IListProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();
  const isSearch = useSelector(isSearchSelector);
  const [list, setList] = useState<IPromList[]>([]);

  const promMutation = useMutation(promListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setPageNav(body?.pageNav);
          setList(body?.promList);
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch) {
      const { promCd, influNm, influCnty } = searchInfo;
      const params = {
        promCd: state?.promCd || promCd,
        influNm: state?.influNm || influNm,
        influCnty: state?.influCnty || influCnty,
        pageNo: page || state?.pageNo,
      };

      console.log("promotion params", params);

      promMutation.mutate(params);
    }
  }, [isSearch, searchInfo, search]);

  const pageRows = promMutation.data?.body?.pageNav?.pageRows;
  const isNotData = !pageRows;

  return (
    <>
      {list.length === 0 && (
        <tr>
          <td colSpan={col}>
            {isNotData && (
              <span className="font-bold">검색조건을 선택하여 주세요.</span>
            )}
            {!isNotData && (
              <span className="font-bold">검색결과가 없습니다.</span>
            )}
          </td>
        </tr>
      )}
      {list &&
        list.map((li: IPromList, index: number) => (
          <tr
            key={index}
            onClick={(e) => {
              e.preventDefault();
              navigate(`info?prmCd=${li.promCd}`, {
                state: {
                  searchInfo,
                  url: pathname + search,
                },
              });
              dispatch(setIsSearch(false));
            }}
          >
            <td>{(Number(page) - 1) * (pageRows || 10) + index + 1}</td>
            <td>{li.promCd}</td>
            <td>{li.influNm}</td>
            <td>{li.influEml}</td>
            <td>{li.influLangCd}</td>
            <td>{li.fstCrtDtti.split(".")[0]}</td>
          </tr>
        ))}
    </>
  );
};

const PromotionList = ({ searchInfo }: IProps) => {
  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const headList = promnHead.map((head) => {
    return head;
  });

  const col = promnHead.length;

  return (
    <div className="list-wrap">
      <SearchHeader pageNav={pageNav} />
      <div>
        <table>
          <thead>
            <tr>
              {headList.map((li) => (
                <th key={li}>{li}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <Suspense>
              <List col={col} searchInfo={searchInfo} setPageNav={setPageNav} />
            </Suspense>
            {pageNav && pageNav?.totalRows > 0 && (
              <tr>
                <td colSpan={col}>
                  <Pagenation total={pageNav?.totalPage} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PromotionList;
