import { useMutation } from "@tanstack/react-query";
import { termListAPi } from "components/api/term";
import SearchHeader from "components/layout/header/subHeader/srchHeader";
import Pagenation from "components/pagenation";
import { pageNavProps } from "interface";
import { IprivList } from "interface/term";
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  isSearchSelector,
  setIsLoading,
  setIsSearch,
  setMessage,
} from "store/commonSlice";
import { dateFormat } from "util/common";
import { termOptionHead } from "util/option";

type IProps = {
  searchInfo: {
    langCd: string;
    userType: string;
  };
};

type IListProps = {
  searchInfo: {
    langCd: string;
    userType: string;
  };
  col: number;
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};
const List = ({ col, searchInfo, setPageNav }: IListProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();

  const isSearch = useSelector(isSearchSelector);

  const [list, setList] = useState([]);

  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  const trmListMutation = useMutation(termListAPi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setList(body?.prvList);
          setPageNav(body?.prvList?.length ?? 0);
          dispatch(setIsSearch(false));
          dispatch(setIsLoading(false));
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  useEffect(() => {
    if (isSearch) {
      trmListMutation.mutate({
        userType: searchInfo.userType,
        langCd: searchInfo.langCd,
      });
    }
  }, [isSearch, searchInfo, search]);

  //   const pageRows = trmListMutation.data?.body?.pageNav?.pageRows;
  const isNotData = 10;

  return (
    <>
      {list.length === 0 && (
        <tr>
          <td colSpan={col}>
            {isNotData && (
              <span className="font-bold">검색조건을 선택하여 주세요.</span>
            )}
            {!isNotData && (
              <span className="font-bold">검색결과가 없습니다.</span>
            )}
          </td>
        </tr>
      )}
      {list &&
        list.map((li: IprivList, index) => {
          return (
            <tr
              key={index}
              data-brd
              onClick={() => {
                navigate(`info?prvCd=${li.prvCd}`, {
                  state: {
                    url: pathname + search,
                    langCd: searchInfo.langCd,
                  },
                });
              }}
            >
              <td>{index}</td>
              <td>{li?.prvNm}</td>
              <td>{li.prvCd}</td>
              <td>{li.prvDesc ?? "-"}</td>
              <td>{li.rgSeq}</td>
              <td>{li.verNm}</td>
              <td>{dateFormat(li.aplySDt ?? "")}</td>
              <td>{dateFormat(li.aplyEDt ?? "")}</td>
              <td>{li.essYn === "Y" ? "필수" : "선택"}</td>
              <td>젹용</td>
            </tr>
          );
        })}
    </>
  );
};

const TermList = ({ searchInfo }: IProps) => {
  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const col = termOptionHead.length;

  return (
    <div className="list-wrap">
      <SearchHeader pageNav={pageNav} />
      <div>
        <table>
          <thead>
            <tr>
              {termOptionHead.map((li) => (
                <th key={li}>{li}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <Suspense>
              <List col={col} searchInfo={searchInfo} setPageNav={setPageNav} />
            </Suspense>
            {pageNav && pageNav?.totalRows > 0 && (
              <tr>
                <td colSpan={col}>
                  <Pagenation total={pageNav?.totalPage} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TermList;
