import { useMutation, useQuery } from "@tanstack/react-query";
import {
  promCreateApi,
  promInfoApi,
  promModifyApi,
} from "components/api/promotion";
import PromModifyView from "components/view/promotion/modify";
import { promProps } from "interface/promotion";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";

const PromModifyPage = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const [info, setInfo] = useState<Omit<promProps, "promCd" | "promNm">>({
    influNm: "",
    influEml: "",
    influCnty: "",
    influLangCd: "",
    influMobCntyNo: "",
    influMobNo: "",
  });

  const handleChange = (name: string, value: string) => {
    setInfo({
      ...info,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { lng, name, value } = target.dataset;
    if (name && value) {
      if (name === "mobCntyCd") {
        setInfo({
          ...info,
          influMobCntyNo: value + "",
        });
      }
      if (name === "country") {
        setInfo({
          ...info,
          influCnty: value + "",
        });
      }
      setInfo({
        ...info,
        [name]: value,
      });
    }
  };

  const query = useQuery({
    queryKey: ["promDtl"],
    queryFn: () => {
      return promInfoApi(search.replace("?prmCd=", ""));
    },
  });

  const promInf = query.data?.body?.promDtl || null;
  const promMutation = useMutation(promModifyApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "프로모션정보를 수정완료 하였습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModify = () => {
    const isModify = Object.values(info).some((inf) => {
      return !inf;
    });

    if (
      isModify &&
      (promInf?.influNm === info.influNm ||
        promInf?.influEml === info.influEml ||
        promInf?.influMobNo === info.influMobNo ||
        promInf?.influMobCntyNo === info.influMobCntyNo ||
        promInf?.influCnty === info.influCnty ||
        promInf?.influLangCd === info.influLangCd)
    ) {
      return alert("프로모션 정보를 수정하여 주세요.");
    }

    console.log("params", {
      ...info,
      promCd: search.replace("?prmCd=", ""),
    });

    dispatch(
      setMessage({
        message: "프로모션 정보를 수정하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          promMutation.mutate({
            ...info,
            promCd: search.replace("?prmCd=", ""),
          });
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  return (
    <PromModifyView
      isLoading={false}
      info={info}
      promInf={promInf}
      handleSelect={handleSelect}
      handleChange={handleChange}
      handleModify={handleModify}
    />
  );
};

export default PromModifyPage;
