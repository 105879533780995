import { useMutation } from "@tanstack/react-query";
import { lodGrpAdInfCreate, lodGrpAdList } from "components/api/lod";
import InContet from "components/inContent";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import Modal from "components/modal/modal";
import InTuiEditor from "components/tuiEditor/inEditot";
import Select from "components/select";
import SmallSelect from "components/select/smSelect";
import { langProps } from "interface";
import { lodGrpAdInfProps } from "interface/lod";
import i18n from "locales/i18n";
import { MutableRefObject, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { isSuccessSelector, setIsSuccess, setMessage } from "store/commonSlice";
import { langOptions } from "util/option";
import ReadTuiEditor from "components/tuiEditor/readEditor";

type IProps = {
  lodGrpNo: MutableRefObject<string>;
};

const AdInfView = ({ lodGrpNo }: IProps) => {
  const dispatch = useDispatch();

  const isSuccess = useSelector(isSuccessSelector);
  const [adList, setList] = useState<Array<lodGrpAdInfProps>>([]);

  const adListMutation = useMutation(lodGrpAdList, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          const list = body.lodGrpAdList;
          setList(list);
          dispatch(setIsSuccess(false));
          setLangCd(list[0]?.langCd);
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  let isLangOp = [] as langProps[];
  let addOp = [] as langProps[];

  langOptions.forEach((lang) => {
    const isAble = adList?.some((ad) => {
      return lang.value === ad.langCd;
    });

    if (isAble) {
      isLangOp.push(lang);
    }
    if (!isAble) {
      addOp.push(lang);
    }
  });

  const [langCd, setLangCd] = useState(i18n.language);

  let adInfo = adList?.find((ad) => {
    return ad?.langCd === langCd;
  });

  const [adInf, setAdInf] = useState<lodGrpAdInfProps>({
    langCd: addOp[0]?.value || i18n.language,
    lodGrpNm: "",
    lodGrpDesc: "",
    lodGrpCntyCd: "KR",
    lodGrpZipc: adInfo?.lodGrpZipc ?? "",
    lodGrpAddr1: "",
    lodGrpAddr2: "",
    lodGrpAddr3: "",
    lodGrpAddr4: "",
  });

  const [isOpen, setIsOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(true);

  const handleChange = (name: string, value: string) => {
    setAdInf({
      ...adInf,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value, code } = target.dataset;

    if (name && value) {
      const adNm = name?.includes("country") ? "lodGrpCntyCd" : name;
      const adValue = name?.includes("country") ? code : value;
      if (name === "country") {
        setAdInf({
          ...adInf,
          [adNm]: adValue,
        });
        return;
      }
      setAdInf({
        ...adInf,
        [adNm]: adValue === "A" ? "" : adValue,
      });
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setAdInf({
      langCd: addOp[0].value,
      lodGrpNm: "",
      lodGrpDesc: "",
      lodGrpCntyCd: "KR",
      lodGrpZipc: "",
      lodGrpAddr1: "",
      lodGrpAddr2: "",
      lodGrpAddr3: "",
      lodGrpAddr4: "",
    });
  };

  // 추가 정보 등록
  const adInfMutation = useMutation(lodGrpAdInfCreate, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "추가정보를 등록하였습니다.",
              isConfirm: true,
              handleClick() {
                handleClose();
                dispatch(setIsSuccess(true));
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCreate = () => {
    if (!adInf.langCd) {
      return alert("추가할 언어를 선택하여 주세요");
    }

    const params = {
      ...adInf,
      lodGrpNo: lodGrpNo.current,
      lodGrpZipc: adInfo?.lodGrpZipc ?? adInf.lodGrpZipc,
    };

    console.log("params", params);

    dispatch(
      setMessage({
        message: "숙소그룹추가정보를 등록하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          adInfMutation.mutate(params);
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  useEffect(() => {
    if (isSuccess) {
      adListMutation.mutate({
        lodGrpNo: lodGrpNo.current,
        langCd: "",
      });
    }
    return () => {
      dispatch(setIsSuccess(false));
    };
  }, [isSuccess]);

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"추가정보"}
          clsBtn
          addBtn={langOptions.length > isLangOp.length}
          addBtnTxt="언어추가"
          isClose={addOpen}
          handleAdCreate={() => setIsOpen(true)}
          handleClose={() => {
            setAddOpen(!addOpen);
          }}
        >
          {isLangOp.length !== 0 && (
            <SmallSelect
              option={langCd ?? isLangOp[0]?.value}
              options={isLangOp}
              handleSelect={(target) => {
                const { value } = target.dataset;
                setLangCd(value ?? i18n.language);
              }}
            />
          )}
        </ITHeader>
        <div className={addOpen ? "" : "hidden"}>
          <ul>
            <li data-full>
              <label htmlFor="">숙소그룹명</label>
              <input type="text" defaultValue={adInfo?.lodGrpNm} readOnly />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">숙소그룹설명</label>
              <ReadTuiEditor name={"lodGrpDesc"} value={adInfo?.lodGrpDesc} />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">우편번호</label>
              <input
                type="text"
                defaultValue={adInfo?.lodGrpZipc ?? ""}
                readOnly
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">주소1</label>
              <input type="text" defaultValue={adInfo?.lodGrpAddr1} readOnly />
            </li>
            <li>
              <label htmlFor="">주소2</label>
              <input type="text" defaultValue={adInfo?.lodGrpAddr2} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">주소3</label>
              <input type="text" defaultValue={adInfo?.lodGrpAddr3} readOnly />
            </li>
            <li>
              <label htmlFor="">주소4</label>
              <input type="text" defaultValue={adInfo?.lodGrpAddr4} readOnly />
            </li>
          </ul>
        </div>
      </div>
      <div className={"table-form"} data-dir-col></div>
      <Modal
        title={"추가정보 등록"}
        isOpen={isOpen}
        styles="w-[90%]"
        btnText="등록"
        handleCloseModal={handleClose}
        onClick={handleCreate}
      >
        <div className="tb-form mt-[3rem]">
          <ul data-brd-top>
            <li>
              <label htmlFor="">언어선택</label>
              <Select
                id={"langCd"}
                name={"langCd"}
                option={adInf.langCd}
                options={addOp}
                isInitial
                onSelect={(target) => handleSelect(target)}
              />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">숙소그룹명</label>
              <InContet
                name={"lodGrpNm"}
                full
                onChange={(value) => {
                  handleChange("lodGrpNm", value);
                }}
                placeholder={"숙소그룹명을 입력하세요"}
              />
            </li>
          </ul>
          <ul>
            <li data-full>
              <label htmlFor="">숙소그룹설명</label>
              <InTuiEditor
                name={"lodGrpDesc"}
                onChange={(value) => {
                  handleChange("lodGrpDesc", value);
                }}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">숙소우편번호</label>
              <input
                type="text"
                defaultValue={adInfo?.lodGrpZipc || adInf.lodGrpZipc}
                onChange={(e) => {
                  handleChange("lodGrpZipc", e.currentTarget.value);
                }}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">주소1</label>
              <InContet
                name={"lodGrpAddr1"}
                full
                onChange={(value) => {
                  handleChange("lodGrpAddr1", value);
                }}
                placeholder={"주소1"}
              />
            </li>
            <li>
              <label htmlFor="">주소2</label>
              <InContet
                name={"lodGrpAddr2"}
                full
                onChange={(value) => {
                  handleChange("lodGrpAddr2", value);
                }}
                placeholder={"주소2"}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">주소3</label>
              <InContet
                name={"lodGrpAddr3"}
                full
                onChange={(value) => {
                  handleChange("lodGrpAddr3", value);
                }}
                placeholder={"주소3"}
              />
            </li>
            <li>
              <label htmlFor="">주소4</label>
              <InContet
                name={"lodGrpAddr4"}
                full
                onChange={(value) => {
                  handleChange("lodGrpAddr4", value);
                }}
                placeholder={"주소4"}
              />
            </li>
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default AdInfView;
