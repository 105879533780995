import DayCheckBox from "components/checkbox/dayChk";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import THeader from "components/layout/header/subHeader/tHeader";
import SmallSelect from "components/select/smSelect";
import ReadTuiEditor from "components/tuiEditor/readEditor";
import { langProps } from "interface";
import { IlodRefdDtl, IlodRfdAd } from "interface/lod";
import i18n from "locales/i18n";
import { useState } from "react";
import { dateFormat } from "util/common";
import { langOptions } from "util/option";

type IProps = {
  refdDtl: IlodRefdDtl;
  refdAdList: Array<IlodRfdAd>;
};

// 숙소환불수수료생성 - 숙소환불수수료율기본
const LodRfdInfoView = ({ refdDtl, refdAdList }: IProps) => {
  let langs = [] as langProps[];
  langOptions.forEach((lang) => {
    const isAble = refdAdList.some((ad) => {
      return lang.value === ad.langCd;
    });

    if (isAble) {
      langs.push(lang);
    }
  });

  const [langCd, setLangCd] = useState(i18n.language);

  let adInfo = refdAdList?.find((ad) => {
    return ad.langCd === langCd;
  });

  const [infOpen, setInfOpen] = useState(true);
  const [addOpen, setAddOpen] = useState(true);

  return (
    <>
      <div className="main-cont" data-dir-col>
        <THeader
          sbTtl={"숙소환불수수료율기본 상세"}
          mdfyBtn
          clsBtn
          handleReset={() => {}}
        />
        <div className="tb-form">
          <ITHeader
            sbTtl={"기본정보"}
            clsBtn
            isClose={infOpen}
            handleClose={() => {
              setInfOpen(!infOpen);
            }}
          />
          <div className={infOpen ? "" : "hidden"}>
            <ul>
              <li>
                <label htmlFor="">숙소그룹명</label>
                <input type="text" defaultValue={refdDtl?.lodGrpNm} readOnly />
              </li>
              <li>
                <label htmlFor="">숙소명</label>
                <input type="text" defaultValue={refdDtl?.lodNm} readOnly />
              </li>
              <li>
                <label htmlFor="">숙소등급</label>
                <input type="text" defaultValue={refdDtl?.lodGrdCd} readOnly />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">기본수수료율</label>
                <input
                  type="text"
                  defaultValue={
                    refdDtl?.feeTypCd === "B01"
                      ? "기본수수료율"
                      : refdDtl?.feeTypCd === "S01"
                      ? "성수기수수료"
                      : ""
                  }
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">적용시작일자</label>
                <input
                  type="text"
                  defaultValue={dateFormat(refdDtl?.aplySDt)}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">적용종료일자</label>
                <input
                  type="text"
                  defaultValue={dateFormat(refdDtl?.aplyEDt)}
                  readOnly
                />
              </li>
            </ul>

            <ul>
              <li>
                <label htmlFor="">적용 숙박일기준</label>
                <input
                  type="text"
                  defaultValue={refdDtl?.aplyChkDay}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">적용 숙박일기준시간</label>
                <input
                  type="text"
                  defaultValue={
                    refdDtl?.aplyTi.replace(/\B(?=(\d{2})+(?!\d))/g, ":") ?? ""
                  }
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">적용 수수료율</label>
                <input type="text" defaultValue={refdDtl?.feeRti} readOnly />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="'">등록일시</label>
                <input
                  type="text"
                  defaultValue={dateFormat(refdDtl?.fstCrtDtti)}
                  readOnly
                />
              </li>
              <li>
                <label htmlFor="">등록회원명</label>
                <input type="text" defaultValue={refdDtl?.ltChDtti} readOnly />
              </li>
              <li>
                <label htmlFor="">상태</label>
                <input
                  type="text"
                  defaultValue={refdDtl?.uyn === "Y" ? "사용" : "미사용"}
                  readOnly
                />
              </li>
            </ul>
            <ul>
              <li>
                <label htmlFor="">적용요일</label>
                <div className="w-full flex">
                  <DayCheckBox
                    id="MON"
                    name="day"
                    label="MON"
                    checked={refdDtl?.monAplyYn === "Y"}
                    readOnly
                    onChange={() => {}}
                  />
                  <DayCheckBox
                    id="TUE"
                    name="day"
                    label="TUE"
                    checked={refdDtl?.tuesAplyYn === "Y"}
                    readOnly
                    onChange={() => {}}
                  />
                  <DayCheckBox
                    id="WED"
                    name="day"
                    label="WED"
                    checked={refdDtl?.wenAplyYn === "Y"}
                    readOnly
                    onChange={() => {}}
                  />
                  <DayCheckBox
                    id="THU"
                    name="day"
                    label="THU"
                    checked={refdDtl?.thurAplyYn === "Y"}
                    readOnly
                    onChange={() => {}}
                  />
                  <DayCheckBox
                    id="FRI"
                    name="day"
                    label="FRI"
                    checked={refdDtl?.friAplyYn === "Y"}
                    readOnly
                    onChange={() => {}}
                  />
                  <DayCheckBox
                    id="SAT"
                    name="day"
                    label="SAT"
                    checked={refdDtl?.satAplyYn === "Y"}
                    readOnly
                    onChange={() => {}}
                  />
                  <DayCheckBox
                    id="SUN"
                    name="day"
                    label="SUN "
                    checked={refdDtl?.sunAplyYn === "Y"}
                    readOnly
                    onChange={() => {}}
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="tb-form">
          <ITHeader
            sbTtl={"추가정보"}
            clsBtn
            desc={
              langs.length > 1
                ? `언어를 선택하여 언어별 추가정보를 확인하세요.`
                : ""
            }
            isClose={addOpen}
            handleClose={() => {
              setAddOpen(!addOpen);
            }}
          >
            {langs.length > 0 && (
              <SmallSelect
                option={langCd}
                options={langs}
                handleSelect={(target) => {
                  const { value } = target.dataset;
                  setLangCd(value ?? i18n.language);
                }}
              />
            )}
          </ITHeader>
          <div className={addOpen ? "" : "hidden"}>
            <ul>
              <li data-full>
                <label htmlFor="">환불명</label>
                <input type="text" value={adInfo?.refdInfNm ?? ""} readOnly />
              </li>
            </ul>
            <ul>
              <li data-full>
                <label htmlFor="">환불설명</label>
                <ReadTuiEditor name={"refdDesc"} value={adInfo?.refdDesc} />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default LodRfdInfoView;
