import { Editor } from "@toast-ui/react-editor";
import { useEffect, useRef } from "react";
import "@toast-ui/editor/toastui-editor.css";
import "./index.scss";

type Props = {
  name: string;
  value: string | undefined;
  content?: string;
  //   onChange(value: string): void;
};

type IOptions = { id: string; label: string };

const toolbar = [
  ["heading", "bold", "italic", "strike"],
  ["hr", "quote"],
  ["ul", "ol", "task", "indent", "outdent"],
  ["table", "image", "link"],
  ["code", "codeblock"],
  ["scrollSync"],
];

const ReadTuiEditor = ({ name, value = "", content }: Props) => {
  const editorRef = useRef<Editor>(null);

  useEffect(() => {
    if (editorRef.current) {
      const html = value ?? "<p></p>";
      editorRef.current?.getInstance().setHTML(html);
    }
  }, [value]);

  useEffect(() => {
    if (editorRef.current) {
      const element = editorRef.current.getRootElement() as HTMLDivElement;
      element.style.pointerEvents = "none";
      element.style.cursor = "no-drop";
      element.autofocus = false;
    }

    return () => {};
  }, []);

  return (
    <div className="flex-1 py-1">
      <Editor
        initialValue={content ?? " "}
        initialEditType="wysiwyg"
        autofocus={false}
        ref={editorRef}
        toolbarItems={toolbar}
        hideModeSwitch
        language="ko"
        minHeight="44px"
        height="auto"

        // onBlur={() => {
        //   const data = editorRef.current?.getInstance().getHTML();
        //   onChange(data ?? "");
        // }}
        //   hooks={{ addImageBlobHook: imageHandler }}
      />
    </div>
  );
};

export default ReadTuiEditor;
