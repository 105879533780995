import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { lodRefdDtl, lodRefdInfModify } from "components/api/lod";
import DayCheckBox from "components/checkbox/dayChk";
import DatePick from "components/datePick";
import ITHeader from "components/layout/header/subHeader/itemHeader";
import Select from "components/select";
import { IlodRfd } from "interface/lod";
import i18n from "locales/i18n";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { dateFormat } from "util/common";
import { feeTypeOptions, lastTimeOptions, uYnOptions } from "util/option";

const InfModifyView = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { search, state } = useLocation();

  const lodNo = search.replace("?lodNo=", "");

  const query = useQuery({
    queryKey: ["lodRfdDtl", lodNo],
    queryFn: () => {
      return lodRefdDtl({
        lodNo,
        langCd: i18n.language,
        rgSeq: state?.rgSeq,
      });
    },
  });

  let refdDtl = query?.data?.body?.lodRefdDtl;

  const [inf, setInf] = useState<Omit<IlodRfd, "lodNo" | "langCd" | "rgSeq">>({
    uyn: "",
    refdFeeTypCd: "",
    feeTypCd: "",
    feeRti: "",
    aplyChkDay: "",
    aplyTi: "",
    thurAplyYn: "",
    friAplyYn: "",
    satAplyYn: "",
    sunAplyYn: "",
    fstCrtDtti: "",
    ltChDtti: "",
    wenAplyYn: "",
    tuesAplyYn: "",
    monAplyYn: "",
    aplySDt: "",
    aplyEDt: "",
  });

  const [infOpen, setInfOpen] = useState(true);

  const handleChange = (name: string, value: string) => {
    setInf({
      ...inf,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setInf({
        ...inf,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const mdfyMutation = useMutation(lodRefdInfModify, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "숙소환불수수료기본정보를 수정하였습니다.",
              handleClick() {
                queryClient.invalidateQueries({
                  queryKey: ["lodRfdDtl", lodNo],
                });
                dispatch(setMessage({ message: null }));
                setInf({
                  uyn: "",
                  refdFeeTypCd: "",
                  feeTypCd: "",
                  feeRti: "",
                  aplyChkDay: "",
                  aplyTi: "",
                  thurAplyYn: "",
                  friAplyYn: "",
                  satAplyYn: "",
                  sunAplyYn: "",
                  fstCrtDtti: "",
                  ltChDtti: "",
                  wenAplyYn: "",
                  tuesAplyYn: "",
                  monAplyYn: "",
                  aplySDt: "",
                  aplyEDt: "",
                });
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModify = () => {
    const isAble = Object.values(inf).some((inf) => {
      return !!inf;
    });

    if (!isAble) {
      return alert("기본정보를 수정하여 주세요.");
    }

    const params = {
      ...inf,
      lodNo,
      rgSeq: refdDtl?.rgSeq,
      langCd: i18n.language,
      aplyTi: inf.aplyTi.replaceAll(":", ""),
      aplySDt: inf.aplySDt.replaceAll("-", ""),
      aplyEDt: inf.aplyEDt.replaceAll("-", ""),
    };

    console.log("params", params);

    dispatch(
      setMessage({
        message: "숙소환불수수료기본정보를 수정하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          mdfyMutation.mutate(params);
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  return (
    <>
      <div className="tb-form">
        <ITHeader
          sbTtl={"기본정보"}
          clsBtn
          mdfyBtn
          isClose={infOpen}
          handleModify={handleModify}
          handleClose={() => {
            setInfOpen(!infOpen);
          }}
        />
        <div className={infOpen ? "" : "hidden"}>
          <ul>
            <li>
              <label htmlFor="">숙소그룹명</label>
              <input type="text" defaultValue={refdDtl?.lodGrpNm} readOnly />
            </li>
            <li>
              <label htmlFor="">숙소명</label>
              <input type="text" defaultValue={refdDtl?.lodNm} readOnly />
            </li>
            <li>
              <label htmlFor="">숙소등급</label>
              <input type="text" defaultValue={refdDtl?.lodGrdCd} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">등록일시</label>
              <input
                type="text"
                defaultValue={dateFormat(refdDtl?.fstCrtDtti)}
                readOnly
              />
            </li>
            <li>
              <label htmlFor="">등록회원</label>
              <input type="text" defaultValue={refdDtl?.fstCrtUsid} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">수정일시</label>
              <input
                type="text"
                defaultValue={dateFormat(refdDtl?.ltChDtti)}
                readOnly
              />
            </li>
            <li>
              <label htmlFor="">수정회원</label>
              <input type="text" defaultValue={refdDtl?.ltChUsid} readOnly />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">숙박일기준</label>
              <input
                type="text"
                defaultValue={inf.aplyChkDay || refdDtl?.aplyChkDay}
                onChange={(e) =>
                  handleChange("aplyChkDay", e.currentTarget.value)
                }
              />
            </li>
            <li>
              <label htmlFor="">숙박일기준시간</label>
              <Select
                id={"aplyTi"}
                name={"aplyTi"}
                option={
                  inf.aplyTi ||
                  refdDtl?.aplyTi.replace(/\B(?=(\d{2})+(?!\d))/g, ":")
                }
                options={lastTimeOptions}
                onSelect={handleSelect}
              />
            </li>
            <li>
              <label htmlFor="">수수료율</label>
              <input
                type="text"
                defaultValue={inf.feeRti || refdDtl?.feeRti}
                onChange={(e) => handleChange("feeRti", e.currentTarget.value)}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">기본수수료종류</label>
              <Select
                id={"feeTypCd"}
                name={"feeTypCd"}
                wrapStyle="w-[12.5rem]"
                option={inf.feeTypCd || refdDtl?.feeTypCd}
                options={feeTypeOptions}
                onSelect={handleSelect}
              />
            </li>
            <li>
              <DatePick
                startNm={"aplySDt"}
                endNm={"aplyEDt"}
                start={inf.aplySDt || dateFormat(refdDtl?.aplySDt)}
                end={inf.aplyEDt || dateFormat(refdDtl?.aplyEDt)}
                handleChange={(name, value) => handleChange(name, value)}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">상태</label>
              <Select
                id={"uyn"}
                name={"uyn"}
                option={inf.uyn}
                options={uYnOptions}
                isInitial
                onSelect={handleSelect}
              />
            </li>
          </ul>
          <ul>
            <li>
              <label htmlFor="">적용요일</label>
              <div className="w-full flex">
                <DayCheckBox
                  id="MON"
                  name="day"
                  label="MON"
                  checked={(inf.monAplyYn || refdDtl?.monAplyYn) === "Y"}
                  onChange={(target) => {
                    handleChange("monAplyYn", target.checked ? "Y" : "N");
                  }}
                />
                <DayCheckBox
                  id="TUE"
                  name="day"
                  label="TUE"
                  checked={(inf.tuesAplyYn || refdDtl?.tuesAplyYn) === "Y"}
                  onChange={(target) =>
                    handleChange("tuesAplyYn", target.checked ? "Y" : "N")
                  }
                />
                <DayCheckBox
                  id="WED"
                  name="day"
                  label="WED"
                  checked={(inf.wenAplyYn || refdDtl?.wenAplyYn) === "Y"}
                  onChange={(target) =>
                    handleChange("wenAplyYn", target.checked ? "Y" : "N")
                  }
                />
                <DayCheckBox
                  id="THU"
                  name="day"
                  label="THU"
                  checked={(inf.thurAplyYn || refdDtl?.thurAplyYn) === "Y"}
                  onChange={(target) =>
                    handleChange("thurAplyYn", target.checked ? "Y" : "N")
                  }
                />
                <DayCheckBox
                  id="FRI"
                  name="day"
                  label="FRI"
                  checked={(inf.friAplyYn || refdDtl?.friAplyYn) === "Y"}
                  onChange={(target) =>
                    handleChange("friAplyYn", target.checked ? "Y" : "N")
                  }
                />
                <DayCheckBox
                  id="SAT"
                  name="day"
                  label="SAT"
                  checked={(inf.satAplyYn || refdDtl?.satAplyYn) === "Y"}
                  onChange={(target) =>
                    handleChange("satAplyYn", target.checked ? "Y" : "N")
                  }
                />
                <DayCheckBox
                  id="SUN"
                  name="day"
                  label="SUN"
                  checked={(inf.sunAplyYn || refdDtl?.sunAplyYn) === "Y"}
                  onChange={(target) =>
                    handleChange("sunAplyYn", target.checked ? "Y" : "N")
                  }
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default InfModifyView;
