import { useMutation, useQueries } from "@tanstack/react-query";
import { opDtl, opsApi } from "components/api/lod";
import LodPrdInfoView from "components/view/lod/prd/info";
import { IopDft, IopList } from "interface/lod";
import i18n from "locales/i18n";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";

const LodPrdInfoPage = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const lodNo = search.replace("?lodNo=", "");

  const dtlMutation = useMutation(opDtl, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
        }

        if (code === -1) {
          dispatch(
            setMessage({
              message: message || "검색 데이터가 없습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  const query = useQueries({
    queries: [
      {
        queryKey: ["LOP"],
        queryFn: () => {
          return opsApi("LOP");
        },
      },
      {
        queryKey: ["ROP"],
        queryFn: () => {
          return opsApi("ROP");
        },
      },
      {
        queryKey: ["ETC"],
        queryFn: () => {
          return opsApi("ETC");
        },
      },
    ],
  });

  useEffect(() => {
    dtlMutation.mutate({
      lodNo,
      langCd: i18n.language,
      opCtgCd: "",
    });
  }, [search]);

  const dtl = dtlMutation.data?.body.lodDtl as IopDft;
  const lopList = dtlMutation.data?.body?.lopList;
  const ropList = dtlMutation.data?.body.ropList as IopList[];
  const etcList = dtlMutation.data?.body.etcList as IopList[];

  const lops = query[0].data?.body?.ops || [];
  const rops = query[1].data?.body?.ops || [];
  const etcs = query[2].data?.body?.ops || [];

  return (
    <LodPrdInfoView
      dtl={dtl}
      lopList={lopList}
      etcList={etcList}
      ropList={ropList}
      lops={lops}
      rops={rops}
      etcs={etcs}
    />
  );
};

export default LodPrdInfoPage;
